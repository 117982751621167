import { generateProductLinkedData } from 'lib/metatags/product';
import {
  extractVideoAssetFromGalleryAssets,
  generateProductVideoObject,
} from 'lib/metatags/video';
import { pageRoutes } from 'lib/routes';
import { displayUrlToCanonicalUrl } from 'lib/routes/utils';

import { AssetFkaImage } from 'types/app';
import {
  HierarchicalCategories,
  OptionType,
  ProductPdpFragment,
  ProductVariantPdpFragment,
  ShoppingStatus,
} from 'types/generated/api';

export interface GetLDSchemaProps {
  product: Pick<
    ProductPdpFragment,
    | 'brand'
    | 'brandSlug'
    | 'familySlug'
    | 'description'
    | 'hierarchicalCategories'
    | 'sid'
    | 'slug'
    | 'title'
    | 'variants'
  >;
  reviewsSummary?: { averageScore: number; totalReview: number } | null;
  selectedColor?: string;
  selectedVariant?: ProductVariantPdpFragment;
  url: string;
  visibleGalleryAssets?: AssetFkaImage[];
}

export const getLDSchema = ({
  product,
  reviewsSummary,
  selectedVariant,
  url,
  visibleGalleryAssets,
}: GetLDSchemaProps) => {
  const loadedProduct = product;
  const description = selectedVariant?.description || product?.description;

  // ImageAsset and VideoAsset can safely be cast as AssetFKA
  const representativeVideoAsset =
    extractVideoAssetFromGalleryAssets(visibleGalleryAssets);

  const videoLinkedData = representativeVideoAsset
    ? generateProductVideoObject({
        product,
        video: representativeVideoAsset as AssetFkaImage,
      })
    : undefined;

  const linkedData = generateProductLinkedData({
    ...loadedProduct,
    brand: loadedProduct?.brand || '',
    description: description || '',
    galleryAssets: visibleGalleryAssets,
    hierarchicalCategories: loadedProduct?.hierarchicalCategories,
    name: product?.title,
    price: selectedVariant?.price || undefined,
    reviewsBottomLine: reviewsSummary || undefined,
    selectedVariant: selectedVariant || undefined,
    shoppingStatus: selectedVariant?.shoppingStatus as ShoppingStatus,
    sku: selectedVariant?.sku,
    url,
    variantAvailability: loadedProduct?.variants.map(variant => ({
      inStock: variant.inStock || false,
      isLowStock: variant.isLowStock || false,
      shoppingStatus: variant.shoppingStatus || ShoppingStatus.UNAVAILABLE,
      sid: variant.sid,
      sku: variant.sku,
    })),
    video: videoLinkedData,
  });
  return linkedData;
};

export const encodeColorAttributeValueForURL = (colorValue: string): string => {
  if (!colorValue) {
    return '';
  }

  // convert spaces and other non-chars to dash sign
  const formattedColorValue = colorValue.split(/\W/).join('-');

  // for everything else use standard encoding
  return encodeURIComponent(formattedColorValue.toLowerCase());
};

export const getColorAttributeForURL = (
  product: Pick<
    ProductPdpFragment,
    'hierarchicalCategories' | 'options' | 'sid'
  >,
  variant?: Pick<ProductVariantPdpFragment, 'selectedOptions' | 'sid'>
): string | null => {
  let colorValue;
  if (!product) {
    // unexpected input, fail gracefully
    return null;
  }

  // first, get product color attribute, if available:
  const productColorOption = product?.options?.find(
    option => option.type === OptionType.COLOR
  );

  if (productColorOption && variant) {
    const variantColorOption = variant?.selectedOptions?.find(
      option => option.name === productColorOption.name
    );

    colorValue = variantColorOption?.value;
  }

  if (!colorValue) {
    colorValue = productColorOption?.values?.[0]?.value || '';
  }

  return encodeColorAttributeValueForURL(colorValue);
};

export const getProductInternalLinkUrl = (
  product: Pick<
    ProductPdpFragment,
    | 'brandSlug'
    | 'familySlug'
    | 'hierarchicalCategories'
    | 'options'
    | 'sid'
    | 'slug'
  >,
  selectedColorValue?: string,
  variant?: Pick<ProductVariantPdpFragment, 'selectedOptions' | 'sid'>,
  supressColorAttribute?: boolean,
  includeVariantIdAttribute?: boolean
) => {
  const colorAttribute = supressColorAttribute
    ? undefined
    : selectedColorValue || getColorAttributeForURL(product, variant);
  const sizeAttribute = includeVariantIdAttribute ? variant?.sid : undefined;

  const destinationUrlParts = pageRoutes.productDetail.displayUrl({
    brandSlug: product.brandSlug,
    familySlug: product.familySlug,
    productSid: product.sid,
    productSlug: product.slug,
    queryStringParams: {
      color: colorAttribute,
      variant_id: variant?.sid,
    },
  });

  let productInternalLinkUrl = destinationUrlParts?.pathname;

  if (colorAttribute) {
    productInternalLinkUrl += '?color=' + colorAttribute;
  }

  if (sizeAttribute) {
    productInternalLinkUrl +=
      (productInternalLinkUrl.indexOf('?') > 0
        ? '&variant_id='
        : '?variant_id=') + sizeAttribute;
  }

  return productInternalLinkUrl?.toLowerCase();
};

// QueryStringTwo values will override queryStringOne when they are the same
export const mergeQueryParams = (
  queryStringOne?: string,
  queryStringTwo?: string
): string => {
  let resultQueryString: string | undefined = '';
  // first, validate inputs:
  if (!queryStringOne) {
    resultQueryString = queryStringTwo;
  }

  if (!queryStringTwo) {
    resultQueryString = queryStringOne;
  }

  if (!resultQueryString) {
    const map = new Map<string, string>();
    for (const pair of queryStringOne?.split('&') || []) {
      const [key, value] = pair.split('=');
      map.set(key, value);
    }

    for (const pair of queryStringTwo?.split('&') || []) {
      const [key, value] = pair.split('=');
      map.set(key, value);
    }

    map.forEach((value, key) => {
      const attribute = key;
      const operatorPrefix = `${resultQueryString}`.length > 0 ? '&' : '';
      if (attribute?.length > 0 && value?.length > 0) {
        resultQueryString = `${resultQueryString}${operatorPrefix}${attribute}=${value}`;
      }
    });
  }

  return resultQueryString || '';
};

export const checkShouldRedirect = (
  currentUrlString: string, // relative URL
  product: Pick<
    ProductPdpFragment,
    'hierarchicalCategories' | 'options' | 'sid' | 'slug'
  >,
  selectedVariant?: Pick<ProductVariantPdpFragment, 'selectedOptions' | 'sid'>
): string | null => {
  if (!currentUrlString || !product) {
    return null;
  }

  const [currentPathname, currentQueryString = null] =
    currentUrlString.split('?');

  const currentSearchParamsString =
    currentUrlString?.indexOf('?') > 0 ? currentUrlString?.split('?')[1] : '';
  const currentQueryParams = new URLSearchParams(currentSearchParamsString);

  const internalLinkUrl = getProductInternalLinkUrl(
    product,
    currentQueryParams?.get('color') || undefined,
    selectedVariant
  );

  const [internalLinkUrlPathname, internalLinkUrlQueryString = null] =
    internalLinkUrl.split('?');

  if (decodeURIComponent(currentPathname) !== internalLinkUrlPathname) {
    const newQueryString = mergeQueryParams(
      currentQueryString || '',
      internalLinkUrlQueryString || ''
    );
    const queryStringPrefix = newQueryString?.length > 0 ? '?' : '';
    const newUrl = internalLinkUrlPathname + queryStringPrefix + newQueryString;
    return newUrl;
  }

  // later, this will also handle redirecting duplicate URLs

  return null;
};

const selectedLvl2Categories = [
  'Women > Accessories > Jewelry'.toUpperCase(),
  'Men > Accessories > Jewelry'.toUpperCase(),
];

export const isInCanonicalColorTestGroup = (
  hierarchicalCategories: HierarchicalCategories
) => {
  return hierarchicalCategories?.lvl2?.some(
    hCategory => selectedLvl2Categories.indexOf(hCategory?.toUpperCase()) >= 0
  );
};

export const getProductCanonicalUrl = (
  product: Pick<
    ProductPdpFragment,
    'hierarchicalCategories' | 'options' | 'variants' | 'sid' | 'slug'
  >,
  selectedColor?: string,
  selectedVariant?: Pick<ProductVariantPdpFragment, 'selectedOptions' | 'sid'>
): string | null => {
  if (!product) {
    return null;
  }

  const supressColorAttribute = !isInCanonicalColorTestGroup(
    product.hierarchicalCategories
  );

  return displayUrlToCanonicalUrl(
    getProductInternalLinkUrl(
      product,
      selectedColor,
      selectedVariant,
      supressColorAttribute /* supressColorAttribute per ECH2-1224 */,
      false /* includeVariantIdAttribute */
    ),
    {},
    false
  );
};
