import { Document } from '@contentful/rich-text-types';
import { Entry } from 'contentful';

import {
  client,
  ContentfulContentTypeEnum,
  UltimateBannerModuleEntry,
} from './index';

import Logger from 'lib/utils/Logger';

export type PDPModel = {
  headerBanner?: UltimateBannerModuleEntry;
  priceGuarantee: Document;
};
export type PDPEntry = Entry<PDPModel>;

const PDP_ID = '1IwMyfjpj1r5dvDz2aJAXf';

export const loadPDP = async (): Promise<PDPModel | undefined> => {
  try {
    const response = await client.getEntries<PDPModel>({
      content_type: ContentfulContentTypeEnum.pdp,
      include: 3,
      limit: 1,
      'sys.id': PDP_ID,
    });

    if (!response || !response.items) {
      Logger.error('Error could not find pdp entry in contentful');
      return;
    }

    return {
      headerBanner: response?.items?.[0]?.fields?.headerBanner,
      priceGuarantee: response?.items?.[0]?.fields?.priceGuarantee,
    };
  } catch (error) {
    Logger.error(`Error retrieving pdp entry in contentful: ${error}`);
    return;
  }
};
