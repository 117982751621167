import isEmpty from 'lodash/isEmpty';
import urlParse from 'url-parse';

import getConfig from 'config/config';

import { REFINEMENT_SEPARATOR } from 'lib/algolia/utils';

import { PageRoute, QueryParams } from './types';

/**
 * Query parameters for refinements which have been identified
 * to reflect a user's purchasing intention. If it is the only refinement
 * enabled, it should be used in the canonical link.
 */
const SHOPPING_INTENT_REFINEMENTS = [
  'dynamicFacets.dresses_neckline',
  'dynamicFacets.dress_style',
  'dynamicFacets.dresses_print',
  'dynamicFacets.dresses_material',
  'dynamicFacets.dress_sleeve_length',
  'dynamicFacets.dress_skirt_length',
  'dynamicFacets.coat_style',
  'dynamicFacets.coat_material',
  'dynamicFacets.top_style',
  'dynamicFacets.sweater_sweatershirt_style',
  'dynamicFacets.sweater_sweatshirt_material',
  'dynamicFacets.skin_type',
  'dynamicFacets.skin_concern',
  'dynamicFacets.dress_occasion',
  'dynamicFacets.neckline',
  'dynamicFacets.print_family',
  'dynamicFacets.sleeve_length',
];

/**
 * Whitelist query parameters for canonical url
 * * This lets google distinguish when query params will affect page content
 * * We don't want google to index pages with random query params like `utm_source` or `fbclid`
 */
const QUERY_PARAM_WHITELIST = [
  'ai',
  'at',
  'brand',
  'color',
  'max',
  'min',
  'page',
  'q',
  'responsible',
  'sc0',
  'sc1',
  'sc2',
  'sc3',
  'variant_id',
  'showDiscontinued',
  'filterables.shopBy',
  ...SHOPPING_INTENT_REFINEMENTS,
];

const sortAndWhitelistQueryParamsAsString = (
  queryParams: QueryParams,
  disallowMultipleQueryParams: boolean
): string => {
  const returnableQueryParams: QueryParams = {};
  let numberOfQueryParam = 0;

  Object.keys(queryParams)
    .sort()
    .forEach((key: string) => {
      if (QUERY_PARAM_WHITELIST.includes(key)) {
        numberOfQueryParam++;
        returnableQueryParams[key] = queryParams[key];
      }
    });

  if (disallowMultipleQueryParams && numberOfQueryParam > 1) {
    return '';
  }

  return new URLSearchParams(returnableQueryParams).toString();
};

export const getPageQueryParam = (urlString: string) => {
  try {
    const url = new URL(urlString);
    const searchParams = new URLSearchParams(url.search);
    return Number(searchParams.get('page'));
  } catch (error) {
    // debugger;
  }
};

/**
 * Returns a canonical URL for a passed in display pageRoute.
 * @displayUrl - a 'display' url from lib/routes
 * @extraQueryParams - query params we want to tack onto the URL
 */

export const displayUrlToCanonicalUrl = (
  displayUrl: string | PageRoute,
  extraQueryParams: QueryParams = {},
  disallowMultipleQueryParams = false,
  baseUrlOverride: string | undefined = undefined
): string => {
  let relativeUrl;
  let queryParams;

  switch (typeof displayUrl) {
    case 'string':
      relativeUrl = displayUrl;
      queryParams = extraQueryParams;
      break;

    case 'object': // of type PageRoute
      relativeUrl = displayUrl.pathname;
      queryParams = {
        ...displayUrl.query,
        ...extraQueryParams,
      };
      break;

    default:
      throw new Error(
        `Unknown displayUrl type encountered: ${typeof displayUrl}`
      );
  }

  const baseUrl = baseUrlOverride ?? getConfig('baseUrl');
  const absoluteUrl = `${baseUrl}${relativeUrl}`;
  const sanitizedQueryParams = sortAndWhitelistQueryParamsAsString(
    queryParams,
    disallowMultipleQueryParams
  );

  return sanitizedQueryParams
    ? `${absoluteUrl}?${sanitizedQueryParams}`
    : absoluteUrl;
};

export const extractQueryParams = (currentUrl: string) =>
  urlParse(currentUrl, true).query as QueryParams;

/***
 * Extracts product SID from the URL address, removing "p"
 * Example input is: www.verishop.com/native-youth/tops/grohl-t-shirt/p4558706868363?color=turquoise
 */
export const getProductSidFromUrl = (urlString: string): string | null => {
  // Match productSid on p<number of at least 10 digits> to be more specific and avoid
  // potential brand or product family names of format p<digit>
  const productSid = /\/p(\d{10,})/i.exec(urlString);
  if (!isEmpty(productSid) && productSid && productSid.length > 1) {
    return productSid?.[1] || null; // Group 1 will have digits only
  }

  // If unable to extract product SID from the URL string, return null;
  return null;
};

/**
 * Excepts a set of query parameters and reduces to only the parameters which should be used in canonical links.
 * If a filter parameter reflects a user's purchasing intent and is the only filter applied, it should be used in the canonical.
 * If more than one filter is being applied, no parameters should be used.
 * @ticket https://verishop.atlassian.net/browse/ECH2-519
 */
export const reduceQueryParamsForCanonicalUrl = (queryParams: QueryParams) => {
  const { page, q, ...restParams } = queryParams;
  const reducedQueryParams = { ...restParams };
  const canonicalFilters = [
    'color',
    'responsible',
    'brand',
    'filterables.shopBy',
    ...SHOPPING_INTENT_REFINEMENTS,
  ];

  // Do not alter search page params
  // Note: since this is limited to category page, this will not apply
  if (q) {
    return queryParams;
  }

  const qualifyingParams = Object.keys(reducedQueryParams).filter(param =>
    canonicalFilters.includes(param)
  );

  // If there is only one refinement type being applied
  if (qualifyingParams.length === 1) {
    Object.keys(restParams).forEach(key => {
      // if there is only one refinement option for that type, return it
      // Otherwise, remove it
      if (
        canonicalFilters.includes(key) &&
        restParams[key]?.split(REFINEMENT_SEPARATOR).length > 1
      ) {
        delete reducedQueryParams[key];
      }
    });
  } else {
    return { ...(page && { page }) };
  }

  // page query param should always passed through when present
  return { ...reducedQueryParams, ...(page && { page }) };
};
