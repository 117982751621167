import { useRouter } from 'next/router';
import { useEffect, ReactNode, useReducer, useCallback } from 'react';
import urlParse from 'url-parse';

import { trackPageView } from 'lib/analytics';
import {
  getCurrentAuthenticatedUser,
  getCurrentSession,
  getUserProviderName,
} from 'lib/auth';
import AuthContext, {
  AuthContextProps,
  SetCurrentUserIdProps,
} from 'lib/context/AuthContext';

type AuthReducerState = {
  currentUserId?: string;
  email?: string;
  error?: string;
  isLoading: boolean;
  providerName?: string;
};

enum AuthActionsTypes {
  CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR',
  CLEAR_USER_DATA = 'CLEAR_USER_DATA',
  SET_AUTH_ERROR = 'SET_AUTH_ERROR',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_USER_DATA = 'SET_USER_DATA',
}

type AuthReducerAction =
  | { type: AuthActionsTypes.SET_USER_DATA; value: AuthReducerState }
  | { type: AuthActionsTypes.SET_AUTH_ERROR; value: string }
  | { type: AuthActionsTypes.CLEAR_USER_DATA }
  | { type: AuthActionsTypes.SET_IS_LOADING; value: boolean }
  | { type: AuthActionsTypes.CLEAR_AUTH_ERROR };

const authReducer = (state: AuthReducerState, action: AuthReducerAction) => {
  switch (action.type) {
    case AuthActionsTypes.SET_USER_DATA: {
      return {
        ...state,
        ...action.value,
        error: undefined,
      };
    }
    case AuthActionsTypes.CLEAR_USER_DATA: {
      return {
        ...state,
        currentUserId: undefined,
        email: undefined,
        isLoading: false,
      };
    }
    case AuthActionsTypes.SET_AUTH_ERROR: {
      return {
        ...state,
        error: action.value,
      };
    }
    case AuthActionsTypes.CLEAR_AUTH_ERROR: {
      return {
        ...state,
        error: undefined,
      };
    }
    case AuthActionsTypes.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.value,
      };
    }
    default:
      return state;
  }
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const setCurrentUserData = useCallback(
    ({
      currentUserId,
      email,
      error,
      isLoading,
      providerName,
    }: SetCurrentUserIdProps) => {
      dispatch({
        type: AuthActionsTypes.SET_USER_DATA,
        value: {
          currentUserId,
          email,
          error,
          isLoading,
          providerName,
        },
      });
    },
    []
  );

  const clearCurrentUserData = useCallback((): void => {
    dispatch({ type: AuthActionsTypes.CLEAR_USER_DATA });
  }, []);

  const clearAuthError = useCallback(() => {
    dispatch({ type: AuthActionsTypes.CLEAR_AUTH_ERROR });
  }, []);

  const setAuthError = useCallback((value: string) => {
    dispatch({ type: AuthActionsTypes.SET_AUTH_ERROR, value });
  }, []);

  const setIsLoading = useCallback((value: boolean) => {
    dispatch({ type: AuthActionsTypes.SET_IS_LOADING, value });
  }, []);

  const authContextInitState: AuthReducerState = {
    currentUserId: undefined,
    error: undefined,
    isLoading: false,
  };

  const router = useRouter();
  const [authState, dispatch] = useReducer(authReducer, authContextInitState);

  const initUser = async () => {
    try {
      await getCurrentSession();
      const user = await getCurrentAuthenticatedUser();
      const { email, sub: userId } = user.attributes;
      dispatch({
        type: AuthActionsTypes.SET_USER_DATA,
        value: {
          currentUserId: userId,
          email,
          isLoading: false,
          providerName: getUserProviderName(user),
        },
      });
    } catch {
      // user is not signed in
    }
  };

  useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {
      const parsedUrl = urlParse(url);
      const properties = {
        path: parsedUrl.pathname,
        search: parsedUrl.query,
        url: parsedUrl.href,
      };
      trackPageView({ properties });
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    initUser();
    trackPageView();

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  const authContextValue: AuthContextProps = {
    ...authState,
    clearAuthError,
    clearCurrentUserData,
    setAuthError,
    setCurrentUserData,
    setIsLoading,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
