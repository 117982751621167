import noop from 'lodash/noop';
import React, { useContext } from 'react';

export type AuthContextProps = {
  clearAuthError: () => void;
  clearCurrentUserData: () => void;
  currentUserId?: string;
  email?: string;
  error?: string;
  isLoading: boolean;
  providerName?: string;
  setAuthError(errorMessage: string): void;
  setCurrentUserData: ({
    currentUserId,
    email,
    error,
    isLoading,
    providerName,
  }: SetCurrentUserIdProps) => void;
  setIsLoading(isLoading: boolean): void;
};

export type SetCurrentUserIdProps = {
  currentUserId: string;
  email: string;
  error?: string;
  isLoading: boolean;
  providerName?: string;
};

const AuthContext = React.createContext<AuthContextProps>({
  clearAuthError: noop,
  clearCurrentUserData: noop,
  currentUserId: undefined,
  isLoading: false,
  setAuthError: noop,
  setCurrentUserData: noop,
  setIsLoading: noop,
});

export const useAuthContext = (): AuthContextProps => {
  return useContext(AuthContext);
};

export default AuthContext;
