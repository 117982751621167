import * as AuthProxy from '@verishop/verishop-auth-proxy';
import { CognitoUser } from '@verishop/verishop-auth-proxy';

// import { logoutFromShopify } from './utils/shopify';
import getConfig from 'config/config';
import { IS_DEVELOPMENT } from 'config/utils/env';
import { Logger } from 'test/integration/utils/Logger';

export const configureAuth = () => {
  const authApiUrl = IS_DEVELOPMENT
    ? '/api/auth'
    : `${getConfig('baseUrl')}/api/auth`;

  AuthProxy.ConfigureAuthProxy({
    apiUrl: authApiUrl,
  });
};

export const signOut = async (options?: { global?: boolean }) => {
  try {
    await AuthProxy.signOut(options);

    /**
     * We need to redirect to this URL so that the OAuth token will get revoked.
     * For example, if the user was signed in with Google, then it will revoke
     * the Google OAuth token so that if the user tries to sign back in using Google
     * it won't automatically choose the previously signed in Google account
     * and instead will show the Google account picker so they can choose the account
     * they want to sign up/in with.
     *
     * Also getConfig('cognito.redirectSignOut') will redirect the user to the Shopify
     * checkout url, after going to the cognito logout url, so that the user will clear
     * their Shopify session as well.
     */
    const logoutUrl = `${getConfig('cognito.signOut')}?client_id=${getConfig(
      'cognito.userPoolWebClientId'
    )}&logout_uri=${getConfig('cognito.redirectSignOut')}`;
    window.location.href = logoutUrl;
  } catch (error) {
    throw new Error('Something went wrong signing out.');
  }
};

export const checkIsUserLoggedIn = async (): Promise<boolean> => {
  try {
    await getCurrentSession();
    return true;
  } catch {
    return false;
  }
};

export const getCurrentAuthenticatedUser = (): Promise<CognitoUser> => {
  return AuthProxy.getCurrentAuthenticatedUser();
};

export const afterSignInHook = (): Promise<void> => {
  return AuthProxy.sendAndSetAuthCookies();
};

export const getCurrentSession = () => {
  return AuthProxy.getCurrentSession();
};

export const changePassword = (oldPassword: string, newPassword: string) => {
  return AuthProxy.changPassword(oldPassword, newPassword);
};

type CognitoIdentityProvider = {
  dateCreated: number;
  issuer?: string | null;
  primary: boolean;
  providerName: string;
  providerType: string;
  userId: string;
};

export const getUserProviderName = (user: CognitoUser) => {
  const identities = user?.attributes?.identities;
  if (identities) {
    try {
      const primaryIndentity = JSON.parse(identities)?.find(
        (identity: CognitoIdentityProvider) => identity.primary
      );
      return primaryIndentity.providerName;
    } catch {
      return;
    }
  }
};

export const getUserIdFromUser = (user: CognitoUser): string => {
  const userId = user?.attributes?.sub;

  if (!userId) {
    Logger.error(`was not able to find userId for ${JSON.stringify(user)}`);
    return '';
  }

  return userId;
};

export { UnauthorizedError } from '@verishop/verishop-auth-proxy';
