import {
  ExperimentValueResolver,
  ExperimentValues,
} from './experimentValueResolver';
import { ExperimentValueResolverProvider } from './index';

import { RecommendedObjectValue } from 'types/app';

export enum PdpRecommendationModelId {
  AllInOne = 'all_in_one',
}

export enum PdpRecommendationModelVariantId {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
  V4 = 'v4',
}

export const DEFAULT_RECOMMENDATION_MODEL_ID =
  PdpRecommendationModelId.AllInOne;
export const DEFAULT_RECOMMENDATION_MODEL_VARIANT_ID =
  PdpRecommendationModelVariantId.V4;
const PDP_RECOMMENDATION_SECTION_NAME = "There's more to explore";

const generateConfig = (
  modelId: string,
  modelVariantId: string,
  recommendationSectionTitle: string
): RecommendedObjectValue => {
  return {
    modelId,
    modelVariantId,
    recommendationSectionTitle,
  };
};

const DEFAULT_PDP_RECOMMENDATION_CONFIG: RecommendedObjectValue =
  generateConfig(
    DEFAULT_RECOMMENDATION_MODEL_ID,
    DEFAULT_RECOMMENDATION_MODEL_VARIANT_ID,
    PDP_RECOMMENDATION_SECTION_NAME
  );

enum PdpRecommendationConfigNames {
  modelId = 'modelId',
  modelVariantId = 'modelVariantId',
}

export class PdpRecommendationConfigResolver extends ExperimentValueResolver<RecommendedObjectValue> {
  static provider(): ExperimentValueResolverProvider {
    return (rawValues?: ExperimentValues) =>
      new PdpRecommendationConfigResolver(rawValues);
  }

  constructor(rawValues?: ExperimentValues) {
    super(
      DEFAULT_PDP_RECOMMENDATION_CONFIG,
      'pdpRecommendationConfig',
      rawValues
    );
  }

  value(): RecommendedObjectValue {
    if (!this.rawValues) {
      return DEFAULT_PDP_RECOMMENDATION_CONFIG;
    }

    let modelId;
    let modelVariantId;
    for (const tuple of this.rawValues) {
      if (!tuple) {
        continue;
      }
      const { key, value } = tuple;
      switch (key) {
        case PdpRecommendationConfigNames.modelId:
          modelId = DEFAULT_RECOMMENDATION_MODEL_ID;
          if (
            (Object.values(PdpRecommendationModelId) as string[]).includes(
              value
            )
          ) {
            modelId = value;
          }
          break;
        case PdpRecommendationConfigNames.modelVariantId:
          modelVariantId = DEFAULT_RECOMMENDATION_MODEL_VARIANT_ID;
          if (
            (
              Object.values(PdpRecommendationModelVariantId) as string[]
            ).includes(value)
          ) {
            modelVariantId = value;
          }
          break;
      }
    }
    if (!modelId || !modelVariantId) {
      return DEFAULT_PDP_RECOMMENDATION_CONFIG;
    }
    return generateConfig(
      modelId,
      modelVariantId,
      PDP_RECOMMENDATION_SECTION_NAME
    );
  }
}
