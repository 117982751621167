import {
  ExperimentValueResolver,
  ExperimentValues,
} from './experimentValueResolver';
import { ExperimentValueResolverProvider } from './index';

import { RecommendedObjectValue } from 'types/app';

export enum PDPRecommendationSectionConfigName {
  categoryCreatorV1 = 'rec_eng_category_creator_v1',
  collaborativeFilteringAttributesV1 = 'rec_eng_attributes_v1',
  collaborativeFilteringBrowsingV1 = 'rec_eng_browsing_v1',
  visenzeCompleteTheLookV1 = 'visenze_ctl_v1',
  visenzeSimilarityV1 = 'visenze_similarity_v1',
  visenzeSimilarityV2 = 'visenze_similarity_v2',
}

const generateConfig = (
  modelId: string,
  modelVariantId: string,
  recommendationSectionTitle: string
): RecommendedObjectValue => {
  return {
    modelId,
    modelVariantId,
    recommendationSectionTitle,
  };
};

export const PDPRecommendationSectionConfig = {
  [PDPRecommendationSectionConfigName.collaborativeFilteringAttributesV1]:
    generateConfig(
      'rec_eng_you_might_also_like',
      'attributes_v1',
      'You may also like'
    ),
  [PDPRecommendationSectionConfigName.collaborativeFilteringBrowsingV1]:
    generateConfig(
      'rec_eng_you_might_also_like',
      'browsing_and_attributes_v1',
      'Others are looking at'
    ),
  [PDPRecommendationSectionConfigName.categoryCreatorV1]: generateConfig(
    'rec_eng_category_creator',
    'v1',
    'Top recommendation by creators'
  ),
  [PDPRecommendationSectionConfigName.visenzeCompleteTheLookV1]: generateConfig(
    'complete_the_look_from_visenze',
    'v1',
    'You may style with'
  ),
  [PDPRecommendationSectionConfigName.visenzeSimilarityV1]: generateConfig(
    'image_similarity_search_from_visenze',
    'v1',
    'Products similar to this item'
  ),
  [PDPRecommendationSectionConfigName.visenzeSimilarityV2]: generateConfig(
    'image_similarity_search_from_visenze',
    'v2',
    'Products similar to this item'
  ),
};

export const DEFAULT_VISENZE_RECOMMENDATION_CONFIG: RecommendedObjectValue[] = [
  PDPRecommendationSectionConfig[
    PDPRecommendationSectionConfigName.collaborativeFilteringAttributesV1
  ],
  PDPRecommendationSectionConfig[
    PDPRecommendationSectionConfigName.visenzeSimilarityV2
  ],
  PDPRecommendationSectionConfig[
    PDPRecommendationSectionConfigName.collaborativeFilteringBrowsingV1
  ],
];

export class VisenzeRecommendationConfigResolver extends ExperimentValueResolver<
  RecommendedObjectValue[]
> {
  static provider(): ExperimentValueResolverProvider {
    return (rawValues?: ExperimentValues) =>
      new VisenzeRecommendationConfigResolver(rawValues);
  }

  constructor(rawValues?: ExperimentValues) {
    super(
      DEFAULT_VISENZE_RECOMMENDATION_CONFIG,
      'visenzeRecommendationConfig',
      rawValues
    );
  }

  parseScalarValue(rawValue: string): RecommendedObjectValue[] {
    const config: RecommendedObjectValue[] = [];
    const configNames = rawValue.split(',');
    for (const name of configNames) {
      if (name) {
        const configName = name.trim();
        if (Object.keys(PDPRecommendationSectionConfig).includes(configName)) {
          const configEnum = configName as PDPRecommendationSectionConfigName;
          config.push(PDPRecommendationSectionConfig[configEnum]);
        }
      }
    }
    if (!config.length) {
      return DEFAULT_VISENZE_RECOMMENDATION_CONFIG;
    }
    return config;
  }
}
