export default {
  affirm: {
    publicApiKey: process.env.AFFIRM_PUBLIC_API_KEY,
    scriptUrl: process.env.AFFIRM_SCRIPT_URL,
  },
  algolia: {
    appId: process.env.ALGOLIA_APP_ID,
    indexName: process.env.ALGOLIA_INDEX_NAME,
    indexNameBrands: process.env.ALGOLIA_INDEX_NAME_BRANDS,
    indexNameContentOwner: process.env.ALGOLIA_INDEX_NAME_CONTENT_OWNER,
    indexNameSearchableNavigations:
      process.env.ALGOLIA_INDEX_NAME_SEARCHABLE_NAVIGATIONS,
    searchKey: process.env.ALGOLIA_SEARCH_KEY,
  },
  appVersion: process.env.BUILD_VERSION,
  baseUrl: process.env.BASE_URL,
  bugsnag: {
    apiKey: process.env.BUGSNAG_API_KEY,
  },
  cognito: {
    domain: process.env.COGNITO_OAUTH_DOMAIN,
    redirectSignInFromLoginPage: `${process.env.BASE_URL}/login?loading=true`,
    redirectSignInFromSignupPage: `${process.env.BASE_URL}/signup?loading=true`,
    redirectSignOut: 'https://checkout.verishop.xyz/account/logout',
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',
    signOut: 'https://auth.verishop.xyz/logout',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.TF_CUSTOMERS_COGNITO_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.TF_CUSTOMERS_COGNITO_FRONTEND_CLIENT_ID,
  },
  contentful: {
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    entryIdHomepage: process.env.CONTENTFUL_ENTRY_ID_HOMEPAGE,
    spaceId: process.env.CONTENTFUL_SPACE_ID,
  },
  // REQUIRED - GraphQL settings:
  graphql: {
    shopify: {
      headers: {
        'X-Shopify-Storefront-Access-Token':
          process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
      },
      uri: process.env.SHOPIFY_GRAPHQL_URI,
    },
    verishop: {
      uri: process.env.VERISHOP_GRAPHQL_URI,
    },
  },
  internalBaseUrl:
    process.env.INTERNAL_BASE_URL ??
    `https://internal-api.${process.env.BASE_URL?.split('.')
      .splice(1)
      .join('.')}`,
  powerReviews: {
    apiKey: process.env.POWER_REVIEWS_API_KEY,
    merchantGroupId: process.env.POWER_REVIEWS_MERCHANT_GROUP_ID,
    merchantId: process.env.POWER_REVIEWS_MERCHANT_ID,
  },
  reCaptcha: {
    siteKey: process.env.RECAPTCHA_SITE_KEY,
  },
  returnly: {
    returnURL: process.env.RETURNLY_URL,
  },
  tracking: {
    gtmSourceId: process.env.GTM_SOURCE_ID,
    segmentSourceId: process.env.SEGMENT_SOURCE_ID,
  },
  xMinds: {
    databaseId: 'iUTxnb1b75FgaENyc0Chig',
    publicAccessKey: '6cW9BTYqRpLri6X',
    serviceLoginId: 'verishop-fe-prod',
  },
  yotpo: {
    rewardsModuleId: '528675',
    rewardsModuleScriptId: '0ZSwP42LLlClt7HG6PkJkA',
  },
};
