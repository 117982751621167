import to from 'await-to-js';
import { createClient } from 'contentful';
import get from 'lodash/get';

import getConfig from 'config/config';

const accessToken = getConfig('contentful.accessToken');
const spaceId = getConfig('contentful.spaceId');

export enum ContentfulContentTypeEnum {
  brand = 'brand',
  cartPage = 'cartPage',
  category = 'category',
  categoryLandingPage = 'categoryLandingPage',
  collection = 'collection',
  dynamicPage = 'dynamicPage',
  editorial = 'editorial',
  homePage = 'homePage',
  localizableLink = 'localizableLink',
  markdown = 'markdown',
  moduleBanner = 'moduleBanner',
  moduleBrandsLogos = 'moduleBrandsLogos',
  moduleButtonRow = 'moduleButtonRow',
  moduleDoubleImageBanner = 'moduleDoubleImageBanner',
  moduleEditorialHeroBanner = 'moduleEditorialHeroBanner',
  moduleGridLayout = 'moduleEditorialGrid',
  moduleHeroBanner = 'moduleHeroBanner',
  moduleImageCarousel = 'moduleImageCarousel',
  moduleImageHolder = 'moduleImageHolder',
  moduleImageLink = 'moduleImageLink',
  moduleLayoutHorizontalStagger = 'moduleLayoutHorizontalStagger',
  moduleLayoutSplitView = 'moduleLayoutSplitView',
  moduleMultiLinkBanner = 'moduleMultiLinkBanner',
  moduleProductCarousel = 'moduleProductCarousel',
  moduleRelatedLinks = 'moduleRelatedLinks',
  moduleRichText = 'moduleRichText',
  moduleShoppablePhoto = 'moduleShoppablePhoto',
  moduleShoppablePhotoItem = 'moduleShoppablePhotoItem',
  moduleShortBanner = 'moduleShortBanner',
  moduleSkinnyBanner = 'moduleSkinnyBanner',
  moduleSplitImageBanner = 'moduleSplitImageBanner',
  moduleStackingProductBlocks = 'moduleStackingProductBlocks',
  moduleStackingTiles = 'moduleStackingTiles',
  moduleStaggeredSections = 'moduleStaggeredSections',
  moduleTallHeroBanner = 'moduleTallHeroBanner',
  moduleTastemakerCarousel = 'moduleTastemakerCarousel',
  moduleTextBanner = 'moduleTextBanner',
  moduleTileGrid = 'moduleTileGrid',
  moduleUltimateBanner = 'ultimateBanner',
  moduleVideoBanner = 'moduleVideoBanner',
  orderPage = 'orderPage',
  pdp = 'pdp',
  recommendations = 'recommendations',
  richTextModal = 'richTextModal',
  siteFurniture = 'siteFurniture',
  sizeGuide = 'sizeGuidePick',
  youTubeVideoEmbedded = 'youTubeVideoEmbedded',
}

export const CONTENTFUL_LAYOUT_MODULES = [
  ContentfulContentTypeEnum.moduleLayoutHorizontalStagger,
  ContentfulContentTypeEnum.moduleLayoutSplitView,
];

export const client = createClient({
  accessToken,
  removeUnresolved: true,
  space: spaceId,
});

export const loadSizeGuide = async (brandSlug: string) => {
  const [error, response] = await to(
    client.getEntries({
      content_type: ContentfulContentTypeEnum.sizeGuide,
      'fields.slug': brandSlug,
      include: 2,
      limit: 1,
    })
  );

  if (error) {
    throw new Error(
      `Error retrieving size guide for slug ${brandSlug}: ${error}`
    );
  }

  return get(response, 'items[0].fields', null);
};

export * from './_androidComingSoonModule';
export * from './_appDownloadInterstitial';
export * from './_brand';
export * from './_cartPage';
export * from './_category';
export * from './_categoryLandingPage';
export * from './_collection';
export * from './_dynamicPage';
export * from './_editorialPage';
export * from './_footer';
export * from './_homePage';
export * from './_iosOnlyFeatureModule';
export * from './_modules';
export * from './_navMenu';
export * from './_pdp';
export * from './_plpDefaultFilters';
export * from './_plpMarketingCard';
export * from './_recommendations';
export * from './_shared';
export * from './_siteFurniture';
export * from './_siteWideBanner';
export * from './_staticPages';
export * from './_tastemakers';
export * from './_ultimateBannerModule';
export * from './_videoBanner';
