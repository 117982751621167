import { ApolloClient, useApolloClient } from '@apollo/client';

import { useAssociateUserWithAnonymousId } from './useAssociateUserWithAnonymousId';
import { useSendAnonymousIdToApi } from './useSendAnonymousIdToApi';
import { GET_ME, GetMeResponse } from 'data/graphql/queries';

import { useIdentifyAndExperimentsToSegment } from 'lib/analytics/useIdentifyAndExperimentsToSegment';
import { useAuthContext } from 'lib/context/AuthContext';
import { getAnonymousUserId } from 'lib/multiStorageWrapper/multiStorageWrapper';
import {
  YOTPO_USER_ID_KEY,
  YOTPO_USER_EMAIL_KEY,
} from 'lib/page/multiStorageScript';
import Logger from 'lib/utils/Logger';

/**
 * This component is responsible for identifying the anonymous/current user to various services (internal and external)
 */
const Identifier = (): null => {
  const anonymousUserId = getAnonymousUserId();
  const { currentUserId, email } = useAuthContext();
  const apolloClient = useApolloClient();

  // Check if user is logged in
  if (!!currentUserId && !!email) {
    getAndSaveLoggedInCustomerInfoToLocalStorageIfNeeded(apolloClient); // This is needed to identify the user for the loyalty program
  }

  if ((!currentUserId && email) || (currentUserId && !email)) {
    Logger.warn(
      `Conflicting states for userId and email: ${currentUserId} <=> ${email}, for ${anonymousUserId}`
    );
  }

  useSendAnonymousIdToApi(anonymousUserId);

  useAssociateUserWithAnonymousId(anonymousUserId, currentUserId);

  useIdentifyAndExperimentsToSegment(
    anonymousUserId,
    currentUserId && email
      ? {
          email,
          userId: currentUserId,
        }
      : undefined
  );

  return null;
};

const getAndSaveLoggedInCustomerInfoToLocalStorageIfNeeded = async (
  apolloClient: ApolloClient<object>
) => {
  // No need to save again if the information is saved already
  const storedUserId = window.VerishopMultiStorage.getItem(YOTPO_USER_ID_KEY);
  if (storedUserId) {
    return;
  }
  const meResponse = await apolloClient.query<GetMeResponse>({
    fetchPolicy: 'network-only',
    query: GET_ME,
  });
  const yotpoId = meResponse.data?.me?.yotpoId ?? '';
  const emailAddress = meResponse.data?.me?.email ?? '';
  window.VerishopMultiStorage.setItem(YOTPO_USER_ID_KEY, yotpoId);
  window.VerishopMultiStorage.setItem(YOTPO_USER_EMAIL_KEY, emailAddress);
};

export default Identifier;
