import { pageRoutes } from 'lib/routes';

type LoginLinkProps = {
  redirectUrl?: string | string[] | undefined;
} & React.LinkHTMLAttributes<HTMLAnchorElement>;

const LoginLink = ({ children, redirectUrl, ...rest }: LoginLinkProps) => {
  const displayUrl = pageRoutes.auth.login.displayUrl({
    redirectUrl: redirectUrl,
  });

  return (
    <a href={displayUrl} {...rest}>
      {children}
    </a>
  );
};

export default LoginLink;
