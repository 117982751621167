/**
 * This file contains definitions for schema.org entities via JSON-LD
 */

import isEmpty from 'lodash/isEmpty';
import { ReactElement } from 'react';
import { JsonLd } from 'react-schemaorg';
import { BuyAction, Product as JsonLdProduct, VideoObject } from 'schema-dts';

import { generateOffer } from './offer';
import { generateAggregateRating } from './review';
import { getCategoryString } from './utils';

import { ASSET_TYPES } from 'lib/asset';
import { resizeImage } from 'lib/image';
import { isBrowser } from 'lib/utils/browser';
import Logger from 'lib/utils/Logger';

import { ProductDetailState, ShoppingStatus } from 'data/graphql/types';
import { VariantAvailability } from 'types/api';
import { AssetFkaImage } from 'types/app';
import { ProductPdpFragment } from 'types/generated/api';

export type GenerateProductLinkedDataProps = Omit<
  ProductPdpFragment,
  | 'categories'
  | 'hierarchicalCategories'
  | 'options'
  | 'sid'
  | 'title'
  | 'variants'
> & {
  galleryAssets?: AssetFkaImage[];
  hierarchicalCategories?: ProductPdpFragment['hierarchicalCategories'];
  name?: string;
  price?: number | null;
  /** Pass in Review Summary here */
  reviewsBottomLine?: {
    summary: { averageScore: number; totalReview: number };
  };
  selectedVariant?: ProductDetailState['selectedVariant'];

  shoppingStatus?: ShoppingStatus | null;
  sku?: string;
  url: string;
  variantAvailability?: VariantAvailability[];
  video?: VideoObject;
};

/*
 * To add as we get more data:
 *  - AggregateRating
 *  - Review Collection
 *  - Product dimensions: weight/width/height
 */

/**
 * Renders the raw LD+JSON object for Schema.org validation.
 * If the value of this function is truthy, pass the value into
 * `<ProductLinkedData>` (can be exported from the same file here)
 * and pass the value as the `product` prop. For easier debugging
 * and troubleshooting, you can add `__VS_PDP_SHOW_SCHEMA` to your
 * localStorage keys with the value of `true` to render the schema in
 * plain text. You can copy and paste this schema code [`here`](https://validator.schema.org/)
 * to validate for errors or warnings
 * @returns ReactNode
 */
export const generateProductLinkedData = (
  pdpProductFragment?: GenerateProductLinkedDataProps
): JsonLdProduct | undefined | void => {
  if (!pdpProductFragment) {
    return;
  }
  const {
    brand,
    description,
    galleryAssets = [],
    hierarchicalCategories,
    name,
    price,
    reviewsBottomLine,
    selectedVariant,
    shoppingStatus,
    sku,
    url,
    variantAvailability,
    video,
  } = pdpProductFragment;
  try {
    // These values are **required** according to https://schema.org/Product
    // if none of these values exist, exit early.
    if (!name || !brand || !price) {
      return;
    }

    const images = galleryAssets
      .filter(asset => asset.type === ASSET_TYPES.IMAGE)
      .map(image => resizeImage({ url: image.url, width: 1200 }));

    const buyAction: BuyAction = {
      '@type': 'BuyAction',
      actionStatus: 'PotentialActionStatus',
    };

    const offer = generateOffer({
      price,
      selectedVariant,
      shoppingStatus,
      variantAvailability,
    });

    const product: JsonLdProduct = {
      '@type': 'Product',
      brand: {
        '@type': 'Brand',
        name: brand,
      },
      category: getCategoryString(hierarchicalCategories),
      description: description || undefined,
      image: images,
      name: `${brand} ${name}`,
      offers: offer,
      potentialAction: buyAction,
      sku,
      url,
    };

    if (video) {
      product.subjectOf = video;
    }

    const reviewSummary = reviewsBottomLine?.summary;

    if (reviewSummary && reviewSummary.totalReview > 0) {
      product.aggregateRating =
        generateAggregateRating({
          ratingValue: reviewSummary.averageScore,
          reviewCount: reviewSummary.totalReview,
        }) || undefined;
    }

    return product;
  } catch (error) {
    Logger.warn('Unable to generate product linked data', error);
    return;
  }
};

type ProductLinkedDataProps = {
  product: JsonLdProduct;
};

/**
 * Renders a LD+JSON tag with the schema.org object passed as a prop.
 * @debug add `__VS_PDP_SHOW_SCHEMA`=`true` in your `localStorage` to see the raw schema printed out to the page
 * @param props.product Pass in the return value of `generateProductLinkedData`
 *                      to this prop. This method can be exported from the same file
 * @returns ReactElement | null
 */
export const ProductLinkedData = ({
  product,
}: ProductLinkedDataProps): ReactElement | null => {
  if (isEmpty(product)) {
    return null;
  }
  return (
    <>
      <JsonLd<JsonLdProduct>
        item={{ '@context': 'https://schema.org', ...product }}
      />
      {isBrowser() &&
        window.localStorage &&
        window.localStorage.getItem('__VS_PDP_SHOW_SCHEMA') && (
          <code>
            {JSON.stringify(
              {
                '@context': 'https://schema.org',
                ...product,
              },
              undefined,
              4
            )}
          </code>
        )}
    </>
  );
};
