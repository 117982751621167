import { ReactElement, useContext } from 'react';

import OptionsForm, { OPTIONS_HEADER_TEXT } from './OptionsForm/OptionsForm';
import { navigateToCheckout } from './utils';
import Modal from 'components/Modals/Modal/Modal';

import GuestCheckoutContext from 'lib/context/GuestCheckoutContext';

import styles from './GuestCheckoutAuthModal.module.scss';

const GuestCheckoutAuthModal = (): ReactElement => {
  const {
    guestCheckoutWebUrl,
    isGuestCheckoutModalShowing,
    setIsGuestCheckoutModalShowing,
  } = useContext(GuestCheckoutContext);

  const handleCloseClick = () => {
    setIsGuestCheckoutModalShowing(false);
  };

  return (
    <Modal
      className={styles.root}
      header={OPTIONS_HEADER_TEXT}
      isOpen={isGuestCheckoutModalShowing}
      isUsingWidthOverride
      onCloseClick={handleCloseClick}
    >
      <OptionsForm
        closeAuthModal={handleCloseClick}
        guestCheckoutWebUrl={guestCheckoutWebUrl}
        onCheckoutAsGuestButtonClick={() =>
          navigateToCheckout(guestCheckoutWebUrl)
        }
      />
    </Modal>
  );
};

export default GuestCheckoutAuthModal;
