import { ApolloCache, InMemoryCache } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import {
  convertLineItemsToCheckoutInput,
  getCheckoutFromCache,
  getLineItemsFromCart,
  shopifyReplaceCheckoutItems,
} from './shopify';
import { LineItemCustomAttributeKeys } from 'data/graphql/enums';
import {
  CheckoutLineItemInput,
  ShopifyCartLineItem,
} from 'data/graphql/types.shopify';

import Logger from 'lib/utils/Logger';

export const updateGiftCardMessage = (
  lineItem: ShopifyCartLineItem,
  newMessage = '' // do not allow null or undefined
) => {
  if (!lineItem) {
    // if falsy return whatever value it was
    return lineItem;
  }

  const customAttributes = lineItem.customAttributes;
  if (isEmpty(customAttributes)) {
    // create new custom attributes set
    lineItem.customAttributes = [
      {
        key: LineItemCustomAttributeKeys.GIFT_CARD_NOTE,
        value: newMessage,
      },
    ];
  } else {
    const messageAttribute = customAttributes.find(
      attribute => attribute.key === LineItemCustomAttributeKeys.GIFT_CARD_NOTE
    );

    if (messageAttribute) {
      messageAttribute.value = newMessage;
    } else {
      customAttributes.push({
        key: LineItemCustomAttributeKeys.GIFT_CARD_NOTE,
        value: newMessage,
      });
    }
  }

  return lineItem;
};

const setGiftCardMessageResolver = async (
  _: unknown,
  {
    cartLineItemId,
    giftCardMessage,
  }: { cartLineItemId: string; giftCardMessage: string },
  { cache }: { cache: ApolloCache<InMemoryCache> }
) => {
  try {
    const checkout = await getCheckoutFromCache();
    const lineItems: ShopifyCartLineItem[] = getLineItemsFromCart(checkout);
    const lineItem = lineItems.find(item => item.id === cartLineItemId);

    updateGiftCardMessage(lineItem!, giftCardMessage);

    const newCheckoutLineItems: CheckoutLineItemInput[] | null =
      convertLineItemsToCheckoutInput(lineItems);

    return shopifyReplaceCheckoutItems(
      cache,
      checkout.id,
      newCheckoutLineItems
    );
  } catch (error) {
    Logger.error(
      `Undable to update/set Gift Card personal message, message: ${giftCardMessage}'`,
      error
    );

    return Promise.reject(error);
  }
};

export default setGiftCardMessageResolver;
