import { gql } from '@apollo/client';

import fragments from './fragments';

import { IStaticPageModel } from 'lib/contentful';

import { ProductVariant } from './types';
import { CategoryV2, Customer } from 'types/generated/api';

const GET_BRANDS = gql`
  query getBrands {
    brands {
      label
      slug
    }
  }
`;

const GET_CART_DETAILS = gql`
  query getCartDetails {
    checkout @client {
      ... on Checkout {
        ...checkoutFields
      }
    }
  }
  ${fragments.checkoutFields}
`;

const GET_CART_DETAILS_FOR_NAVBAR = gql`
  query cartItemsCount {
    checkout @client {
      ... on Checkout {
        ...checkoutFields
      }
    }
  }
  ${fragments.checkoutFields}
`;

const GET_CATEGORIES = gql`
  query getCategories {
    categories {
      algoliaHierarchy
      hasChildren
      label
      level
      parentSlug
      slug
    }
  }
`;

export type GetCategoriesResponse = {
  categories: CategoryV2[];
};

const GET_CHECKOUT = gql`
  query node($cartId: ID!) {
    node(id: $cartId) {
      ... on Checkout {
        ...checkoutFields
      }
    }
  }
  ${fragments.checkoutFields}
`;

const GET_LAST_USED_BOOKMARK_LIST = gql`
  query getLastUsedBookMark {
    me {
      id
      lastUsedBookmarkList {
        id
        bookmarks {
          edges {
            node {
              product {
                sid
              }
            }
          }
        }
      }
    }
  }
`;

const GET_ME = gql`
  query me {
    me {
      ...customerFields
      profileV2 {
        name
        contentOwner {
          ownerName
        }
      }
    }
  }
  ${fragments.customerFields}
`;

export type GetMeResponse = {
  me: Pick<
    Customer,
    | 'createdAt'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'multipassUrl'
    | 'shopifyCheckoutId'
    | 'yotpoId'
  >;
};

const GET_ME_WITH_BOOKMARKS = gql`
  query me {
    me {
      ...bookmarkListsFields
      ...customerFields
    }
  }
  ${fragments.bookmarkListsFields}
  ${fragments.customerFields}
`;

const GET_PRODUCTS = gql`
  query {
    products(first: 40) {
      edges {
        node {
          id
          handle
          title
          priceRange {
            minVariantPrice {
              currencyCode
            }
          }
          variants(first: 1) {
            edges {
              node {
                price
                id
                image {
                  originalSrc
                }
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_PRODUCTS_BY_SIDS = gql`
  query getProductsBySids($sids: [String]!) {
    product(sids: $sids) {
      ...productByHandleFields
    }
  }
  ${fragments.productByHandleFields}
`;

const GET_PRODUCT_PDP = gql`
  query getProductPDP(
    $productSid: String!
    $modelId: String!
    $modelVariantId: String!
  ) {
    product(sids: [$productSid]) {
      ...productDetailPageFields
      hierarchicalCategories {
        lvl0
        lvl1
        lvl2
        lvl3
      }
    }
    recommendedProducts(
      key: {
        modelId: $modelId
        modelVariantId: $modelVariantId
        inputId: $productSid
      }
      page: { cursor: 0, limit: 4 }
    ) {
      brand
      brandSlug
      familySlug
      sid
      slug
      title
      variants(filterByShoppable: true) {
        galleryAssets {
          url
          type
        }
        price
        selectedOptions {
          name
          value
        }
      }
    }
  }
  ${fragments.productDetailPageFields}
`;

const GET_STATIC_PAGE_DATA = gql`
  query getStaticPageData($id: String!) {
    getStaticPageData(id: $id) @client
  }
`;

export type GetStaticPageDataResponse = {
  getStaticPageData: IStaticPageModel;
};

export type GetStaticPageDataVariables = {
  id: string;
};

const GET_PRODUCT_VARIANTS_BY_SKU = gql`
  query getProductVariantsBySku($sids: [String], $skus: [String]) {
    productVariant(sids: $sids, skus: $skus) {
      galleryImages {
        url
      }
      modelImages {
        url
      }
      price
      product {
        brand
        brandSlug
        family
        familySlug
        options {
          ... on ColorOption {
            label
            name
            type
            values {
              brandColor
              colorFamily
              label
              swatchColorCode
              value
            }
          }
          ... on StringOption {
            label
            name
            type
            values {
              label
              value
            }
          }
        }
        sid
        slug
        title
      }
      productImages {
        url
      }
      sid
      selectedOptions {
        name
        value
      }
      sku
      title
    }
  }
`;

export type ProductVariantQueryResponse = {
  productVariant: ProductVariant[];
};

export type ProductVariantQueryVariables = {
  sids?: string[];
  skus?: string[];
};

export {
  GET_BRANDS,
  GET_CART_DETAILS,
  GET_CART_DETAILS_FOR_NAVBAR,
  GET_CATEGORIES,
  GET_CHECKOUT,
  GET_LAST_USED_BOOKMARK_LIST,
  GET_ME,
  GET_ME_WITH_BOOKMARKS,
  GET_PRODUCT_PDP,
  GET_PRODUCTS,
  GET_PRODUCTS_BY_SIDS,
  GET_PRODUCT_VARIANTS_BY_SKU,
  GET_STATIC_PAGE_DATA,
};
