import { CustomAttribute, Decimal, ShopifyProduct } from './types.shopify';
import { typeNames } from 'data/graphql/localState/typeNames';
import { RecommendedProduct } from 'types/api';
import { AssetFkaImage, ContentfulBrand } from 'types/app';
import {
  ColorOptionValue,
  OfferDetails,
  ProductPdpFragment,
  StringOptionValue,
  ReviewSummary,
} from 'types/generated/api';

import { ProductVariantPdpFragment } from '../../types/generated/api';

export type AddToCartLineItem = {
  bookmarks?: Array<{ bookmarkList: { id: string; name: string } }>;
  category: string;
  customAttributes: CustomAttribute[];
  fromSavedForLater?: boolean;
  quantity: number;
  variantId: string;
};

export type ApiProductResult = {
  product?: ApiProduct[];
  recommendedProducts?: RecommendedProduct[];
};

export type ApiProductIsInStockResult = {
  product?: Array<{
    variants: Array<{
      inStock: boolean;
      sid: string;
    }>;
  }>;
};

// Let's start to fix the bad name usage, but carefully
// TODO: Unify ProductVariant typing
export type ApiProduct = AlgoliaProduct;
export type ApiProductVariant = AlgoliaProductVariant;

export type Cart = {
  appliedGiftCards: Array<{ amountUsedV2: MoneyV2; id: string }>;
  completedAt?: Date;
  currencyCode: string;
  discounts: CartDiscountApplication[];
  id: string;
  lineItems?: CartLineItem[];
  lineItemsSubtotalPrice: MoneyV2;
  orderStatusUrl?: string;
  subtotalPrice: MoneyV2;
  totalPrice: string;
  totalTax: string;
  webUrl: string;
};

export type CartLineItem = {
  compareAtPrice: number;
  customAttributes: CustomAttribute[];
  discountAllocations: DiscountAllocation[];
  id: string;
  image: {
    altText: string;
    originalSrc: string;
  };
  price: number;
  product: ShopifyProduct;
  quantity: number;
  selectedOptions: SelectedOption[];
  sku: string;
  title: string;
  variant: {
    requiresShipping: boolean;
    sku: string;
  };
  variantId: string;
  vendor: string;
};

// Can be promo code or script discount
export type CartDiscountApplication = {
  applicable?: boolean; // Specifies whether the discount code was applied successfully.
  code?: string;
  value?: MoneyV2 | PricingPercentageValue;
};

export type DiscountAllocation = {
  allocatedAmount: MoneyV2;
  discountApplication: {
    title?: string;
  };
};

export type Money = {
  amount: number;
  currencyCode: string;
};

export type MoneyV2 = {
  amount: Decimal;
  currencyCode: string;
};

export type MoneySet = {
  presentmentMoney: Money;
};

export type PricingPercentageValue = {
  percentage: number;
};

// Product types
// TODO: [ch2075] pretty sure this should be ApiProduct
export type AlgoliaProduct = {
  brand: string;
  brandSlug: string;
  categories: CategoriesProps;
  description: string;
  family: string;
  familySlug: string;
  fullSid: string;
  options: unknown;
  plpGroupId: string;
  plpOptions: unknown; // TODO: [ch1408] this is wrong and temporary
  sid: string;
  slug: string;
  title: string;
  variants: unknown;
};

export type YoutubeVideo = {
  type: string;
  url: string;
};

export type VariantFilterables = {
  responsible: string[];
};

// TODO: [ch2075] pretty sure this should be ApiProductVariant
// TODO: Eddy +1 for renaming this, super confusing
export type AlgoliaProductVariant = {
  bulky?: boolean;
  colorFamilyCode: string;
  colorFamilyName: string;
  compareAtPrice: number;
  currencyCode: string;
  description: string;
  editorsNote: string;
  editorsNoteAuthor: string;
  eligiblePromotions?: string[];
  filterables: VariantFilterables;
  finalSale: boolean;
  fit: string;
  fullSid: string;
  galleryAssets: AssetFkaImage[];
  galleryImages: Image[];
  hazmat: boolean;
  howToVideos: YoutubeVideo[];
  inStock: boolean;
  ingredients: string[];
  isDiscountable: boolean;
  isDropshipped: boolean;
  isGiftCard: boolean;
  isMarketplace: boolean;
  isProductBundle?: boolean;
  materialList: string[];
  materials: string;
  modelImages: Image[];
  offerDetails?: OfferDetails | null;
  plpGroupId: string;
  price: number;
  product: AlgoliaProduct;
  productImages: Image[];
  productSid: string;
  requiresShipping: boolean;
  selectedOptions: SelectedOption[];
  shoppingStatus: ShoppingStatus;
  sid: string;
  sku: string;
  specifications: [
    {
      label: string;
      value: string;
    },
  ];
  termsAndConditions: string;
  title: string;
  weight: number;
};

type CategoriesProps = {
  lvl0: string[];
  lvl1: string[];
  lvl2: string[];
  lvl3: string[];
};

/**
 * Suggested rename: ColorOptionValue
 */
export type ColorProps = {
  brandColor: string;
  colorFamily: string;
  label: string;
  swatchColorCode: string;
  value: string;
};

export type Image = {
  url: string;
};

export type SelectedOption = {
  label?: string;
  name: string;
  value: string;
};

export type SizeOptionValue = {
  label: string;
  value: string;
};

export enum ProductOptionTypeEnum {
  Colors = 'COLOR',
  Sizes = 'STRING',
}

// TODO: Remove this type. Refactor any imports of this
// to /generated/api - This should not be a handwritten type!
export enum ShoppingStatus {
  ACCEPT_PREORDERS = 'ACCEPT_PREORDERS',
  BUNDLE_ONLY = 'BUNDLE_ONLY',
  COMING_SOON = 'COMING_SOON',
  DISCONTINUED = 'DISCONTINUED',
  EXPLORER_ONLY_PRODUCT = 'EXPLORER_ONLY_PRODUCT',
  SELLABLE = 'SELLABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export type Product = {
  brand: string;
  brandSlug: string;
  family: string;
  familySlug: string;
  options: ProductOption[];
  sid: string;
  slug: string;
  title: string;
};

export type ProductVariant = {
  compareAtPrice: number;
  fullSid: string;
  galleryImages: Image[];
  modelImages: Image[];
  price: number;
  product: Product;
  productImages: Image[];
  selectedOptions?: SelectedOption[];
  sid: string;
  sku: string;
  title?: string;
};

export type ProductOption = {
  label: string;
  name: string;
  primary?: boolean;
  type: ProductOptionTypeEnum;
  values: Array<ProductOptionValueString | ProductOptionValueColor>;
};

export type ProductOptionValueString = {
  label: string;
  value: string;
};

export type ProductOptionValueColor = {
  brandColor: string;
  colorFamily: string;
  label: string;
  sortOrder?: number;
  swatchColorCode: string;
  value: string;
};

export type PDPProductTrackingAttributes = {
  brand: string;
  family: string;
  sid: string;
  title: string;
};

export type PDPVariantTrackingAttributes = {
  galleryImages: Image[];
  inStock: boolean;
  price: number;
  shoppingStatus: ShoppingStatus;
  sid: string;
  sku: string;
};

export type Fulfillment = {
  createdAt: string;
  status: string;
  trackingCompany: string;
  trackingNumber: string;
  updatedAt: string;
};

export type UpdateCustomerInput = {
  email?: string;
  firstName?: string;
  lastName?: string;
  shopifyCheckoutId?: string;
};

// ***************** Apollo Local State - START *****************

export type ModalState = {
  __typename: typeNames.Modal;
  id: string;
  isOpen: boolean;
};

export type Referral = {
  __typename: typeNames.Referral;
  friendDiscount?: string;
  friendEmail?: string;
  referralCode?: string;
};

export type ColorToVariantMap = {
  [key: string]: ProductVariantPdpFragment[];
};

export type ColorSizeVariantMap = {
  [color: string]: { [size: string]: ProductVariantPdpFragment | undefined };
};

export type ProductDetailStaticInfo = {
  brandReviews?: ReviewSummary;
  colorOptionKey?: string;
  colorOptionValueMapping?: { [colorValueString: string]: ColorOptionValue };
  colorSizeVariantMap?: ColorSizeVariantMap;
  colorToVariantMap?: ColorToVariantMap;
  contentfulBrandData?: ContentfulBrand;
  defaultVariant?: ProductVariantPdpFragment;
  product?: ProductPdpFragment;
  sizeOptionKey?: string;
  sizeOptionValueMapping?: { [sizeValueString: string]: StringOptionValue };
  sizeToVariantMap?: { [sizeValueString: string]: ProductVariantPdpFragment };
};

export type ProductDetailState = {
  addToCartLoading?: boolean;
  productLoading?: boolean;
  selectedColor?: ColorOptionValue; // Update type for selected color and size to just include value and label
  selectedSize?: StringOptionValue;
  selectedVariant?: ProductVariantPdpFragment;
  variantIsValid?: boolean;
};

// ***************** Apollo Local State - END *****************
