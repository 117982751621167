import { minifyStringForHtml } from 'lib/formatters';
import Logger from 'lib/utils/Logger';

/**
 * window.VerishopMultiStorage is a utility for saving data to, and retrieving data from localStorage or cookies.
 * If localStorage is not avaible, cookies will be used.
 * Safari on ios in private mode does not suppord localStorage.
 */

export const YOTPO_USER_ID_KEY = 'YOTPO_USER_ID';
export const YOTPO_USER_EMAIL_KEY = 'YOTPO_USER_EMAIL';

const initScript = () => {
  return `(${initMultiStorage.toString()}())`;
};

export function initMultiStorage(): void {
  const ANONYMOUS_USER_ID_KEY = 'ajs_anonymous_id';

  /* Taken from: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API */
  const checkWebStorageAvailablity = () => {
    try {
      const x = '__storage_test__';
      window.localStorage.setItem(x, x);
      window.localStorage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  };
  const isWebStorageAvailable = checkWebStorageAvailablity();

  const MultiStorage = {
    getAnonymousUserId() {
      /* The Segment ID can have extra quotes around it because they call JSON.stringify on everyhting that they store.
           This regex will remove any extra quotes. */
      const anonId = this.getItem(ANONYMOUS_USER_ID_KEY);
      const parsedId = (anonId || '').replace(/['"]+/g, '');
      return parsedId;
    },

    getCookie(key: string) {
      try {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
          const parts = cookie.split('=');
          if (key === parts[0]) {
            return parts[1];
          }
        }
      } catch (error) {
        Logger.log('MultiStorage Error - getCookie: ', error);
      }
    },

    /* Gets an item from localStorage. If localStorage is not available it will use document.cookie. */
    getItem(key: string) {
      try {
        if (isWebStorageAvailable) {
          return window.localStorage.getItem(key);
        }

        const cookie = this.getCookie(key);
        if (cookie) {
          return decodeURIComponent(cookie);
        }
      } catch (error) {
        Logger.log('MultiStorage Error - getItem: ', error);
      }
    },

    setAnonymousUserId(value: string) {
      /* The Segment ID can have extra quotes around it because they call JSON.stringify on everyhting that they store.
           This regex will remove any extra quotes. */
      const parsedId = (value || '').replace(/['"]+/g, '');
      return this.setItem(ANONYMOUS_USER_ID_KEY, parsedId);
    },

    setCookie(key: string, value: string, expiresInSecond?: number) {
      try {
        document.cookie = `${key}=${value}${
          expiresInSecond ? `;Max-Age=${expiresInSecond};` : ''
        }`;
      } catch (error) {
        Logger.log('MultiStorage Error - setCookie: ', error);
      }
    },

    /* Sets an item in both localStorage and document.cookie */
    setItem(key: string, value: string) {
      try {
        if (isWebStorageAvailable) {
          window.localStorage.setItem(key, value);
        }

        this.setCookie(key, encodeURIComponent(value));
      } catch (error) {
        Logger.log('MultiStorage Error - setItem: ', error);
      }
    },
  };

  window.VerishopMultiStorage = MultiStorage;
}

export default { __html: minifyStringForHtml(initScript()) };
