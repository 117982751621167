import gql from 'graphql-tag';

export const ProductBookmarkFragment = gql`
  fragment productBookmark on ProductBookmark {
    bookmarkList {
      id
      name
    }
    id
    product {
      brand
      brandSlug
      categories {
        category
      }
      family
      familySlug
      options {
        label
        name
        primary
        type
        values {
          label
          sortOrder
          value
          ... on ColorOptionValue {
            brandColor
            colorFamily
            swatchColorCode
          }
        }
      }
      sid
      slug
      title
      variants {
        compareAtPrice
        currencyCode
        filterables {
          responsible
        }
        fullSid
        galleryImages {
          url
        }
        inStock
        price
        selectedOptions {
          name
          value
        }
        sku
        sid
        shoppingStatus
        title
      }
    }
    selectedOptions {
      name
      value
    }
  }
`;

export const SelectedOptionFragment = gql`
  fragment SelectedOption on SelectedOption {
    name
    value
  }
`;

export const ProductOptionPDPFragment = gql`
  fragment ProductOptionPDP on Option {
    ... on ColorOption {
      label
      name
      type
      values {
        brandColor
        colorFamily
        label
        swatchColorCode
        value
      }
    }
    ... on StringOption {
      label
      name
      type
      values {
        label
        value
      }
    }
  }
`;

export const ProductVariantPDPFragment = gql`
  fragment ProductVariantPDP on ProductVariant {
    bulky
    collections
    compareAtPrice
    currencyCode
    description
    editorsNote
    editorsNoteAuthor
    eligiblePromotions
    filterables {
      responsible
    }
    finalSale
    fit
    fullSid
    galleryAssets {
      url
      type
      ... on Image {
        ecommModel {
          bust
          chest
          height
          hip
          inseam
          waist
        }
        ecommModelSize {
          name
          value
        }
      }
      ... on Video {
        cloudinaryPublicId
      }
    }
    galleryImages {
      url
    }
    howToVideos {
      url
      type
    }
    ingredients
    inStock
    isDiscountable
    isDropshipped
    isGiftCard
    isLowStock
    isMarketplace
    isProductBundle
    materialList
    materials
    modelImages {
      url
    }
    offerDetails {
      endDate
      offerType
      startDate
    }
    price
    productBundleItems {
      quantity
      sid
      sku
    }
    productImages {
      url
    }
    publishDate
    isReplenishable
    requiresShipping
    selectedOptions {
      ...SelectedOption
    }
    specifications {
      label
      value
    }
    shoppingStatus
    sid
    sku
    termsAndConditions
    title
    hazmat
    weight
    weightUnit
  }
  ${SelectedOptionFragment}
`;

export const ProductPDPFragment = gql`
  fragment ProductPDP on Product {
    brand
    brandEntity {
      label # required for ssr caching
      slug # required for ssr caching
      subscriptionTier
    }
    brandSlug
    categories {
      category
    }
    description
    descriptionLegacy
    family
    familySlug
    hierarchicalCategories {
      lvl0
      lvl1
      lvl2
      lvl3
    }
    sid
    slug
    title
    variants(omitDiscontinued: true, isCollectionsNeeded: true) {
      ...ProductVariantPDP
    }
    options {
      ...ProductOptionPDP
    }
    slug
  }
  ${ProductOptionPDPFragment}
  ${ProductVariantPDPFragment}
`;

export const PostBookmarkFragment = gql`
  fragment postBookmark on ExplorerPostBookmark {
    bookmarkedEntityId
    explorerPost {
      assets {
        id
        type
        url
      }
      description
      headline
      postId
      profile {
        brandSlug
        id
        type
        username
      }
      type
    }
    id
  }
`;

export const relatedProductFields = gql`
  fragment relatedProductFields on Product {
    brand
    brandSlug
    description
    familySlug
    sid
    slug
    title
    variants {
      currencyCode
      filterables {
        responsible
      }
      galleryImages {
        url
      }
      inStock
      offerDetails {
        endDate
        offerType
        startDate
      }
      price
      shoppingStatus
      sid
      sku
      title
    }
  }
`;

export const ImageDisplayUrl = gql`
  fragment ImageDisplayUrl on Image {
    url
  }
`;

export const customerFields = gql`
  fragment customerFields on Customer {
    createdAt
    email
    firstName
    id
    lastName
    multipassUrl
    shopifyCheckoutId
    yotpoId
  }
`;

export const profileFields = gql`
  fragment profileFields on Profile {
    biography
    brandSlug
    coverImageAsset {
      url
    }
    creatorInfo {
      isCreator
      status
    }
    displayName
    facebookName
    followerCount
    followingCount
    id
    instagramName
    isFollowing
    isProfileOwner
    likeCount
    metaDescription
    metaTitle
    profileAsset {
      url
    }
    twitterName
    type
    username
  }
`;

export const reviewsPageFragment = gql`
  fragment reviewsPageFields on ReviewsPage {
    pageInfo {
      currentPage
      numberOfPages
    }
    reviews {
      comment {
        content
        createdAt
        id
      }
      content
      id
      imagesData {
        id
        thumbUrl
        originalUrl
      }
      reviewDate
      score
      sourceName
      sourceUrl
      title
      user {
        displayName
      }
      verifiedBuyer
    }
    summary {
      averageScore
      ratingDistribution {
        count
        score
      }
      totalReview
    }
  }
`;
