import { client } from './index';

import { AlgoliaFacetListModel } from 'types/contentful';

export enum DefaultPLPFilterIds {
  BRAND = '1owTfvnNFOjN4yEu8rkirF',
  CATEGORY = '7iBn2fYD90WvPtlt4x2oEj',
  COLLECTION = 'BiWneyQ6f6XAFLleSE89Z',
  NEW_ARRIVALS = '44JnvaPgxCv0asuyedpwWq',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SALE = '44JnvaPgxCv0asuyedpwWq',
  SEARCH = '2lk8pNQ1LqIdCL6d2uzpIB',
}

export const loadDefaultPLPFilter = async (plpType: DefaultPLPFilterIds) => {
  return await client.getEntry<AlgoliaFacetListModel>(plpType, {
    include: 3,
    limit: 1,
  });
};
