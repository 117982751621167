import Script from 'next/script';

// import { useExperiments } from 'lib/experiment';
// import { ExperimentName } from 'lib/experiment/experimentConfig';

export const UpsellitWidget: React.FC = () => {
  // const { experiments: { enableUpsellit } = {} } = useExperiments(
  //   ExperimentName.enableUpsellit
  // );

  // return enableUpsellit ? (
  //   <Script
  //     dangerouslySetInnerHTML={{
  //       __html: `usi_installed = 0; USI_installCode = function() { if (usi_installed == 0) { usi_installed = 1; var USI_headID = document.getElementsByTagName("head")[0]; var USI_installID = document.createElement('script'); USI_installID.type = 'text/javascript'; USI_installID.src = 'https://www.upsellit.com/active/verishop.jsp'; USI_headID.appendChild(USI_installID); } }; if (typeof(document.readyState) != "undefined" && document.readyState === "complete") { USI_installCode(); } else if (window.addEventListener){ window.addEventListener('load', USI_installCode, true); } else if (window.attachEvent) { window.attachEvent('onload', USI_installCode); } else { USI_installCode(); } setTimeout("USI_installCode()", 10000);`,
  //     }}
  //     id="show-upsellit-widget"
  //     strategy="lazyOnload"
  //   />
  // ) : null;

  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `usi_installed = 0; USI_installCode = function() { if (usi_installed == 0) { usi_installed = 1; var USI_headID = document.getElementsByTagName("head")[0]; var USI_installID = document.createElement('script'); USI_installID.type = 'text/javascript'; USI_installID.src = 'https://www.upsellit.com/active/verishop.jsp'; USI_headID.appendChild(USI_installID); } }; if (typeof(document.readyState) != "undefined" && document.readyState === "complete") { USI_installCode(); } else if (window.addEventListener){ window.addEventListener('load', USI_installCode, true); } else if (window.attachEvent) { window.attachEvent('onload', USI_installCode); } else { USI_installCode(); } setTimeout("USI_installCode()", 10000);`,
      }}
      id="show-upsellit-widget"
      strategy="lazyOnload"
    />
  );
};
