import {
  ApolloCache,
  ApolloError,
  DataProxy,
  FetchResult,
  InMemoryCache,
} from '@apollo/client';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import setGiftCardMessageResolver from './_giftCardResolvers';
import {
  CHECKOUT_REQUIRED_ATTRIBUTES,
  CHECKOUT_SOURCE_VALUE,
  CheckoutAttributesMutation,
  CheckoutAttributesSupportedKeys,
} from './checkoutAttributesMutation';
import { CartCustomAttributeUtil } from './utils/CartCustomAttributeUtil';
import getConfig from 'config/config';
import {
  CheckoutLineItemInput,
  CustomAttribute,
  ShopifyCart,
  ShopifyCartLineItem,
} from 'data/graphql/types.shopify';

import {
  trackProductAddedToCart,
  trackProductAddedToCartFromSaved,
} from 'lib/analytics';
import { extractTrackingDataFromCheckoutByVariantId } from 'lib/analytics/utils';
import { trackWishlistProductAddedToCart } from 'lib/analytics/wishlist';
import initializeApollo from 'lib/apollo/initializeApollo';
import { getBundleId, getCompositeLineItemKey } from 'lib/cart/bundlesUtils';
import { YOTPO_USER_ID_KEY } from 'lib/page/multiStorageScript';
import { getLocalCartId, setLocalCartId } from 'lib/shopify/utils';
import Logger from 'lib/utils/Logger';

import { AddToCartTrackingData } from 'types/segment';

import { LineItemCustomAttributeKeys } from '../enums';
import {
  APPEND_GIFT_CARD_CODE,
  CHECKOUT_ATTRIBUTES_UPDATE_V2,
  CHECKOUT_DISCOUNT_CODE_APPLY,
  CHECKOUT_DISCOUNT_CODE_REMOVE,
  CHECKOUT_LINE_ITEMS_REPLACE,
  CREATE_CHECKOUT_ID,
  REMOVE_GIFT_CARD_CODE,
} from '../mutations.shopify';
import { GET_CART_DETAILS, GET_CHECKOUT } from '../queries';
import { AddToCartLineItem, Cart } from '../types';

export const MAX_LINE_ITEM_QUANTITY = 6;

const shopifyClient = initializeApollo({
  clientId: 'ShopifyApolloClient',
  connectToDevTools: false,
  linkConfig: getConfig('graphql.shopify'),
  skipCache: true,
  ssrMode: false,
});

export const convertLineItemsToCheckoutInput = (
  lineItems: ShopifyCartLineItem[]
): CheckoutLineItemInput[] | null => {
  try {
    return lineItems.map((lineItem: ShopifyCartLineItem) => {
      const {
        customAttributes,
        quantity,
        variant: { id },
      } = lineItem;

      const filteredCustomAttributes = customAttributes.map(
        omitTypeNameFromCustomAttribute
      );

      return {
        customAttributes: filteredCustomAttributes,
        quantity,
        variantId: id,
      };
    });
  } catch (error) {
    Logger.error(
      'Error when trying to convert cart cline items to checkout input',
      error
    );
  }

  return null;
};

// Detect duplicate variant records and combine them into one line item
// with updated quantity as sum of the individual line items,
// up to MAX_LINE_ITEM_QUANTITY
export const getCartLineItemsDedupped = (
  cartLineItems: ShopifyCartLineItem[]
): ShopifyCartLineItem[] => {
  const deduppedCartLineItems: ShopifyCartLineItem[] = [];
  try {
    const visitedVariantIds: { [id: string]: ShopifyCartLineItem } = {};

    cartLineItems.forEach((lineItem: ShopifyCartLineItem) => {
      const selectedBundleId = getBundleId(lineItem);
      // TODO BUNDLES: use util for calculating compositeKey - https://app.clubhouse.io/verishoptechnologyv2/story/9081/add-a-utility-function-for-creating-the-composite-key
      const id = selectedBundleId
        ? `${lineItem.variant.sku}~${selectedBundleId}`
        : lineItem.variant.id;

      const existingVariant = visitedVariantIds[id];
      if (existingVariant) {
        // this is a duplicate! take the higher quantity
        existingVariant.quantity = Math.min(
          Math.max(existingVariant.quantity, lineItem.quantity),
          MAX_LINE_ITEM_QUANTITY
        );
      } else {
        deduppedCartLineItems.push(lineItem);
        visitedVariantIds[lineItem.variant.id] = lineItem;
      }
    });
  } catch (error) {
    Logger.error(
      'Error when trying to dedup cartLineItems in getCartLineItemsDedupped()',
      error
    );
  }

  return deduppedCartLineItems;
};

// Currently, it will only detect duplicate variant records and combine them into one line item
export const normalizeCartLineItems = (
  cartLineItems: ShopifyCartLineItem[]
): ShopifyCartLineItem[] => {
  let result: ShopifyCartLineItem[] = [];
  const deduppedItems: ShopifyCartLineItem[] =
    getCartLineItemsDedupped(cartLineItems);

  // More validation steps can be added here (like checking for in-stock, etc...)

  if (!isEmpty(deduppedItems)) {
    result = deduppedItems;
  }

  return result;
};

export const getCartId = async () => {
  try {
    let cartId = getLocalCartId();
    if (!cartId) {
      // If cart ID is not found in the broswer local storage,
      // get a new one from shopify and store in local storage for future use
      const mutation = CREATE_CHECKOUT_ID;
      const { data } = await shopifyClient.mutate({
        mutation,
        // allowPartialAddresses: true - needed for iOS ApplePay [CH5842]
        variables: {
          input: {
            allowPartialAddresses: true,
            customAttributes: CHECKOUT_REQUIRED_ATTRIBUTES,
          },
        },
      });
      cartId = data.checkoutCreate.checkout.id;
      if (cartId) {
        setLocalCartId(cartId);
      }
    }
    return { __typename: 'cartId', cartId };
  } catch (error) {
    Logger.error('Error getting CartId. Message: ', error);
    return {}; // has to be a non-null
  }
};

export const getLineItemsFromCart = (
  cart: ShopifyCart
): ShopifyCartLineItem[] => {
  try {
    const lineItemEdges = cart?.lineItems?.edges;
    if (isEmpty(lineItemEdges)) {
      // avoid throwing an error on empty cart, just return empty array
      return [];
    }

    // beyond this point, all errors whould throw and log...
    const lineItems: ShopifyCartLineItem[] = lineItemEdges.map(({ node }) => ({
      ...node,
    }));

    return lineItems;
  } catch (error) {
    Logger.error('Error extracting line items from Cart', error);
  }
  return [];
};

const hasCartTokenAttribute = (customAttributes?: CustomAttribute[]) => {
  return (
    !isEmpty(customAttributes) &&
    customAttributes?.find(
      attr => attr.key === CheckoutAttributesSupportedKeys.CART_TOKEN
    )
  );
};

const updateCartTokenAttribute = async (
  checkoutId: string,
  customAttributes: CustomAttribute[] = []
) => {
  if (!hasCartTokenAttribute(customAttributes)) {
    try {
      // remove __typename
      const inputAttributes = customAttributes.map(
        omitTypeNameFromCustomAttribute
      );

      const newCustomAttributes = [
        ...inputAttributes,
        {
          key: CheckoutAttributesSupportedKeys.CART_TOKEN,
          value: window.analytics.user().anonymousId() || '',
        },
      ];

      const mutationOptions = CheckoutAttributesMutation.getUpdateMutation(
        checkoutId,
        newCustomAttributes
      );
      await shopifyClient.mutate(mutationOptions);
    } catch (error) {
      Logger.error('Failed to update cart attributes', error);
    }
  }
};

const validateCheckoutSourceAttribute = async (
  checkoutId: string,
  customAttributes: CustomAttribute[] = []
) => {
  const checkoutSource = customAttributes?.find(
    attribute =>
      attribute.key === CheckoutAttributesSupportedKeys.CHECKOUT_SOURCE
  );

  if (!checkoutSource || checkoutSource.value !== CHECKOUT_SOURCE_VALUE) {
    try {
      // remove __typename
      const inputAttributes = customAttributes.map(
        omitTypeNameFromCustomAttribute
      );

      const newCustomAttributes = [
        ...inputAttributes,
        {
          key: CheckoutAttributesSupportedKeys.CHECKOUT_SOURCE,
          value: CHECKOUT_SOURCE_VALUE,
        },
      ];

      const mutationOptions = CheckoutAttributesMutation.getUpdateMutation(
        checkoutId,
        newCustomAttributes
      );
      await shopifyClient.mutate(mutationOptions);
    } catch (error) {
      Logger.error(
        'Failed to update cart attributes for checkout_source',
        error
      );
    }
  }
};

const validateCheckoutYotpoAttribute = async (checkout: ShopifyCart) => {
  try {
    const { customAttributes } = checkout;
    const currentYotpoId =
      window.VerishopMultiStorage.getItem(YOTPO_USER_ID_KEY);
    const checkoutYotpoIdAttribute = customAttributes?.find(
      attribute =>
        attribute.key === CheckoutAttributesSupportedKeys.YOTPO_ACCOUNT_ID
    );
    let shouldUpdate = false;

    if (currentYotpoId) {
      if (!checkoutYotpoIdAttribute) {
        // if does not exist, set checkout yotpo account id to current value
        checkout.customAttributes?.push({
          key: CheckoutAttributesSupportedKeys.YOTPO_ACCOUNT_ID,
          value: currentYotpoId,
        });
        shouldUpdate = true;
      } else if (currentYotpoId !== checkoutYotpoIdAttribute.value) {
        // wrong yotpo account id on the checkout, update it
        checkoutYotpoIdAttribute.value = currentYotpoId;
        shouldUpdate = true;
      }
    }

    if (shouldUpdate) {
      // remove __typename
      const inputAttributes = checkout.customAttributes
        ?.filter(customAttribute => !!customAttribute)
        ?.map(omitTypeNameFromCustomAttribute);

      const mutationOptions = CheckoutAttributesMutation.getUpdateMutation(
        checkout.id,
        inputAttributes || []
      );
      await shopifyClient.mutate(mutationOptions);
    }
  } catch (error) {
    Logger.error(
      'Something went wrong validating checkout yotpo attribute',
      error
    );
    return false;
  }

  return true;
};

// Validates line items inside the cart. If a variant no longer exists,
// updates the cart contents to only valid variants.
export const validateAndUpdateLineItems = async (
  checkout: ShopifyCart,
  cache: DataProxy
): Promise<boolean | undefined> => {
  try {
    const {
      customAttributes,
      id: checkoutId,
      lineItems: { edges: lineItemsArray },
    } = checkout;
    const validatedLineItems = lineItemsArray.filter(
      lineItem => !!lineItem.node.variant
    );

    if (lineItemsArray.length !== validatedLineItems.length) {
      const checkoutLineItemInput = validatedLineItems.map(
        validatedLineItem => ({
          customAttributes: extraKeyValuePairsFromCustomAttributes(
            validatedLineItem.node.customAttributes
          ),
          quantity: validatedLineItem.node.quantity,
          variantId: validatedLineItem.node.variant.id,
        })
      );

      await shopifyReplaceCheckoutItems(
        cache,
        checkoutId,
        checkoutLineItemInput
      );

      return false;
    }
    window.analytics.ready(
      async () => await updateCartTokenAttribute(checkoutId, customAttributes)
    );
    return true;
  } catch (error) {
    Logger.error('Something went wrong validating the cart line items', error);
  }
};

export const extraKeyValuePairsFromCustomAttributes = (
  customAttributes: CustomAttribute[]
): CustomAttribute[] =>
  customAttributes.map(customAttribute => ({
    key: customAttribute.key,
    value: customAttribute.value,
  }));

// Warning - this will not renew an expired/completed cart, for that use getCheckout()
export const getCartDetails = async (
  cartIdArg?: string | null
): Promise<ShopifyCart> => {
  const cartId = cartIdArg || get(await getCartId(), 'cartId');

  const { data } = await shopifyClient.query({
    fetchPolicy: 'network-only',
    query: GET_CHECKOUT,
    variables: { cartId },
  });

  const { node = {} } = data;
  return { ...node };
};

export const getCheckoutFromCache = async (): Promise<ShopifyCart> => {
  try {
    const cartId = get(await getCartId(), 'cartId');

    const { data } = await shopifyClient.query({
      fetchPolicy: 'cache-only',
      query: GET_CHECKOUT,
      variables: { cartId },
    });
    const { node = {} } = data;
    const cart: Promise<ShopifyCart> = Promise.resolve({ ...node });
    cart.then(async (value: ShopifyCart) => {
      await validateCheckoutYotpoAttribute(value);
    });

    return cart;
  } catch (error) {
    Logger.warn('Unable to get checkout object from Apollo cache', error);
    return Promise.reject();
  }
};

export const getCheckout = async (cache: DataProxy): Promise<ShopifyCart> => {
  const { cartId } = await getCurrentCartId();
  let checkout = await getCartDetails(cartId);

  const isValid = await validateAndUpdateLineItems(checkout, cache);
  if (!isValid) {
    checkout = await getCartDetails(cartId);
  }

  await validateCheckoutSourceAttribute(
    checkout?.id,
    checkout?.customAttributes
  );

  await validateCheckoutYotpoAttribute(checkout);

  return checkout;
};

const isCheckoutCompleted = (checkoutObject?: Cart | ShopifyCart) => {
  if (!checkoutObject) {
    return false;
  }

  const hasOrderStatusUrl =
    checkoutObject.orderStatusUrl && checkoutObject.orderStatusUrl.length > 0;

  return !!checkoutObject.completedAt || hasOrderStatusUrl;
};

export const mergeCarts = async (
  sourceCart: ShopifyCart,
  targetCart: ShopifyCart,
  dataProxy: DataProxy
) => {
  try {
    const sourceLineItems = getLineItemsFromCart(sourceCart);
    const targetLineItems = getLineItemsFromCart(targetCart);

    const newLineItems = sourceLineItems.concat(targetLineItems);
    const normalizedLineItems = normalizeCartLineItems(newLineItems);
    const checkoutInputLines =
      convertLineItemsToCheckoutInput(normalizedLineItems);

    return await shopifyReplaceCheckoutItems(
      dataProxy,
      targetCart.id,
      checkoutInputLines
    );
  } catch (error) {
    Logger.error('Error when trying to merge carts', error);
    return Promise.reject(error);
  }
};

export const shopifyReplaceCheckoutItems = async (
  cache: DataProxy,
  checkoutId: string,
  newLineItems: CheckoutLineItemInput[] | null
) => {
  if (!newLineItems) {
    return Promise.reject('ShopifyReplaceCheckoutItems was called with null');
  }

  const lineItemsWithCartAttribute =
    await CartCustomAttributeUtil.addCartCustomAttributeToLineItems(
      newLineItems,
      checkoutId
    );

  const response = await shopifyClient.mutate({
    mutation: CHECKOUT_LINE_ITEMS_REPLACE,
    update: (_proxy, { data: { checkoutLineItemsReplace: checkoutData } }) => {
      updateCartDetails(cache, checkoutData);
    },

    variables: { checkoutId, lineItems: lineItemsWithCartAttribute },
  });

  return response?.data.checkoutLineItemsReplace;
};

export const syncCarts = async ({
  cache,
  currentCartId,
  newCartId,
}: {
  cache: ApolloCache<InMemoryCache>;
  currentCartId: string | null;
  newCartId: string | null;
}): Promise<string | null> => {
  let finalCartId: string | null = null;

  // if they are alrady in sync, do nothing, return the current cart id
  if (currentCartId && newCartId && currentCartId === newCartId) {
    return Promise.resolve(currentCartId);
  }

  try {
    const newCartDetails: ShopifyCart = await getCartDetails(newCartId);
    const currentCartDetails: ShopifyCart = await getCartDetails(currentCartId);

    // check if incoming cart id has already been used for a checkout and completed
    if (!isCheckoutCompleted(newCartDetails)) {
      if (isCheckoutCompleted(currentCartDetails)) {
        // if current cart has already completed ckeckout proccess, simply replace the cartID with a new one
        setLocalCartId(newCartId);
        return Promise.resolve(newCartId);
      } else {
        Logger.log(
          'Attempting merge of carts: ' +
            JSON.stringify(newCartDetails) +
            JSON.stringify(currentCartDetails)
        );
        // if both carts are valid and not completed, merge the old one into the new one:
        await mergeCarts(currentCartDetails, newCartDetails, cache);

        // if it did not error out, make new CartId be the current cart id
        setLocalCartId(newCartDetails.id);
      }
    }
  } catch (error) {
    Logger.error('Error while trying to syncCarts, ', error);
  }

  await getCartId().then(({ cartId }) => (finalCartId = cartId || null));

  // return final active cart id to send back to the API
  return Promise.resolve(finalCartId);
};

const clearCartIdIfCompleted = (checkoutData: ShopifyCart) => {
  if (isCheckoutCompleted(checkoutData)) {
    // Checkout is already completed, we need to reset the Cart Id
    setLocalCartId(null);
  }
};

const clearCartIdIfInvalid = (checkoutData: ShopifyCart) => {
  /* Clear cart when we get invalid data:
  Example: sometimes we get:
  {
    "data": {
        "node": null
    }
  }
  */

  if (isEmpty(checkoutData)) {
    Logger.log('Checkout has invalid data => checkout Id is now cleared');
    setLocalCartId(null);
  }
};

const getCurrentCartId = async () => {
  const checkoutData = await getCartDetails();
  clearCartIdIfCompleted(checkoutData);
  clearCartIdIfInvalid(checkoutData);
  return getCartId();
};

const updateCartDetails = (
  proxy: DataProxy,
  checkoutData: { checkout: ShopifyCart }
) => {
  try {
    proxy.writeQuery({
      data: { ...checkoutData, __typename: 'Checkout' },
      query: GET_CART_DETAILS,
    });
  } catch (error) {
    Logger.error('ERROR while trying to update query GET_CART_DETAILS', error);
  }
};

const updateGiftNote = async (giftMessage: string, cache: DataProxy) => {
  const { id: checkoutId, customAttributes: oldAttributes = [] } =
    await getCartDetails();
  const customAttributes = oldAttributes.map(omitTypeNameFromCustomAttribute);

  customAttributes.push({
    key: CheckoutAttributesSupportedKeys.GIFT_MESSAGE,
    value: giftMessage,
  });

  return shopifyClient.mutate({
    mutation: CHECKOUT_ATTRIBUTES_UPDATE_V2,
    update: async () => {
      const checkoutData = await getCheckout(cache);
      return updateCartDetails(cache, { checkout: checkoutData });
    },
    variables: {
      checkoutId,
      input: {
        customAttributes,
      },
    },
  });
};

const tryTrackAddToCart = (
  lineItems: AddToCartLineItem[],
  checkoutData: ShopifyCart
) => {
  if (isEmpty(lineItems)) {
    return;
  }

  lineItems.forEach((lineItemInput: AddToCartLineItem) => {
    try {
      const addToCartEventData: AddToCartTrackingData = {
        ...extractTrackingDataFromCheckoutByVariantId(
          lineItemInput.variantId,
          checkoutData,
          lineItemInput.category
        ),
        cart_id: checkoutData.id,
        quantity: lineItemInput.quantity,
      };

      lineItemInput.fromSavedForLater
        ? trackProductAddedToCartFromSaved(addToCartEventData)
        : trackProductAddedToCart(addToCartEventData);

      const { bookmarks = [] } = lineItemInput;
      if (bookmarks.length > 0) {
        trackWishlistProductAddedToCart({
          ...addToCartEventData,
          wishlist_ids: bookmarks.map(bookmark => bookmark.bookmarkList.id),
        });
      }
    } catch (error) {
      Logger.error('Unable to send AddToCart analytics event', error);
    }
  });
};

export const tryConvertAddToCartLineItemPropsToShopifyFormat = (
  lineItems: AddToCartLineItem[]
): CheckoutLineItemInput[] | undefined => {
  try {
    return lineItems.map(
      ({
        category,
        customAttributes = [],
        quantity,
        variantId,
      }: AddToCartLineItem) => {
        // if category attribute does not exist yet, add it:
        if (
          !customAttributes.find(attribute => attribute.key === '_category')
        ) {
          customAttributes.push({ key: '_category', value: category });
        }

        return {
          customAttributes,
          quantity,
          variantId,
        };
      }
    );
  } catch (error) {
    Logger.error(
      'Unable to convert to AddToCart arguments to Shopify Format',
      error
    );
  }
};

export const omitTypeNameFromCustomAttribute = (
  customAttribute: CustomAttribute
) => {
  return omit(customAttribute, '__typename');
};

export type ApplyGiftCardToCurrentCheckoutResponse = {
  data?: {
    checkoutGiftCardsAppend?: {
      checkout?: {
        appliedGiftCards: [{ id: string }];
        id: string;
      };
    };
  };
};

/**
 * Applies a gift card to the current checkout
 * @param giftCardCode - The gift card code to apply to the current checkout
 * @return Promise<ApplyGiftCardToCurrentCheckoutResponse>
 */
export const applyGiftCardToCurrentCheckout = async (
  giftCardCode: string
): Promise<ApplyGiftCardToCurrentCheckoutResponse> => {
  const { cartId: checkoutId } = await getCartId();

  const response = await shopifyClient.mutate({
    mutation: APPEND_GIFT_CARD_CODE,
    variables: {
      checkoutId,
      giftCardCodes: [giftCardCode],
    },
  });
  return response;
};

export const removeGiftCardFromCurrentCheckout = async (
  appliedGiftCardId: string
): Promise<void> => {
  const { cartId: checkoutId } = await getCartId();
  await shopifyClient.mutate({
    mutation: REMOVE_GIFT_CARD_CODE,
    variables: {
      appliedGiftCardId,
      checkoutId,
    },
  });
};

export default {
  Mutation: {
    addToCart: async (
      _: unknown, // rootObject
      { lineItems }: { lineItems: AddToCartLineItem[] },
      { cache }: { cache: DataProxy }
    ) => {
      const { cartId } = await getCurrentCartId();

      const shopifyLineItems =
        tryConvertAddToCartLineItemPropsToShopifyFormat(lineItems);
      if (!shopifyLineItems) {
        Logger.error(
          `Unable to process AddToCart action due to invalid arguments. Arguments are: ${JSON.stringify(
            lineItems
          )}`
        );
        return;
      }

      const checkout = await getCheckoutFromCache();
      const currentLineItems = getLineItemsFromCart(checkout);
      const currentConvertedLineItems: CheckoutLineItemInput[] =
        convertLineItemsToCheckoutInput(currentLineItems) || [];

      const newShopifyLineItems: CheckoutLineItemInput[] = []; // new meaning the cart does not contain this item. Otherwise, we increase quantity by 1
      shopifyLineItems.forEach(shopifyLineItem => {
        const giftCardId = shopifyLineItem.customAttributes.find(
          attribute =>
            attribute.key === LineItemCustomAttributeKeys.GIFT_CARD_ID
        );

        const shopifyLineItemCompositeKey = getCompositeLineItemKey(
          shopifyLineItem.variantId,
          shopifyLineItem.customAttributes
        );

        const matchingIndex = currentConvertedLineItems.findIndex(
          currentConvertedLineItem => {
            const currentLineItemCompositeKey = getCompositeLineItemKey(
              currentConvertedLineItem.variantId,
              currentConvertedLineItem.customAttributes
            );
            return currentLineItemCompositeKey === shopifyLineItemCompositeKey;
          }
        );

        if (matchingIndex >= 0 && !giftCardId) {
          currentConvertedLineItems[matchingIndex].quantity +=
            shopifyLineItem.quantity;
        } else {
          newShopifyLineItems.push(shopifyLineItem);
        }
      });

      const newLineItems = [
        ...currentConvertedLineItems,
        ...newShopifyLineItems,
      ];

      if (isEmpty(newLineItems)) {
        Logger.error('There are no current or new items to add to cart');
        return undefined;
      }

      const lineItemsWithCartAttribute =
        await CartCustomAttributeUtil.addCartCustomAttributeToLineItems(
          newLineItems,
          checkout.id
        );

      const performAddToCart = async (checkoutId: string) => {
        // it is possible the current variants are invalid, first get checkout
        // and validate variants
        const currentCheckoutData = await getCartDetails(checkoutId);
        await validateAndUpdateLineItems(currentCheckoutData, cache);

        return await shopifyClient.mutate({
          mutation: CHECKOUT_LINE_ITEMS_REPLACE,
          update: async (_proxy: DataProxy, result: FetchResult<unknown>) => {
            const { data } = result;
            const checkoutData = get(data, 'checkoutLineItemsReplace');
            updateCartDetails(cache, checkoutData);
            tryTrackAddToCart(lineItems, get(checkoutData, 'checkout'));
          },

          variables: {
            checkoutId,
            lineItems: lineItemsWithCartAttribute,
          },
        });
      };

      try {
        const result = await performAddToCart(cartId);
        return await result;
      } catch (error) {
        // Most common error is "Checkout is already completed", so
        // check if that is the case, renew checkout ID only if needed,
        // and try again...
        const { cartId: newCheckoutId } = await getCurrentCartId();
        if (newCheckoutId && newCheckoutId !== cartId) {
          return await performAddToCart(newCheckoutId);
        }

        Logger.error('Error adding to cart', error);

        return {}; // return empty object to clear out the cache
      }
    },

    applyPromoCode: async (
      _: unknown,
      { promoCode }: { promoCode: string },
      { cache }: { cache: DataProxy }
    ) => {
      const { cartId: checkoutId } = await getCurrentCartId();
      let applyCodeErrors;
      let checkoutData;
      await shopifyClient.mutate({
        mutation: CHECKOUT_DISCOUNT_CODE_APPLY,
        update: async (_proxy: DataProxy, { data }: FetchResult<unknown>) => {
          if (data) {
            checkoutData = get(data, 'checkoutDiscountCodeApplyV2');
            updateCartDetails(cache, checkoutData);
            applyCodeErrors = get(checkoutData, 'checkoutUserErrors');
          }
        },

        variables: {
          checkoutId,
          discountCode: promoCode,
        },
      });

      if (!isEmpty(applyCodeErrors)) {
        throw new ApolloError({
          errorMessage: 'Promo code error',
          extraInfo: applyCodeErrors,
        });
      }

      return checkoutData;
    },

    removeItemsFromCart: async (
      _: unknown,
      { cartLineItemIds }: { cartLineItemIds: string[] },
      { cache }: { cache: ApolloCache<InMemoryCache> }
    ) => {
      try {
        const checkout = await getCheckoutFromCache();
        const lineItems = getLineItemsFromCart(checkout);

        const filteredLineItems = lineItems.filter(item => {
          // TODO Bundles: https://app.clubhouse.io/verishoptechnologyv2/story/9081/add-a-utility-function-for-creating-the-composite-key
          const selectedBundleId = getBundleId(item);
          return !cartLineItemIds.includes(
            selectedBundleId
              ? `${item.variant.sku}~${selectedBundleId}`
              : item.id
          );
        });

        const newLineItems: CheckoutLineItemInput[] | null =
          convertLineItemsToCheckoutInput(filteredLineItems);

        // if not undefined/null
        if (newLineItems) {
          return await shopifyReplaceCheckoutItems(
            cache,
            checkout.id,
            newLineItems
          );
        }
      } catch (error) {
        Logger.error(
          'Error trying to execute removeItemFromCart, Error: ',
          error
        );
      }
    },

    removePromoCode: async (
      _: unknown,
      { promoCode }: { promoCode: string },
      { cache }: { cache: DataProxy }
    ) => {
      const { cartId: checkoutId } = await getCurrentCartId();
      return shopifyClient.mutate({
        mutation: CHECKOUT_DISCOUNT_CODE_REMOVE,
        update: async (_proxy: DataProxy, { data }: FetchResult<unknown>) => {
          if (data) {
            updateCartDetails(cache, get(data, 'checkoutDiscountCodeRemove'));
          }
        },

        variables: {
          checkoutId,
          discountCode: promoCode,
        },
      });
    },

    setGiftCardMessage: setGiftCardMessageResolver,

    setGiftNote: async (
      _: unknown,
      { giftNote }: { giftNote: string },
      { cache }: { cache: ApolloCache<InMemoryCache> }
    ) => {
      const giftNoteSanitized = escape(giftNote);
      try {
        return updateGiftNote(giftNote || '', cache);
      } catch (error) {
        Logger.error(
          `Error trying to set GIFT NOTE with value: ${giftNoteSanitized} .`,
          error
        );
      }
      return null;
    },

    syncCartId: async (
      _: unknown,
      { newCartId }: { newCartId: string },
      { cache }: { cache: ApolloCache<InMemoryCache> }
    ) => {
      const { cartId: currentCartId = null } = await getCurrentCartId();
      return syncCarts({ cache, currentCartId, newCartId });
    },

    updateCartItemQuantity: async (
      _: unknown,
      { newQuantity, quantityMap, variantIds }: never,
      { cache }: { cache: ApolloCache<InMemoryCache> }
    ) => {
      try {
        const { id: checkoutId, lineItems } = await getCheckout(cache);

        // Find the line items by id, update their quantity and keep the rest the same...
        const newLineItems = lineItems.edges.map(
          (lineItem: { node: ShopifyCartLineItem }) => {
            const {
              node: {
                customAttributes,
                quantity,
                variant: { id, sku },
              },
            } = lineItem;
            const filteredCustomAttributes = customAttributes.map(
              omitTypeNameFromCustomAttribute
            );

            let compositeKey = sku;
            const selectedBundleId = getBundleId({ customAttributes });
            if (selectedBundleId) {
              compositeKey += `~${selectedBundleId}`;
            }

            return {
              customAttributes: filteredCustomAttributes,
              quantity: variantIds?.includes(compositeKey)
                ? (quantityMap && quantityMap[sku] * newQuantity) || newQuantity
                : quantity,
              variantId: id,
            };
          }
        );

        return await shopifyReplaceCheckoutItems(
          cache,
          checkoutId,
          newLineItems
        );
      } catch (error) {
        Logger.error(
          'Error trying to execute changeCartItemQuantity, Error: ',
          error
        );
      }
    },
  },
  Query: {
    checkout: async (
      _: unknown, // parent state
      __: unknown, // args, could be anything
      { cache }: { cache: DataProxy }
    ): Promise<ShopifyCart> => {
      return await getCheckout(cache);
    },
  },
};
