import { Document } from '@contentful/rich-text-types';
import { Asset, Entry } from 'contentful';

import {
  ILocalizableLink,
  ImageLinkEntry,
  LocalizableLinkEntry,
  TextColor,
} from './_shared';
import { TastemakerModel } from './_tastemakers';

export type RichTextModuleModel = {
  content: Document;
  header: string;
  name: string;
};

export type RichTextModuleEntry = Entry<RichTextModuleModel>;

export type BreadcrumbModel = {
  link?: LocalizableLinkEntry;
  parentBreadcrumb?: BreadcrumbEntry;
  title: string;
};

export type BreadcrumbEntry = Entry<BreadcrumbModel>;

export enum CarouselHeaderLayout {
  embedded = 'embedded',
  inline = 'inline',
  stacked = 'stacked',
}

type CarouselHeader = {
  headerText: string;
  headerType: HeaderType;
  layout: CarouselHeaderLayout;
  link?: LocalizableLinkEntry;
  subHeaderText?: string;
};

export type CarouselHeaderEntry = Entry<CarouselHeader>;

export type ContentfulModel = {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | Record<string, unknown>;
  moduleDescription?: string;
};

export enum HeaderType {
  h1 = 'h1',
  h2 = 'h2',
}

export enum HeroBannerModuleCtaType {
  Button = 'Button',
  Link = 'Link',
}

type HeroBannerModuleModel = {
  backgroundImage: Asset;
  backgroundImageQuality?: ImageQuality;
  ctaType?: HeroBannerModuleCtaType;
  firstCtaText: string;
  firstCtaUrl: string;
  firstLink?: LocalizableLinkEntry;
  header: string;
  mobileBackgroundImage?: Asset;
  mobileBackgroundImageQuality?: ImageQuality;
  secondCtaText?: string;
  secondCtaUrl?: string;
  subHeader: string;
  textColor?: TextColor;
};

export type HeroBannerModuleEntry = Entry<HeroBannerModuleModel>;

export enum ProductCarouselModuleImageType {
  Model = 'Model',
  Product = 'Product',
}

type ProductCarouselModuleModel = {
  headerData?: CarouselHeaderEntry;
  imageType?: ProductCarouselModuleImageType;
  link?: LocalizableLinkEntry;
  skus?: string[];
};

export type ProductCarouselModuleEntry = Entry<ProductCarouselModuleModel>;

type TastemakerCarouselModuleModel = {
  headerData?: CarouselHeaderEntry;
  link?: LocalizableLinkEntry;
  tastemakers: Array<Entry<TastemakerModel>>;
};

export type TastemakerCarouselModuleEntry =
  Entry<TastemakerCarouselModuleModel>;

type ImageCarouselModuleItemModel = {
  image: Asset;
  imageAltText?: string;
  link: LocalizableLinkEntry;
  linkText?: string;
  linkUrl?: string;
  size?: 'small' | 'large';
  subTitle?: string;
  title?: string;
  videoId?: number;
};

export type ImageCarouselModuleItemEntry = Entry<ImageCarouselModuleItemModel>;

export enum ImageCarouselModuleStyles {
  card = 'card',
  cardHorizontal = 'cardHorizontal',
  circle = 'circle',
  largeCardHorizontal = 'largeCardHorizontal',
  list = 'list',
}

type ImageCarouselModuleModel = {
  displayLinksForItems?: boolean;
  headerData?: CarouselHeaderEntry;
  itemSize: 'small' | 'large';
  itemStyle?: ImageCarouselModuleStyles;
  items: ImageCarouselModuleItemEntry[];
  link?: LocalizableLinkEntry;
};

export type ImageCarouselModuleEntry = Entry<ImageCarouselModuleModel>;

type ImageHolderModuleModel = {
  altText: string;
  image: Asset;
};

export type ImageHolderModuleEntry = Entry<ImageHolderModuleModel>;

type StaggeredSectionModuleModel = {
  doNotStagger?: boolean;
  section1Caption?: string;
  section1Description?: string;
  section1Header: string;
  section1Image: Asset;
  section1Link: LocalizableLinkEntry;
  section1ModuleDescription?: string;
  section1SubHeader: string;
  section1Wide: boolean;
  section2Caption?: string;
  section2Description?: string;
  section2Header: string;
  section2Image: Asset;
  section2Link: LocalizableLinkEntry;
  section2ModuleDescription?: string;
  section2SubHeader: string;
  section2Wide: boolean;
};

export type StaggeredSectionModuleEntry = Entry<StaggeredSectionModuleModel>;

type BannerModuleModel = {
  image: Asset;
  imageAltText?: string;
  imageQuality?: ImageQuality;
  link?: LocalizableLinkEntry;
  mobileImage?: Asset;
  mobileImageQuality?: ImageQuality;
  tabletImage?: Asset;
  tabletImageQuality?: ImageQuality;
};

export type BannerModuleEntry = Entry<BannerModuleModel>;

type BrandsLogosModuleItemModel = {
  link?: Entry<ILocalizableLink>;
  logo: Asset;
  name: string;
};

export type BrandsLogosModuleItemEntry = Entry<BrandsLogosModuleItemModel>;

type BrandsLogosModuleModel = {
  items: BrandsLogosModuleItemEntry[];
  name: string;
};

export type BrandsLogosModuleEntry = Entry<BrandsLogosModuleModel>;

type DoubleImageBannerModuleModel = {
  section1ButtonText: string;
  section1Image: Asset;
  section1IsLight: boolean;
  section1Link: LocalizableLinkEntry;
  section1MobileImage?: Asset;
  section1SubTitle?: string;
  section1Title: string;
  section2ButtonText: string;
  section2Image: Asset;
  section2IsLight: boolean;
  section2Link: LocalizableLinkEntry;
  section2MobileImage?: Asset;
  section2SubTitle?: string;
  section2Title: string;
};

export type DoubleImageBannerModuleEntry = Entry<DoubleImageBannerModuleModel>;

type TextBannerModuleModel = {
  link?: LocalizableLinkEntry;
  mobileText?: string;
  text: string;
};

export type TextBannerModuleEntry = Entry<TextBannerModuleModel>;

export type ShortBannerModuleModel = {
  header: string;
  image: Asset;
  link: LocalizableLinkEntry;
  mobileImage?: Asset;
  subHeader?: string;
  textColor?: TextColor;
};

export type ShortBannerModuleEntry = Entry<ShortBannerModuleModel>;

export type SkinnyBannerModuleModel = {
  header: string;
  image: Asset;
  link: LocalizableLinkEntry;
  mobileImage?: Asset;
  name?: string;
  textColor?: TextColor;
};

export type SkinnyBannerModuleEntry = Entry<SkinnyBannerModuleModel>;

export type ButtonRowModuleModel = {
  header?: string;
  imageLinks: ImageLinkEntry[];
  name: string;
};

export type ButtonRowModuleEntry = Entry<ButtonRowModuleModel>;

export type TileGridModuleModel = {
  header?: string;
  subHeader?: string;
  tiles: TileGridModuleTileEntry[];
};

export type TileGridModuleEntry = Entry<TileGridModuleModel>;

export type TileGridModuleTile = {
  image: Asset;
  imageAltText?: string;
  link: Entry<ILocalizableLink>;
  text: string;
};

export type TileGridModuleTileEntry = Entry<TileGridModuleTile>;

export type RelatedLinksModuleModel = {
  imageLinks: ImageLinkEntry[];
  richText: Document;
  subHeader?: string;
};

export type RelatedLinksModuleEntry = Entry<RelatedLinksModuleModel>;

/**
 * Layout Modules
 */
export type LayoutSplitViewModel = {
  contentA: Entry<ContentfulModel>;
  contentB: Entry<ContentfulModel>;
  staggerModules?: boolean;
};

export type LayoutSplitViewEntry = Entry<LayoutSplitViewModel>;

type LayoutHorizontalStaggerModel = {
  contentModules: Array<Entry<ContentfulModel>>;
};
export type LayoutHorizontalStaggerEntry = Entry<LayoutHorizontalStaggerModel>;

export type GridLayoutModuleModel = {
  largeItemHeader: string;
  largeItemImage: Asset;
  largeItemLink: LocalizableLinkEntry;
  largeItemMobileImage?: Asset;
  largeItemSubHeader: string;
  largeItemTextColor?: TextColor;
  link: LocalizableLinkEntry;
  smallItemOneHeader: string;
  smallItemOneImage: Asset;
  smallItemOneLink: LocalizableLinkEntry;
  smallItemOneMobileImage?: Asset;
  smallItemOneSubHeader: string;
  smallItemOneTextColor?: TextColor;
  smallItemTwoHeader: string;
  smallItemTwoImage: Asset;
  smallItemTwoLink: LocalizableLinkEntry;
  smallItemTwoMobileImage?: Asset;
  smallItemTwoSubHeader: string;
  smallItemTwoTextColor?: TextColor;
};

export type GridLayoutModuleEntry = Entry<GridLayoutModuleModel>;

export type ShoppablePhotoModel = {
  linkedProducts: Array<Entry<ShoppablePhotoProductItemModel>>;
  photo: Asset;
};

export type ShoppablePhotoProductItemModel = {
  sku: string;
  summaryText: string;
};

export type ShoppablePhotoEntry = Entry<ShoppablePhotoModel>;

type SplitImageBannerModuleModel = {
  buttonText: string;
  image: Asset;
  link: LocalizableLinkEntry;
  mobileImage: Asset;
  subtitle?: string;
  title: string;
};

export type SplitImageBannerModuleEntry = Entry<SplitImageBannerModuleModel>;

export type StackingProductBlocksModuleModel = {
  link?: LocalizableLinkEntry;
  skus: string[];
};

export type StackingProductBlocksModuleEntry =
  Entry<StackingProductBlocksModuleModel>;
export type StackingTilesModuleModel = {
  tiles: StackingTilesModuleTileEntry[];
};

export type StackingTilesModuleEntry = Entry<StackingTilesModuleModel>;

export enum StackingTilesModuleTileTextPlacement {
  Below = 'Below',
  Inside = 'Inside',
}

export type StackingTilesModuleTileModel = {
  header: string;
  image: Asset;
  imageQuality?: ImageQuality;
  link: LocalizableLinkEntry;
  subHeader?: string;
  textColor?: TextColor;
  textPlacement?: StackingTilesModuleTileTextPlacement;
};

export type StackingTilesModuleTileEntry = Entry<StackingTilesModuleTileModel>;

export type MultiLinkBannerModuleModel = {
  header?: string;
  image: Asset;
  imageAltText?: string;
  links: LocalizableLinkEntry[];
  mobileImage?: Asset;
  textColor?: TextColor;
};

export type MultiLinkBannerModuleEntry = Entry<MultiLinkBannerModuleModel>;

export type TallHeroBannerModuleModel = {
  buttons: TallHeroBannerModuleButtonEntry[];
  header?: string;
  headerIsTitleHeader: boolean;
  horizontalAlignment: TallHeroBannerModuleHorizontalAlignment;
  image: Asset;
  imageQuality?: ImageQuality;
  link: LocalizableLinkEntry;
  mobileImage?: Asset;
  mobileImageQuality?: ImageQuality;
  subHeader?: string;
  textColor: TextColor;
  verticalAlignment: TallHeroBannerModuleVerticalAlignment;
};

export enum TallHeroBannerModuleHorizontalAlignment {
  Center = 'Center',
  Left = 'Left',
  Right = 'Right',
}

export enum TallHeroBannerModuleVerticalAlignment {
  Bottom = 'Bottom',
  Center = 'Center',
}

type TallHeroBannerModuleButtonModel = {
  buttonType: TallHeroBannerModuleButtonType;
  link: LocalizableLinkEntry;
};

export type TallHeroBannerModuleButtonEntry =
  Entry<TallHeroBannerModuleButtonModel>;

export enum TallHeroBannerModuleButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum ImageQuality {
  Maximum = 'Maximum',
  Optimized = 'Optimized',
  Original = 'Original',
}

export type TallHeroBannerModuleEntry = Entry<TallHeroBannerModuleModel>;

export type YoutubeVideoModuleModel = {
  name: string;
  url: string;
  videoId: string;
};

export type YoutubeVideoModuleEntry = Entry<YoutubeVideoModuleModel>;
