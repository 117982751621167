import compact from 'lodash/compact';
import flatten from 'lodash/flatten';

import {
  CONTENTFUL_LAYOUT_MODULES,
  ContentfulContentTypeEnum,
  LayoutHorizontalStaggerEntry,
  LayoutSplitViewEntry,
} from 'lib/contentful';
import Logger from 'lib/utils/Logger';

const CONTENTFUL_CDN_HOST = 'ctfassets.net';

type Dimensions = {
  height: number;
  width: number;
};

export interface Entry<T> {
  fields: T;
  sys: {
    contentType: {
      sys: {
        id: string | ContentfulContentTypeEnum;
      };
    };
  };
}

export interface Asset {
  fields: {
    description: string;
    file: {
      contentType: string;
      details: {
        image: Dimensions;
      };
      fileName: string;
      url: string;
    };
    title: string;
  };
}

export const handleProtocolRelativeUrl = (url: string) =>
  url.substring(0, 2) === '//' ? `https:${url}` : url;

/**
 * @param asset - Contentful Asset (e.g. image)
 * @returns the url for the asset
 */
export const getUrlFromAsset = (asset: Asset) => {
  const contentfulUrl = asset.fields.file.url;
  return handleProtocolRelativeUrl(contentfulUrl);
};

export const getDimensionsFromAsset = (
  asset: Asset
): Dimensions | undefined => {
  try {
    return asset.fields.file.details.image;
  } catch (error) {
    Logger.warn(
      `Unable to get Dimensions from Asset ${JSON.stringify(asset)}`,
      error
    );
  }
};

export const isContentfulUrl = (url: string) =>
  url.includes(CONTENTFUL_CDN_HOST);

/**
 * Extract content modules from "layout" entries
 */
const findContentOfTypeFromLayoutModules = (
  contentModules: Array<Entry<unknown>>,
  contentType: ContentfulContentTypeEnum
): Array<Entry<unknown>> => {
  const resultsInLayoutModules = contentModules.map(mod => {
    const thisContentType = mod.sys.contentType.sys
      .id as ContentfulContentTypeEnum;

    switch (thisContentType) {
      case ContentfulContentTypeEnum.moduleLayoutSplitView: {
        return recursivelyFindContentOfType(
          [
            (mod as LayoutSplitViewEntry).fields.contentA,
            (mod as LayoutSplitViewEntry).fields.contentB,
          ],
          contentType
        );
      }

      case ContentfulContentTypeEnum.moduleLayoutHorizontalStagger: {
        return recursivelyFindContentOfType(
          (mod as LayoutHorizontalStaggerEntry).fields.contentModules,
          contentType
        );
      }

      default:
        Logger.warn(
          `Unable to extract modules from unknown layout type '${thisContentType}'`
        );
        break;
    }
  });

  return flatten(compact(resultsInLayoutModules));
};

export const isContentfulLayoutModule = (entry: Entry<unknown>) =>
  CONTENTFUL_LAYOUT_MODULES.includes(
    entry.sys.contentType.sys.id as ContentfulContentTypeEnum
  );

/**
 * Recursively searches through all modules (and inside of layout modules) for specified contentType.
 */
export const recursivelyFindContentOfType = (
  contentModules: Array<Entry<unknown>>,
  contentType: ContentfulContentTypeEnum
): Array<Entry<unknown>> => {
  const modulesOfType = contentModules.filter(
    mod => mod.sys.contentType.sys.id === contentType
  );

  const layoutModules = contentModules.filter(isContentfulLayoutModule);
  const modulesInsideLayouts = layoutModules.length
    ? findContentOfTypeFromLayoutModules(layoutModules, contentType)
    : [];

  return [...modulesOfType, ...modulesInsideLayouts];
};

export const handleContentfulError = (
  contentfulType: string,
  error: Error | unknown
) => {
  Logger.error(
    `Error when trying to render ${contentfulType} (contentful type) component`,
    error
  );
};
