import 'components/Grid/setup';
import 'styles/app.scss';

import { ApolloProvider } from '@apollo/client';
import { AppProps } from 'next/app';
import React, { ReactElement, useMemo } from 'react';

import AuthProvider from 'components/AuthProvider/AuthProvider';
import Identifier from 'components/Identifier/Identifier';
import GuestCheckoutAuthModal from 'components/Modals/GuestCheckoutAuthModal/GuestCheckoutAuthModal';
import GuestCheckoutAuthModalProvider from 'components/Modals/GuestCheckoutAuthModal/GuestCheckoutAuthModalProvider';
import { UpsellitWidget } from 'components/UpsellitWidget/UpsellitWidget';
import getConfig from 'config/config';

import algoliaClient from 'lib/algolia/client';
import { useApollo } from 'lib/apollo/useApollo';
import { configureAuth } from 'lib/auth';
import AlgoliaContext from 'lib/context/AlgoliaContext';
import { ExperimentProvider } from 'lib/experiment';
import Bugsnag from 'lib/utils/bugsnag';

import { getAlgoliaInitialState } from '../lib/algolia/getAlgoliaServerState';
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const BugsnagReactPlugin = Bugsnag.getPlugin('react')!;
const BugsnagClientErrorBoundary =
  BugsnagReactPlugin.createErrorBoundary(React);
configureAuth();

/**
 * App is responsible for rendering page components in `pages/*`.
 * It is mounted once on the client, when the page first loads.
 * It lives for the lifetime of the client; it is never unmounted.
 */
const VerishopApp = (props: AppProps): ReactElement => {
  const { Component, pageProps } = props;

  const apolloClient = useApollo(pageProps);
  const currentUrl = useMemo(
    () => getConfig('baseUrl') + props.router?.asPath,
    [props.router?.asPath]
  );
  const algoliaInitialState = useMemo(
    () => getAlgoliaInitialState(pageProps),
    [pageProps]
  );

  return (
    <div data-hj-ignore-attributes>
      <BugsnagClientErrorBoundary>
        <AuthProvider>
          <ApolloProvider client={apolloClient}>
            <ExperimentProvider>
              <GuestCheckoutAuthModalProvider>
                <AlgoliaContext.Provider
                  value={{
                    algoliaClient,
                    initialState: algoliaInitialState,
                  }}
                >
                  <Component {...pageProps} currentUrl={currentUrl} />
                  <GuestCheckoutAuthModal />
                  <UpsellitWidget />
                </AlgoliaContext.Provider>
              </GuestCheckoutAuthModalProvider>
            </ExperimentProvider>
            <Identifier />
          </ApolloProvider>
        </AuthProvider>
      </BugsnagClientErrorBoundary>
    </div>
  );
};

export default VerishopApp;
