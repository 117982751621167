import { useCallback } from 'react';

import { resetAnalytics, trackSignOut } from 'lib/analytics';
import { signOut } from 'lib/auth';
import { useAuthContext } from 'lib/context/AuthContext';
import {
  YOTPO_USER_EMAIL_KEY,
  YOTPO_USER_ID_KEY,
} from 'lib/page/multiStorageScript';
import { setLocalCartId as shopifySetCartId } from 'lib/shopify/utils';
import Logger from 'lib/utils/Logger';

export const useSignOut = () => {
  const { clearCurrentUserData } = useAuthContext();

  const handleSignOut = useCallback(async () => {
    try {
      clearCurrentUserData();
      await shopifySetCartId(undefined);
      trackSignOut();
      resetAnalytics();
      window.VerishopMultiStorage.setItem(YOTPO_USER_ID_KEY, '');
      window.VerishopMultiStorage.setItem(YOTPO_USER_EMAIL_KEY, '');
      await signOut();
    } catch (error) {
      Logger.error('Error in useSignOut', error);
    }
  }, [clearCurrentUserData]);

  return handleSignOut;
};
