import Link from 'next/link';
import { ReactElement } from 'react';

import { pageRoutes } from 'lib/routes';

type OrderLinkProps = {
  children: ReactElement;
  orderId?: string;
  passHref?: boolean;
};

const OrderMerchantInfoLink = ({
  children,
  orderId,
  passHref,
}: OrderLinkProps): ReactElement => {
  const displayUrl = pageRoutes.order.merchantInfo.displayUrl(orderId);

  return (
    <Link href={displayUrl} passHref={passHref}>
      {children}
    </Link>
  );
};

export default OrderMerchantInfoLink;
