import idx from 'idx';
import find from 'lodash/find';
import get from 'lodash/get';
import last from 'lodash/last';
import parseInt from 'lodash/parseInt';
import set from 'lodash/set';
import qs from 'qs';
import urlParse from 'url-parse';

import { MarketingCardUtil } from 'components/ProductGrid/MarketingCard/marketingCardUtil';
import getConfig from 'config/config';

import { DEFAULT_HITS_PERPAGE } from 'lib/algolia/constants';
import { PlpMarketingCardEntry } from 'lib/contentful';
import { isBrowser } from 'lib/utils/browser';

import { SearchStateProps } from './types';

const primaryIndexName = getConfig('algolia.indexName');
const DEFAULT_PAGE_NUM = 1;
export const MAX_FILTER_PRICE = 99999;
export const MAX_SLIDER_RANGE = 600;
export const REFINEMENT_SEPARATOR = '~';

export const PROMO_ELIGIBLE_FILTER_QUERY_PARAM = 'promoEligible';

export enum AlgoliaShippingMethodEnum {
  TWO_DAY_ELIGIBLE = 'TWO_DAY_ELIGIBLE',
}

export const AlgoliaShippingMethodToLabel = {
  [AlgoliaShippingMethodEnum.TWO_DAY_ELIGIBLE]: 'Fast, Free Shipping',
};

export enum AlgoliaFacetOptionEnum {
  BRAND = 'product.brand',
  CATEGORY0 = 'product.hierarchicalCategories.lvl0',
  CATEGORY1 = 'product.hierarchicalCategories.lvl1',
  CATEGORY2 = 'product.hierarchicalCategories.lvl2',
  CATEGORY3 = 'product.hierarchicalCategories.lvl3',
  COLOR = 'colorFamilyName',
  DELIVERY_OPTION = 'deliveryOption',
  PRICE = 'price',
  PRICE_RANGE = 'priceRange',
  RESPONSIBLE = 'filterables.responsible',
  SHOP_BY = 'filterables.shopBy',
}

export enum AlgoliaSortOptionEnum {
  BEST_SELLER = 'best-seller',
  NEWEST = 'newest',
  PERCENT_OFF_DESC = 'percent-off-descending',
  PRICE_ASC = 'price-ascending',
  PRICE_DESC = 'price-descending',
  RECOMMENDED = 'recommended',
  RECOMMENDED_EXP1 = 'recommended-exp1',
  REVIEWS = 'customer-review',
}

/**
 * Maps sort values to algolia sort replica index names
 * Ensure default sort is the first element
 */
export type SortOption = {
  label: string;
  queryParamValue: AlgoliaSortOptionEnum;
  value: string;
};

const EXPERIMENT1_SUFFIX = 'experiment1';

const sortOptionsByKey = {
  [AlgoliaSortOptionEnum.RECOMMENDED]: {
    label: 'Recommended',
    queryParamValue: AlgoliaSortOptionEnum.RECOMMENDED,
    value: `${primaryIndexName}_cm`,
  },
  [AlgoliaSortOptionEnum.RECOMMENDED_EXP1]: {
    // This is an alternative index just to test different recommendation models in AB test, thus with same label
    label: 'Recommended',
    queryParamValue: AlgoliaSortOptionEnum.RECOMMENDED_EXP1,
    value: `${primaryIndexName}_automerching_${EXPERIMENT1_SUFFIX}`,
  },
  [AlgoliaSortOptionEnum.BEST_SELLER]: {
    label: 'Best Seller',
    queryParamValue: AlgoliaSortOptionEnum.BEST_SELLER,
    value: `${primaryIndexName}`,
  },
  [AlgoliaSortOptionEnum.NEWEST]: {
    label: 'Newest',
    queryParamValue: AlgoliaSortOptionEnum.NEWEST,
    value: `${primaryIndexName}_newest`,
  },
  [AlgoliaSortOptionEnum.REVIEWS]: {
    label: 'Review Score',
    queryParamValue: AlgoliaSortOptionEnum.REVIEWS,
    value: `${primaryIndexName}_customer_review`,
  },
  [AlgoliaSortOptionEnum.PRICE_ASC]: {
    label: 'Price - Low to High',
    queryParamValue: AlgoliaSortOptionEnum.PRICE_ASC,
    value: `${primaryIndexName}_price_asc`,
  },
  [AlgoliaSortOptionEnum.PRICE_DESC]: {
    label: 'Price - High to Low',
    queryParamValue: AlgoliaSortOptionEnum.PRICE_DESC,
    value: `${primaryIndexName}_price_desc`,
  },
  [AlgoliaSortOptionEnum.PERCENT_OFF_DESC]: {
    label: 'Percent off',
    queryParamValue: AlgoliaSortOptionEnum.PERCENT_OFF_DESC,
    value: `${primaryIndexName}_percent_off`,
  },
};

export const getDefaultSortOption = (url?: string): SortOption => {
  const currentUrl = isBrowser() ? window.location.href : url;
  if (currentUrl?.includes('/brand/')) {
    return sortOptionsByKey[AlgoliaSortOptionEnum.NEWEST];
  }
  return sortOptionsByKey[AlgoliaSortOptionEnum.BEST_SELLER];
};

export const SortOptions = [
  sortOptionsByKey[AlgoliaSortOptionEnum.BEST_SELLER],
  sortOptionsByKey[AlgoliaSortOptionEnum.RECOMMENDED],
  sortOptionsByKey[AlgoliaSortOptionEnum.REVIEWS],
  sortOptionsByKey[AlgoliaSortOptionEnum.NEWEST],
  sortOptionsByKey[AlgoliaSortOptionEnum.PERCENT_OFF_DESC],
  sortOptionsByKey[AlgoliaSortOptionEnum.PRICE_ASC],
  sortOptionsByKey[AlgoliaSortOptionEnum.PRICE_DESC],
];

export const getAlgoliaIndexFromEnum = (
  sortOptionEnum: AlgoliaSortOptionEnum
): string | undefined => sortOptionsByKey[sortOptionEnum]?.value;

export const querySortValueToAlgoliaIndex = (
  queryValue: AlgoliaSortOptionEnum,
  defaultSort: SortOption
): string => (sortOptionsByKey[queryValue] ?? defaultSort).value;

export const algoliaIndexNameToQueryStringValue = (
  algoliaIndexName: string,
  defaultSort: SortOption
): AlgoliaSortOptionEnum => {
  const sortOption: SortOption =
    find(
      Object.values(sortOptionsByKey),
      option => option.value === algoliaIndexName
    ) ?? defaultSort;
  return sortOption.queryParamValue;
};

const facetNameToQueryParam: { [key: string]: string } = {
  colorFamilyName: 'color',
  'filterables.responsible': 'responsible',
  'product.brand': 'brand',
  'product.hierarchicalCategories.lvl0': 'sc0',
  'product.hierarchicalCategories.lvl1': 'sc1',
  'product.hierarchicalCategories.lvl2': 'sc2',
  'product.hierarchicalCategories.lvl3': 'sc3',
};

const queryParamToFacetName: { [key: string]: string } = {
  brand: 'product.brand',
  color: 'colorFamilyName',
  responsible: 'filterables.responsible',
  sc0: 'product.hierarchicalCategories.lvl0',
  sc1: 'product.hierarchicalCategories.lvl1',
  sc2: 'product.hierarchicalCategories.lvl2',
  sc3: 'product.hierarchicalCategories.lvl3',
};

/* All search filters must be defined here.
 * The search state is bound to the URL query parameters,
 *  so any time a filter is updated, the URL must reflect that.
 */
export const searchFiltersByQueryParam = {
  page: 'page',
  q: 'configure.query',
  sort: 'sortBy',
};

type SearchQueryParams = {
  [key: string]: string;
};

/**
 * This peeks inside of the current Algolia search state and generates
 * a pretty set of query parameters that we can tack on to our current URL.
 */
export const searchStateToQueryParams = (
  searchState: SearchStateProps,
  defaultSort: SortOption
): SearchQueryParams => {
  const queryParams: SearchQueryParams = {};
  // STATIC FILTERS

  // `page` (page)
  const pageNum = get(searchState, searchFiltersByQueryParam.page);
  if (parseInt(pageNum) === DEFAULT_PAGE_NUM) {
    // NOOP: this is default, do not append query param
  } else if (pageNum) {
    queryParams.page = pageNum;
  }

  // `q` (search)
  const searchQuery = get(searchState, searchFiltersByQueryParam.q);
  if (searchQuery) {
    queryParams.q = searchQuery;
  }

  // `sort` (sort)
  const sort = get(searchState, searchFiltersByQueryParam.sort);
  if (sort === defaultSort.value) {
    // NOOP: this is default, do not append query param
  } else if (sort) {
    queryParams.sort = algoliaIndexNameToQueryStringValue(sort, defaultSort);
  }

  // DYNAMIC FILTERS
  // the rest of the filters are part of the refinementList object
  const refinementList = idx(searchState, _ => _.refinementList) || {};

  for (const refinement in refinementList) {
    if (
      refinementList.hasOwnProperty(refinement) &&
      refinementList[refinement]
    ) {
      const queryParamsRefinementKey =
        facetNameToQueryParam[refinement] || refinement;

      queryParams[queryParamsRefinementKey] =
        refinementList[refinement].join(REFINEMENT_SEPARATOR);
    }
  }

  // SYNTHETIC FACET ITEM FILTER PARAMS
  // ex: 'Promo Eligible Filter'
  // These parameters are directly added to
  if (
    searchState?.configure?.filters &&
    searchState.configure.filters.includes('eligiblePromotions')
  ) {
    queryParams[PROMO_ELIGIBLE_FILTER_QUERY_PARAM] = 'true';
  } else {
    delete queryParams[PROMO_ELIGIBLE_FILTER_QUERY_PARAM];
  }

  return queryParams;
};

export const urlToSearchState = (
  defaultSort: SortOption,
  url: string,
  ignoreQueryParams?: boolean
): SearchStateProps => {
  const { query } = urlParse(url, true);

  const searchState: SearchStateProps = {
    configure: {},
    page: DEFAULT_PAGE_NUM,
    refinementList: {},
  };

  // STATIC FILTERS

  // search (q)
  if (query.q) {
    set(searchState, searchFiltersByQueryParam.q, query.q);
  }
  delete query.q;

  // page (page)
  if (query.page) {
    set(searchState, searchFiltersByQueryParam.page, parseInt(query.page));
  } else {
    set(searchState, searchFiltersByQueryParam.page, DEFAULT_PAGE_NUM); // TODO this can prob be removed since page: DEFAULT_PAGE_NUM already
  }
  delete query.page;

  // sort (sort)
  if (query.sort) {
    set(
      searchState,
      searchFiltersByQueryParam.sort,
      querySortValueToAlgoliaIndex(
        query.sort as AlgoliaSortOptionEnum,
        defaultSort
      )
    );
  } else {
    set(searchState, searchFiltersByQueryParam.sort, defaultSort.value); // TODO delete and make default for sort in searchState
  }
  delete query.sort;

  if (ignoreQueryParams) {
    return searchState;
  }

  // DYNAMIC FILTERS
  // after deleting all static keys, we can now iterate over the object
  for (const refinement in query) {
    if (query.hasOwnProperty(refinement)) {
      const expandedAlgoliaKey =
        queryParamToFacetName[refinement] || refinement;

      searchState.refinementList[expandedAlgoliaKey] =
        query[refinement]?.split(REFINEMENT_SEPARATOR) ?? [];
    }
  }

  return searchState;
};

export const escapeSingleQuotes = (str: string): string =>
  str.replace(/'/g, "\\'");

export const parseHierarchicalFacetLabel = (
  label: string
): string | undefined => last(label.split(' > '));

export const getHitsPerPage = (
  plpMarketingCardEntries?: PlpMarketingCardEntry[]
): number => {
  // Temporarily disabled PLP Marketing cards:
  return DEFAULT_HITS_PERPAGE;

  const numberOfPlpMarketingCards = MarketingCardUtil.getNumberOfMarketingCards(
    plpMarketingCardEntries
  );

  return DEFAULT_HITS_PERPAGE - numberOfPlpMarketingCards;
};

export function formatUrl(urlObj: {
  pathname: string;
  query: SearchQueryParams;
}) {
  const queryString = qs.stringify(urlObj.query, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
    format: 'RFC1738',
  });
  return `${urlObj.pathname}${queryString}`;
}
