import { pageRoutes } from 'lib/routes';

type SignupLinkProps = {
  redirectUrl?: string | string[] | undefined;
} & React.LinkHTMLAttributes<HTMLAnchorElement>;

const SignupLink = ({ children, redirectUrl, ...rest }: SignupLinkProps) => {
  const displayUrl = pageRoutes.auth.signUp.displayUrl({
    redirectUrl: redirectUrl,
  });

  return (
    <a href={displayUrl} {...rest}>
      {children}
    </a>
  );
};

export default SignupLink;
