import { Entry } from 'contentful';
import idx from 'idx';

import getConfig from 'config/config';
import { IS_DEVELOPMENT } from 'config/utils/env';

import { ContentPageType, PLP_TYPE } from 'lib/analytics';
import { mockAnalytics } from 'lib/analytics/utils';
import { minifyStringForHtml } from 'lib/formatters';
import { PRERENDER_QUERY_PARAM } from 'lib/page/globalVarsScript';
import { LOGIN_PATH, SIGNUP_PATH } from 'lib/routes';
import Logger from 'lib/utils/Logger';

import { AttributionInput } from 'types/generated/api';

/**
 * Global toggle for analytics, disabled in development by default.
 */
export const IS_ANALYTICS_ENABLED = !IS_DEVELOPMENT;

export const CONTENT_MODULE_TRACKING_CLASS = 'content_module_tracking';
export const CONTENT_PAGE_TRACKING_CLASS = 'content_page_tracking';
export const PDP_SECTION_TRACKING_CLASS = 'pdp_section_tracking';
export const PDP_TRACKING_CLASS = 'pdp_tracking';
export const PLP_TRACKING_CLASS = 'plp_page_tracking';
export const POST_TRACKING_CLASS = 'post_tracking';
export const TRUST_CORP_TRACKING_CLASS = 'trust_corp_tracking';

export enum SectionType {
  BRAND_INFO = 'brand_info',
  PRODUCT_INFO = 'product_info',
  PRODUCT_REVIEWS = 'product_reviews',
  PRODUCT_SPEC = 'product_spec',
  RECOMMENDED_POSTS = 'recommended_posts',
  RECOMMENDED_PRODUCTS = 'recommended_products',
  TOP_RECOMMENDED_PRODUCTS = 'top_recommended_products',
}

type SectionAttributes = {
  hasInStockVariants: boolean;
  productId: string;
  section: SectionType;
  sectionPosition: number;
  source: string;
  sourceId: string;
};

export const buildAttributesForSection = ({
  hasInStockVariants,
  productId,
  section,
  sectionPosition,
  source,
  sourceId,
}: SectionAttributes) => ({
  has_in_stock_variants: hasInStockVariants,
  product_id: productId,
  section,
  section_position: sectionPosition,
  source,
  source_id: sourceId,
});

type GtmProductCardAttributes = {
  attribution?: AttributionInput;
  hasInStockVariants?: boolean;
  maxPrice?: number;
  minPrice?: number;
  modelId?: string;
  modelVariantId?: string;
  productId?: string;
  productPosition?: number;
  productSlug?: string;
};

export const buildGtmAttributesForProduct = ({
  attribution,
  hasInStockVariants,
  maxPrice,
  minPrice,
  modelId,
  modelVariantId,
  productId,
  productPosition,
  productSlug,
}: GtmProductCardAttributes) => ({
  'data-attribution-id': attribution?.identifier,
  'data-attribution-type': attribution?.type,
  'data-has-in-stock-variants': hasInStockVariants,
  'data-model-id': modelId,
  'data-model-variant-id': modelVariantId,
  'data-product-id': productId,
  'data-product-position': productPosition,
  'data-product-slug': productSlug,
  'data-variant-max-price': maxPrice,
  'data-variant-min-price': minPrice,
});

type GtmContentPageAttributes = {
  slug: string;
};

type ContentPageDataAttributes = {
  'data-content-page-slug'?: string;
  'data-content-page-type': string;
};

const buildGtmAttributesForContentPage = (
  pageType: ContentPageType,
  attributes: GtmContentPageAttributes | Record<string, never>
) => {
  const dataAttributes: ContentPageDataAttributes = {
    'data-content-page-type': pageType,
  };

  try {
    if ('slug' in attributes) {
      dataAttributes['data-content-page-slug'] = attributes.slug;
    }
  } catch (error) {
    Logger.warn('gtmAttributeBuild error for content page.', error);
  }

  return dataAttributes;
};

export const buildGtmAttributesForCategoryLandingPage = (
  attributes: GtmContentPageAttributes
) =>
  buildGtmAttributesForContentPage(ContentPageType.CategoryLanding, attributes);

export const buildGtmAttributesForDynamicLandingPage = (
  attributes: GtmContentPageAttributes
) =>
  buildGtmAttributesForContentPage(ContentPageType.DynamicLanding, attributes);

export const buildGtmAttributesForEditorialPage = (
  attributes: GtmContentPageAttributes
) => buildGtmAttributesForContentPage(ContentPageType.Editorial, attributes);

export const gtmAttributesForHomePage = () =>
  buildGtmAttributesForContentPage(ContentPageType.Home, {});

export const gtmAttributesForAndroidComingSoon = () =>
  buildGtmAttributesForContentPage(ContentPageType.AndroidComingSoon, {});

export const gtmAttributesForIosOnlyFeature = () =>
  buildGtmAttributesForContentPage(ContentPageType.IosOnlyFeature, {});

type GtmPlpAttributes = {
  slug: string;
};

type GtmSearchAttributes = {
  searchTerm: string;
};

type GtmTastemakerAttributes = {
  slug?: string;
};

type PlpDataAttributes = {
  'data-content-page-search-term'?: string;
  'data-content-page-slug'?: string;
  'data-content-page-type': string;
};

const buildGtmAttributesForPlp = (
  pageType: PLP_TYPE,
  attributes:
    | GtmPlpAttributes
    | GtmSearchAttributes
    | GtmTastemakerAttributes
    | Record<string, never>
) => {
  const dataAttributes: PlpDataAttributes = {
    'data-content-page-type': pageType,
  };

  try {
    if ('searchTerm' in attributes) {
      dataAttributes['data-content-page-slug'] = attributes.searchTerm;
    }

    if ('slug' in attributes) {
      dataAttributes['data-content-page-slug'] = attributes.slug;
    }
  } catch (error) {
    Logger.warn('gtmAttributeBuild error for PLP', error);
  }

  return dataAttributes;
};

export const buildGtmAttributesForBrandPage = (attributes: GtmPlpAttributes) =>
  buildGtmAttributesForPlp(PLP_TYPE.BRAND, attributes);

export const buildGtmAttributesForCategoryPage = (
  attributes: GtmPlpAttributes
) => buildGtmAttributesForPlp(PLP_TYPE.CATEGORY, attributes);

export const buildGtmAttributesForCollectionPage = (
  attributes: GtmPlpAttributes
) => buildGtmAttributesForPlp(PLP_TYPE.COLLECTION, attributes);

export const buildGtmAttributesForSearchPage = (
  attributes: GtmSearchAttributes
) => buildGtmAttributesForPlp(PLP_TYPE.SEARCH, attributes);

export const buildGtmAttributesForTastemakersPage = (
  attributes?: GtmTastemakerAttributes
) => buildGtmAttributesForPlp(PLP_TYPE.TASTEMAKERS, attributes || {});

export const gtmAttributesForSalePage = () =>
  buildGtmAttributesForPlp(PLP_TYPE.SALE, {});

export const gtmAttributesForNewArrivalsPage = () =>
  buildGtmAttributesForPlp(PLP_TYPE.NEW_ARRIVALS, {});

export const buildGtmAttributesForContentModule = (
  index: number | string,
  mod: Entry<any>
) => ({
  'data-content-module-id': idx(mod, _ => _.sys.id),
  'data-content-module-index': String(index),
  'data-content-module-type': idx(mod, _ => _.sys.contentType.sys.id),
});

/**
 * Returns mock analytics if isPrerender=true is in the query-params.
 */
const analyticsForPrerender =
  minifyStringForHtml(`if (window.location.href.includes("${PRERENDER_QUERY_PARAM}=true")) {
  ${mockAnalytics}
  return;
}
`);

export const setSegmentTag = (page: string): { __html: string } => {
  const disableThirdPartyScripts = page === LOGIN_PATH || page === SIGNUP_PATH;
  const loadOptions = disableThirdPartyScripts
    ? JSON.stringify({ integrations: { All: false, 'Segment.io': true } })
    : '';

  const sourceId = getConfig('tracking.segmentSourceId');
  return sourceId
    ? {
        __html: `!function(){${analyticsForPrerender}var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
  analytics.load("${sourceId}", ${loadOptions});
  }}();`,
      }
    : { __html: '' };
};

export const generateAnalyticsScriptTag = (page: string) => {
  if (IS_ANALYTICS_ENABLED) {
    return <script async dangerouslySetInnerHTML={setSegmentTag(page)} defer />;
  } else {
    return <script dangerouslySetInnerHTML={{ __html: mockAnalytics }} />;
  }
};
