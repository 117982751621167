import get from 'lodash/get';

import configDev from './config.dev';
import configProd from './config.prod';
import configStaging from './config.staging';
import configTest from './config.testing';

let config = {};
const CURRENT_ENV = process.env.TRUE_NODE_ENV;

switch (CURRENT_ENV) {
  case 'beta':
  case 'production': {
    config = configProd;
    break;
  }
  case 'staging': {
    config = configStaging;
    break;
  }
  case 'development': {
    config = configDev;
    break;
  }
  case 'test': {
    config = configTest;
    break;
  }
  default: {
    throw new Error(`environment variable TRUE_NODE_ENV is ${CURRENT_ENV}`);
  }
}

const getConfig = (path: string): unknown => {
  const value = get(config, path, undefined);

  if (value === undefined) {
    throw new Error(`\n\nExpected config variable is missing: ${path}`);
  }
  return value;
};

export default getConfig;
