/* THIS IS A GENERATED FILE - DO NOT MODIFY */
/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  BigInt: any;
  AccountNumber: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  Date: any;
  DateTimeISO: any;
  DeweyDecimal: any;
  DID: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  Hexadecimal: any;
  HexColorCode: any;
  HSL: any;
  HSLA: any;
  IBAN: any;
  IP: any;
  IPCPatent: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LCCSubclass: any;
  LocalDate: any;
  LocalDateTime: any;
  Locale: any;
  LocalEndTime: any;
  LocalTime: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  SemVer: any;
  Time: any;
  Timestamp: any;
  TimeZone: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  URL: any;
  USCurrency: any;
  UtcOffset: any;
  UUID: any;
  Void: any;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  affiliateLink: AffiliateLinkResponse;
  /** request all the anonymousUserIdentities we granted to the cognitoUser */
  anonymousUserIdentitiesFromCognitoToken?: Maybe<
    Array<Maybe<AnonymousUserIdentity>>
  >;
  /** request the anonymousUserIdentity from an anonymousUserId */
  anonymousUserIdentityFromAnonymousId?: Maybe<AnonymousUserIdentity>;
  /** customerId is deprecated and not used. */
  bookmarkList?: Maybe<BookmarkList>;
  bookmarkListByOwnerIdAndCollectionSlug?: Maybe<BookmarkList>;
  bookmarkLists?: Maybe<BookmarkListPaginatedResponse>;
  brand?: Maybe<Brand>;
  brandPosts: ExplorerPostResponse;
  brandReviewsPage?: Maybe<ReviewsPage>;
  brands: Array<Maybe<Brand>>;
  categories: Array<Maybe<CategoryV2>>;
  channel?: Maybe<Channel>;
  channelById?: Maybe<Channel>;
  channels: ChannelPaginatedResponse;
  checkout?: Maybe<Checkout>;
  coHostLiveShops: Array<Maybe<LiveShop>>;
  competitorInsights: Array<CompetitorInsights>;
  config?: Maybe<ConfigResponse>;
  createSignedEmbedUrlInput: CreateSignedEmbedUrlResponse;
  creator?: Maybe<Creator>;
  creatorCollectionInsight?: Maybe<CreatorCollectionInsight>;
  creatorInsight?: Maybe<CreatorInsight>;
  creatorLiveShopInsight?: Maybe<CreatorLiveShopInsight>;
  creatorPayPeriodDetail?: Maybe<CreatorPayPeriodDetail>;
  creatorPostInsight?: Maybe<CreatorPostInsight>;
  creatorProductInsight?: Maybe<CreatorProductInsight>;
  customerReviewsPage?: Maybe<ReviewsPage>;
  dataInsights: Array<DataInsights>;
  earnings?: Maybe<Earnings>;
  estimatedDelivery: Array<Maybe<EstimatedDelivery>>;
  estimatedDeliveryGroup: Array<Maybe<EstimatedDelivery>>;
  estimatedDeliveryTwoDayShipping: TwoDayEstimatedDelivery;
  estimatedDeliveryV2: Array<Maybe<EstimatedDeliveryV2>>;
  estimatedDeliveryV3: Array<EstimatedDeliveryV3>;
  experiment?: Maybe<ExperimentResponse>;
  explore: ExplorerPostResponse;
  explorePostDebugInfo?: Maybe<ExplorerPostDebugInfoResponse>;
  explorerContentReportingReasons?: Maybe<
    Array<Maybe<ExplorerContentReportingReasons>>
  >;
  explorerPost?: Maybe<ExplorerPost>;
  explorerPosts: ExplorerPostResponse;
  explorerPostsFromIds?: Maybe<Array<Maybe<ExplorerPost>>>;
  exploreTopics?: Maybe<Array<Maybe<ExplorerTopic>>>;
  getCreateShopifyAppStatus: GetCreateShopifyAppStatusQueryResponse;
  getDynamicModules?: Maybe<Array<Maybe<DynamicModuleResponse>>>;
  getFollowees?: Maybe<ProfileFollowPageResponse>;
  getFollowers: ProfileFollowPageResponse;
  getProfile?: Maybe<Profile>;
  getReviewProviderConfigs?: Maybe<Array<Maybe<ReviewProviderConfig>>>;
  getReviewsForRewards: Array<Maybe<ReviewsForRewardByOrder>>;
  getVpcRequests: GetVerishopProductCodeRequestsResponse;
  getVpcs: GetVerishopProductCodesResponse;
  liveShop?: Maybe<LiveShop>;
  liveShopByInviteCode?: Maybe<LiveShop>;
  liveShopConnectionCounts: Array<Maybe<LiveShopConnectionCounter>>;
  /**
   * Note if using DESC order and a cursor is returned, use as bounds.end in your subsequent request.
   * For ASC, if a cursor is returned, use as bounds.start in your subsequent request.
   */
  liveShopsByDateBounded: LiveShopPaginatedResponse;
  liveShopsByIds: Array<LiveShop>;
  liveShopsByOwner: LiveShopPaginatedResponse;
  liveShopsForYou: LiveShopPaginatedResponse;
  me: Customer;
  merchant?: Maybe<Merchant>;
  merchantInfoForOrder?: Maybe<Array<PublicOrderInfo>>;
  merchantOnboardingCompletionStatus: MerchantOnboardingCompletionStatus;
  merchantProductStats?: Maybe<MerchantProductStats>;
  merchants: PaginatedMerchantsResponse;
  mobileApps: Array<Maybe<MobileApp>>;
  modals: Array<Modal>;
  notificationSettings?: Maybe<Array<Maybe<NotificationSectionResponse>>>;
  ownedLiveShops: LiveShopPaginatedResponse;
  pdpInfo: PdpInfo;
  pimAttribute?: Maybe<PimAttribute>;
  pimAttributes: Array<PimAttribute>;
  pimGetJobsByGroupId: PimGetJobsByGroupIdResponse;
  pimGetOffers: PimGetOffersResponse;
  pimGetSpreadsheetProductVariants: PimGetSpreadsheetProductVariantsResponse;
  pimGetSpreadsheetTemplate: PimGetSpreadsheetTemplateResponse;
  pimOrder?: Maybe<PimOrder>;
  pimProduct?: Maybe<PimProduct>;
  pimProducts: Array<PimProduct>;
  pimProductsForBulkEditing: Array<PimProduct>;
  pimProductVariant?: Maybe<PimProductVariant>;
  pimRejectionReasons: Array<PimRejectionReason>;
  pimRootSchemaCategory: PimSchemaCategory;
  pimSchemaCategories: Array<PimSchemaCategory>;
  pimSchemaCategory?: Maybe<PimSchemaCategory>;
  product: Array<Maybe<Product>>;
  productAttributesRecommendations: ProductAttributesRecommendationsResponse;
  productAttributesRecommendationsV2: ProductAttributesRecommendationsResponse;
  productCategorizationRecommendations: Array<Maybe<Scalars['String']>>;
  productLevelAttributeCodes: Array<Scalars['String']>;
  productPosts: ExplorerPostResponse;
  productV2: ProductsPaginatedResponse;
  productVariant: Array<Maybe<ProductVariant>>;
  profiles: ProfilePageResponse;
  promoCode?: Maybe<PromotionDetailResponse>;
  recommendedBrands: Array<Maybe<Brand>>;
  recommendedMultiKeyProducts: Array<Maybe<Product>>;
  recommendedProducts: Array<Maybe<Product>>;
  recommendedProductVariants: Array<Maybe<ProductVariant>>;
  referral?: Maybe<Referral>;
  shippingTiers?: Maybe<Array<ShippingTier>>;
  /** Queries the shopify product id by Pim product id. Only used in Dash now. */
  shopifyProductIdByPimProductId?: Maybe<Scalars['String']>;
  shopParties: ShopPartyPaginatedResponse;
  shopParty?: Maybe<ShopParty>;
  suggestedProfiles: Array<Maybe<Profile>>;
  tags: Array<Tag>;
  toFollowProfiles: Array<Maybe<Profile>>;
  topProducts: TopProductsResponse;
  trendingProfiles: Array<Maybe<Profile>>;
  /** get a list of customers based on filter, include ownerIds for each */
  users?: Maybe<PagedUser>;
  validateUsername: Scalars['Boolean'];
  verifiedDeal?: Maybe<VerifiedDeal>;
  verifiedDeals: VerifiedDealPaginatedResponse;
  visenzeProductRecommendations: VisenzeProductRecommendations;
  watchlistedLiveShops: LiveShopPaginatedResponse;
};

export type QueryAffiliateLinkArgs = {
  url: Scalars['String'];
};

export type QueryAnonymousUserIdentitiesFromCognitoTokenArgs = {
  cognitoUserToken?: InputMaybe<Scalars['String']>;
};

export type QueryAnonymousUserIdentityFromAnonymousIdArgs = {
  anonymousUserId?: InputMaybe<Scalars['String']>;
};

export type QueryBookmarkListArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type QueryBookmarkListByOwnerIdAndCollectionSlugArgs = {
  collectionSlug: Scalars['String'];
  ownerId: Scalars['String'];
};

export type QueryBookmarkListsArgs = {
  bookmarkListIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['String']>;
};

export type QueryBrandArgs = {
  slug: Scalars['String'];
};

export type QueryBrandPostsArgs = {
  brandSlugs: Array<InputMaybe<Scalars['String']>>;
  pageSize?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
  postTypes?: InputMaybe<Array<InputMaybe<ExplorerPostType>>>;
};

export type QueryBrandReviewsPageArgs = {
  brandSlug: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryChannelArgs = {
  guestUserId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type QueryChannelByIdArgs = {
  guestUserId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type QueryChannelsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  guestUserId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryCompetitorInsightsArgs = {
  inputs: Array<DataInsightsInput>;
};

export type QueryConfigArgs = {
  env?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type QueryCreateSignedEmbedUrlInputArgs = {
  input: CreateSignedEmbedUrlInput;
};

export type QueryCreatorCollectionInsightArgs = {
  collectionId: Scalars['String'];
  periodStartDate: Scalars['String'];
  periodType: CreatorInsightPeriodType;
};

export type QueryCreatorInsightArgs = {
  periodStartDate: Scalars['String'];
  periodType: CreatorInsightPeriodType;
};

export type QueryCreatorLiveShopInsightArgs = {
  liveShopId: Scalars['String'];
  periodStartDate: Scalars['String'];
  periodType: CreatorInsightPeriodType;
};

export type QueryCreatorPayPeriodDetailArgs = {
  periodStartDate: Scalars['String'];
};

export type QueryCreatorPostInsightArgs = {
  periodStartDate: Scalars['String'];
  periodType: CreatorInsightPeriodType;
  postId: Scalars['String'];
};

export type QueryCreatorProductInsightArgs = {
  periodStartDate: Scalars['String'];
  periodType: CreatorInsightPeriodType;
  productId: Scalars['String'];
};

export type QueryCustomerReviewsPageArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  productSid: Scalars['String'];
};

export type QueryDataInsightsArgs = {
  inputs: Array<DataInsightsInput>;
};

export type QueryEarningsArgs = {
  numPayPeriods?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryEstimatedDeliveryArgs = {
  productSid?: InputMaybe<Scalars['String']>;
};

export type QueryEstimatedDeliveryGroupArgs = {
  productSids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEstimatedDeliveryV2Args = {
  aggregate?: InputMaybe<Scalars['Boolean']>;
  productVariantSkus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryEstimatedDeliveryV3Args = {
  lineItems: Array<EstimatedDeliveryLineItem>;
};

export type QueryExperimentArgs = {
  id: Scalars['String'];
  idType: ExperimentIdType;
  isProduction?: InputMaybe<Scalars['Boolean']>;
  namespace: Scalars['String'];
};

export type QueryExploreArgs = {
  customRecommendationUrl?: InputMaybe<Scalars['String']>;
  fetchSource?: InputMaybe<ExplorerFeedFetchSource>;
  guestUserId?: InputMaybe<Scalars['String']>;
  isPullToRefresh?: InputMaybe<Scalars['Boolean']>;
  modelId: Scalars['String'];
  modelVariantId: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  productSkus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  queryToken?: InputMaybe<Scalars['String']>;
  taggedModulesProductLimit?: InputMaybe<Scalars['Int']>;
  topicIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userInterests?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryExplorePostDebugInfoArgs = {
  postId?: InputMaybe<Scalars['String']>;
};

export type QueryExplorerContentReportingReasonsArgs = {
  contentType?: InputMaybe<ExplorerReportedContentType>;
};

export type QueryExplorerPostArgs = {
  postId?: InputMaybe<Scalars['String']>;
  postStatuses?: InputMaybe<Array<InputMaybe<ExplorerPostStatusInput>>>;
  taggedModulesProductLimit?: InputMaybe<Scalars['Int']>;
};

export type QueryExplorerPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
  postStatuses?: InputMaybe<Array<InputMaybe<ExplorerPostStatusInput>>>;
  profileId: Scalars['String'];
  taggedModulesProductLimit?: InputMaybe<Scalars['Int']>;
};

export type QueryExplorerPostsFromIdsArgs = {
  postIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryExploreTopicsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};

export type QueryGetCreateShopifyAppStatusArgs = {
  input: GetCreateShopifyAppStatusQueryInput;
};

export type QueryGetDynamicModulesArgs = {
  modules: Array<InputMaybe<DynamicModuleInput>>;
};

export type QueryGetFolloweesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
  profileId: Scalars['String'];
};

export type QueryGetFollowersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
  profileId: Scalars['String'];
};

export type QueryGetProfileArgs = {
  brandSlug?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type QueryGetReviewProviderConfigsArgs = {
  merchantId?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
};

export type QueryGetVpcRequestsArgs = {
  filter?: InputMaybe<VerishopProductCodeRequestFilter>;
  orderBy?: InputMaybe<VerishopProductCodeRequestOrder>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryGetVpcsArgs = {
  filter?: InputMaybe<VerishopProductCodeFilter>;
  orderBy?: InputMaybe<VerishopProductCodeOrder>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryLiveShopArgs = {
  id: Scalars['String'];
};

export type QueryLiveShopByInviteCodeArgs = {
  inviteCode: Scalars['String'];
};

export type QueryLiveShopConnectionCountsArgs = {
  ids: Array<InputMaybe<Scalars['String']>>;
};

export type QueryLiveShopsByDateBoundedArgs = {
  bounds: LiveShopBounds;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
  privacy?: InputMaybe<Array<InputMaybe<LiveShopPrivacy>>>;
  status?: InputMaybe<Array<InputMaybe<LiveShopStatus>>>;
};

export type QueryLiveShopsByIdsArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryLiveShopsByOwnerArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
  ownerId: Scalars['String'];
  privacy?: InputMaybe<Array<InputMaybe<LiveShopPrivacy>>>;
  status?: InputMaybe<Array<InputMaybe<LiveShopStatus>>>;
};

export type QueryLiveShopsForYouArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
  privacy?: InputMaybe<Array<InputMaybe<LiveShopPrivacy>>>;
};

export type QueryMerchantArgs = {
  id: Scalars['ID'];
};

export type QueryMerchantInfoForOrderArgs = {
  shopifyOrderId: Scalars['String'];
};

export type QueryMerchantOnboardingCompletionStatusArgs = {
  id: Scalars['ID'];
};

export type QueryMerchantProductStatsArgs = {
  email?: InputMaybe<Scalars['String']>;
  shopDomain: Scalars['String'];
};

export type QueryMerchantsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
  status?: InputMaybe<MerchantStatus>;
  updatedAfter?: InputMaybe<Scalars['DateTime']>;
};

export type QueryOwnedLiveShopsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
  privacy?: InputMaybe<Array<InputMaybe<LiveShopPrivacy>>>;
  status?: InputMaybe<Array<InputMaybe<LiveShopStatus>>>;
};

export type QueryPdpInfoArgs = {
  sid?: InputMaybe<Scalars['String']>;
};

export type QueryPimAttributeArgs = {
  code: Scalars['String'];
};

export type QueryPimGetJobsByGroupIdArgs = {
  groupId: Scalars['ID'];
};

export type QueryPimGetOffersArgs = {
  input: PimGetOffersInput;
};

export type QueryPimGetSpreadsheetProductVariantsArgs = {
  input: PimGetSpreadsheetProductVariantsInput;
};

export type QueryPimGetSpreadsheetTemplateArgs = {
  input: PimGetSpreadsheetTemplateInput;
};

export type QueryPimOrderArgs = {
  id: Scalars['String'];
};

export type QueryPimProductArgs = {
  id: Scalars['String'];
};

export type QueryPimProductsArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryPimProductsForBulkEditingArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryPimProductVariantArgs = {
  id: Scalars['String'];
};

export type QueryPimSchemaCategoryArgs = {
  code: Scalars['String'];
};

export type QueryProductArgs = {
  isCollectionsNeeded?: InputMaybe<Scalars['Boolean']>;
  omitDiscontinued?: InputMaybe<Scalars['Boolean']>;
  sids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryProductAttributesRecommendationsArgs = {
  pimProductId: Scalars['String'];
};

export type QueryProductAttributesRecommendationsV2Args = {
  pimProductId: Scalars['String'];
};

export type QueryProductCategorizationRecommendationsArgs = {
  pimProductId: Scalars['String'];
};

export type QueryProductPostsArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
  postTypes?: InputMaybe<Array<InputMaybe<ExplorerPostType>>>;
  productIds: Array<InputMaybe<Scalars['String']>>;
};

export type QueryProductV2Args = {
  filters?: InputMaybe<FiltersExpression>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderByCondition>;
};

export type QueryProductVariantArgs = {
  filterByMarketplace?: InputMaybe<Scalars['Boolean']>;
  filterByPlpGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterByShoppable?: InputMaybe<Scalars['Boolean']>;
  sids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  skus?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryProfilesArgs = {
  profileIds: Array<InputMaybe<Scalars['String']>>;
};

export type QueryRecommendedBrandsArgs = {
  key: RecommendationKey;
  page?: InputMaybe<RecommendationPage>;
};

export type QueryRecommendedMultiKeyProductsArgs = {
  key: MultiKeyRecommendationKey;
  page?: InputMaybe<RecommendationPage>;
};

export type QueryRecommendedProductsArgs = {
  key: RecommendationKey;
  page?: InputMaybe<RecommendationPage>;
  skipIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRecommendedProductVariantsArgs = {
  key: RecommendationKey;
  page?: InputMaybe<RecommendationPage>;
};

export type QueryShopifyProductIdByPimProductIdArgs = {
  pimProductId: Scalars['String'];
};

export type QueryShopPartiesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  status: ShopPartyQueryStatus;
};

export type QueryShopPartyArgs = {
  invitationCode?: InputMaybe<Scalars['String']>;
  shopPartyId?: InputMaybe<Scalars['String']>;
};

export type QueryTopProductsArgs = {
  input: TopProductsInput;
};

export type QueryTrendingProfilesArgs = {
  guestUserId?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
  taggedModulesProductLimit?: InputMaybe<Scalars['Int']>;
  userInterests?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryUsersArgs = {
  filterField: UserFilterFields;
  filterFieldValue: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
  partialMatch?: InputMaybe<Scalars['Boolean']>;
};

export type QueryValidateUsernameArgs = {
  username: Scalars['String'];
};

export type QueryVerifiedDealArgs = {
  shopifyGiftCardId?: InputMaybe<Scalars['String']>;
};

export type QueryVerifiedDealsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryVisenzeProductRecommendationsArgs = {
  input: VisenzeProductRecommendationsInput;
};

export type QueryWatchlistedLiveShopsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
};

export type AffiliateLinkResponse = {
  __typename?: 'AffiliateLinkResponse';
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type AnonymousUserIdentity = {
  __typename?: 'AnonymousUserIdentity';
  cognitoUuid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type BookmarkList = {
  __typename?: 'BookmarkList';
  bookmarks: BookmarkPaginatedResponse;
  coverPhoto?: Maybe<Image>;
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  filteredBookmark?: Maybe<Bookmark>;
  filteredBookmarkV2?: Maybe<Bookmark>;
  id: Scalars['ID'];
  name: Scalars['String'];
  public: Scalars['Boolean'];
  slug: Scalars['String'];
};

export type BookmarkListBookmarksArgs = {
  bookmarkType?: InputMaybe<BookmarkType>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
};

export type BookmarkListFilteredBookmarkArgs = {
  filter?: InputMaybe<ProductBookmarkInput>;
};

export type BookmarkListFilteredBookmarkV2Args = {
  filter?: InputMaybe<BookmarkInput>;
};

export enum BookmarkType {
  ALL = 'ALL',
  EXPLORER_POST = 'EXPLORER_POST',
  PRODUCT = 'PRODUCT',
}

export enum PaginationOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type BookmarkPaginatedResponse = {
  __typename?: 'BookmarkPaginatedResponse';
  items: Array<Maybe<Bookmark>>;
  pagination: Pagination;
};

export type Bookmark = {
  attribution?: Maybe<Attribution>;
  bookmarkList: BookmarkList;
  bookmarkListId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  type: BookmarkType;
};

export type Attribution = {
  __typename?: 'Attribution';
  identifier: Scalars['String'];
  type: AttributionType;
};

export enum AttributionType {
  BOOKMARK = 'BOOKMARK',
  BOOKMARK_LIST = 'BOOKMARK_LIST',
  LIVESTREAM = 'LIVESTREAM',
  LIVESTREAM_LIST = 'LIVESTREAM_LIST',
  PDP = 'PDP',
  PLP = 'PLP',
  POST = 'POST',
  PROFILE = 'PROFILE',
  PROFILES = 'PROFILES',
  TASTEMAKER = 'TASTEMAKER',
}

export type Pagination = {
  __typename?: 'Pagination';
  cursor?: Maybe<Scalars['String']>;
  hasMore: Scalars['Boolean'];
  totalCount: Scalars['Int'];
};

export type Image = Asset & {
  __typename?: 'Image';
  ecommModel?: Maybe<EcommModel>;
  ecommModelSize?: Maybe<EcommModelSize>;
  type?: Maybe<AssetType>;
  url: Scalars['String'];
};

export type Asset = {
  type?: Maybe<AssetType>;
  url: Scalars['String'];
};

export enum AssetType {
  GIF = 'GIF',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  VIDEO_NO_SOUND = 'VIDEO_NO_SOUND',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
}

export type EcommModel = {
  __typename?: 'EcommModel';
  bust?: Maybe<Scalars['String']>;
  chest?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  height?: Maybe<Scalars['String']>;
  hip?: Maybe<Scalars['String']>;
  inseam?: Maybe<Scalars['String']>;
  waist?: Maybe<Scalars['String']>;
};

export type EcommModelSize = {
  __typename?: 'EcommModelSize';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ProductBookmarkInput = {
  attribution?: InputMaybe<AttributionInput>;
  /** If bookmarkListId is omitted, the mutation will add the bookmark to the last list used or default list. */
  bookmarkListId?: InputMaybe<Scalars['ID']>;
  productSid: Scalars['ID'];
  selectedOptions?: InputMaybe<
    Array<InputMaybe<ProductBookmarkSelectedOptionInput>>
  >;
};

export type AttributionInput = {
  identifier: Scalars['String'];
  type: AttributionType;
};

export type ProductBookmarkSelectedOptionInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type BookmarkInput = {
  attribution?: InputMaybe<AttributionInput>;
  /** If bookmarkListId is omitted, the mutation will add the bookmark to the last list used or default list. */
  bookmarkedEntityId?: InputMaybe<Scalars['ID']>;
  bookmarkListId?: InputMaybe<Scalars['ID']>;
  productSid?: InputMaybe<Scalars['ID']>;
  selectedOptions?: InputMaybe<
    Array<InputMaybe<ProductBookmarkSelectedOptionInput>>
  >;
  type: BookmarkType;
};

export type BookmarkListPaginatedResponse = {
  __typename?: 'BookmarkListPaginatedResponse';
  items: Array<Maybe<BookmarkList>>;
  pagination: Pagination;
};

export type Brand = {
  __typename?: 'Brand';
  code?: Maybe<Scalars['String']>;
  facetMetadata?: Maybe<Array<BrandFacetMetadata>>;
  label?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  subscriptionTier?: Maybe<MerchantSubscriptionTier>;
};

export type BrandFacetMetadataArgs = {
  input: BrandFacetMetadataInput;
};

export type BrandFacetMetadataInput = {
  attributes: Array<Scalars['String']>;
  labels: Array<Scalars['String']>;
};

export type BrandFacetMetadata = {
  __typename?: 'BrandFacetMetadata';
  attribute?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export enum MerchantSubscriptionTier {
  TIER_0 = 'TIER_0',
  TIER_1 = 'TIER_1',
  TIER_2 = 'TIER_2',
}

export enum ExplorerPostType {
  CURATED = 'CURATED',
  INSTAGRAM_BRAND_CRAWL = 'INSTAGRAM_BRAND_CRAWL',
  INSTAGRAM_HASHTAG_CRAWL = 'INSTAGRAM_HASHTAG_CRAWL',
  LIVE_SHOP = 'LIVE_SHOP',
  LIVE_SHOP_CLIP = 'LIVE_SHOP_CLIP',
  PRODUCT = 'PRODUCT',
  SALE = 'SALE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  USER = 'USER',
  YOUTUBE_VIDEO_CRAWL = 'YOUTUBE_VIDEO_CRAWL',
}

export type ExplorerPostResponse = {
  __typename?: 'ExplorerPostResponse';
  paginationToken?: Maybe<Scalars['String']>;
  posts: Array<Maybe<ExplorerPost>>;
};

export type ExplorerPost = {
  __typename?: 'ExplorerPost';
  assets?: Maybe<Array<Maybe<ExplorerAsset>>>;
  bookmark?: Maybe<ExplorerPostBookmark>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  hasShared?: Maybe<Scalars['Boolean']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isLiked?: Maybe<Scalars['Boolean']>;
  likeCount?: Maybe<Scalars['Int']>;
  modules?: Maybe<Array<Maybe<ExplorerModule>>>;
  /** Deprecated, use profile */
  owner: ExplorerContentOwner;
  postId: Scalars['String'];
  productVariants?: Maybe<Array<Maybe<ProductVariant>>>;
  profile?: Maybe<Profile>;
  relatedItems?: Maybe<RelatedItemsResponse>;
  shareCount?: Maybe<Scalars['Int']>;
  similarPosts: ExplorerPostResponse;
  /** Live Shop Post specific, we should consider move the whole response design to v2 - multi-type support */
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ExplorerPostStatus>;
  /** The vtt format transcript resource url */
  transcriptUrl?: Maybe<Scalars['String']>;
  type?: Maybe<ExplorerPostType>;
  webUrl?: Maybe<Scalars['String']>;
};

export type ExplorerPostRelatedItemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type ExplorerPostSimilarPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
};

export type ExplorerAsset = {
  assetRequestId?: Maybe<Scalars['String']>;
  dimensions?: Maybe<AssetDimensions>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<ExplorerAssetType>;
  url?: Maybe<Scalars['String']>;
};

export type AssetDimensions = {
  __typename?: 'AssetDimensions';
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export enum ExplorerAssetType {
  GIF = 'GIF',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type ExplorerPostBookmark = Bookmark & {
  __typename?: 'ExplorerPostBookmark';
  attribution?: Maybe<Attribution>;
  bookmarkedEntityId: Scalars['ID'];
  bookmarkList: BookmarkList;
  bookmarkListId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  explorerPost?: Maybe<ExplorerPost>;
  id: Scalars['ID'];
  type: BookmarkType;
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['String'];
  name: Scalars['String'];
  postCount: Scalars['Int'];
  posts: ExplorerPostResponse;
};

export type ChannelPostsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  guestUserId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type ExplorerModule = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ExplorerModuleType>;
};

export enum ExplorerModuleType {
  BANNER = 'BANNER',
  BRAND = 'BRAND',
  BRAND_WEBSITE = 'BRAND_WEBSITE',
  COLLECTION = 'COLLECTION',
  DYNAMIC_PRODUCTS_LIST = 'DYNAMIC_PRODUCTS_LIST',
  EXTERNAL_LINK_LIST = 'EXTERNAL_LINK_LIST',
  PRODUCTS_LIST = 'PRODUCTS_LIST',
  PROFILE_LIST = 'PROFILE_LIST',
  SECTION_HEADER = 'SECTION_HEADER',
}

export type ExplorerContentOwner = {
  __typename?: 'ExplorerContentOwner';
  brandSlug?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  ownerId: Scalars['String'];
  ownerName: Scalars['String'];
  type: ExplorerContentOwnerType;
};

export enum ExplorerContentOwnerType {
  BRAND = 'BRAND',
  INFLUENCER = 'INFLUENCER',
  MERCHANT = 'MERCHANT',
  USER = 'USER',
  VERISHOPADMIN = 'VERISHOPADMIN',
}

/** Variations of Product */
export type ProductVariant = {
  __typename?: 'ProductVariant';
  bulky?: Maybe<Scalars['Boolean']>;
  collections?: Maybe<Array<Maybe<Scalars['String']>>>;
  colorFamilyCode?: Maybe<Scalars['String']>;
  colorFamilyName?: Maybe<Scalars['String']>;
  compareAtPrice?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editorsNote?: Maybe<Scalars['String']>;
  editorsNoteAuthor?: Maybe<Scalars['String']>;
  eligiblePromotions: Array<Maybe<Scalars['String']>>;
  filterables?: Maybe<Filterables>;
  finalSale?: Maybe<Scalars['Boolean']>;
  fit?: Maybe<Scalars['String']>;
  /** @deprecated use product specs or fit field */
  fitAndSize?: Maybe<Scalars['String']>;
  freeShippingEligible?: Maybe<Scalars['Boolean']>;
  fullSid: Scalars['ID'];
  galleryAssets: Array<Asset>;
  galleryImages: Array<Image>;
  hazmat?: Maybe<Scalars['Boolean']>;
  howToVideos?: Maybe<Array<Maybe<YoutubeVideo>>>;
  ingredients?: Maybe<Scalars['String']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isDiscountable: Scalars['Boolean'];
  isDropshipped: Scalars['Boolean'];
  isGiftCard?: Maybe<Scalars['Boolean']>;
  isLowStock: Scalars['Boolean'];
  isMarketplace?: Maybe<Scalars['Boolean']>;
  isProductBundle?: Maybe<Scalars['Boolean']>;
  isReplenishable?: Maybe<Scalars['Boolean']>;
  isReturnToVerishop?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Maybe<Label>>>;
  materialList?: Maybe<Array<Maybe<Scalars['String']>>>;
  materials?: Maybe<Scalars['String']>;
  merchantOffer?: Maybe<MerchantOffer>;
  modelImages: Array<Image>;
  offerDetails?: Maybe<OfferDetails>;
  onSale?: Maybe<Scalars['Boolean']>;
  plpGroupId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  printFamily?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  productBundleItems?: Maybe<Array<Maybe<ProductBundleItem>>>;
  productFullSid: Scalars['ID'];
  productImages?: Maybe<Array<Maybe<Image>>>;
  productSid: Scalars['ID'];
  publishDate?: Maybe<Scalars['DateTime']>;
  requiresShipping?: Maybe<Scalars['Boolean']>;
  selectedOptions: Array<SelectedOption>;
  shoppingStatus?: Maybe<ShoppingStatus>;
  sid: Scalars['ID'];
  sku: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  specifications?: Maybe<Array<Maybe<ProductSpecification>>>;
  supriseDealPrice?: Maybe<Scalars['Float']>;
  tags: Array<Maybe<Scalars['String']>>;
  taxable?: Maybe<Scalars['Boolean']>;
  taxCode?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trueToSize?: Maybe<Scalars['String']>;
  twoDayShippingEligible?: Maybe<Scalars['Boolean']>;
  videos?: Maybe<Array<Maybe<Video>>>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<WeightUnit>;
};

export type Filterables = {
  __typename?: 'Filterables';
  responsible?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type YoutubeVideo = Asset & {
  __typename?: 'YoutubeVideo';
  type?: Maybe<AssetType>;
  url: Scalars['String'];
};

export type Label = {
  __typename?: 'Label';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type MerchantOffer = {
  __typename?: 'MerchantOffer';
  merchantId: Scalars['String'];
  offerId: Scalars['String'];
  offerSku: Scalars['String'];
};

export type OfferDetails = {
  __typename?: 'OfferDetails';
  endDate?: Maybe<Scalars['DateTime']>;
  offerType?: Maybe<OfferType>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export enum OfferType {
  PERM_MARKDOWN = 'PERM_MARKDOWN',
  TEMP_MARKDOWN = 'TEMP_MARKDOWN',
}

/** Product */
export type Product = {
  __typename?: 'Product';
  brand?: Maybe<Scalars['String']>;
  brandEntity?: Maybe<Brand>;
  brandSlug?: Maybe<Scalars['String']>;
  categories: Array<Category>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  descriptionLegacy?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  familySlug?: Maybe<Scalars['String']>;
  fullSid: Scalars['ID'];
  hierarchicalCategories: HierarchicalCategories;
  options: Array<Option>;
  publishStatus?: Maybe<PublishStatus>;
  reviewSummary?: Maybe<ReviewSummary>;
  sid: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
  taxable?: Maybe<Scalars['Boolean']>;
  taxCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  variants: Array<ProductVariant>;
};

/** Product */
export type ProductVariantsArgs = {
  filterByMarketplace?: InputMaybe<Scalars['Boolean']>;
  filterByPlpGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterByShoppable?: InputMaybe<Scalars['Boolean']>;
  isCollectionsNeeded?: InputMaybe<Scalars['Boolean']>;
  omitDiscontinued?: InputMaybe<Scalars['Boolean']>;
};

export type Category = {
  __typename?: 'Category';
  category: Array<Scalars['String']>;
};

/** Categories paths as levels, useful for breadcrumbs and algolia indexing */
export type HierarchicalCategories = {
  __typename?: 'HierarchicalCategories';
  lvl0: Array<Scalars['String']>;
  lvl1: Array<Scalars['String']>;
  lvl2: Array<Scalars['String']>;
  lvl3: Array<Scalars['String']>;
};

export type Option = {
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  type: OptionType;
  values: Array<OptionValue>;
};

export enum OptionType {
  COLOR = 'COLOR',
  STRING = 'STRING',
}

export type OptionValue = {
  label: Scalars['String'];
  sortOrder: Scalars['Int'];
  value: Scalars['String'];
};

export enum PublishStatus {
  DONT_PUBLISH = 'DONT_PUBLISH',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export type ReviewSummary = {
  __typename?: 'ReviewSummary';
  averageScore: Scalars['Float'];
  ratingDistribution: Array<Rating>;
  totalReview: Scalars['Int'];
};

export type Rating = {
  __typename?: 'Rating';
  count: Scalars['Int'];
  score: Scalars['Int'];
};

/** Product Bundle Item */
export type ProductBundleItem = {
  __typename?: 'ProductBundleItem';
  discountPrice: Scalars['Float'];
  quantity: Scalars['Int'];
  sid: Scalars['String'];
  sku: Scalars['String'];
};

export type SelectedOption = {
  __typename?: 'SelectedOption';
  name: Scalars['String'];
  value: Scalars['String'];
};

export enum ShoppingStatus {
  ACCEPT_PREORDERS = 'ACCEPT_PREORDERS',
  BUNDLE_ONLY = 'BUNDLE_ONLY',
  COMING_SOON = 'COMING_SOON',
  DISCONTINUED = 'DISCONTINUED',
  EXPLORER_ONLY_PRODUCT = 'EXPLORER_ONLY_PRODUCT',
  SELLABLE = 'SELLABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

/** Product Specifications */
export type ProductSpecification = {
  __typename?: 'ProductSpecification';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Video = Asset & {
  __typename?: 'Video';
  cloudinaryPublicId?: Maybe<Scalars['String']>;
  type?: Maybe<AssetType>;
  url: Scalars['String'];
};

export enum WeightUnit {
  GRAMS = 'GRAMS',
  KILOGRAMS = 'KILOGRAMS',
  LITERS = 'LITERS',
  MILLILITERS = 'MILLILITERS',
  OUNCES = 'OUNCES',
  POUNDS = 'POUNDS',
}

export type Profile = {
  __typename?: 'Profile';
  biography?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  brandSlug?: Maybe<Scalars['String']>;
  canHostLiveShoppingEvent?: Maybe<Scalars['Boolean']>;
  contactInfo?: Maybe<ProfileContactInfo>;
  coverImageAsset?: Maybe<Asset>;
  creatorInfo?: Maybe<CreatorInfo>;
  customers: CustomerPaginationResponse;
  displayName?: Maybe<Scalars['String']>;
  facebookName?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  followingCount?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  id: Scalars['String'];
  impressionCount?: Maybe<Scalars['Int']>;
  instagramName?: Maybe<Scalars['String']>;
  isFollowing?: Maybe<Scalars['Boolean']>;
  isPremium?: Maybe<Scalars['Boolean']>;
  isProfileOwner?: Maybe<Scalars['Boolean']>;
  likeCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  profileAsset?: Maybe<Asset>;
  topPost?: Maybe<ExplorerPost>;
  twitterName?: Maybe<Scalars['String']>;
  type: ExplorerContentOwnerType;
  username?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type ProfileCustomersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
};

export type ProfileContactInfo = {
  __typename?: 'ProfileContactInfo';
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CreatorInfo = {
  __typename?: 'CreatorInfo';
  isCreator: Scalars['Boolean'];
  status?: Maybe<CreatorStatus>;
};

export enum CreatorStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type CustomerPaginationResponse = {
  __typename?: 'CustomerPaginationResponse';
  customers: Array<Maybe<Customer>>;
  paginationToken?: Maybe<Scalars['String']>;
};

/** Customer represents a verishop customer account */
export type Customer = {
  __typename?: 'Customer';
  /** The active profile Id to make requests using if not set in header */
  activeProfileId?: Maybe<Scalars['String']>;
  /** Get a bookmark list by id.  Returns null if none found. */
  bookmarkList?: Maybe<BookmarkList>;
  bookmarkLists: BookmarkListPaginatedResponse;
  bookmarks: BookmarkPaginatedResponse;
  /** The date and time when the customer was created. */
  createdAt: Scalars['DateTime'];
  creator?: Maybe<Creator>;
  discounts?: Maybe<Array<Maybe<DiscountCode>>>;
  /** The customer’s email address. */
  email: Scalars['String'];
  /** The customer’s first name */
  firstName?: Maybe<Scalars['String']>;
  /** A unique identifier for the customer. */
  id: Scalars['ID'];
  /** Whether they have proved they can receive email at current email address */
  isEmailConfirmed: Scalars['Boolean'];
  /** The customer’s last name */
  lastName?: Maybe<Scalars['String']>;
  likedPostsBookmarkList?: Maybe<BookmarkList>;
  /**
   * A special auth url that signs customer into shopify using multipass
   * @deprecated use createMultipassUrl mutation
   */
  multipassUrl?: Maybe<Scalars['String']>;
  /** Customer order history */
  orders?: Maybe<Array<Maybe<Order>>>;
  productBookmarksBySids: Array<Maybe<ProductBookmarksBySidResult>>;
  /** The customer's profiles, primary is the first one in the array */
  profiles?: Maybe<Array<Maybe<Profile>>>;
  profilesV2: Array<ProfileV2>;
  /** Returns your profile currently set in x-profile-id header or default user profile */
  profileV2?: Maybe<ProfileV2>;
  saveForLaterBookmarkList?: Maybe<BookmarkList>;
  /** base64 encoded shopify checkout id */
  shopifyCheckoutId?: Maybe<Scalars['String']>;
  /** For external user signed in through SSO flow, whether they have completed signup flow */
  ssoSignedUp: Scalars['Boolean'];
  /** Yotpo user id of the customer */
  yotpoId?: Maybe<Scalars['String']>;
};

/** Customer represents a verishop customer account */
export type CustomerBookmarkListArgs = {
  id: Scalars['String'];
};

/** Customer represents a verishop customer account */
export type CustomerBookmarkListsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

/** Customer represents a verishop customer account */
export type CustomerBookmarksArgs = {
  bookmarkListId?: InputMaybe<Scalars['String']>;
  bookmarkType?: InputMaybe<BookmarkType>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
};

/** Customer represents a verishop customer account */
export type CustomerOrdersArgs = {
  id?: InputMaybe<Scalars['String']>;
  productSid?: InputMaybe<Scalars['String']>;
};

/** Customer represents a verishop customer account */
export type CustomerProductBookmarksBySidsArgs = {
  sids: Array<InputMaybe<Scalars['String']>>;
};

export type Creator = {
  __typename?: 'Creator';
  balance: Scalars['Float'];
  id: Scalars['String'];
  lifetimeEarnings: Scalars['Float'];
  netsuiteId?: Maybe<Scalars['String']>;
  optedInAt: Scalars['DateTime'];
  ownerId: Scalars['String'];
  paypalEmail?: Maybe<Scalars['String']>;
  status: CreatorStatus;
  w9Status?: Maybe<CreatorW9Status>;
};

export enum CreatorW9Status {
  COMPLETE = 'COMPLETE',
  IN_PROCESS = 'IN_PROCESS',
  OPTIONAL = 'OPTIONAL',
  RECOMMENDED = 'RECOMMENDED',
}

export type DiscountCode = {
  __typename?: 'DiscountCode';
  code?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DiscountCodeStatus>;
};

export enum DiscountCodeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type Order = {
  __typename?: 'Order';
  billingAddress?: Maybe<MailingAddress>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
  customerSid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  financialStatus?: Maybe<Scalars['String']>;
  fulfillments?: Maybe<Array<Fulfillment>>;
  fulfillmentStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lineItems: Array<LineItem>;
  name?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<PaymentDetails>;
  phone?: Maybe<Scalars['String']>;
  processedAt?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<MailingAddress>;
  subtotalLineItemsQuantity?: Maybe<Scalars['Int']>;
  subtotalPriceSet?: Maybe<MoneySet>;
  totalDiscountsSet?: Maybe<MoneySet>;
  totalLineItemsPriceSet?: Maybe<MoneySet>;
  totalPriceSet?: Maybe<MoneySet>;
  totalShippingPriceSet?: Maybe<MoneySet>;
  totalTaxSet?: Maybe<MoneySet>;
  updatedAt?: Maybe<Scalars['String']>;
};

/** Represents a mailing address for customers and shipping. */
export type MailingAddress = {
  __typename?: 'MailingAddress';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type Fulfillment = {
  __typename?: 'Fulfillment';
  createdAt?: Maybe<Scalars['String']>;
  lineItems: Array<LineItem>;
  shipmentStatus?: Maybe<FulfillmentEventStatus>;
  status?: Maybe<Scalars['String']>;
  trackingCompany?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type LineItem = {
  __typename?: 'LineItem';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  productId: Scalars['ID'];
  productVariant: ProductVariant;
  quantity: Scalars['Int'];
  sku: Scalars['String'];
  title: Scalars['String'];
  totalDiscount?: Maybe<Scalars['String']>;
  variantId: Scalars['ID'];
  variantTitle: Scalars['String'];
  vendor?: Maybe<Scalars['String']>;
};

export enum FulfillmentEventStatus {
  CONFIRMED = 'confirmed',
  DELIVERED = 'delivered',
  FAILURE = 'failure',
  IN_TRANSIT = 'in_transit',
  OUT_FOR_DELIVERY = 'out_for_delivery',
}

export type PaymentDetails = {
  __typename?: 'PaymentDetails';
  avsResultCode?: Maybe<Scalars['String']>;
  creditCardBin?: Maybe<Scalars['String']>;
  creditCardCompany?: Maybe<Scalars['String']>;
  creditCardNumber?: Maybe<Scalars['String']>;
  cvvResultCode?: Maybe<Scalars['String']>;
};

export type MoneySet = {
  __typename?: 'MoneySet';
  presentmentMoney?: Maybe<Money>;
  shopMoney?: Maybe<Money>;
};

export type Money = {
  __typename?: 'Money';
  amount?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type ProductBookmarksBySidResult = {
  __typename?: 'ProductBookmarksBySidResult';
  productBookmarks: Array<Maybe<ProductBookmark>>;
  productSid: Scalars['String'];
};

export type ProductBookmark = Bookmark & {
  __typename?: 'ProductBookmark';
  attribution?: Maybe<Attribution>;
  bookmarkList: BookmarkList;
  bookmarkListId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  product: Product;
  productSid: Scalars['ID'];
  selectedOptions?: Maybe<Array<Maybe<SelectedOption>>>;
  type: BookmarkType;
};

export type ProfileV2 = {
  contentOwner?: Maybe<ExplorerContentOwner>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ProfileType;
  user: User;
};

export enum ProfileType {
  BRAND = 'BRAND',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  INFLUENCER = 'INFLUENCER',
  MERCHANT = 'MERCHANT',
  MERCHANT_SOLUTIONS_ADMIN = 'MERCHANT_SOLUTIONS_ADMIN',
  USER = 'USER',
  VERISHOPADMIN = 'VERISHOPADMIN',
}

export type User = {
  __typename?: 'User';
  /** The user's active profile */
  activeProfileId?: Maybe<Scalars['String']>;
  /** The date and time when the user was created. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The user’s email address. */
  email: Scalars['String'];
  /** The user’s first name */
  firstName?: Maybe<Scalars['String']>;
  /** A unique identifier for the user. */
  id: Scalars['ID'];
  /** Verishop email confirmation status */
  isEmailConfirmed?: Maybe<Scalars['Boolean']>;
  /** The user’s last name */
  lastName?: Maybe<Scalars['String']>;
  /** The user's profiles, primary is the first one in the array */
  profiles?: Maybe<Array<ProfileV2>>;
  /** base64 encoded shopify checkout id */
  shopifyCheckoutId?: Maybe<Scalars['String']>;
  /** Cognito user status */
  status?: Maybe<UserStatus>;
  /** The date and time when the user was updated. */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum UserStatus {
  ARCHIVED = 'ARCHIVED',
  COMPROMISED = 'COMPROMISED',
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
  UNKNOWN = 'UNKNOWN',
}

export enum Gender {
  ANONYMOUS = 'ANONYMOUS',
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
  OTHER = 'OTHER',
  TRANSGENDER = 'TRANSGENDER',
}

export type RelatedItemsResponse = {
  __typename?: 'RelatedItemsResponse';
  count?: Maybe<Scalars['Int']>;
  nextPage?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<Product>>>;
};

export enum ExplorerPostStatus {
  PENDING = 'PENDING',
  READY = 'READY',
  REVOKED = 'REVOKED',
}

export type ReviewsPage = {
  __typename?: 'ReviewsPage';
  pageInfo: ReviewsPageInfo;
  reviews: Array<ReviewV2>;
  summary: ReviewSummary;
};

export type ReviewsPageInfo = {
  __typename?: 'ReviewsPageInfo';
  currentPage: Scalars['Int'];
  numberOfPages: Scalars['Int'];
};

export type ReviewV2 = {
  __typename?: 'ReviewV2';
  comment?: Maybe<ReviewComment>;
  content: Scalars['String'];
  id: Scalars['String'];
  imagesData?: Maybe<Array<ReviewImageData>>;
  product?: Maybe<Product>;
  reviewDate: Scalars['String'];
  score: Scalars['Int'];
  sourceName?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user?: Maybe<ReviewUser>;
  verifiedBuyer: Scalars['Boolean'];
};

export type ReviewComment = {
  __typename?: 'ReviewComment';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['String'];
};

export type ReviewImageData = {
  __typename?: 'ReviewImageData';
  id: Scalars['String'];
  originalUrl?: Maybe<Scalars['String']>;
  thumbUrl?: Maybe<Scalars['String']>;
};

export type ReviewUser = {
  __typename?: 'ReviewUser';
  displayName?: Maybe<Scalars['String']>;
};

export type CategoryV2 = {
  __typename?: 'CategoryV2';
  algoliaHierarchy: Scalars['String'];
  code: Scalars['String'];
  hasChildren: Scalars['Boolean'];
  label: Scalars['String'];
  level: Scalars['Int'];
  parent?: Maybe<Scalars['String']>;
  parentSlug?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type ChannelPaginatedResponse = {
  __typename?: 'ChannelPaginatedResponse';
  channels: Array<Maybe<Channel>>;
  paginationToken?: Maybe<Scalars['String']>;
};

export type Checkout = {
  __typename?: 'Checkout';
  customAttributes: CheckoutCustomAttribute;
  id: Scalars['ID'];
};

export type CheckoutCustomAttribute = {
  __typename?: 'CheckoutCustomAttribute';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LiveShop = {
  __typename?: 'LiveShop';
  casts?: Maybe<Array<LiveShopCast>>;
  coHosts: Array<Maybe<LiveShopMembership>>;
  confirmedEndsAt?: Maybe<Scalars['DateTime']>;
  confirmedStartsAt?: Maybe<Scalars['DateTime']>;
  controls?: Maybe<Array<Control>>;
  coverAsset?: Maybe<Asset>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  featuredProducts: Array<LiveShopProductVariant>;
  id: Scalars['String'];
  inviteCodes: Array<LiveShopInviteCode>;
  /** This will determine if requestor has watchlisted this live shop event. */
  isWatchlisted: Scalars['Boolean'];
  membership?: Maybe<LiveShopMembership>;
  memberships: LiveShopMembershipPaginatedResponse;
  notificationEmail?: Maybe<Scalars['String']>;
  owner: Profile;
  ownerId: Scalars['String'];
  privacy: LiveShopPrivacy;
  productsFeaturedAt?: Maybe<Array<Maybe<LiveShopProductVariant>>>;
  recordVideoUrl?: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  status: LiveShopStatus;
  teaserAsset?: Maybe<Asset>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LiveShopMembershipsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type LiveShopCast = {
  __typename?: 'LiveShopCast';
  streamKey: Scalars['String'];
  streamUrl: Scalars['String'];
  type: LiveShopCastType;
};

export enum LiveShopCastType {
  TWITCH = 'TWITCH',
  YOUTUBE = 'YOUTUBE',
}

export type LiveShopMembership = {
  __typename?: 'LiveShopMembership';
  isWatchlisted: Scalars['Boolean'];
  likedFeaturedProductSkuList: Array<Maybe<Scalars['String']>>;
  liveShopInviteCode?: Maybe<LiveShopInviteCode>;
  profile: Profile;
  status: LiveShopMembershipStatus;
};

export type LiveShopInviteCode = {
  __typename?: 'LiveShopInviteCode';
  id: Scalars['String'];
  inviteCode: Scalars['String'];
  type: LiveShopInviteCodeType;
};

export enum LiveShopInviteCodeType {
  HOST = 'HOST',
  MEMBER = 'MEMBER',
}

export enum LiveShopMembershipStatus {
  ACCEPTED = 'ACCEPTED',
  BLOCKED = 'BLOCKED',
  JOINED = 'JOINED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type Control = {
  __typename?: 'Control';
  key: Scalars['String'];
  value: Scalars['String'];
  valueType: ControlValueType;
};

export enum ControlValueType {
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
}

export type LiveShopProductVariant = {
  __typename?: 'LiveShopProductVariant';
  associatePostId?: Maybe<Scalars['String']>;
  featuredAt?: Maybe<Scalars['String']>;
  featuredAts: Array<Scalars['String']>;
  initialFeaturedAt?: Maybe<Scalars['String']>;
  isDealApplied: Scalars['Boolean'];
  likeCount?: Maybe<Scalars['Int']>;
  liveStreamingDealPrice: Scalars['Float'];
  productVariant: ProductVariant;
  sku: Scalars['String'];
};

export type LiveShopMembershipPaginatedResponse = {
  __typename?: 'LiveShopMembershipPaginatedResponse';
  items: Array<Maybe<LiveShopMembership>>;
  pagination: PaginationWithoutCount;
};

export type PaginationWithoutCount = {
  __typename?: 'PaginationWithoutCount';
  cursor?: Maybe<Scalars['String']>;
  hasMore: Scalars['Boolean'];
};

export enum LiveShopPrivacy {
  INTERNAL = 'INTERNAL',
  PUBLIC = 'PUBLIC',
}

export enum LiveShopStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  INACTIVE = 'INACTIVE',
  PUBLISHED = 'PUBLISHED',
}

export type DataInsightsInput = {
  beginDate: Scalars['String'];
  endDate: Scalars['String'];
  objectId: Scalars['String'];
  objectType: Scalars['String'];
  timeFrameType: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};

export type CompetitorInsights = {
  __typename?: 'CompetitorInsights';
  beginDate: Scalars['String'];
  endDate: Scalars['String'];
  metrics: Array<CompetitorMetric>;
  objectId: Scalars['String'];
  objectType: Scalars['String'];
  timeFrameType: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type CompetitorMetric = {
  __typename?: 'CompetitorMetric';
  countPlpImpressions?: Maybe<Scalars['Float']>;
  countSessions?: Maybe<Scalars['Float']>;
  grossMerchReceipts?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  quantitySold?: Maybe<Scalars['Float']>;
  reportDate: Scalars['String'];
};

export type ConfigResponse = {
  __typename?: 'ConfigResponse';
  domain: Array<Maybe<Scalars['String']>>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateSignedEmbedUrlInput = {
  accessFilters: Array<LookerEmbedUrlConfigEntry>;
  embedHost?: InputMaybe<Scalars['String']>;
  embedSecret?: InputMaybe<Scalars['String']>;
  embedUrl: Scalars['String'];
  embedViewTheme?: InputMaybe<Scalars['String']>;
  externalGroupId: Scalars['String'];
  externalUserId: Scalars['String'];
  firstName: Scalars['String'];
  forceLogoutLogin: Scalars['Boolean'];
  groupIds: Array<Scalars['String']>;
  lastName: Scalars['String'];
  models: Array<Scalars['String']>;
  permissions: Array<Scalars['String']>;
  sessionLength: Scalars['Int'];
  userAttributes: Array<LookerEmbedUrlConfigEntry>;
};

export type LookerEmbedUrlConfigEntry = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type CreateSignedEmbedUrlResponse = {
  __typename?: 'CreateSignedEmbedUrlResponse';
  signedUrl: Scalars['String'];
};

export enum CreatorInsightPeriodType {
  WEEKLY = 'WEEKLY',
}

export type CreatorCollectionInsight = {
  __typename?: 'CreatorCollectionInsight';
  collectionId: Scalars['String'];
  commission: Scalars['Float'];
  deduction: Scalars['Float'];
  impression?: Maybe<Scalars['Int']>;
  orderCount: Scalars['Int'];
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  periodType: CreatorInsightPeriodType;
  sales: Scalars['Float'];
  topCommissionProducts: Array<ProductCommissionInsight>;
  topUnitSoldProducts: Array<ProductUnitSoldInsight>;
  unitSold: Scalars['Int'];
};

export type ProductCommissionInsight = {
  __typename?: 'ProductCommissionInsight';
  commission: Scalars['Float'];
  productId: Scalars['String'];
};

export type ProductUnitSoldInsight = {
  __typename?: 'ProductUnitSoldInsight';
  productId: Scalars['String'];
  unitSold: Scalars['Float'];
};

export type CreatorInsight = {
  __typename?: 'CreatorInsight';
  collectionCommission: Scalars['Float'];
  collectionDeduction: Scalars['Float'];
  collectionImpression?: Maybe<Scalars['Int']>;
  collectionInsights?: Maybe<PaginatedCollectionInsight>;
  collectionOrderCount: Scalars['Int'];
  collectionSales: Scalars['Float'];
  collectionUnitSold: Scalars['Int'];
  commissionRates?: Maybe<Array<Maybe<CreatorCommissionRate>>>;
  followersAllTime?: Maybe<Scalars['Int']>;
  followersLastPeriod?: Maybe<Scalars['Int']>;
  followersThisPeriod?: Maybe<Scalars['Int']>;
  impressionAllTime?: Maybe<Scalars['Int']>;
  impressionLastPeriod?: Maybe<Scalars['Int']>;
  impressionThisPeriod?: Maybe<Scalars['Int']>;
  liveShopAvgTimeSpentInSec?: Maybe<Scalars['Int']>;
  liveShopCommission: Scalars['Float'];
  liveShopDeduction: Scalars['Float'];
  liveShopImpression?: Maybe<Scalars['Int']>;
  liveShopInsights?: Maybe<PaginatedLiveShopInsight>;
  liveShopLikeCount?: Maybe<Scalars['Int']>;
  liveShopOrderCount: Scalars['Int'];
  liveShopReactionCount?: Maybe<Scalars['Int']>;
  liveShopSales: Scalars['Float'];
  liveShopSaveCount?: Maybe<Scalars['Int']>;
  liveShopUnitSold: Scalars['Int'];
  liveShopViewCount?: Maybe<Scalars['Int']>;
  periodEndDate: Scalars['String'];
  periodStartDate: Scalars['String'];
  periodType: CreatorInsightPeriodType;
  postCommission: Scalars['Float'];
  postDeduction: Scalars['Float'];
  postImpression?: Maybe<Scalars['Int']>;
  postInsights?: Maybe<PaginatedPostInsight>;
  postLikeCount?: Maybe<Scalars['Int']>;
  postOrderCount: Scalars['Int'];
  postSales: Scalars['Float'];
  postSaveCount?: Maybe<Scalars['Int']>;
  postShareCount?: Maybe<Scalars['Int']>;
  postUnitSold: Scalars['Int'];
  postViewCount?: Maybe<Scalars['Int']>;
  productInsights?: Maybe<PaginatedProductInsight>;
  shopPartyAvgTimeSpentInSec?: Maybe<Scalars['Int']>;
  shopPartyCommission: Scalars['Float'];
  shopPartyDeduction: Scalars['Float'];
  shopPartyImpression?: Maybe<Scalars['Int']>;
  shopPartyInsights?: Maybe<PaginatedShopPartyInsight>;
  shopPartyOrderCount: Scalars['Int'];
  shopPartyParticipantCount?: Maybe<Scalars['Int']>;
  shopPartySales: Scalars['Float'];
  shopPartyUnitSold: Scalars['Int'];
  totalCommission: Scalars['Float'];
  totalDeduction: Scalars['Float'];
  totalEarnings: Scalars['Float'];
  totalOrderCount: Scalars['Int'];
  totalSales: Scalars['Float'];
  totalUnitSold: Scalars['Int'];
};

export type CreatorInsightCollectionInsightsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  paginationOrder: CreatorInsightPaginationOrder;
};

export type CreatorInsightLiveShopInsightsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  paginationOrder: CreatorInsightPaginationOrder;
};

export type CreatorInsightPostInsightsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  paginationOrder: CreatorInsightPaginationOrder;
};

export type CreatorInsightProductInsightsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  paginationOrder: CreatorInsightPaginationOrder;
};

export type CreatorInsightShopPartyInsightsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  paginationOrder: CreatorInsightPaginationOrder;
};

export enum CreatorInsightPaginationOrder {
  COMMISSION = 'COMMISSION',
  IMPRESSION = 'IMPRESSION',
  UNIT_SOLD = 'UNIT_SOLD',
}

export type PaginatedCollectionInsight = {
  __typename?: 'PaginatedCollectionInsight';
  items: Array<CreatorCollectionInsight>;
  pagination?: Maybe<PaginationWithoutCount>;
};

export type CreatorCommissionRate = {
  __typename?: 'CreatorCommissionRate';
  commissionPercentage: Scalars['Float'];
  endDate?: Maybe<Scalars['DateTime']>;
  liveShopCommissionPercentage: Scalars['Float'];
  startDate: Scalars['DateTime'];
};

export type PaginatedLiveShopInsight = {
  __typename?: 'PaginatedLiveShopInsight';
  items: Array<CreatorLiveShopInsight>;
  pagination?: Maybe<PaginationWithoutCount>;
};

export type CreatorLiveShopInsight = {
  __typename?: 'CreatorLiveShopInsight';
  avgTimeSpentInSec?: Maybe<Scalars['Int']>;
  commission: Scalars['Float'];
  deduction: Scalars['Float'];
  impression?: Maybe<Scalars['Int']>;
  liveShopId: Scalars['String'];
  orderCount: Scalars['Int'];
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  periodType: CreatorInsightPeriodType;
  reactionCount?: Maybe<Scalars['Int']>;
  sales: Scalars['Float'];
  topCommissionProducts: Array<ProductCommissionInsight>;
  topUnitSoldProducts: Array<ProductUnitSoldInsight>;
  unitSold: Scalars['Int'];
  viewCount?: Maybe<Scalars['Int']>;
};

export type PaginatedPostInsight = {
  __typename?: 'PaginatedPostInsight';
  items: Array<CreatorPostInsight>;
  pagination?: Maybe<PaginationWithoutCount>;
};

export type CreatorPostInsight = {
  __typename?: 'CreatorPostInsight';
  commission: Scalars['Float'];
  deduction: Scalars['Float'];
  impression?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  orderCount: Scalars['Int'];
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  periodType: CreatorInsightPeriodType;
  postId: Scalars['String'];
  sales: Scalars['Float'];
  saveCount?: Maybe<Scalars['Int']>;
  shareCount?: Maybe<Scalars['Int']>;
  topCommissionProducts: Array<ProductCommissionInsight>;
  topUnitSoldProducts: Array<ProductUnitSoldInsight>;
  unitSold: Scalars['Int'];
  viewCount?: Maybe<Scalars['Int']>;
};

export type PaginatedProductInsight = {
  __typename?: 'PaginatedProductInsight';
  items: Array<CreatorProductInsight>;
  pagination?: Maybe<PaginationWithoutCount>;
};

export type CreatorProductInsight = {
  __typename?: 'CreatorProductInsight';
  commission: Scalars['Float'];
  deduction: Scalars['Float'];
  orderCount: Scalars['Int'];
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  periodType: CreatorInsightPeriodType;
  productId: Scalars['String'];
  sales: Scalars['Float'];
  unitSold: Scalars['Int'];
};

export type PaginatedShopPartyInsight = {
  __typename?: 'PaginatedShopPartyInsight';
  items: Array<CreatorShopPartyInsight>;
  pagination?: Maybe<PaginationWithoutCount>;
};

export type CreatorShopPartyInsight = {
  __typename?: 'CreatorShopPartyInsight';
  avgTimeSpentInSec?: Maybe<Scalars['Int']>;
  commission: Scalars['Float'];
  deduction: Scalars['Float'];
  impression?: Maybe<Scalars['Int']>;
  orderCount: Scalars['Int'];
  participantCount?: Maybe<Scalars['Int']>;
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  periodType: CreatorInsightPeriodType;
  sales: Scalars['Float'];
  shopPartyId: Scalars['String'];
  topCommissionProducts: Array<ProductCommissionInsight>;
  topUnitSoldProducts: Array<ProductUnitSoldInsight>;
  unitSold: Scalars['Int'];
};

export type CreatorPayPeriodDetail = {
  __typename?: 'CreatorPayPeriodDetail';
  commission: Scalars['Float'];
  commissionRates?: Maybe<Array<Maybe<CreatorCommissionRate>>>;
  deduction: Scalars['Float'];
  endingBalance: Scalars['Float'];
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  startingBalance: Scalars['Float'];
  transferred: Scalars['Float'];
};

export type DataInsights = {
  __typename?: 'DataInsights';
  beginDate: Scalars['String'];
  endDate: Scalars['String'];
  metrics: Array<Metric>;
  objectId: Scalars['String'];
  objectType: Scalars['String'];
  props?: Maybe<ObjectProperty>;
  timeFrameType: Scalars['String'];
  version?: Maybe<Scalars['String']>;
};

export type Metric = {
  __typename?: 'Metric';
  adsFacebook?: Maybe<Array<FloatTuple>>;
  adsGoogle?: Maybe<Array<FloatTuple>>;
  averageDiscounts?: Maybe<Scalars['Float']>;
  averageMarkdown?: Maybe<Scalars['Float']>;
  averageRating?: Maybe<Scalars['Float']>;
  brandName?: Maybe<Scalars['String']>;
  cancelQuantity?: Maybe<Scalars['Int']>;
  cancelSubtotal?: Maybe<Scalars['Float']>;
  categories?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Scalars['String']>;
  categoryPath?: Maybe<Scalars['String']>;
  categorySubcategories?: Maybe<Array<Scalars['String']>>;
  colorFamily?: Maybe<Scalars['String']>;
  countActiveUsers?: Maybe<Scalars['Int']>;
  countBouncedSessions?: Maybe<Scalars['Int']>;
  countBrands?: Maybe<Scalars['Int']>;
  countBrandsHasPostOrVideo?: Maybe<Scalars['Int']>;
  countBrandsHasReview?: Maybe<Scalars['Int']>;
  countClicks?: Maybe<Scalars['Int']>;
  countExplorerPostAtc?: Maybe<Scalars['Int']>;
  countExplorerPostClicks?: Maybe<Scalars['Int']>;
  countExplorerPostDisplays?: Maybe<Scalars['Int']>;
  countExplorerPostLikes?: Maybe<Scalars['Int']>;
  countExplorerPostPurchase?: Maybe<Scalars['Int']>;
  countExplorerPostViewDurationSeconds?: Maybe<Scalars['BigInt']>;
  countExplorerPostViews?: Maybe<Scalars['Int']>;
  countGoodReviews?: Maybe<Scalars['Int']>;
  countImageClickedSessions?: Maybe<Scalars['Int']>;
  countImageClicks?: Maybe<Scalars['Int']>;
  countImages?: Maybe<Scalars['Int']>;
  countLiveProductsL90D?: Maybe<Scalars['Int']>;
  countPdpHasReviewImpressionsSessions?: Maybe<Scalars['Int']>;
  countPdpHasReviewViewedSessions?: Maybe<Scalars['Int']>;
  countPdpImpressions?: Maybe<Scalars['Int']>;
  countPdpImpressionsSessions?: Maybe<Scalars['Int']>;
  countPdpViewedSessions?: Maybe<Scalars['Int']>;
  countPdpViews?: Maybe<Scalars['Int']>;
  countPlpImpressions?: Maybe<Scalars['Int']>;
  countPosts?: Maybe<Scalars['Int']>;
  countProductAddedSessions?: Maybe<Scalars['Int']>;
  countProductAdds?: Maybe<Scalars['Int']>;
  countProductDetailsViewedSessions?: Maybe<Scalars['Int']>;
  countProductRemoves?: Maybe<Scalars['Int']>;
  countProductReviewViewed?: Maybe<Scalars['Int']>;
  countProductReviewViewedSessions?: Maybe<Scalars['Int']>;
  countProducts?: Maybe<Scalars['Int']>;
  countProductVariantViewedSessions?: Maybe<Scalars['Int']>;
  countProductVariantViews?: Maybe<Scalars['Int']>;
  countPurchasedSessions?: Maybe<Scalars['Int']>;
  countPurchases?: Maybe<Scalars['Int']>;
  countReviews?: Maybe<Scalars['Int']>;
  countSessionByClass?: Maybe<Array<FloatTuple>>;
  countSessionByPrice?: Maybe<Array<FloatTuple>>;
  countSessions?: Maybe<Scalars['Int']>;
  countSku?: Maybe<Scalars['Int']>;
  countSkuOos?: Maybe<Scalars['Int']>;
  countVideos?: Maybe<Scalars['Int']>;
  countViewedProductDetails?: Maybe<Scalars['Int']>;
  countVisitors?: Maybe<Scalars['Int']>;
  explorerPostClickCountByCategory?: Maybe<Array<FloatTuple>>;
  explorerPostDisplayCountByCategory?: Maybe<Array<FloatTuple>>;
  explorerPostLikeCountByCategory?: Maybe<Array<FloatTuple>>;
  explorerPostViewCountByCategory?: Maybe<Array<FloatTuple>>;
  firstPurchaseSales?: Maybe<Scalars['Int']>;
  genericSize?: Maybe<Scalars['String']>;
  goodReviewsInfo?: Maybe<Array<FloatTuple>>;
  grossMerchReceipts?: Maybe<Scalars['Float']>;
  grossMerchSales?: Maybe<Scalars['Float']>;
  imagesInfo?: Maybe<Array<FloatTuple>>;
  inventory?: Maybe<Scalars['Int']>;
  merchantBrands?: Maybe<Array<Scalars['String']>>;
  merchantId?: Maybe<Scalars['String']>;
  netSales?: Maybe<Scalars['Float']>;
  orderedUnitsCancelledOrRefused?: Maybe<Scalars['Int']>;
  originalPrice?: Maybe<Scalars['Float']>;
  pdpViewsByClass?: Maybe<Array<FloatTuple>>;
  pdpViewsByPrice?: Maybe<Array<FloatTuple>>;
  plpClicksByClass?: Maybe<Array<FloatTuple>>;
  plpClicksByPrice?: Maybe<Array<FloatTuple>>;
  plpImpressionsByClass?: Maybe<Array<FloatTuple>>;
  plpImpressionsByPrice?: Maybe<Array<FloatTuple>>;
  postEngagedByClass?: Maybe<Array<FloatTuple>>;
  postEngagedByPrice?: Maybe<Array<FloatTuple>>;
  postPurchaseCountByCategory?: Maybe<Array<FloatTuple>>;
  postsInfo?: Maybe<Array<FloatTuple>>;
  productAddCountByCategory?: Maybe<Array<FloatTuple>>;
  productAddsByClass?: Maybe<Array<FloatTuple>>;
  productAddsByPrice?: Maybe<Array<FloatTuple>>;
  productClickedCountByCategory?: Maybe<Array<FloatTuple>>;
  productDetailsClickCountByCategory?: Maybe<Array<FloatTuple>>;
  productDetailsVisibleCountByCategory?: Maybe<Array<FloatTuple>>;
  productListingVisibleCountByCategory?: Maybe<Array<FloatTuple>>;
  productReviewViewedCountByCategory?: Maybe<Array<FloatTuple>>;
  productVariantViewedCountByCategory?: Maybe<Array<FloatTuple>>;
  productViewedCountByCategory?: Maybe<Array<FloatTuple>>;
  purchaseCountByCategory?: Maybe<Array<FloatTuple>>;
  quantityReturned?: Maybe<Scalars['Int']>;
  quantitySold?: Maybe<Scalars['Int']>;
  ratingsInfo?: Maybe<Array<FloatTuple>>;
  refundSubtotal?: Maybe<Scalars['Float']>;
  reportDate?: Maybe<Scalars['String']>;
  returnBreakdowns?: Maybe<Array<ObjectTuple>>;
  reviewEngagedByClass?: Maybe<Array<FloatTuple>>;
  reviewEngagedByPrice?: Maybe<Array<FloatTuple>>;
  reviewsInfo?: Maybe<Array<FloatTuple>>;
  rewardAppliedSales?: Maybe<Scalars['Int']>;
  salePrice?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Int']>;
  sessionCountByCategory?: Maybe<Array<FloatTuple>>;
  sessionDurationSeconds?: Maybe<Scalars['BigInt']>;
  shippingLabel?: Maybe<Scalars['String']>;
  skuCancelledByColor?: Maybe<Array<FloatTuple>>;
  skuCancelledBySize?: Maybe<Array<FloatTuple>>;
  skuCountByClass?: Maybe<Array<FloatTuple>>;
  skuCountByColor?: Maybe<Array<FloatTuple>>;
  skuCountByPrice?: Maybe<Array<FloatTuple>>;
  skuCountBySize?: Maybe<Array<FloatTuple>>;
  skuReturnsByColor?: Maybe<Array<FloatTuple>>;
  skuReturnsBySize?: Maybe<Array<FloatTuple>>;
  skuSalesByClass?: Maybe<Array<FloatTuple>>;
  skuSalesByColor?: Maybe<Array<FloatTuple>>;
  skuSalesByPrice?: Maybe<Array<FloatTuple>>;
  skuSalesBySize?: Maybe<Array<FloatTuple>>;
  stateBreakdowns?: Maybe<Array<ObjectTuple>>;
  topAtcProducts?: Maybe<Array<FloatTuple>>;
  topCtrProducts?: Maybe<Array<FloatTuple>>;
  topGsrProducts?: Maybe<Array<FloatTuple>>;
  topPdpViewsProducts?: Maybe<Array<FloatTuple>>;
  topQtySoldProducts?: Maybe<Array<FloatTuple>>;
  topSaleProducts?: Maybe<Array<FloatTuple>>;
  topUcrProducts?: Maybe<Array<FloatTuple>>;
  topViewedReviewProducts?: Maybe<Array<FloatTuple>>;
  totalDiscounts?: Maybe<Scalars['Float']>;
  totalMarkdowns?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['BigInt']>;
  videosInfo?: Maybe<Array<FloatTuple>>;
};

export type FloatTuple = {
  __typename?: 'FloatTuple';
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type ObjectTuple = {
  __typename?: 'ObjectTuple';
  key: Scalars['String'];
  value: Array<FloatTuple>;
};

export type ObjectProperty = {
  __typename?: 'ObjectProperty';
  brandName?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  merchantName?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  publishDate?: Maybe<Scalars['String']>;
  shippingLabel?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Earnings = {
  __typename?: 'Earnings';
  balance: Scalars['Float'];
  earningsAvailableForTransfer: Scalars['Float'];
  earningsSinceLastTransfer: Scalars['Float'];
  payoutAvailability: CreatorPayoutAvailability;
  transactionHistory: Array<PayPeriod>;
};

export enum CreatorPayoutAvailability {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE_MINIMUM_BALANCE_NOT_MET = 'UNAVAILABLE_MINIMUM_BALANCE_NOT_MET',
  UNAVAILABLE_TAX_DOCUMENT_REQUIRED = 'UNAVAILABLE_TAX_DOCUMENT_REQUIRED',
}

export type PayPeriod = {
  __typename?: 'PayPeriod';
  balance: Scalars['Float'];
  earned: Scalars['Float'];
  periodEndDate: Scalars['DateTime'];
  periodStartDate: Scalars['DateTime'];
  transferred: Scalars['Float'];
};

export type EstimatedDelivery = {
  __typename?: 'EstimatedDelivery';
  cutoffDateTime?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  formattedDate: Scalars['String'];
  maxDeliveryDate: Scalars['String'];
  maxDeliveryFormattedDate: Scalars['String'];
  minDeliveryDate: Scalars['String'];
  minDeliveryFormattedDate: Scalars['String'];
};

export type TwoDayEstimatedDelivery = {
  __typename?: 'TwoDayEstimatedDelivery';
  cutoffDateTime: Scalars['DateTime'];
  maxDeliveryDate: Scalars['String'];
  minDeliveryDate: Scalars['String'];
  shippingMethod: ShippingMethod;
};

export enum ShippingMethod {
  STANDARD_GROUND = 'STANDARD_GROUND',
  STANDARD_ONEDAY = 'STANDARD_ONEDAY',
  STANDARD_TWODAY = 'STANDARD_TWODAY',
}

export type EstimatedDeliveryV2 = {
  __typename?: 'EstimatedDeliveryV2';
  cutoffDateTime?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  formattedDate: Scalars['String'];
  maxDeliveryDate: Scalars['String'];
  maxDeliveryFormattedDate: Scalars['String'];
  minDeliveryDate: Scalars['String'];
  minDeliveryFormattedDate: Scalars['String'];
  productVariantSku: Scalars['String'];
};

export type EstimatedDeliveryLineItem = {
  bundleId?: InputMaybe<Scalars['String']>;
  lineItemId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  sku: Scalars['String'];
};

export type EstimatedDeliveryV3 = {
  __typename?: 'EstimatedDeliveryV3';
  bundleId?: Maybe<Scalars['String']>;
  cutoffDateTime?: Maybe<Scalars['DateTime']>;
  formattedDate: Scalars['String'];
  hasMetMinOrderValueThreshold: Scalars['Boolean'];
  marketplaceShippingDeadline?: Maybe<Scalars['DateTime']>;
  maxDeliveryDate: Scalars['String'];
  maxDeliveryFormattedDate: Scalars['String'];
  minDeliveryDate: Scalars['String'];
  minDeliveryFormattedDate: Scalars['String'];
  minOrderValue: Scalars['Float'];
  minOrderValueEligible: Scalars['Boolean'];
  productVariantSku: Scalars['String'];
  shipDate?: Maybe<Scalars['DateTime']>;
  shippingMethod: ShippingMethod;
};

export enum ExperimentIdType {
  DEVICE = 'DEVICE',
  EMAIL = 'EMAIL',
  USER = 'USER',
}

export type ExperimentResponse = {
  __typename?: 'ExperimentResponse';
  experiments: Array<Maybe<Experiment>>;
  lastUpdatedTs: Scalars['Int'];
};

export type Experiment = {
  __typename?: 'Experiment';
  bucket: Scalars['Int'];
  forceUpdate?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedTs: Scalars['Int'];
  values?: Maybe<Array<Maybe<ExperimentValue>>>;
  version: Scalars['Int'];
};

export type ExperimentValue = {
  __typename?: 'ExperimentValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum ExplorerFeedFetchSource {
  CLIENT_BACKGROUND_FETCH = 'CLIENT_BACKGROUND_FETCH',
  CLIENT_REGULAR = 'CLIENT_REGULAR',
  WEBSITE = 'WEBSITE',
}

export type ExplorerPostDebugInfoResponse = {
  __typename?: 'ExplorerPostDebugInfoResponse';
  assetLabels?: Maybe<Array<Maybe<DebugAssetLabels>>>;
  ownerCarriedCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  ownerId?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  postTier?: Maybe<ExplorerPostTier>;
};

export type DebugAssetLabels = {
  __typename?: 'DebugAssetLabels';
  assetId?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ExplorerPostTier {
  NORMAL = 'NORMAL',
  PREFERRED = 'PREFERRED',
  PREMIUM = 'PREMIUM',
}

export enum ExplorerReportedContentType {
  OWNER = 'OWNER',
  POST = 'POST',
}

export type ExplorerContentReportingReasons = {
  __typename?: 'ExplorerContentReportingReasons';
  code: Scalars['String'];
  message: Scalars['String'];
  reasons?: Maybe<Array<Maybe<ExplorerContentReportingReasons>>>;
  url?: Maybe<Scalars['String']>;
};

export enum ExplorerPostStatusInput {
  PENDING = 'PENDING',
  READY = 'READY',
}

export type ExplorerTopic = {
  __typename?: 'ExplorerTopic';
  id?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  type?: Maybe<ExplorerTopicType>;
};

export enum ExplorerTopicType {
  FILTER = 'FILTER',
  FUZZY = 'FUZZY',
  INTERNAL = 'INTERNAL',
  PERSONALIZED = 'PERSONALIZED',
}

export type GetCreateShopifyAppStatusQueryInput = {
  merchantId: Scalars['String'];
  version?: InputMaybe<Scalars['String']>;
};

export type GetCreateShopifyAppStatusQueryResponse = MutationResponse & {
  __typename?: 'GetCreateShopifyAppStatusQueryResponse';
  hasProductVariantsInDash?: Maybe<Scalars['Boolean']>;
  isInstalled?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<Merchant>;
  query?: Maybe<Query>;
  status?: Maybe<CreateShopifyAppStatus>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type MutationResponse = {
  /** To support query with mutation. Link: https://graphql-rules.com/rules/mutation-payload-query */
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  code: Scalars['Int'];
  field: Scalars['String'];
  message: Scalars['String'];
};

export type Merchant = {
  __typename?: 'Merchant';
  address?: Maybe<Address>;
  adminUserId?: Maybe<Scalars['String']>;
  agreedToInsuranceRequirements: Scalars['Boolean'];
  agreedToTOS?: Maybe<Scalars['Boolean']>;
  aloiShopifyConnectorName?: Maybe<Scalars['String']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccount>;
  canInstallShopifyConnector: Scalars['Boolean'];
  completionSummary: CompletionSummary;
  completionSummaryV2: CompletionSummaryV2;
  createdAt: Scalars['DateTime'];
  customerServiceEmail?: Maybe<Scalars['String']>;
  customerServiceFirstName?: Maybe<Scalars['String']>;
  customerServiceLastName?: Maybe<Scalars['String']>;
  customerServicePhone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  establishedIn?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  fulfillmentDaysRequired?: Maybe<Scalars['Int']>;
  fulfillmentEmail?: Maybe<Scalars['String']>;
  fulfillmentFirstName?: Maybe<Scalars['String']>;
  fulfillmentHolidays?: Maybe<Array<Scalars['String']>>;
  fulfillmentLastName?: Maybe<Scalars['String']>;
  fulfillmentOrderCutoffTime?: Maybe<Scalars['String']>;
  fulfillmentPhone?: Maybe<Scalars['String']>;
  fulfillmentShippingMethod?: Maybe<ShippingMethod>;
  fulfillmentTimezone?: Maybe<Scalars['String']>;
  heroUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  informationActDocuments: Array<ExternalAsset>;
  instagramName?: Maybe<Scalars['String']>;
  isMigratedFromMirakl?: Maybe<Scalars['Boolean']>;
  isOnboardingComplete: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  linkedToMasterMerchantAccount?: Maybe<Scalars['Boolean']>;
  merchantShopifyAuthLink?: Maybe<Scalars['String']>;
  merchantShopifyAuthLinkV2?: Maybe<Scalars['String']>;
  miraklLoginType?: Maybe<MiraklLoginType>;
  miraklShopId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onboardingCompletionStatus: MerchantOnboardingCompletionStatus;
  onboardingVersion: MerchantOnboardingVersion;
  operationalDaysOfWeek?: Maybe<Array<Scalars['Int']>>;
  optIntoExplorerContent?: Maybe<Scalars['Boolean']>;
  orderManagementSystem?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profiles: Array<ProfileV2>;
  proofOfInsurance: Array<ProofOfInsurance>;
  publicContactInfo?: Maybe<MerchantPublicContactInfo>;
  sellsProducts: Scalars['Boolean'];
  shopifyURL?: Maybe<Scalars['String']>;
  status: MerchantStatus;
  subscriptionTier: MerchantSubscriptionTier;
  tags: Array<Tag>;
  taxId?: Maybe<Scalars['String']>;
  totalVpcsAssigned: Scalars['Int'];
  totalVpcsInUse: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  vpcRequestLimit: Scalars['Int'];
  warehouseAddress?: Maybe<Address>;
  website?: Maybe<Scalars['String']>;
  youtubeLink?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  civility?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountOwnerName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<BankAccountAddress>;
  bankName?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type BankAccountAddress = {
  __typename?: 'BankAccountAddress';
  city?: Maybe<Scalars['String']>;
  civility?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  type?: Maybe<PaymentMethodType>;
};

export enum PaymentMethodType {
  ABA = 'ABA',
  AUBSB = 'AUBSB',
  CANADIAN = 'CANADIAN',
  COLOMBIAN = 'COLOMBIAN',
  HK = 'HK',
  IBAN = 'IBAN',
  INDIAN = 'INDIAN',
  UK = 'UK',
}

export type CompletionSummary = {
  __typename?: 'CompletionSummary';
  approvalErrors: Array<ValidationError>;
  bankSettings: Scalars['Boolean'];
  fulfillmentSettings: Scalars['Boolean'];
  generalSettings: Scalars['Boolean'];
  integrationSettings: Scalars['Boolean'];
  readyForApproval: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type CompletionSummaryV2 = {
  __typename?: 'CompletionSummaryV2';
  approvalErrors: Array<ValidationError>;
  bankSettings: Scalars['Boolean'];
  bankSettingsErrors: Array<ValidationError>;
  fulfillmentSettings: Scalars['Boolean'];
  fulfillmentSettingsErrors: Array<ValidationError>;
  generalSettings: Scalars['Boolean'];
  generalSettingsErrors: Array<ValidationError>;
  integrationSettings: Scalars['Boolean'];
  integrationSettingsErrors: Array<ValidationError>;
  pointsOfContactSettings: Scalars['Boolean'];
  pointsOfContactSettingsErrors: Array<ValidationError>;
  proofOfInsuranceSettings: Scalars['Boolean'];
  proofOfInsuranceSettingsErrors: Array<ValidationError>;
  readyForApproval: Scalars['Boolean'];
};

export type ExternalAsset = {
  __typename?: 'ExternalAsset';
  assetId: Scalars['String'];
  id: Scalars['String'];
  merchantId?: Maybe<Scalars['String']>;
};

export enum MiraklLoginType {
  LOGIN_PAGE = 'LOGIN_PAGE',
  SSO = 'SSO',
}

export type MerchantOnboardingCompletionStatus = {
  __typename?: 'MerchantOnboardingCompletionStatus';
  bankInformation: MerchantOnboardingStepCompletionStatus;
  fulfillmentInformation: MerchantOnboardingStepCompletionStatus;
  merchantProfile: MerchantOnboardingStepCompletionStatus;
  pointsOfContact: MerchantOnboardingStepCompletionStatus;
  proofOfInsurance: MerchantOnboardingStepCompletionStatus;
};

export type MerchantOnboardingStepCompletionStatus = {
  __typename?: 'MerchantOnboardingStepCompletionStatus';
  errors: Array<ValidationError>;
  isComplete: Scalars['Boolean'];
};

export enum MerchantOnboardingVersion {
  V0 = 'V0',
  V1 = 'V1',
}

export type ProofOfInsurance = {
  __typename?: 'ProofOfInsurance';
  assetId: Scalars['String'];
  id: Scalars['String'];
  merchantId: Scalars['String'];
};

export type MerchantPublicContactInfo = {
  __typename?: 'MerchantPublicContactInfo';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum MerchantStatus {
  ACTIVE = 'ACTIVE',
  IMPORTED = 'IMPORTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String'];
  label: Scalars['String'];
};

export enum CreateShopifyAppStatus {
  FAILED = 'FAILED',
  NEVER_CREATED = 'NEVER_CREATED',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  SUCCEEDED = 'SUCCEEDED',
}

export type DynamicModuleInput = {
  identifier?: InputMaybe<Scalars['String']>;
  module: DynamicModuleModule;
  segment: DynamicModuleSegment;
};

export enum DynamicModuleModule {
  GENERAL = 'GENERAL',
}

export enum DynamicModuleSegment {
  BEST_SELLERS = 'BEST_SELLERS',
}

export type DynamicModuleResponse = {
  __typename?: 'DynamicModuleResponse';
  identifier?: Maybe<Scalars['String']>;
  module: DynamicModuleModule;
  products: Array<Maybe<Product>>;
  segment: DynamicModuleSegment;
};

export type ProfileFollowPageResponse = {
  __typename?: 'ProfileFollowPageResponse';
  pagination?: Maybe<Pagination>;
  profiles: Array<Maybe<Profile>>;
};

export type ReviewProviderConfig = {
  __typename?: 'ReviewProviderConfig';
  app_key: Scalars['String'];
  app_secret: Scalars['String'];
  last_at?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  provider: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updated_at: Scalars['Int'];
};

export type ReviewsForRewardByOrder = {
  __typename?: 'ReviewsForRewardByOrder';
  orderId: Scalars['String'];
  products: Array<Maybe<ReviewsForRewardStatusItem>>;
};

export type ReviewsForRewardStatusItem = {
  __typename?: 'ReviewsForRewardStatusItem';
  bypassReward: Scalars['Boolean'];
  potentialValue: Scalars['Int'];
  productSid: Scalars['String'];
  reviewWritten: Scalars['Boolean'];
  rewardGenerated: Scalars['Boolean'];
  sku: Scalars['String'];
};

export type VerishopProductCodeRequestFilter = {
  merchantId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<VerishopProductCodeRequestStatus>;
};

export enum VerishopProductCodeRequestStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type VerishopProductCodeRequestOrder = {
  direction: PaginationOrder;
  field: Scalars['String'];
};

export type GetVerishopProductCodeRequestsResponse = {
  __typename?: 'GetVerishopProductCodeRequestsResponse';
  requests: Array<VerishopProductCodeRequest>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VerishopProductCodeRequest = {
  __typename?: 'VerishopProductCodeRequest';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  merchant: Merchant;
  quantity: Scalars['Int'];
  status: VerishopProductCodeRequestStatus;
  statusChangedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type VerishopProductCodeFilter = {
  code?: InputMaybe<Scalars['String']>;
  isInUse?: InputMaybe<Scalars['Boolean']>;
  merchantId?: InputMaybe<Scalars['String']>;
};

export type VerishopProductCodeOrder = {
  direction: PaginationOrder;
  field: Scalars['String'];
};

export type GetVerishopProductCodesResponse = {
  __typename?: 'GetVerishopProductCodesResponse';
  totalCount?: Maybe<Scalars['Int']>;
  vpcs: Array<VerishopProductCode>;
};

export type VerishopProductCode = {
  __typename?: 'VerishopProductCode';
  assignedAt?: Maybe<Scalars['DateTime']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  isInUse: Scalars['Boolean'];
  merchantId?: Maybe<Scalars['String']>;
  productVariant?: Maybe<PimProductVariant>;
  productVariantTitle?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PimProductVariant = {
  __typename?: 'PimProductVariant';
  archived: Scalars['Boolean'];
  attributeValues: Array<PimAttributeValue>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  merchantCatalogListings: Array<PimMerchantCatalogListing>;
  miraklAcceptanceStatus: MiraklAcceptanceStatus;
  miraklRejectionMessage?: Maybe<Scalars['String']>;
  miraklRejectionReasonCode?: Maybe<Scalars['String']>;
  miraklSynchronizationStatus: MiraklSynchronizationStatus;
  miraklValidationStatus: MiraklValidationStatus;
  optionAttributeValues: Array<PimAttributeValue>;
  product: PimProduct;
  productId: Scalars['ID'];
  qualityScore?: Maybe<Scalars['Float']>;
  schemaCategory: PimSchemaCategory;
  schemaCategoryCode: Scalars['String'];
  syncToMiraklSynchronizationStatus: PimSynchronizationStatus;
  updatedAt: Scalars['DateTime'];
  validationErrors: Array<PimValidationError>;
  validationStatus: PimValidationStatus;
  verishopSku?: Maybe<Scalars['String']>;
};

export type PimAttributeValue = {
  attribute: PimAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimAttribute = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export enum PimAttributeType {
  DATE = 'DATE',
  DECIMAL = 'DECIMAL',
  INTEGER = 'INTEGER',
  LIST = 'LIST',
  LIST_MULTIPLE_VALUES = 'LIST_MULTIPLE_VALUES',
  LONG_TEXT = 'LONG_TEXT',
  MEASUREMENT = 'MEASUREMENT',
  MEDIA = 'MEDIA',
  MULTIPLE = 'MULTIPLE',
  TEXT = 'TEXT',
}

export type PimSchemaCategoryAttribute = {
  __typename?: 'PimSchemaCategoryAttribute';
  attribute: PimAttribute;
  attributeCode: Scalars['String'];
  requirementLevel: PimAttributeRequirementLevel;
  schemaCategory: PimSchemaCategory;
  schemaCategoryCode: Scalars['String'];
  schemaLevel: PimSchemaLevel;
};

export enum PimAttributeRequirementLevel {
  DISABLED = 'DISABLED',
  OPTIONAL = 'OPTIONAL',
  RECOMMENDED = 'RECOMMENDED',
  REQUIRED = 'REQUIRED',
}

export type PimSchemaCategory = {
  __typename?: 'PimSchemaCategory';
  children?: Maybe<Array<PimSchemaCategory>>;
  code: Scalars['String'];
  label: Scalars['String'];
  parent?: Maybe<PimSchemaCategory>;
  parentCode?: Maybe<Scalars['String']>;
  schemaCategoryAttributes: Array<PimSchemaCategoryAttribute>;
};

export enum PimSchemaLevel {
  PRODUCT = 'PRODUCT',
  PRODUCT_VARIANT = 'PRODUCT_VARIANT',
}

export type PimValidationError = {
  __typename?: 'PimValidationError';
  attributeCode?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  level?: Maybe<PimValidationErrorLevel>;
  message: Scalars['String'];
  productVariantId?: Maybe<Scalars['String']>;
};

export enum PimValidationErrorLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export type PimMerchantCatalogListing = {
  __typename?: 'PimMerchantCatalogListing';
  createdAt: Scalars['DateTime'];
  merchant?: Maybe<Merchant>;
  merchantSku: Scalars['String'];
  miraklShopId?: Maybe<Scalars['String']>;
  productVariantId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum MiraklAcceptanceStatus {
  ACCEPTED = 'ACCEPTED',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  TO_REVIEW = 'TO_REVIEW',
}

export enum MiraklSynchronizationStatus {
  INTEGRATION_ERRORS = 'INTEGRATION_ERRORS',
  PENDING = 'PENDING',
  SYNCHRONIZED = 'SYNCHRONIZED',
}

export enum MiraklValidationStatus {
  INVALID = 'INVALID',
  VALID = 'VALID',
}

export type PimProduct = {
  __typename?: 'PimProduct';
  attributeValues: Array<PimAttributeValue>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  qualityScore?: Maybe<Scalars['Float']>;
  schemaCategoryCodes: Array<Scalars['String']>;
  shopifyId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  validationErrors: Array<PimValidationError>;
  validationStatus: PimValidationStatus;
  variants: Array<PimProductVariant>;
};

export enum PimValidationStatus {
  INVALID = 'INVALID',
  VALID = 'VALID',
}

export enum PimSynchronizationStatus {
  ERRORS = 'ERRORS',
  PENDING_MIRAKL_SYNC = 'PENDING_MIRAKL_SYNC',
  PENDING_SYNC = 'PENDING_SYNC',
  SYNCHRONIZED = 'SYNCHRONIZED',
}

export type LiveShopConnectionCounter = {
  __typename?: 'LiveShopConnectionCounter';
  connectionCount: Scalars['Int'];
  id: Scalars['String'];
};

export type LiveShopBounds = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type LiveShopPaginatedResponse = {
  __typename?: 'LiveShopPaginatedResponse';
  items: Array<Maybe<LiveShop>>;
  pagination: PaginationWithoutCount;
};

export type PublicOrderInfo = {
  __typename?: 'PublicOrderInfo';
  merchantPublicInfo?: Maybe<MerchantPublicContactInfo>;
  productVariant: PublicOrderProductVariant;
};

export type PublicOrderProductVariant = {
  __typename?: 'PublicOrderProductVariant';
  brandSlug: Scalars['String'];
  familySlug: Scalars['String'];
  options?: Maybe<Array<Maybe<Option>>>;
  productId: Scalars['String'];
  productSlug: Scalars['String'];
  selectedOptions?: Maybe<Array<Maybe<SelectedOption>>>;
  title: Scalars['String'];
};

export type MerchantProductStats = {
  __typename?: 'MerchantProductStats';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  existing_offers?: Maybe<ProductStats_ExistingOffers>;
  shopify?: Maybe<ProductStats_Shopify>;
};

export type ProductStats_ExistingOffers = {
  __typename?: 'ProductStats_ExistingOffers';
  active?: Maybe<Scalars['Int']>;
  inactive?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  verishop_discount?: Maybe<Scalars['Int']>;
  verishop_pricing?: Maybe<Scalars['Int']>;
};

export type ProductStats_Shopify = {
  __typename?: 'ProductStats_Shopify';
  tagged_not_loaded?: Maybe<ProductStats_TaggedNotLoaded>;
  tagged_product_count?: Maybe<Scalars['Int']>;
  tagged_variants?: Maybe<ProductStats_TaggedVariants>;
};

export type ProductStats_TaggedNotLoaded = {
  __typename?: 'ProductStats_TaggedNotLoaded';
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  variants?: Maybe<Array<Maybe<ProductStats_Variants>>>;
};

export type ProductStats_Variants = {
  __typename?: 'ProductStats_Variants';
  product_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  variant_id?: Maybe<Scalars['String']>;
};

export type ProductStats_TaggedVariants = {
  __typename?: 'ProductStats_TaggedVariants';
  duplicate_upcs?: Maybe<Array<ProductStats_DuplicateUpcs>>;
  no_upcs?: Maybe<Scalars['Int']>;
  total_duplicate_upcs?: Maybe<Scalars['Int']>;
  total_unique_upcs?: Maybe<Scalars['Int']>;
  total_variants?: Maybe<Scalars['Int']>;
};

export type ProductStats_DuplicateUpcs = {
  __typename?: 'ProductStats_DuplicateUpcs';
  duplicates?: Maybe<Array<Maybe<Scalars['String']>>>;
  upc?: Maybe<Scalars['String']>;
};

export type PaginatedMerchantsResponse = {
  __typename?: 'PaginatedMerchantsResponse';
  items: Array<Merchant>;
  pagination: Pagination;
};

export type MobileApp = {
  __typename?: 'MobileApp';
  minimumSupportedVersion?: Maybe<Scalars['String']>;
  os?: Maybe<Os>;
  shopPartyDiscountPercentage: Scalars['Float'];
};

export enum Os {
  IOS = 'IOS',
}

export type Modal = {
  __typename?: 'Modal';
  id: Scalars['ID'];
  isOpen: Scalars['Boolean'];
};

export type NotificationSectionResponse = {
  __typename?: 'NotificationSectionResponse';
  displayString?: Maybe<Scalars['String']>;
  settings?: Maybe<Array<Maybe<NotificationSettingOutput>>>;
  titleCode?: Maybe<Scalars['String']>;
};

export type NotificationSettingOutput = {
  __typename?: 'NotificationSettingOutput';
  displayString?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  notificationType?: Maybe<NotificationType>;
};

export enum NotificationType {
  FOLLOWS = 'FOLLOWS',
  LIKES = 'LIKES',
  TAGS = 'TAGS',
}

export type PdpInfo = {
  __typename?: 'PdpInfo';
  product?: Maybe<Product>;
  status: PdpInfoStatus;
};

export type PdpInfoStatus = {
  __typename?: 'PdpInfoStatus';
  code: Scalars['String'];
  newSid?: Maybe<Scalars['String']>;
};

export type PimGetJobsByGroupIdResponse = {
  __typename?: 'PimGetJobsByGroupIdResponse';
  failedJobs: Array<PimJob>;
  isComplete: Scalars['Boolean'];
  jobs: Array<PimJob>;
  numberJobsCompleted: Scalars['Int'];
  totalJobs: Scalars['Int'];
};

export type PimJob = {
  __typename?: 'PimJob';
  errors: Array<PimValidationError>;
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  offerId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productVariantId?: Maybe<Scalars['String']>;
  status: PimJobStatusEnum;
};

export enum PimJobStatusEnum {
  ERRORS = 'ERRORS',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export type PimGetOffersInput = {
  ids: Array<Scalars['ID']>;
};

export type PimGetOffersResponse = {
  __typename?: 'PimGetOffersResponse';
  offers: Array<PimOffer>;
  query?: Maybe<Query>;
};

export type PimOffer = {
  __typename?: 'PimOffer';
  active: Scalars['Boolean'];
  dashControlled: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  discountPrice?: Maybe<Scalars['Float']>;
  discountPriceEndDate?: Maybe<Scalars['String']>;
  discountPriceStartDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inactivityReasons: Array<OfferInactivityReason>;
  merchant: Merchant;
  price: Scalars['Float'];
  productVariant: PimProductVariant;
  quantity: Scalars['Int'];
  sku: Scalars['String'];
};

export enum OfferInactivityReason {
  AVAILABLE_QUANTITY_LESS_THAN_MIN_ORDER_QUANTITY = 'AVAILABLE_QUANTITY_LESS_THAN_MIN_ORDER_QUANTITY',
  AVAILABLE_QUANTITY_LESS_THAN_PACKAGE_QUANTITY = 'AVAILABLE_QUANTITY_LESS_THAN_PACKAGE_QUANTITY',
  NO_ACTIVE_PRICE = 'NO_ACTIVE_PRICE',
  NO_CHANNEL_ON_SHOP = 'NO_CHANNEL_ON_SHOP',
  NO_MIN_SHIPPING_RATE = 'NO_MIN_SHIPPING_RATE',
  NOT_IN_AVAILABILITY_PERIOD = 'NOT_IN_AVAILABILITY_PERIOD',
  PRODUCT_DISABLED = 'PRODUCT_DISABLED',
  PRODUCT_STATUS_INVALID = 'PRODUCT_STATUS_INVALID',
  PRODUCT_STATUS_NEW = 'PRODUCT_STATUS_NEW',
  PRODUCT_STATUS_PENDING = 'PRODUCT_STATUS_PENDING',
  PRODUCT_STATUS_REJECTED = 'PRODUCT_STATUS_REJECTED',
  PRODUCT_STATUS_SYNCHRO_ERROR = 'PRODUCT_STATUS_SYNCHRO_ERROR',
  PRODUCT_STATUS_UNAUTHORIZED_TO_SELL = 'PRODUCT_STATUS_UNAUTHORIZED_TO_SELL',
  SHOP_CURRENCY_DISABLED = 'SHOP_CURRENCY_DISABLED',
  SHOP_NOT_OPEN = 'SHOP_NOT_OPEN',
  ZERO_QUANTITY = 'ZERO_QUANTITY',
}

export type PimGetSpreadsheetProductVariantsInput = {
  limit: Scalars['Int'];
  merchantId: Scalars['String'];
  offset: Scalars['Int'];
};

export type PimGetSpreadsheetProductVariantsResponse = {
  __typename?: 'PimGetSpreadsheetProductVariantsResponse';
  columns: Array<PimSpreadsheetColumn>;
  productVariants: Array<PimGetSpreadsheetProductVariant>;
  productVariantsTotalCount: Scalars['Int'];
};

export type PimSpreadsheetColumn = {
  __typename?: 'PimSpreadsheetColumn';
  attributeCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isVariantOption?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  listValues?: Maybe<Array<PimSpreadsheetColumnListValue>>;
  requirementLevel?: Maybe<PimAttributeRequirementLevel>;
  type: PimAttributeType;
};

export type PimSpreadsheetColumnListValue = {
  __typename?: 'PimSpreadsheetColumnListValue';
  code: Scalars['String'];
  label: Scalars['String'];
};

export type PimGetSpreadsheetProductVariant = {
  __typename?: 'PimGetSpreadsheetProductVariant';
  attributeValues: Array<PimGetSpreadsheetAttributeValue>;
  id: Scalars['String'];
  miraklAcceptanceStatus: MiraklAcceptanceStatus;
  productId: Scalars['String'];
  validations: Array<PimSpreadsheetValidation>;
};

export type PimGetSpreadsheetAttributeValue = {
  __typename?: 'PimGetSpreadsheetAttributeValue';
  code: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PimSpreadsheetValidation = {
  __typename?: 'PimSpreadsheetValidation';
  code: Scalars['Int'];
  field: Scalars['String'];
  id: Scalars['String'];
  level: Scalars['String'];
  message: Scalars['String'];
};

export type PimGetSpreadsheetTemplateInput = {
  schemaCategoryCode: Scalars['String'];
};

export type PimGetSpreadsheetTemplateResponse = {
  __typename?: 'PimGetSpreadsheetTemplateResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type PimOrder = {
  __typename?: 'PimOrder';
  billingAddress: Address;
  createdAt: Scalars['DateTime'];
  customerName: Scalars['String'];
  customerOrderNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items: Array<PimOrderItem>;
  merchant: Merchant;
  miraklOrderCreatedAt: Scalars['DateTime'];
  miraklOrderId: Scalars['String'];
  orderState: OrderState;
  orderTaxMode: OrderTaxMode;
  shippingAddress: Address;
  shippingCompany?: Maybe<Scalars['String']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingTrackingNumber?: Maybe<Scalars['String']>;
  shippingTrackingUrl?: Maybe<Scalars['String']>;
  shippingTypeCode?: Maybe<Scalars['String']>;
  shippingTypeLabel?: Maybe<Scalars['String']>;
  shopCustomerOrder?: Maybe<Scalars['String']>;
  shopOrderId?: Maybe<Scalars['String']>;
  totalCommission?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type PimOrderItem = {
  __typename?: 'PimOrderItem';
  cancelations: Array<PimOrderItemCancelation>;
  id: Scalars['ID'];
  offerSku: Scalars['String'];
  orderLineState: OrderState;
  productMediaUrl: Scalars['String'];
  productSku: Scalars['String'];
  productTitle: Scalars['String'];
  quantity: Scalars['Int'];
  refunds: Array<PimOrderItemRefund>;
  totalCommission: Scalars['Float'];
  totalCommissionTaxes: Scalars['Float'];
  totalDiscounts: Scalars['Float'];
  totalPrice: Scalars['Float'];
  totalShipping: Scalars['Float'];
  totalShippingTaxes: Scalars['Float'];
  totalTaxes: Scalars['Float'];
  unitPrice: Scalars['Float'];
};

export type PimOrderItemCancelation = {
  __typename?: 'PimOrderItemCancelation';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  reason: Scalars['String'];
  totalAmount: Scalars['Float'];
  totalCommission: Scalars['Float'];
  totalCommissionTaxes: Scalars['Float'];
  totalShipping: Scalars['Float'];
  totalShippingTaxes: Scalars['Float'];
  totalTaxes: Scalars['Float'];
};

export enum OrderState {
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  INCIDENT_OPEN = 'INCIDENT_OPEN',
  RECEIVED = 'RECEIVED',
  REFUNDED = 'REFUNDED',
  REFUSED = 'REFUSED',
  SHIPPED = 'SHIPPED',
  SHIPPING = 'SHIPPING',
  STAGING = 'STAGING',
  TO_COLLECT = 'TO_COLLECT',
  WAITING_ACCEPTANCE = 'WAITING_ACCEPTANCE',
  WAITING_DEBIT = 'WAITING_DEBIT',
  WAITING_DEBIT_PAYMENT = 'WAITING_DEBIT_PAYMENT',
  WAITING_REFUND = 'WAITING_REFUND',
  WAITING_REFUND_PAYMENT = 'WAITING_REFUND_PAYMENT',
}

export type PimOrderItemRefund = {
  __typename?: 'PimOrderItemRefund';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  reason: Scalars['String'];
  state?: Maybe<OrderRefundState>;
  totalAmount: Scalars['Float'];
  totalCommission: Scalars['Float'];
  totalCommissionTaxes: Scalars['Float'];
  totalShipping: Scalars['Float'];
  totalShippingTaxes: Scalars['Float'];
  totalTaxes: Scalars['Float'];
};

export enum OrderRefundState {
  REFUNDED = 'REFUNDED',
  WAITING_REFUND = 'WAITING_REFUND',
  WAITING_REFUND_PAYMENT = 'WAITING_REFUND_PAYMENT',
}

export enum OrderTaxMode {
  TAX_EXCLUDED = 'TAX_EXCLUDED',
  TAX_INCLUDED = 'TAX_INCLUDED',
}

export type PimRejectionReason = {
  __typename?: 'PimRejectionReason';
  code: Scalars['String'];
  label: Scalars['String'];
  type: PimRejectionReasonType;
};

export enum PimRejectionReasonType {
  DEFINITIVE = 'DEFINITIVE',
  TEMPORARY = 'TEMPORARY',
}

export type ProductAttributesRecommendationsResponse = {
  __typename?: 'ProductAttributesRecommendationsResponse';
  attributes: Array<Maybe<Attribute>>;
  googleCategorization: Array<Scalars['String']>;
  navCategories: NavCategoriesResponse;
  navCategorization: Array<Scalars['String']>;
  pimProductId: Scalars['String'];
  taxCodes: Array<Scalars['String']>;
};

export type Attribute = {
  __typename?: 'Attribute';
  code: Scalars['String'];
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NavCategoriesResponse = {
  __typename?: 'NavCategoriesResponse';
  allCategories?: Maybe<Array<Maybe<Category>>>;
  currentCategories?: Maybe<Array<Maybe<Category>>>;
  recommendedCategories: Array<Maybe<Category>>;
};

export type FiltersExpression = {
  childExpressions?: InputMaybe<Array<FiltersExpression>>;
  filters?: InputMaybe<Array<Filter>>;
  operator: Operator;
};

export type Filter = {
  field: Scalars['String'];
  op: Operation;
  relationField?: InputMaybe<Scalars['String']>;
  values: Array<Scalars['String']>;
};

export enum Operation {
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  IN = 'IN',
  LIKE = 'LIKE',
  LT = 'LT',
  LTE = 'LTE',
}

export enum Operator {
  AND = 'AND',
  OR = 'OR',
}

export type OrderByCondition = {
  columnName: Scalars['String'];
  direction: PaginationOrder;
};

export type ProductsPaginatedResponse = {
  __typename?: 'ProductsPaginatedResponse';
  items: Array<Maybe<Product>>;
  pagination: PaginationWithoutCount;
};

export type ProfilePageResponse = {
  __typename?: 'ProfilePageResponse';
  cursor?: Maybe<Pagination>;
  profiles: Array<Maybe<Profile>>;
};

export type PromotionDetailResponse = {
  __typename?: 'PromotionDetailResponse';
  appliesToBundles?: Maybe<Scalars['Boolean']>;
  appliesToDiscountedItems?: Maybe<Scalars['Boolean']>;
  code: Scalars['String'];
  details: Scalars['String'];
  detailsModalId: Scalars['String'];
  discounts?: Maybe<Array<PromotionDiscount>>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  minSubtotal?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type PromotionDiscount = {
  __typename?: 'PromotionDiscount';
  /** Percentage, whole number */
  discount: Scalars['Int'];
  /** Promo Code eligibility criteria */
  eligibility?: Maybe<Array<PromotionEligibility>>;
};

export type PromotionEligibility = {
  __typename?: 'PromotionEligibility';
  /** Promo Code eligibility type */
  type: PromotionEligibilityType;
  /** Promo Code eligibility value (ex. 'mens' for mens category) */
  value: Scalars['String'];
};

export enum PromotionEligibilityType {
  CATEGORY = 'CATEGORY',
  SKU = 'SKU',
}

export type RecommendationKey = {
  inputId: Scalars['String'];
  modelId: Scalars['String'];
  modelVariantId: Scalars['String'];
};

export type RecommendationPage = {
  cursor?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type MultiKeyRecommendationKey = {
  modelId: Scalars['String'];
  modelVariantId: Scalars['String'];
  productIds: Array<InputMaybe<Scalars['String']>>;
};

export type Referral = {
  __typename?: 'Referral';
  advocateDiscountCode?: Maybe<Scalars['String']>;
  friendEmail: Scalars['String'];
  id: Scalars['ID'];
};

export type ShippingTier = {
  __typename?: 'ShippingTier';
  shippingFee: Scalars['Int'];
  shippingMethod: ShippingMethod;
  threshold: Scalars['Int'];
  type: ShippingTierType;
};

export enum ShippingTierType {
  BASE = 'BASE',
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
}

export enum ShopPartyQueryStatus {
  FUTURE = 'FUTURE',
  PAST = 'PAST',
}

export type ShopPartyPaginatedResponse = {
  __typename?: 'ShopPartyPaginatedResponse';
  cursor?: Maybe<Scalars['String']>;
  shopParties: Array<Maybe<ShopParty>>;
};

export type ShopParty = {
  __typename?: 'ShopParty';
  carts: ShopPartyCartPaginatedResponse;
  collectionUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  coverAsset?: Maybe<Asset>;
  description?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  id: Scalars['String'];
  invitationCode?: Maybe<Scalars['String']>;
  members: ShopPartyMemberPaginatedResponse;
  startsAt: Scalars['DateTime'];
  status: ShopPartyStatus;
  title: Scalars['String'];
};

export type ShopPartyCartsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type ShopPartyMembersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  rsvpStatus?: InputMaybe<ShopPartyMemberRsvpStatus>;
  type?: InputMaybe<ShopPartyMemberType>;
};

export type ShopPartyCartPaginatedResponse = {
  __typename?: 'ShopPartyCartPaginatedResponse';
  carts: Array<Maybe<ShopPartyCart>>;
  cursor?: Maybe<Scalars['String']>;
};

export type ShopPartyCart = {
  __typename?: 'ShopPartyCart';
  lineItems: Array<Maybe<ShopPartyCartLineItem>>;
  profile: Profile;
};

export type ShopPartyCartLineItem = {
  __typename?: 'ShopPartyCartLineItem';
  id: Scalars['String'];
  isPurchased: Scalars['Boolean'];
  productVariant: ProductVariant;
  reactionMetadata: Array<Maybe<ShopPartyCartLineItemReactionMetadata>>;
};

export type ShopPartyCartLineItemReactionMetadata = {
  __typename?: 'ShopPartyCartLineItemReactionMetadata';
  count: Scalars['Int'];
  didUserReact: Scalars['Boolean'];
  type: ShopPartyCartLineItemReactionType;
};

export enum ShopPartyCartLineItemReactionType {
  LIKE = 'LIKE',
}

export enum ShopPartyMemberRsvpStatus {
  GOING = 'GOING',
  JOINED = 'JOINED',
  NOT_GOING = 'NOT_GOING',
  PENDING = 'PENDING',
  REMOVED = 'REMOVED',
}

export enum ShopPartyMemberType {
  HOST = 'HOST',
  MEMBER = 'MEMBER',
}

export type ShopPartyMemberPaginatedResponse = {
  __typename?: 'ShopPartyMemberPaginatedResponse';
  cursor?: Maybe<Scalars['String']>;
  members: Array<Maybe<ShopPartyMember>>;
};

export type ShopPartyMember = {
  __typename?: 'ShopPartyMember';
  profile: Profile;
  rsvpStatus: ShopPartyMemberRsvpStatus;
  type: ShopPartyMemberType;
};

export enum ShopPartyStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export type TopProductsInput = {
  date: Scalars['String'];
  fields: Array<Scalars['String']>;
  objectId: Scalars['String'];
  objectType: Scalars['String'];
  timeFrameType?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type TopProductsResponse = {
  __typename?: 'TopProductsResponse';
  topAtcProducts?: Maybe<Array<TopProduct>>;
  topGsrProducts?: Maybe<Array<TopProduct>>;
  topQtySoldProducts?: Maybe<Array<TopProduct>>;
  updatedAt?: Maybe<Scalars['BigInt']>;
};

export type TopProduct = {
  __typename?: 'TopProduct';
  brand?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  countPlpImpressions?: Maybe<Scalars['Int']>;
  countSessions?: Maybe<Scalars['Int']>;
  grossMerchReceipts?: Maybe<Scalars['Float']>;
  grossMerchSales?: Maybe<Scalars['Float']>;
  productId: Scalars['String'];
  quantitySold?: Maybe<Scalars['Int']>;
  sales?: Maybe<Scalars['Int']>;
  score: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  totalDiscounts?: Maybe<Scalars['Float']>;
  totalMarkdowns?: Maybe<Scalars['Float']>;
};

export enum UserFilterFields {
  EMAIL = 'EMAIL',
  FIRSTNAME = 'FIRSTNAME',
  LASTNAME = 'LASTNAME',
  PHONE_NUMBER = 'PHONE_NUMBER',
  UUID = 'UUID',
}

export type PagedUser = {
  __typename?: 'PagedUser';
  paginationToken?: Maybe<Scalars['String']>;
  result: Array<User>;
};

export type VerifiedDeal = {
  __typename?: 'VerifiedDeal';
  appliedShopifyGiftCardId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['String'];
  isSeen: Scalars['Boolean'];
  liveShopId?: Maybe<Scalars['String']>;
  maximumOrderCount?: Maybe<Scalars['Int']>;
  minimumSpend?: Maybe<Scalars['Int']>;
  pictureAsset?: Maybe<Asset>;
  productIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  shopifyGiftCardCode?: Maybe<Scalars['String']>;
  shopifyGiftCardId?: Maybe<Scalars['String']>;
  shopifyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  shopifyOrderId?: Maybe<Scalars['String']>;
  shopPartyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  skus?: Maybe<Array<Maybe<Scalars['String']>>>;
  sponsor?: Maybe<Profile>;
  status: VerifiedDealStatus;
  type: Scalars['String'];
  unlocksAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  value: Scalars['Float'];
  valueType: VerifiedDealValueType;
};

export enum VerifiedDealStatus {
  UNUSED = 'UNUSED',
  USED = 'USED',
}

export enum VerifiedDealValueType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export type VerifiedDealPaginatedResponse = {
  __typename?: 'VerifiedDealPaginatedResponse';
  cursor?: Maybe<Scalars['String']>;
  deals: Array<Maybe<VerifiedDeal>>;
};

export type VisenzeProductRecommendationsInput = {
  discoverSearchApiResponseObjectsJsonString: Scalars['String'];
  imageHeight: Scalars['Int'];
  imageWidth: Scalars['Int'];
  recognizeApiResponseResultsJsonString: Scalars['String'];
};

export type VisenzeProductRecommendations = {
  __typename?: 'VisenzeProductRecommendations';
  productVariants: Array<Maybe<ProductVariant>>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  acceptCoHostInvite: GenericMutationResponse;
  acceptCoHostInviteByCode: GenericMutationResponse;
  assignVpcsToMerchant: AssignVpcsToMerchantResponse;
  /** associate the given anonymousUserId to the user (need cognitoToken) */
  associateUserToAnonymousId: UserIdentityMutationResponse;
  blockPost: ExplorerPostUserResponse;
  changeMerchantAdminUser: ChangeMerchantAdminUserResponse;
  claimLiveShopDiscount: ClaimLiveShopDiscountResponse;
  closeModal?: Maybe<Scalars['ID']>;
  confirmEmail: CustomerMutationResponse;
  createAssetSignedUrls: CreateSignedUrlMutationResponse;
  createBookmark: BookmarkMutationResponse;
  createBookmarkList: BookmarkListMutationResponse;
  createBookmarks: MutationResponse;
  /** subscribes customer to coming soon notifications */
  createComingSoonNotification: ComingSoonNotificationMutationResponse;
  /** Collect email */
  createEmailSignUp: GenericMutationResponse;
  createExplorerPost: CreateExplorerPostResponse;
  /** subscribes customer to in stock notifications */
  createInStockNotification: InStockNotificationMutationResponse;
  createMerchant: CreateMerchantResponse;
  /** generate a multipass url for signing user to shopify checkout */
  createMultipassUrl: CreateMultipassUrlMutationResponse;
  createOnboardingDiscount: MutationResponse;
  createProductBookmark: BookmarkMutationResponse;
  createProductReview: GenericMutationResponse;
  createProfile: CreateProfileMutationResponse;
  /** Creates a saml assertion response to to enable single sign on.  User must have appropriate access token and profile authorization. */
  createSAMLResponse: CreateSamlResponseMutationResponse;
  createShopifyApp: CreateShopifyAppMutationResponse;
  createSignedUrlForSecuredAssets: CreateSignedUrlsForSecuredAssetsResponse;
  createVpcRequest: CreateVerishopProductCodeRequestResponse;
  creatorRequestW9: CreatorMutationResponse;
  deleteBookmark: DeleteBookmarkMutationResponse;
  deleteBookmarkList: DeleteBookmarkMutationResponse;
  deleteBookmarks: MutationResponse;
  deleteExplorerPost: MutationResponse;
  deleteLiveShop: GenericMutationResponse;
  deleteProfile: DeleteProfileMutationResponse;
  deleteReviewProviderConfig: GenericMutationResponse;
  /** delete user's account */
  deleteUserAccount: MutationResponse;
  demotePost: ExplorerPostUserResponse;
  enqueueEmailForCorporateAffiliate: GenericMutationResponse;
  follow: ProfileFollowMutationResponse;
  /** force-logout user from all devices. */
  forceLogout: MutationResponse;
  /** Create an AnonymousIdentity. Will generate an id if one is not provided. */
  generateAnonymousIdentity: UserIdentityMutationResponse;
  generateOnboardingInviteCode: GenerateOnboardingInviteCodeResponse;
  generateRewardForOrderId: GenerateRewardForOrderIdResponse;
  inviteCoHost: GenericMutationResponse;
  inviteEmailToProfile: InviteEmailToProfileResponse;
  likeFeaturedProduct: GenericMutationResponse;
  likePost: ExplorerPostUserResponse;
  liveShopCreate: LiveShopMutationResponse;
  markVerifiedDealsAsSeen: GenericMutationResponse;
  notificationSettingsUpdate?: Maybe<MutationResponse>;
  openModal?: Maybe<Scalars['ID']>;
  optInCreator: CreatorMutationResponse;
  payout: GenericMutationResponse;
  pimArchiveProducts: PimArchiveProductsMutationResponse;
  pimArchiveProductVariants: PimArchiveProductVariantsMutationResponse;
  pimBulkUpdateProductVariants: PimBulkUpdateProductVariantsMutationResponse;
  pimBulkUpsertOffers: PimBulkUpsertOffersResponse;
  pimDeleteProducts: PimDeleteProductsMutationResponse;
  pimEnqueueBulkProductsUpdate: PimEnqueueBulkProductsUpdateResponse;
  pimGroupProductVariants: PimGroupProductVariantsMutationResponse;
  pimMigrateProductsToDash: PimMigrateProductsToDashResponse;
  pimSyncProduct: PimSyncProductResponse;
  pimUngroupProductVariants: PimUngroupProductVariantsMutationResponse;
  pimUpdateProduct: PimUpdateProductMutationResponse;
  pimUpdateProductArchiveStatus: PimUpdateProductArchiveStatusMutationResponse;
  pimUpdateProductCategories: PimUpdateProductCategoriesMutationResponse;
  pimUpdateProducts: PimUpdateProductsMutationResponse;
  pimUpdateProductSchemaCategory: PimUpdateProductSchemaCategoryMutationResponse;
  pimUpdateProductVariant: PimUpdateProductVariantMutationResponse;
  pimUpdateProductVariantArchiveStatus: PimUpdateProductVariantArchiveStatusMutationResponse;
  pimUpdateProductVariants: PimUpdateProductVariantsMutationResponse;
  pimUpdateProductVariantsAcceptanceStatus: PimUpdateProductVariantsAcceptanceStatusMutationResponse;
  pimUpsertOffers: PimUpsertOffersResponse;
  pimUpsertSpreadsheetProductVariants: PimUpsertSpreadsheetProductVariantsResponse;
  postShared: ExplorerPostUserResponse;
  promotePost: ExplorerPostUserResponse;
  redeemOnboardingInviteCode: RedeemOnboardingInviteCodeResponse;
  redeemVerifiedDeal: RedeemVerifiedDealResponse;
  rejectCoHostInvite: GenericMutationResponse;
  reloadMerchantCatalog?: Maybe<ReloadMerchantCatalogResponse>;
  removeItemsFromCart?: Maybe<RemoveItemsFromCartResponse>;
  reportExplorerContent: MutationResponse;
  /** Report a post to customer service */
  reportPost: ExplorerPostUserResponse;
  revokeMerchantProfile: RevokeMerchantProfileResponse;
  /** Internal user actions */
  revokePost: ExplorerPostUserResponse;
  saveAppliedShopifyGiftCardId: GenericMutationResponse;
  saveConfig: GenericMutationResponse;
  sendConfirmationEmail: GenericMutationResponse;
  /** Send DMCA email */
  sendDMCAEmail: GenericMutationResponse;
  setCreateShopifyAppStatus: SetCreateShopifyAppStatusMutationResponse;
  setGiftCardMessage?: Maybe<Scalars['String']>;
  setGiftNote?: Maybe<Scalars['String']>;
  setReferralData?: Maybe<SetReferralDataResponse>;
  setReviewWrittenForProductAndOrderId: GenericMutationResponse;
  shopPartyCancel: MutationResponse;
  shopPartyCartLineItemReact: MutationResponse;
  shopPartyCartUpsert: MutationResponse;
  shopPartyCreate: ShopPartyMutationResponse;
  shopPartyJoin: ShopPartyJoinMutationResponse;
  shopPartyRsvp: MutationResponse;
  shopPartyUpdate: MutationResponse;
  ssoCompleteSignup: GenericMutationResponse;
  timestampFeaturedProduct: GenericMutationResponse;
  unfollow: ProfileFollowMutationResponse;
  unlikeFeaturedProduct: GenericMutationResponse;
  /** Remove the like for a post */
  unlikePost: ExplorerPostUserResponse;
  unwatchlistLiveShop: GenericMutationResponse;
  updateBookmark: BookmarkMutationResponse;
  updateBookmarkList: BookmarkListMutationResponse;
  /** update customer attributes */
  updateCustomer: CustomerMutationResponse;
  updateExplorerPost: MutationResponse;
  updateLiveShop: LiveShopMutationResponse;
  updateLiveShopCast: LiveShopMutationResponse;
  updateMerchant: UpdateMerchantResponse;
  updateMerchantBankInfo: UpdateMerchantBankInfoResponse;
  updateMerchantStatus: UpdateMerchantStatusResponse;
  updateMerchantVpcRequestLimit: UpdateMerchantVpcRequestLimitResponse;
  updatePayoutSetting: CreatorMutationResponse;
  updateProfile: ProfileMutationResponse;
  updateVpcRequest: UpdateVerishopProductCodeRequestResponse;
  upsertMerchant: UpsertMerchantResponse;
  upsertRanking: GenericMutationResponse;
  upsertRecommendation: GenericMutationResponse;
  upsertReviewProviderConfigs: GenericMutationResponse;
  watchlistLiveShop: GenericMutationResponse;
};

export type MutationAcceptCoHostInviteArgs = {
  input: InviteCoHostInput;
};

export type MutationAcceptCoHostInviteByCodeArgs = {
  input: AcceptCoHostByCodeInput;
};

export type MutationAssignVpcsToMerchantArgs = {
  input: AssignVpcsToMerchantInput;
};

export type MutationAssociateUserToAnonymousIdArgs = {
  anonymousUserId: Scalars['String'];
};

export type MutationBlockPostArgs = {
  postId: Scalars['String'];
};

export type MutationChangeMerchantAdminUserArgs = {
  input: ChangeMerchantAdminUserInput;
};

export type MutationClaimLiveShopDiscountArgs = {
  input: ClaimLiveShopDiscountInput;
};

export type MutationCloseModalArgs = {
  id: Scalars['ID'];
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationCreateAssetSignedUrlsArgs = {
  input: CreateAssetSignedUrlsInput;
};

export type MutationCreateBookmarkArgs = {
  input: BookmarkInput;
};

export type MutationCreateBookmarkListArgs = {
  input: BookmarkListInput;
};

export type MutationCreateBookmarksArgs = {
  input: Array<InputMaybe<BookmarkInput>>;
};

export type MutationCreateComingSoonNotificationArgs = {
  input: ComingSoonNotificationInput;
};

export type MutationCreateEmailSignUpArgs = {
  input: EmailSignUpInput;
};

export type MutationCreateExplorerPostArgs = {
  input: CreateExplorerPostInput;
};

export type MutationCreateInStockNotificationArgs = {
  input: InStockNotificationInput;
};

export type MutationCreateMerchantArgs = {
  input: CreateMerchantInput;
};

export type MutationCreateMultipassUrlArgs = {
  input: CreateMultipassUrlInput;
};

export type MutationCreateOnboardingDiscountArgs = {
  input?: InputMaybe<CreateOnboardingDiscountInput>;
};

export type MutationCreateProductBookmarkArgs = {
  input: ProductBookmarkInput;
};

export type MutationCreateProductReviewArgs = {
  createProductReviewInput: CreateProductReviewInput;
};

export type MutationCreateProfileArgs = {
  input: CreateProfileMutationInput;
};

export type MutationCreateSamlResponseArgs = {
  input: CreateSamlResponseInput;
};

export type MutationCreateShopifyAppArgs = {
  input: CreateShopifyAppInput;
};

export type MutationCreateSignedUrlForSecuredAssetsArgs = {
  input: CreateSignedUrlsForSecuredAssetsInput;
};

export type MutationCreateVpcRequestArgs = {
  input: VerishopProductCodeRequestInput;
};

export type MutationCreatorRequestW9Args = {
  input?: InputMaybe<CreatorRequestW9Input>;
};

export type MutationDeleteBookmarkArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBookmarkListArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBookmarksArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type MutationDeleteExplorerPostArgs = {
  postId: Scalars['String'];
};

export type MutationDeleteLiveShopArgs = {
  id: Scalars['String'];
};

export type MutationDeleteProfileArgs = {
  input: DeleteProfileMutationInput;
};

export type MutationDeleteReviewProviderConfigArgs = {
  input: DeleteReviewProviderConfigInput;
};

export type MutationDeleteUserAccountArgs = {
  input: DeleteUserAccountInput;
};

export type MutationDemotePostArgs = {
  postId: Scalars['String'];
};

export type MutationEnqueueEmailForCorporateAffiliateArgs = {
  email: Scalars['String'];
  marketingOptIn: Scalars['Boolean'];
  slug: Scalars['String'];
};

export type MutationFollowArgs = {
  profileId: Scalars['String'];
};

export type MutationForceLogoutArgs = {
  input: ForceLogoutInput;
};

export type MutationGenerateAnonymousIdentityArgs = {
  anonymousUserId?: InputMaybe<Scalars['String']>;
};

export type MutationGenerateOnboardingInviteCodeArgs = {
  url: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};

export type MutationGenerateRewardForOrderIdArgs = {
  orderId: Scalars['String'];
};

export type MutationInviteCoHostArgs = {
  input: InviteCoHostInput;
};

export type MutationInviteEmailToProfileArgs = {
  input: InviteEmailToProfileInput;
};

export type MutationLikeFeaturedProductArgs = {
  input: LikeFeaturedProductInput;
};

export type MutationLikePostArgs = {
  postId: Scalars['String'];
};

export type MutationLiveShopCreateArgs = {
  input: LiveShopCreateInput;
};

export type MutationMarkVerifiedDealsAsSeenArgs = {
  verifiedDealIds: Array<Scalars['String']>;
};

export type MutationNotificationSettingsUpdateArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationType?: InputMaybe<NotificationType>;
};

export type MutationOpenModalArgs = {
  id: Scalars['ID'];
};

export type MutationPimArchiveProductsArgs = {
  input: PimArchiveProductsInput;
};

export type MutationPimArchiveProductVariantsArgs = {
  input: PimArchiveProductVariantsInput;
};

export type MutationPimBulkUpdateProductVariantsArgs = {
  input: PimBulkUpdateProductVariantsInput;
};

export type MutationPimBulkUpsertOffersArgs = {
  input: PimBulkUpsertOffersInput;
};

export type MutationPimDeleteProductsArgs = {
  input: PimDeleteProductsInput;
};

export type MutationPimEnqueueBulkProductsUpdateArgs = {
  input: PimEnqueueBulkProductsUpdateInput;
};

export type MutationPimGroupProductVariantsArgs = {
  input: PimGroupProductVariantsInput;
};

export type MutationPimMigrateProductsToDashArgs = {
  input: PimMigrateProductsToDashInput;
};

export type MutationPimSyncProductArgs = {
  input: PimSyncProductInput;
};

export type MutationPimUngroupProductVariantsArgs = {
  input: PimUngroupProductVariantsInput;
};

export type MutationPimUpdateProductArgs = {
  input: PimUpdateProductInput;
};

export type MutationPimUpdateProductArchiveStatusArgs = {
  input: PimUpdateProductArchiveStatusInput;
};

export type MutationPimUpdateProductCategoriesArgs = {
  input: PimUpdateProductCategoriesInput;
};

export type MutationPimUpdateProductsArgs = {
  input: PimUpdateProductsInput;
};

export type MutationPimUpdateProductSchemaCategoryArgs = {
  input: PimUpdateProductSchemaCategoryInput;
};

export type MutationPimUpdateProductVariantArgs = {
  input: PimUpdateProductVariantInput;
};

export type MutationPimUpdateProductVariantArchiveStatusArgs = {
  input: PimUpdateProductVariantArchiveStatusInput;
};

export type MutationPimUpdateProductVariantsArgs = {
  input: PimUpdateProductVariantsInput;
};

export type MutationPimUpdateProductVariantsAcceptanceStatusArgs = {
  input: PimUpdateProductVariantsAcceptanceStatusInput;
};

export type MutationPimUpsertOffersArgs = {
  input: PimUpsertOffersInput;
};

export type MutationPimUpsertSpreadsheetProductVariantsArgs = {
  input: PimUpsertSpreadsheetProductVariantsInput;
};

export type MutationPostSharedArgs = {
  postId?: InputMaybe<Scalars['String']>;
};

export type MutationPromotePostArgs = {
  postId: Scalars['String'];
};

export type MutationRedeemOnboardingInviteCodeArgs = {
  code: Scalars['String'];
};

export type MutationRedeemVerifiedDealArgs = {
  input: RedeemVerifiedDealInput;
};

export type MutationRejectCoHostInviteArgs = {
  input: InviteCoHostInput;
};

export type MutationReloadMerchantCatalogArgs = {
  input: ReloadMerchantCatalogInput;
};

export type MutationRemoveItemsFromCartArgs = {
  cartLineItemIds: Array<InputMaybe<Scalars['ID']>>;
};

export type MutationReportExplorerContentArgs = {
  input: ReportExplorerContentInput;
};

export type MutationReportPostArgs = {
  postId?: InputMaybe<Scalars['String']>;
};

export type MutationRevokeMerchantProfileArgs = {
  input: RevokeMerchantProfileInput;
};

export type MutationRevokePostArgs = {
  postId: Scalars['String'];
};

export type MutationSaveAppliedShopifyGiftCardIdArgs = {
  input: SaveAppliedShopifyGiftCardIdInput;
};

export type MutationSaveConfigArgs = {
  input?: InputMaybe<ConfigInput>;
};

export type MutationSendConfirmationEmailArgs = {
  source?: InputMaybe<Scalars['String']>;
};

export type MutationSendDmcaEmailArgs = {
  input?: InputMaybe<EmailDmcaInput>;
};

export type MutationSetCreateShopifyAppStatusArgs = {
  input: SetCreateShopifyAppStatusMutationInput;
};

export type MutationSetGiftCardMessageArgs = {
  cartLineItemId: Scalars['ID'];
  giftCardMessage: Scalars['String'];
};

export type MutationSetGiftNoteArgs = {
  giftNote: Scalars['String'];
};

export type MutationSetReferralDataArgs = {
  friendDiscount: Scalars['String'];
  friendEmail: Scalars['String'];
  referralCode: Scalars['String'];
};

export type MutationSetReviewWrittenForProductAndOrderIdArgs = {
  orderId: Scalars['String'];
  productSid: Scalars['String'];
};

export type MutationShopPartyCancelArgs = {
  shopPartyId: Scalars['String'];
};

export type MutationShopPartyCartLineItemReactArgs = {
  input: ShopPartyCartLineItemReactInput;
};

export type MutationShopPartyCartUpsertArgs = {
  input: ShopPartyCartUpsertInput;
};

export type MutationShopPartyCreateArgs = {
  input: ShopPartyCreateInput;
};

export type MutationShopPartyJoinArgs = {
  justReturnToken?: InputMaybe<Scalars['Boolean']>;
  shopPartyId: Scalars['String'];
};

export type MutationShopPartyRsvpArgs = {
  input: ShopPartyRsvpInput;
};

export type MutationShopPartyUpdateArgs = {
  input: ShopPartyUpdateInput;
};

export type MutationTimestampFeaturedProductArgs = {
  input: TimestampFeaturedProductInput;
};

export type MutationUnfollowArgs = {
  profileId: Scalars['String'];
};

export type MutationUnlikeFeaturedProductArgs = {
  input: LikeFeaturedProductInput;
};

export type MutationUnlikePostArgs = {
  postId: Scalars['String'];
};

export type MutationUnwatchlistLiveShopArgs = {
  input: WatchlistLiveShopInput;
};

export type MutationUpdateBookmarkArgs = {
  bookmarkId: Scalars['ID'];
  bookmarkListId: Scalars['ID'];
};

export type MutationUpdateBookmarkListArgs = {
  input: UpdateBookmarkListInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateExplorerPostArgs = {
  input: UpdateExplorerPostInput;
};

export type MutationUpdateLiveShopArgs = {
  input: LiveShopUpdateInput;
};

export type MutationUpdateLiveShopCastArgs = {
  input: LiveShopCastInput;
};

export type MutationUpdateMerchantArgs = {
  input: UpdateMerchantInput;
};

export type MutationUpdateMerchantBankInfoArgs = {
  input: UpdateMerchantBankInfoInput;
};

export type MutationUpdateMerchantStatusArgs = {
  input: UpdateMerchantStatusInput;
};

export type MutationUpdateMerchantVpcRequestLimitArgs = {
  input: UpdateMerchantVpcRequestLimitInput;
};

export type MutationUpdatePayoutSettingArgs = {
  input?: InputMaybe<UpdatePayoutSettingInput>;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationUpdateVpcRequestArgs = {
  input: UpdateVerishopProductCodeRequestInput;
};

export type MutationUpsertMerchantArgs = {
  input: UpsertMerchantInput;
};

export type MutationUpsertRankingArgs = {
  input: RankingInput;
};

export type MutationUpsertRecommendationArgs = {
  input: RecommendationInput;
};

export type MutationUpsertReviewProviderConfigsArgs = {
  inputs: Array<UpsertReviewProviderConfigInput>;
};

export type MutationWatchlistLiveShopArgs = {
  input: WatchlistLiveShopInput;
};

export type InviteCoHostInput = {
  liveShopId: Scalars['String'];
  ownerId: Scalars['String'];
};

export type GenericMutationResponse = MutationResponse & {
  __typename?: 'GenericMutationResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type AcceptCoHostByCodeInput = {
  inviteCode: Scalars['String'];
  liveShopId: Scalars['String'];
};

export type AssignVpcsToMerchantInput = {
  merchantId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type AssignVpcsToMerchantResponse = MutationResponse & {
  __typename?: 'AssignVpcsToMerchantResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
  verishopProductCodeRequest?: Maybe<VerishopProductCodeRequest>;
};

export type UserIdentityMutationResponse = MutationResponse & {
  __typename?: 'UserIdentityMutationResponse';
  anonymousUserIdentity?: Maybe<AnonymousUserIdentity>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type ExplorerPostUserResponse = MutationResponse & {
  __typename?: 'ExplorerPostUserResponse';
  postId?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type ChangeMerchantAdminUserInput = {
  merchantId: Scalars['String'];
  userId: Scalars['String'];
};

export type ChangeMerchantAdminUserResponse = MutationResponse & {
  __typename?: 'ChangeMerchantAdminUserResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type ClaimLiveShopDiscountInput = {
  lineItems: Array<InputMaybe<ClaimLiveShopDiscountLineItemInput>>;
  liveShopId: Scalars['String'];
};

export type ClaimLiveShopDiscountLineItemInput = {
  productVariant: ClaimLiveShopDiscountLineItemProductVariantInput;
};

export type ClaimLiveShopDiscountLineItemProductVariantInput = {
  sku: Scalars['String'];
};

export type ClaimLiveShopDiscountResponse = MutationResponse & {
  __typename?: 'ClaimLiveShopDiscountResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
  verifiedDealId?: Maybe<Scalars['String']>;
};

export type ConfirmEmailInput = {
  code: Scalars['String'];
  userId: Scalars['String'];
};

export type CustomerMutationResponse = MutationResponse & {
  __typename?: 'CustomerMutationResponse';
  customer?: Maybe<Customer>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type CreateAssetSignedUrlsInput = {
  configs: Array<InputMaybe<CreateSignedUrlConfig>>;
};

export type CreateSignedUrlConfig = {
  extension: MediaFileExtension;
  height: Scalars['Int'];
  type: AssetType;
  videoDurationMs?: InputMaybe<Scalars['Int']>;
  width: Scalars['Int'];
};

export enum MediaFileExtension {
  JPEG = 'JPEG',
  JPG = 'JPG',
  MP4 = 'MP4',
}

export type CreateSignedUrlMutationResponse = MutationResponse & {
  __typename?: 'CreateSignedUrlMutationResponse';
  assetIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  query?: Maybe<Query>;
  signedUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type BookmarkMutationResponse = MutationResponse & {
  __typename?: 'BookmarkMutationResponse';
  bookmark?: Maybe<Bookmark>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type BookmarkListInput = {
  name: Scalars['String'];
  public?: InputMaybe<Scalars['Boolean']>;
};

export type BookmarkListMutationResponse = MutationResponse & {
  __typename?: 'BookmarkListMutationResponse';
  bookmarkList?: Maybe<BookmarkList>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type ComingSoonNotificationInput = {
  email: Scalars['String'];
  productSid: Scalars['ID'];
};

export type ComingSoonNotificationMutationResponse = MutationResponse & {
  __typename?: 'ComingSoonNotificationMutationResponse';
  comingSoonNotification?: Maybe<ComingSoonNotification>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type ComingSoonNotification = {
  __typename?: 'ComingSoonNotification';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  productSid: Scalars['ID'];
};

export type EmailSignUpInput = {
  email: Scalars['String'];
  subscribeToMens?: InputMaybe<Scalars['Boolean']>;
  subscribeToWomens?: InputMaybe<Scalars['Boolean']>;
};

export type CreateExplorerPostInput = {
  assetIds: Array<InputMaybe<Scalars['String']>>;
  caption: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<ExplorerPostTagInput>>>;
};

export type ExplorerPostTagInput = {
  assetId: Scalars['String'];
  /** Only used for EXTERNAL_LINK */
  overrideImagePreviewUrl?: InputMaybe<Scalars['String']>;
  /** This should a url for EXTERNAL_LINK, a SKU for PRODUCT, and a profileId for PROFILE */
  taggedEntityId: Scalars['String'];
  type: ExplorerPostTaggedEntityType;
};

export enum ExplorerPostTaggedEntityType {
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  PRODUCT = 'PRODUCT',
  PROFILE = 'PROFILE',
}

export type CreateExplorerPostResponse = MutationResponse & {
  __typename?: 'CreateExplorerPostResponse';
  explorerPost?: Maybe<ExplorerPost>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type InStockNotificationInput = {
  email: Scalars['String'];
  productVariantSid: Scalars['ID'];
};

export type InStockNotificationMutationResponse = MutationResponse & {
  __typename?: 'InStockNotificationMutationResponse';
  inStockNotification?: Maybe<InStockNotification>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type InStockNotification = {
  __typename?: 'InStockNotification';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  productVariantSid: Scalars['ID'];
};

export type CreateMerchantInput = {
  merchant: MerchantInputForCreate;
};

export type MerchantInputForCreate = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboardingVersion: MerchantOnboardingVersion;
  phone?: InputMaybe<Scalars['String']>;
  sellsProducts?: InputMaybe<Scalars['Boolean']>;
};

export type CreateMerchantResponse = MutationResponse & {
  __typename?: 'CreateMerchantResponse';
  createdProfileId?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type CreateMultipassUrlInput = {
  /** url path of where you want browser to be redirected to after multipass */
  returnUrlPath: Scalars['String'];
};

export type CreateMultipassUrlMutationResponse = MutationResponse & {
  __typename?: 'CreateMultipassUrlMutationResponse';
  multipassUrl?: Maybe<Scalars['String']>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type CreateOnboardingDiscountInput = {
  anniversaryRewardType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateProductReviewInput = {
  author: Scalars['String'];
  content: Scalars['String'];
  email: Scalars['String'];
  productSid: Scalars['String'];
  rating: Scalars['Int'];
  sku?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateProfileMutationInput = {
  profileType: ProfileType;
  userId: Scalars['String'];
};

export type CreateProfileMutationResponse = MutationResponse & {
  __typename?: 'CreateProfileMutationResponse';
  profile?: Maybe<ProfileV2>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type CreateSamlResponseInput = {
  email?: InputMaybe<Scalars['String']>;
  serviceProvider: SamlServiceProvider;
};

export enum SamlServiceProvider {
  MIRAKL = 'MIRAKL',
}

export type CreateSamlResponseMutationResponse = MutationResponse & {
  __typename?: 'CreateSAMLResponseMutationResponse';
  query?: Maybe<Query>;
  samlResponse?: Maybe<SamlResponse>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type SamlResponse = {
  __typename?: 'SAMLResponse';
  context: Scalars['String'];
  url: Scalars['String'];
};

export type CreateShopifyAppInput = {
  merchantId: Scalars['String'];
  retries?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['String']>;
};

export type CreateShopifyAppMutationResponse = MutationResponse & {
  __typename?: 'CreateShopifyAppMutationResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type CreateSignedUrlsForSecuredAssetsInput = {
  configs: Array<SecuredAssetConfig>;
};

export type SecuredAssetConfig = {
  assetType: SecuredAssetType;
  fileName: Scalars['String'];
  merchantId?: InputMaybe<Scalars['String']>;
};

export enum SecuredAssetType {
  INFORMATION_ACT_DOCUMENT = 'INFORMATION_ACT_DOCUMENT',
  PROOF_OF_INSURANCE = 'PROOF_OF_INSURANCE',
}

export type CreateSignedUrlsForSecuredAssetsResponse = MutationResponse & {
  __typename?: 'CreateSignedUrlsForSecuredAssetsResponse';
  query?: Maybe<Query>;
  signedUrls: Array<SignedUrl>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type SignedUrl = {
  __typename?: 'SignedUrl';
  assetId: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type VerishopProductCodeRequestInput = {
  verishopProductCodeRequest: VerishopProductCodeRequestInputForCreate;
};

export type VerishopProductCodeRequestInputForCreate = {
  merchantId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CreateVerishopProductCodeRequestResponse = MutationResponse & {
  __typename?: 'CreateVerishopProductCodeRequestResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
  verishopProductCodeRequest?: Maybe<VerishopProductCodeRequest>;
};

export type CreatorRequestW9Input = {
  signerEmail: Scalars['String'];
  signerName: Scalars['String'];
};

export type CreatorMutationResponse = MutationResponse & {
  __typename?: 'CreatorMutationResponse';
  creator?: Maybe<Creator>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type DeleteBookmarkMutationResponse = MutationResponse & {
  __typename?: 'DeleteBookmarkMutationResponse';
  id: Scalars['ID'];
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type DeleteProfileMutationInput = {
  profileId: Scalars['String'];
};

export type DeleteProfileMutationResponse = MutationResponse & {
  __typename?: 'DeleteProfileMutationResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type DeleteReviewProviderConfigInput = {
  merchant_id?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
};

export type DeleteUserAccountInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ProfileFollowMutationResponse = MutationResponse & {
  __typename?: 'ProfileFollowMutationResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type ForceLogoutInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type GenerateOnboardingInviteCodeResponse = {
  __typename?: 'GenerateOnboardingInviteCodeResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GenerateRewardForOrderIdResponse = MutationResponse & {
  __typename?: 'GenerateRewardForOrderIdResponse';
  query?: Maybe<Query>;
  rewardValue: Scalars['Int'];
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type InviteEmailToProfileInput = {
  email: Scalars['String'];
  entityId: Scalars['ID'];
  profileType: ProfileType;
};

export type InviteEmailToProfileResponse = MutationResponse & {
  __typename?: 'InviteEmailToProfileResponse';
  profile?: Maybe<ProfileV2>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  userCreated?: Maybe<Scalars['Boolean']>;
  validationErrors: Array<ValidationError>;
};

export type LikeFeaturedProductInput = {
  liveShopId: Scalars['String'];
  sku: Scalars['String'];
};

export type LiveShopCreateInput = {
  casts?: InputMaybe<Array<InputMaybe<LiveShopCastInput>>>;
  controls?: InputMaybe<Array<ControlInput>>;
  coverMediaId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** Dates should be in UTC and calculated for user's timezone (and DST) in the following format: 2021-11-07T01:30:00Z */
  endsAt: Scalars['DateTime'];
  featuredProducts: Array<FeaturedProductInput>;
  privacy: LiveShopPrivacy;
  /** Dates should be in UTC and calculated for user's timezone (and DST) in the following format: 2021-11-07T01:30:00Z */
  startsAt: Scalars['DateTime'];
  status: LiveShopStatus;
  teaserMediaId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type LiveShopCastInput = {
  liveShopId?: InputMaybe<Scalars['String']>;
  streamKey: Scalars['String'];
  streamUrl: Scalars['String'];
  type: LiveShopCastType;
};

export type ControlInput = {
  key: Scalars['String'];
  value: Scalars['String'];
  valueType: ControlValueType;
};

export type FeaturedProductInput = {
  isDealApplied?: InputMaybe<Scalars['Boolean']>;
  sku: Scalars['String'];
};

export type LiveShopMutationResponse = MutationResponse & {
  __typename?: 'LiveShopMutationResponse';
  liveShop?: Maybe<LiveShop>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type PimArchiveProductsInput = {
  ids: Array<Scalars['ID']>;
};

export type PimArchiveProductsMutationResponse = MutationResponse & {
  __typename?: 'PimArchiveProductsMutationResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimArchiveProductVariantsInput = {
  ids: Array<Scalars['ID']>;
};

export type PimArchiveProductVariantsMutationResponse = MutationResponse & {
  __typename?: 'PimArchiveProductVariantsMutationResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimBulkUpdateProductVariantsInput = {
  variants: Array<PimBulkUpdateProductVariantsProductVariantInput>;
};

export type PimBulkUpdateProductVariantsProductVariantInput = {
  attributeValues?: InputMaybe<Array<PimAttributeValueInput>>;
  id: Scalars['ID'];
};

export type PimAttributeValueInput = {
  attributeCode: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type PimBulkUpdateProductVariantsMutationResponse = MutationResponse & {
  __typename?: 'PimBulkUpdateProductVariantsMutationResponse';
  productVariants: Array<PimProductVariant>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimBulkUpsertOffersInput = {
  offers: Array<PimUpsertOffersOfferInput>;
};

export type PimUpsertOffersOfferInput = {
  dashControlled?: InputMaybe<Scalars['Boolean']>;
  discountPrice?: InputMaybe<Scalars['Float']>;
  discountPriceEndDate?: InputMaybe<Scalars['String']>;
  discountPricePercentage?: InputMaybe<Scalars['Float']>;
  discountPriceStartDate?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  price?: InputMaybe<Scalars['Float']>;
};

export type PimBulkUpsertOffersResponse = MutationResponse & {
  __typename?: 'PimBulkUpsertOffersResponse';
  jobGroupId?: Maybe<Scalars['ID']>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimDeleteProductsInput = {
  ids: Array<Scalars['ID']>;
};

export type PimDeleteProductsMutationResponse = MutationResponse & {
  __typename?: 'PimDeleteProductsMutationResponse';
  ids: Array<Scalars['ID']>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimEnqueueBulkProductsUpdateInput = {
  ids: Array<Scalars['ID']>;
  productData: PimEnqueueBulkProductsProductDataInput;
};

export type PimEnqueueBulkProductsProductDataInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  attributeValues: Array<PimAttributeValueInput>;
};

export type PimEnqueueBulkProductsUpdateResponse = MutationResponse & {
  __typename?: 'PimEnqueueBulkProductsUpdateResponse';
  jobGroupId?: Maybe<Scalars['ID']>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimGroupProductVariantsInput = {
  ids: Array<Scalars['ID']>;
  productId: Scalars['ID'];
};

export type PimGroupProductVariantsMutationResponse = MutationResponse & {
  __typename?: 'PimGroupProductVariantsMutationResponse';
  id?: Maybe<Scalars['ID']>;
  product?: Maybe<PimProduct>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimMigrateProductsToDashInput = {
  productIds: Array<Scalars['ID']>;
};

export type PimMigrateProductsToDashResponse = {
  __typename?: 'PimMigrateProductsToDashResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimSyncProductInput = {
  productId: Scalars['String'];
};

export type PimSyncProductResponse = MutationResponse & {
  __typename?: 'PimSyncProductResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimUngroupProductVariantsInput = {
  attributeValues: Array<PimAttributeValueInput>;
  ids: Array<Scalars['ID']>;
};

export type PimUngroupProductVariantsMutationResponse = MutationResponse & {
  __typename?: 'PimUngroupProductVariantsMutationResponse';
  id?: Maybe<Scalars['ID']>;
  product?: Maybe<PimProduct>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimUpdateProductInput = {
  attributeValues: Array<PimAttributeValueInput>;
  dryRun?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type PimUpdateProductMutationResponse = MutationResponse & {
  __typename?: 'PimUpdateProductMutationResponse';
  id: Scalars['ID'];
  product?: Maybe<PimProduct>;
  query?: Maybe<Query>;
  /** indicator if update was successful. If any requested value had validation errors, update will fail.  */
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimUpdateProductArchiveStatusInput = {
  archive: Scalars['Boolean'];
  ids: Array<Scalars['ID']>;
};

export type PimUpdateProductArchiveStatusMutationResponse = MutationResponse & {
  __typename?: 'PimUpdateProductArchiveStatusMutationResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimUpdateProductCategoriesInput = {
  categories: Array<Array<Scalars['String']>>;
  id: Scalars['ID'];
};

export type PimUpdateProductCategoriesMutationResponse = MutationResponse & {
  __typename?: 'PimUpdateProductCategoriesMutationResponse';
  id: Scalars['ID'];
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type PimUpdateProductsInput = {
  attributeValues: Array<PimAttributeValueInput>;
  dryRun?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['ID']>;
  miraklAcceptanceStatus?: InputMaybe<MiraklAcceptanceStatus>;
  miraklRejectionMessage?: InputMaybe<Scalars['String']>;
  miraklRejectionReasonCode?: InputMaybe<Scalars['String']>;
};

export type PimUpdateProductsMutationResponse = MutationResponse & {
  __typename?: 'PimUpdateProductsMutationResponse';
  ids: Array<Scalars['ID']>;
  products?: Maybe<Array<PimProduct>>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimUpdateProductSchemaCategoryInput = {
  id: Scalars['ID'];
  schemaCategoryCode: Scalars['String'];
};

export type PimUpdateProductSchemaCategoryMutationResponse =
  MutationResponse & {
    __typename?: 'PimUpdateProductSchemaCategoryMutationResponse';
    id: Scalars['ID'];
    product?: Maybe<PimProduct>;
    query?: Maybe<Query>;
    success: Scalars['Boolean'];
    validationErrors: Array<ValidationError>;
  };

export type PimUpdateProductVariantInput = {
  attributeValues: Array<PimAttributeValueInput>;
  dryRun?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type PimUpdateProductVariantMutationResponse = MutationResponse & {
  __typename?: 'PimUpdateProductVariantMutationResponse';
  id: Scalars['ID'];
  productVariant?: Maybe<PimProductVariant>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimUpdateProductVariantArchiveStatusInput = {
  archive: Scalars['Boolean'];
  ids: Array<Scalars['ID']>;
};

export type PimUpdateProductVariantArchiveStatusMutationResponse =
  MutationResponse & {
    __typename?: 'PimUpdateProductVariantArchiveStatusMutationResponse';
    query?: Maybe<Query>;
    success: Scalars['Boolean'];
    validationErrors: Array<ValidationError>;
  };

export type PimUpdateProductVariantsInput = {
  attributeValues: Array<PimAttributeValueInput>;
  dryRun?: InputMaybe<Scalars['Boolean']>;
  miraklAcceptanceStatus?: InputMaybe<MiraklAcceptanceStatus>;
  miraklRejectionMessage?: InputMaybe<Scalars['String']>;
  miraklRejectionReasonCode?: InputMaybe<Scalars['String']>;
  variantIds: Array<Scalars['ID']>;
};

export type PimUpdateProductVariantsMutationResponse = MutationResponse & {
  __typename?: 'PimUpdateProductVariantsMutationResponse';
  productVariants?: Maybe<Array<PimProductVariant>>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
  variantIds: Array<Scalars['ID']>;
};

export type PimUpdateProductVariantsAcceptanceStatusInput = {
  miraklAcceptanceStatus: MiraklAcceptanceStatus;
  miraklRejectionMessage?: InputMaybe<Scalars['String']>;
  miraklRejectionReasonCode?: InputMaybe<Scalars['String']>;
  variantIds: Array<Scalars['ID']>;
};

export type PimUpdateProductVariantsAcceptanceStatusMutationResponse =
  MutationResponse & {
    __typename?: 'PimUpdateProductVariantsAcceptanceStatusMutationResponse';
    productVariants?: Maybe<Array<PimProductVariant>>;
    query?: Maybe<Query>;
    success: Scalars['Boolean'];
    validationErrors?: Maybe<Array<ValidationError>>;
    variantIds: Array<Scalars['ID']>;
  };

export type PimUpsertOffersInput = {
  offers: Array<PimUpsertOffersOfferInput>;
};

export type PimUpsertOffersResponse = MutationResponse & {
  __typename?: 'PimUpsertOffersResponse';
  offers?: Maybe<Array<PimOffer>>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type PimUpsertSpreadsheetProductVariantsInput = {
  dryRun: Scalars['Boolean'];
  merchantId: Scalars['String'];
  productVariants: Array<PimUpsertSpreadsheetProductVariantInput>;
};

export type PimUpsertSpreadsheetProductVariantInput = {
  attributeValues: Array<PimUpsertSpreadsheetAttributeValueInput>;
  id: Scalars['String'];
};

export type PimUpsertSpreadsheetAttributeValueInput = {
  code: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type PimUpsertSpreadsheetProductVariantsResponse = {
  __typename?: 'PimUpsertSpreadsheetProductVariantsResponse';
  dryRun: Scalars['Boolean'];
  productVariants: Array<PimGetSpreadsheetProductVariant>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  /** @deprecated Validations are being returned as part of each variant */
  validations: Array<PimSpreadsheetValidation>;
};

export type RedeemOnboardingInviteCodeResponse = {
  __typename?: 'RedeemOnboardingInviteCodeResponse';
  success: Scalars['Boolean'];
  url: Scalars['String'];
};

export type RedeemVerifiedDealInput = {
  lineItems: Array<InputMaybe<RedeemVerifiedDealLineItemInput>>;
  verifiedDealId: Scalars['String'];
};

export type RedeemVerifiedDealLineItemInput = {
  price: Scalars['Float'];
  productVariant: RedeemVerifiedDealLineItemProductVariantInput;
  quantity: Scalars['Int'];
};

export type RedeemVerifiedDealLineItemProductVariantInput = {
  sku: Scalars['String'];
};

export type RedeemVerifiedDealResponse = MutationResponse & {
  __typename?: 'RedeemVerifiedDealResponse';
  query?: Maybe<Query>;
  shopifyGiftCardCode?: Maybe<Scalars['String']>;
  shopifyGiftCardId?: Maybe<Scalars['String']>;
  shopifyGiftCardValue?: Maybe<Scalars['Float']>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export type ReloadMerchantCatalogInput = {
  email?: InputMaybe<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
  shopDomain: Scalars['String'];
};

export type ReloadMerchantCatalogResponse = MutationResponse & {
  __typename?: 'ReloadMerchantCatalogResponse';
  numberOfProductsResynced?: Maybe<Scalars['Int']>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type RemoveItemsFromCartResponse = {
  __typename?: 'RemoveItemsFromCartResponse';
  checkout?: Maybe<Checkout>;
  userErrors?: Maybe<UserErrors>;
};

export type UserErrors = {
  __typename?: 'UserErrors';
  code?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ReportExplorerContentInput = {
  reason: ExplorerContentReportingReason;
  reportedContentId: Scalars['String'];
  reportedContentType: ExplorerReportedContentType;
};

export enum ExplorerContentReportingReason {
  CATFISHING = 'CATFISHING',
  FAKE_NEWS = 'FAKE_NEWS',
  FRAUD = 'FRAUD',
  HARASSMENT = 'HARASSMENT',
  HATE_SPEECH = 'HATE_SPEECH',
  ILLEGAL_GOODS = 'ILLEGAL_GOODS',
  INAPPROPRIATE = 'INAPPROPRIATE',
  IP_VIOLATION = 'IP_VIOLATION',
  IP_VIOLATION_COPYRIGHT = 'IP_VIOLATION_COPYRIGHT',
  IP_VIOLATION_DEFAMATION = 'IP_VIOLATION_DEFAMATION',
  IP_VIOLATION_PRIVACY = 'IP_VIOLATION_PRIVACY',
  IP_VIOLATION_TRADEMARK = 'IP_VIOLATION_TRADEMARK',
  OTHER = 'OTHER',
  SELF_HARM = 'SELF_HARM',
  SEX = 'SEX',
  SPAM = 'SPAM',
  UNDERAGE = 'UNDERAGE',
  VIOLENCE = 'VIOLENCE',
}

export type RevokeMerchantProfileInput = {
  profileId: Scalars['String'];
};

export type RevokeMerchantProfileResponse = MutationResponse & {
  __typename?: 'RevokeMerchantProfileResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<ValidationError>;
};

export type SaveAppliedShopifyGiftCardIdInput = {
  appliedShopifyGiftCardId: Scalars['String'];
  verifiedDealId: Scalars['String'];
};

export type ConfigInput = {
  domain?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  env?: InputMaybe<EnvironmentType>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export enum EnvironmentType {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  STAGING = 'staging',
  TEST = 'test',
}

export type EmailDmcaInput = {
  authority?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  copyrightOwner?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  describe?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  goodFaith?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  section512?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  terminationAccount?: InputMaybe<Scalars['Boolean']>;
  token?: InputMaybe<Scalars['String']>;
  underPenalty?: InputMaybe<Scalars['Boolean']>;
  url?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type SetCreateShopifyAppStatusMutationInput = {
  aloiShopifyConnectorName?: InputMaybe<Scalars['String']>;
  linkedToMasterMerchantAccount?: InputMaybe<Scalars['Boolean']>;
  merchantId: Scalars['String'];
  merchantShopifyAuthLink?: InputMaybe<Scalars['String']>;
  status: CreateShopifyAppStatus;
  statusMessage?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type SetCreateShopifyAppStatusMutationResponse = MutationResponse & {
  __typename?: 'SetCreateShopifyAppStatusMutationResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type SetReferralDataResponse = {
  __typename?: 'SetReferralDataResponse';
  friendDiscount: Scalars['String'];
  friendEmail: Scalars['String'];
  referralCode: Scalars['String'];
};

export type ShopPartyCartLineItemReactInput = {
  reactionType: ShopPartyCartLineItemReactionType;
  shopPartyCartLineItemId: Scalars['String'];
  shopPartyCartOwnerId: Scalars['String'];
  shopPartyId: Scalars['String'];
  toggle: Scalars['Boolean'];
};

export type ShopPartyCartUpsertInput = {
  lineItems: Array<InputMaybe<ShopPartyCartLineItemInput>>;
  shopPartyId: Scalars['String'];
};

export type ShopPartyCartLineItemInput = {
  id: Scalars['String'];
  productVariant: ShopPartyCartLineItemProductVariantInput;
};

export type ShopPartyCartLineItemProductVariantInput = {
  sku: Scalars['String'];
};

export type ShopPartyCreateInput = {
  collectionUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  title: Scalars['String'];
};

export type ShopPartyMutationResponse = MutationResponse & {
  __typename?: 'ShopPartyMutationResponse';
  query?: Maybe<Query>;
  shopParty?: Maybe<ShopParty>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type ShopPartyJoinMutationResponse = MutationResponse & {
  __typename?: 'ShopPartyJoinMutationResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
  validationErrors?: Maybe<Array<Maybe<ValidationError>>>;
};

export type ShopPartyRsvpInput = {
  memberProfileId?: InputMaybe<Scalars['String']>;
  shopPartyId: Scalars['String'];
  status: ShopPartyMemberRsvpStatus;
};

export type ShopPartyUpdateInput = {
  collectionUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  shopPartyId: Scalars['String'];
  startsAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TimestampFeaturedProductInput = {
  liveShopId: Scalars['String'];
  sku: Scalars['String'];
  /** timestamp should be in the format 000:00:00. Range is from '-838:59:59' to '838:59:59' */
  timestamp: Scalars['String'];
};

export type WatchlistLiveShopInput = {
  liveShopId: Scalars['String'];
};

export type UpdateBookmarkListInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCustomerInput = {
  /** The profile the user has last selected */
  activeProfileId?: InputMaybe<Scalars['String']>;
  /** customer's email */
  email?: InputMaybe<Scalars['String']>;
  /** The customer’s first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** The customer’s last name */
  lastName?: InputMaybe<Scalars['String']>;
  /** base64 encoded shopify checkout id */
  shopifyCheckoutId?: InputMaybe<Scalars['String']>;
};

export type UpdateExplorerPostInput = {
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  caption?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<ExplorerPostTagInput>>>;
};

export type LiveShopUpdateInput = {
  casts?: InputMaybe<Array<InputMaybe<LiveShopCastInput>>>;
  confirmedEndsAt?: InputMaybe<Scalars['DateTime']>;
  confirmedStartsAt?: InputMaybe<Scalars['DateTime']>;
  controls?: InputMaybe<Array<ControlInput>>;
  coverMediaId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** Dates should be in UTC and calculated for user's timezone (and DST) in the following format: 2021-11-07T01:30:00Z */
  endsAt?: InputMaybe<Scalars['DateTime']>;
  featuredProducts?: InputMaybe<Array<FeaturedProductInput>>;
  liveShopId: Scalars['String'];
  notificationEmail?: InputMaybe<Scalars['String']>;
  /** Dates should be in UTC and calculated for user's timezone (and DST) in the following format: 2021-11-07T01:30:00Z */
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<LiveShopStatus>;
  teaserMediaId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateMerchantInput = {
  merchant: MerchantInputForUpdate;
};

export type MerchantInputForUpdate = {
  address?: InputMaybe<AddressInput>;
  agreedToInsuranceRequirements?: InputMaybe<Scalars['Boolean']>;
  agreedToTOS?: InputMaybe<Scalars['Boolean']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  customerServiceEmail?: InputMaybe<Scalars['String']>;
  customerServiceFirstName?: InputMaybe<Scalars['String']>;
  customerServiceLastName?: InputMaybe<Scalars['String']>;
  customerServicePhone?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  establishedIn?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  fulfillmentDaysRequired?: InputMaybe<Scalars['Int']>;
  fulfillmentEmail?: InputMaybe<Scalars['String']>;
  fulfillmentFirstName?: InputMaybe<Scalars['String']>;
  fulfillmentHolidays?: InputMaybe<Array<Scalars['String']>>;
  fulfillmentLastName?: InputMaybe<Scalars['String']>;
  fulfillmentOrderCutoffTime?: InputMaybe<Scalars['String']>;
  fulfillmentPhone?: InputMaybe<Scalars['String']>;
  fulfillmentShippingMethod?: InputMaybe<ShippingMethod>;
  fulfillmentTimezone?: InputMaybe<Scalars['String']>;
  heroUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  informationActDocuments?: InputMaybe<Array<ExternalAssetInput>>;
  instagramName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  operationalDaysOfWeek?: InputMaybe<Array<Scalars['Int']>>;
  optIntoExplorerContent?: InputMaybe<Scalars['Boolean']>;
  orderManagementSystem?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  proofOfInsurance?: InputMaybe<Array<ProofOfInsuranceInputForMerchantUpdate>>;
  publicContactInfo?: InputMaybe<MerchantPublicContactInfoInput>;
  shopifyURL?: InputMaybe<Scalars['String']>;
  subscriptionTier?: InputMaybe<MerchantSubscriptionTier>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  taxId?: InputMaybe<Scalars['String']>;
  warehouseAddress?: InputMaybe<AddressInput>;
  website?: InputMaybe<Scalars['String']>;
  youtubeLink?: InputMaybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  civility?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ExternalAssetInput = {
  assetId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
};

export type ProofOfInsuranceInputForMerchantUpdate = {
  assetId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
};

export type MerchantPublicContactInfoInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateMerchantResponse = MutationResponse & {
  __typename?: 'UpdateMerchantResponse';
  merchant?: Maybe<Merchant>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type UpdateMerchantBankInfoInput = {
  merchant: MerchantInputForUpdateBankInfo;
};

export type MerchantInputForUpdateBankInfo = {
  bankAccount: BankAccountInput;
  id: Scalars['ID'];
  informationActDocuments?: InputMaybe<Array<ExternalAssetInput>>;
  sellsProducts?: InputMaybe<Scalars['Boolean']>;
};

export type BankAccountInput = {
  abaPaymentMethod?: InputMaybe<AbaPaymentMethodInput>;
  accountOwnerName?: InputMaybe<Scalars['String']>;
  aubsbPaymentMethod?: InputMaybe<AubsbPaymentMethodInput>;
  bankAddress?: InputMaybe<AddressInput>;
  bankName?: InputMaybe<Scalars['String']>;
  canadianPaymentMethod?: InputMaybe<CanadianPaymentMethodInput>;
  colombianPaymentMethod?: InputMaybe<ColombianPaymentMethodInput>;
  hongKongPaymentMethod?: InputMaybe<HongKongPaymentMethodInput>;
  ibanPaymentMethod?: InputMaybe<IbanPaymentMethodInput>;
  indianPaymentMethod?: InputMaybe<IndianPaymentMethodInput>;
  paymentMethodType?: InputMaybe<PaymentMethodType>;
  ukPaymentMethod?: InputMaybe<UkPaymentMethodInput>;
};

export type AbaPaymentMethodInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  routingNumber?: InputMaybe<Scalars['String']>;
};

export type AubsbPaymentMethodInput = {
  australiaAccountNumber?: InputMaybe<Scalars['String']>;
  bsb?: InputMaybe<Scalars['String']>;
};

export type CanadianPaymentMethodInput = {
  canadaAccountNumber?: InputMaybe<Scalars['String']>;
  institutionNumber?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
  transitNumber?: InputMaybe<Scalars['String']>;
};

export type ColombianPaymentMethodInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  bankAccountType?: InputMaybe<ColombianPaymentMethodBankAccountType>;
};

export enum ColombianPaymentMethodBankAccountType {
  C = 'C',
  P = 'P',
}

export type HongKongPaymentMethodInput = {
  hongKongAccountNumber?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type IbanPaymentMethodInput = {
  bic?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
};

export type IndianPaymentMethodInput = {
  ifsc?: InputMaybe<Scalars['String']>;
  indiaAccountNumber?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type UkPaymentMethodInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  sortCode?: InputMaybe<Scalars['String']>;
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type UpdateMerchantBankInfoResponse = MutationResponse & {
  __typename?: 'UpdateMerchantBankInfoResponse';
  merchant?: Maybe<Merchant>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type UpdateMerchantStatusInput = {
  id: Scalars['String'];
  status: MerchantStatus;
};

export type UpdateMerchantStatusResponse = MutationResponse & {
  __typename?: 'UpdateMerchantStatusResponse';
  merchant?: Maybe<Merchant>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type UpdateMerchantVpcRequestLimitInput = {
  merchantId: Scalars['String'];
  vpcRequestLimit: Scalars['Int'];
};

export type UpdateMerchantVpcRequestLimitResponse = MutationResponse & {
  __typename?: 'UpdateMerchantVpcRequestLimitResponse';
  merchant?: Maybe<Merchant>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type UpdatePayoutSettingInput = {
  paypalEmail: Scalars['String'];
};

export type UpdateProfileInput = {
  biography?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  contactInfo?: InputMaybe<ContactInfoInput>;
  coverImageAssetId?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  instagramName?: InputMaybe<Scalars['String']>;
  profileAssetId?: InputMaybe<Scalars['String']>;
  profilePhotoUrl?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type ContactInfoInput = {
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type ProfileMutationResponse = MutationResponse & {
  __typename?: 'ProfileMutationResponse';
  profile?: Maybe<Profile>;
  profileError?: Maybe<ProfileError>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors: Array<Maybe<ValidationError>>;
};

export enum ProfileError {
  ASSET_STATUS_ERROR = 'ASSET_STATUS_ERROR',
  BRANDSLUG_TAKEN = 'BRANDSLUG_TAKEN',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVALID_INPUT_FORMAT = 'INVALID_INPUT_FORMAT',
  INVALID_USERNAME = 'INVALID_USERNAME',
  OWNER_ID_TAKEN = 'OWNER_ID_TAKEN',
  OWNER_NOT_FOUND = 'OWNER_NOT_FOUND',
  PROFILE_COVER_IMAGE_ASSET_NOT_FOUND = 'PROFILE_COVER_IMAGE_ASSET_NOT_FOUND',
  PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND',
  PROFILE_PHOTO_ASSET_NOT_FOUND = 'PROFILE_PHOTO_ASSET_NOT_FOUND',
  USERNAME_TAKEN = 'USERNAME_TAKEN',
  WEBSITE_INVALID = 'WEBSITE_INVALID',
}

export type UpdateVerishopProductCodeRequestInput = {
  verishopProductCodeRequest: VerishopProductCodeRequestInputForUpdate;
};

export type VerishopProductCodeRequestInputForUpdate = {
  id: Scalars['ID'];
  status: VerishopProductCodeRequestStatus;
};

export type UpdateVerishopProductCodeRequestResponse = MutationResponse & {
  __typename?: 'UpdateVerishopProductCodeRequestResponse';
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
  verishopProductCodeRequest?: Maybe<VerishopProductCodeRequest>;
};

export type UpsertMerchantInput = {
  merchant: MerchantInput;
};

export type MerchantInput = {
  address?: InputMaybe<AddressInput>;
  adminUserId?: InputMaybe<Scalars['String']>;
  agreedToTOS?: InputMaybe<Scalars['Boolean']>;
  bankAccount?: InputMaybe<BankAccountInput>;
  customerServiceEmail?: InputMaybe<Scalars['String']>;
  customerServicePhone?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fulfillmentDaysRequired?: InputMaybe<Scalars['Int']>;
  fulfillmentEmail?: InputMaybe<Scalars['String']>;
  fulfillmentHolidays?: InputMaybe<Array<Scalars['String']>>;
  fulfillmentOrderCutoffTime?: InputMaybe<Scalars['String']>;
  fulfillmentPhone?: InputMaybe<Scalars['String']>;
  fulfillmentShippingMethod?: InputMaybe<ShippingMethod>;
  fulfillmentTimezone?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  operationalDaysOfWeek?: InputMaybe<Array<Scalars['Int']>>;
  orderManagementSystem?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  shopifyURL?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MerchantStatus>;
  taxId?: InputMaybe<Scalars['String']>;
  warehouseAddress?: InputMaybe<AddressInput>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpsertMerchantResponse = MutationResponse & {
  __typename?: 'UpsertMerchantResponse';
  createdProfileId?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  query?: Maybe<Query>;
  success: Scalars['Boolean'];
  validationErrors?: Maybe<Array<ValidationError>>;
};

export type RankingInput = {
  ranking: Ranking;
  rankingKey: RankingKey;
};

export type Ranking = {
  extra?: InputMaybe<Scalars['String']>;
  rank: Scalars['Float'];
};

export type RankingKey = {
  inputId: Scalars['String'];
  modelId: Scalars['String'];
  modelVariantId: Scalars['String'];
};

export type RecommendationInput = {
  recommendation: Recommendation;
  recommendationKey: RecommendationKey;
};

export type Recommendation = {
  ids: Array<InputMaybe<Scalars['String']>>;
  type: RecommendationType;
};

export enum RecommendationType {
  BRAND = 'BRAND',
  PRODUCT = 'PRODUCT',
  PRODUCT_VARIANT = 'PRODUCT_VARIANT',
}

export type UpsertReviewProviderConfigInput = {
  app_key: Scalars['String'];
  app_secret: Scalars['String'];
  last_at?: InputMaybe<Scalars['String']>;
  merchant_id?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
};

export type AbaPaymentMethod = PaymentMethod & {
  __typename?: 'ABAPaymentMethod';
  accountNumber?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type AssetInput = {
  type: AssetType;
  url: Scalars['String'];
};

export enum AssetMetadataSource {
  CURATED = 'CURATED',
  INSTAGRAM_BRAND_CRAWL = 'INSTAGRAM_BRAND_CRAWL',
  INSTAGRAM_HASHTAG_CRAWL = 'INSTAGRAM_HASHTAG_CRAWL',
  PRODUCT = 'PRODUCT',
  SALE = 'SALE',
  USER = 'USER',
  YOUTUBE_VIDEO_CRAWL = 'YOUTUBE_VIDEO_CRAWL',
}

export type AssetSkuMarker = {
  __typename?: 'AssetSKUMarker';
  boundingBox?: Maybe<BoundingBox>;
  sku?: Maybe<Scalars['String']>;
};

export type BoundingBox = {
  __typename?: 'BoundingBox';
  h?: Maybe<Scalars['Int']>;
  w?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type AubsbPaymentMethod = PaymentMethod & {
  __typename?: 'AUBSBPaymentMethod';
  australiaAccountNumber?: Maybe<Scalars['String']>;
  bsb?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type BrandProfile = ProfileV2 & {
  __typename?: 'BrandProfile';
  brand: Brand;
  brandId: Scalars['String'];
  contentOwner?: Maybe<ExplorerContentOwner>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ProfileType;
  user: User;
};

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type CanadianPaymentMethod = PaymentMethod & {
  __typename?: 'CanadianPaymentMethod';
  canadaAccountNumber?: Maybe<Scalars['String']>;
  institutionNumber?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  transitNumber?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type ColombianPaymentMethod = PaymentMethod & {
  __typename?: 'ColombianPaymentMethod';
  accountNumber?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<ColombianPaymentMethodBankAccountType>;
  type: PaymentMethodType;
};

export type ColorOption = Option & {
  __typename?: 'ColorOption';
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  type: OptionType;
  values: Array<ColorOptionValue>;
};

export type ColorOptionValue = OptionValue & {
  __typename?: 'ColorOptionValue';
  brandColor: Scalars['String'];
  colorFamily: Scalars['String'];
  label: Scalars['String'];
  sortOrder: Scalars['Int'];
  swatchColorCode: Scalars['String'];
  value: Scalars['String'];
};

export type CustomerServiceProfile = ProfileV2 & {
  __typename?: 'CustomerServiceProfile';
  contentOwner?: Maybe<ExplorerContentOwner>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ProfileType;
  user: User;
};

export type ExplorerBannerModule = ExplorerModule & {
  __typename?: 'ExplorerBannerModule';
  bannerType?: Maybe<ExplorerBannerType>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ExplorerModuleType>;
};

export enum ExplorerBannerType {
  DISCOUNT = 'DISCOUNT',
  SECTION_HEADER = 'SECTION_HEADER',
}

export type ExplorerBrandModule = ExplorerModule & {
  __typename?: 'ExplorerBrandModule';
  filters?: Maybe<Array<Maybe<ExplorerModuleFilter>>>;
  productVariants?: Maybe<Array<Maybe<ProductVariant>>>;
  skus?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ExplorerModuleType>;
};

export type ExplorerModuleFilter = {
  __typename?: 'ExplorerModuleFilter';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ExplorerBrandWebsiteModule = ExplorerModule & {
  __typename?: 'ExplorerBrandWebsiteModule';
  title: Scalars['String'];
  type: ExplorerModuleType;
  url: Scalars['String'];
};

export type ExplorerCollection = {
  __typename?: 'ExplorerCollection';
  label?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ExplorerCollectionModule = ExplorerModule & {
  __typename?: 'ExplorerCollectionModule';
  collections?: Maybe<Array<Maybe<ExplorerCollection>>>;
  filters?: Maybe<Array<Maybe<ExplorerModuleFilter>>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ExplorerModuleType>;
};

export type ExplorerDynamicProductsListModule = ExplorerModule & {
  __typename?: 'ExplorerDynamicProductsListModule';
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ExplorerModuleType>;
  url?: Maybe<Scalars['String']>;
};

export type ExplorerExternalLinkListModule = ExplorerModule & {
  __typename?: 'ExplorerExternalLinkListModule';
  externalLinks: Array<Maybe<ExternalLink>>;
  title: Scalars['String'];
  type: ExplorerModuleType;
};

export type ExternalLink = {
  __typename?: 'ExternalLink';
  overrideImagePreviewUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ExplorerImageAsset = ExplorerAsset & {
  __typename?: 'ExplorerImageAsset';
  assetRequestId?: Maybe<Scalars['String']>;
  dimensions?: Maybe<AssetDimensions>;
  id?: Maybe<Scalars['String']>;
  skuMarkers?: Maybe<Array<Maybe<AssetSkuMarker>>>;
  type?: Maybe<ExplorerAssetType>;
  url?: Maybe<Scalars['String']>;
};

export type ExplorerProductsListModule = ExplorerModule & {
  __typename?: 'ExplorerProductsListModule';
  productVariants?: Maybe<Array<Maybe<ProductVariant>>>;
  skus?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ExplorerModuleType>;
};

export type ExplorerProfileListModule = ExplorerModule & {
  __typename?: 'ExplorerProfileListModule';
  profileIds: Array<Maybe<Scalars['String']>>;
  title: Scalars['String'];
  type: ExplorerModuleType;
};

export type ExplorerSectionHeaderModule = ExplorerModule & {
  __typename?: 'ExplorerSectionHeaderModule';
  title: Scalars['String'];
  type: ExplorerModuleType;
  useSeparator: Scalars['Boolean'];
};

export type ExplorerVideoAsset = ExplorerAsset & {
  __typename?: 'ExplorerVideoAsset';
  assetRequestId?: Maybe<Scalars['String']>;
  dimensions?: Maybe<AssetDimensions>;
  id?: Maybe<Scalars['String']>;
  noSound?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ExplorerAssetType>;
  url?: Maybe<Scalars['String']>;
  videoMetadata?: Maybe<VideoMetadata>;
};

export type VideoMetadata = {
  __typename?: 'VideoMetadata';
  duration?: Maybe<Scalars['Int']>;
  longformAssetRequestId?: Maybe<Scalars['String']>;
  longformUrl?: Maybe<Scalars['String']>;
  longformVideoDuration?: Maybe<Scalars['Int']>;
};

export type HongKongPaymentMethod = PaymentMethod & {
  __typename?: 'HongKongPaymentMethod';
  hongKongAccountNumber?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type IbanPaymentMethod = PaymentMethod & {
  __typename?: 'IBANPaymentMethod';
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type IndianPaymentMethod = PaymentMethod & {
  __typename?: 'IndianPaymentMethod';
  ifsc?: Maybe<Scalars['String']>;
  indiaAccountNumber?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type LiveShopMembershipFilter = {
  liveShopInviteCodeType?: InputMaybe<LiveShopInviteCodeType>;
};

export enum LiveShopProductDiscountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type MerchantProfile = ProfileV2 & {
  __typename?: 'MerchantProfile';
  contentOwner?: Maybe<ExplorerContentOwner>;
  id: Scalars['ID'];
  merchant: Merchant;
  merchantId: Scalars['String'];
  name: Scalars['String'];
  type: ProfileType;
  user: User;
};

export type MerchantSolutionsAdminProfile = ProfileV2 & {
  __typename?: 'MerchantSolutionsAdminProfile';
  contentOwner?: Maybe<ExplorerContentOwner>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ProfileType;
  user: User;
};

export type PendingVerifiedDeal = {
  __typename?: 'PendingVerifiedDeal';
  description: Scalars['String'];
  email: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  isClaimed: Scalars['Boolean'];
  maximumOrderCount?: Maybe<Scalars['Int']>;
  minimumSpend?: Maybe<Scalars['Int']>;
  pictureAsset?: Maybe<Asset>;
  skus?: Maybe<Array<Maybe<Scalars['String']>>>;
  type: Scalars['String'];
  unlocksAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['Float'];
  valueType: VerifiedDealValueType;
};

export type PimAttributeListValue = {
  __typename?: 'PimAttributeListValue';
  attributeCode: Scalars['String'];
  code: Scalars['String'];
  label: Scalars['String'];
};

export type PimDateAttribute = PimAttribute & {
  __typename?: 'PimDateAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export type PimDateAttributeValue = PimAttributeValue & {
  __typename?: 'PimDateAttributeValue';
  attribute: PimDateAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimDecimalAttribute = PimAttribute & {
  __typename?: 'PimDecimalAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export type PimDecimalAttributeValue = PimAttributeValue & {
  __typename?: 'PimDecimalAttributeValue';
  attribute: PimDecimalAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimIntegerAttribute = PimAttribute & {
  __typename?: 'PimIntegerAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export type PimIntegerAttributeValue = PimAttributeValue & {
  __typename?: 'PimIntegerAttributeValue';
  attribute: PimIntegerAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimListAttribute = PimAttribute & {
  __typename?: 'PimListAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  listValues: Array<PimAttributeListValue>;
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export type PimListAttributeValue = PimAttributeValue & {
  __typename?: 'PimListAttributeValue';
  attribute: PimListAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  listValue?: Maybe<PimAttributeListValue>;
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimListMultipleValuesAttribute = PimAttribute & {
  __typename?: 'PimListMultipleValuesAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  listValues: Array<PimAttributeListValue>;
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export type PimListMultipleValuesAttributeValue = PimAttributeValue & {
  __typename?: 'PimListMultipleValuesAttributeValue';
  attribute: PimListMultipleValuesAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  listValues: Array<PimAttributeListValue>;
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimLongTextAttribute = PimAttribute & {
  __typename?: 'PimLongTextAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export type PimLongTextAttributeValue = PimAttributeValue & {
  __typename?: 'PimLongTextAttributeValue';
  attribute: PimLongTextAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimMeasurementAttribute = PimAttribute & {
  __typename?: 'PimMeasurementAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  type: PimAttributeType;
  unit: PimMeasurementUnit;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export enum PimMeasurementUnit {
  ACRE = 'ACRE',
  AMPERE = 'AMPERE',
  AMPEREHOUR = 'AMPEREHOUR',
  ANGLE_MINUTE = 'ANGLE_MINUTE',
  ANGLE_SECOND = 'ANGLE_SECOND',
  ARE = 'ARE',
  ARPENT = 'ARPENT',
  ATM = 'ATM',
  ATTOFARAD = 'ATTOFARAD',
  BAR = 'BAR',
  BARREL = 'BARREL',
  BIT = 'BIT',
  BYTE = 'BYTE',
  CALORIE = 'CALORIE',
  CELSIUS = 'CELSIUS',
  CENTIAMPERE = 'CENTIAMPERE',
  CENTIARE = 'CENTIARE',
  CENTIBAR = 'CENTIBAR',
  CENTICOULOMB = 'CENTICOULOMB',
  CENTILITER = 'CENTILITER',
  CENTIMETER = 'CENTIMETER',
  CENTIOHM = 'CENTIOHM',
  CENTIVOLT = 'CENTIVOLT',
  CHAIN = 'CHAIN',
  CHAR = 'CHAR',
  COULOMB = 'COULOMB',
  CUBIC_CENTIMETER = 'CUBIC_CENTIMETER',
  CUBIC_CENTIMETER_PER_DAY = 'CUBIC_CENTIMETER_PER_DAY',
  CUBIC_CENTIMETER_PER_HOUR = 'CUBIC_CENTIMETER_PER_HOUR',
  CUBIC_CENTIMETER_PER_MINUTE = 'CUBIC_CENTIMETER_PER_MINUTE',
  CUBIC_CENTIMETER_PER_SECOND = 'CUBIC_CENTIMETER_PER_SECOND',
  CUBIC_DECIMETER = 'CUBIC_DECIMETER',
  CUBIC_DECIMETER_PER_HOUR = 'CUBIC_DECIMETER_PER_HOUR',
  CUBIC_DECIMETER_PER_MINUTE = 'CUBIC_DECIMETER_PER_MINUTE',
  CUBIC_FOOT = 'CUBIC_FOOT',
  CUBIC_INCH = 'CUBIC_INCH',
  CUBIC_METER = 'CUBIC_METER',
  CUBIC_METER_PER_DAY = 'CUBIC_METER_PER_DAY',
  CUBIC_METER_PER_HOUR = 'CUBIC_METER_PER_HOUR',
  CUBIC_METER_PER_MINUTE = 'CUBIC_METER_PER_MINUTE',
  CUBIC_METER_PER_SECOND = 'CUBIC_METER_PER_SECOND',
  CUBIC_MILLIMETER = 'CUBIC_MILLIMETER',
  CUBIC_YARD = 'CUBIC_YARD',
  DAY = 'DAY',
  DECACOULOMB = 'DECACOULOMB',
  DECAMETER = 'DECAMETER',
  DECAMPERE = 'DECAMPERE',
  DECAOHM = 'DECAOHM',
  DECAVOLT = 'DECAVOLT',
  DECIAMPERE = 'DECIAMPERE',
  DECIBAR = 'DECIBAR',
  DECIBEL = 'DECIBEL',
  DECICOULOMB = 'DECICOULOMB',
  DECILITER = 'DECILITER',
  DECIMETER = 'DECIMETER',
  DECIOHM = 'DECIOHM',
  DECIVOLT = 'DECIVOLT',
  DEGREE = 'DEGREE',
  DOZEN = 'DOZEN',
  FAHRENHEIT = 'FAHRENHEIT',
  FARAD = 'FARAD',
  FEET = 'FEET',
  FLUID_OUNCE = 'FLUID_OUNCE',
  FOOT_PER_HOUR = 'FOOT_PER_HOUR',
  FOOT_PER_SECOND = 'FOOT_PER_SECOND',
  FURLONG = 'FURLONG',
  GALLON = 'GALLON',
  GIGABIT = 'GIGABIT',
  GIGABYTE = 'GIGABYTE',
  GIGAHERTZ = 'GIGAHERTZ',
  GIGAPASCAL = 'GIGAPASCAL',
  GIGAWATT = 'GIGAWATT',
  GON = 'GON',
  GRAIN = 'GRAIN',
  GRAM = 'GRAM',
  HECTARE = 'HECTARE',
  HECTOAMPERE = 'HECTOAMPERE',
  HECTOCOULOMB = 'HECTOCOULOMB',
  HECTOHM = 'HECTOHM',
  HECTOLITER = 'HECTOLITER',
  HECTOMETER = 'HECTOMETER',
  HECTOPASCAL = 'HECTOPASCAL',
  HECTOVOLT = 'HECTOVOLT',
  HERTZ = 'HERTZ',
  HOUR = 'HOUR',
  INCH = 'INCH',
  JOULE = 'JOULE',
  KELVIN = 'KELVIN',
  KILOAMPERE = 'KILOAMPERE',
  KILOBAR = 'KILOBAR',
  KILOBIT = 'KILOBIT',
  KILOBYTE = 'KILOBYTE',
  KILOCALORIE = 'KILOCALORIE',
  KILOCOULOMB = 'KILOCOULOMB',
  KILOFARAD = 'KILOFARAD',
  KILOGRAM = 'KILOGRAM',
  KILOHERTZ = 'KILOHERTZ',
  KILOHM = 'KILOHM',
  KILOJOULE = 'KILOJOULE',
  KILOLITER_PER_HOUR = 'KILOLITER_PER_HOUR',
  KILOMETER = 'KILOMETER',
  KILOMETER_PER_HOUR = 'KILOMETER_PER_HOUR',
  KILONEWTON = 'KILONEWTON',
  KILOPASCAL = 'KILOPASCAL',
  KILOVOLT = 'KILOVOLT',
  KILOWATT = 'KILOWATT',
  LITER = 'LITER',
  LITER_PER_DAY = 'LITER_PER_DAY',
  LITER_PER_HOUR = 'LITER_PER_HOUR',
  LITER_PER_MINUTE = 'LITER_PER_MINUTE',
  LITER_PER_SECOND = 'LITER_PER_SECOND',
  LUMEN = 'LUMEN',
  MEGABAR = 'MEGABAR',
  MEGABIT = 'MEGABIT',
  MEGABYTE = 'MEGABYTE',
  MEGAHERTZ = 'MEGAHERTZ',
  MEGANEWTON = 'MEGANEWTON',
  MEGAPASCAL = 'MEGAPASCAL',
  MEGAWATT = 'MEGAWATT',
  MEGOHM = 'MEGOHM',
  METER = 'METER',
  METER_PER_HOUR = 'METER_PER_HOUR',
  METER_PER_MINUTE = 'METER_PER_MINUTE',
  METER_PER_SECOND = 'METER_PER_SECOND',
  MICROFARAD = 'MICROFARAD',
  MICROLITER = 'MICROLITER',
  MICROMETER = 'MICROMETER',
  MICRORADIAN = 'MICRORADIAN',
  MIL = 'MIL',
  MILE = 'MILE',
  MILE_PER_HOUR = 'MILE_PER_HOUR',
  MILLIAMPERE = 'MILLIAMPERE',
  MILLIAMPEREHOUR = 'MILLIAMPEREHOUR',
  MILLIBAR = 'MILLIBAR',
  MILLICOULOMB = 'MILLICOULOMB',
  MILLIFARAD = 'MILLIFARAD',
  MILLIGRAM = 'MILLIGRAM',
  MILLILITER = 'MILLILITER',
  MILLILITER_PER_DAY = 'MILLILITER_PER_DAY',
  MILLILITER_PER_HOUR = 'MILLILITER_PER_HOUR',
  MILLILITER_PER_MINUTE = 'MILLILITER_PER_MINUTE',
  MILLILITER_PER_SECOND = 'MILLILITER_PER_SECOND',
  MILLIMETER = 'MILLIMETER',
  MILLINEWTON = 'MILLINEWTON',
  MILLIOHM = 'MILLIOHM',
  MILLIRADIAN = 'MILLIRADIAN',
  MILLISECOND = 'MILLISECOND',
  MILLIVOLT = 'MILLIVOLT',
  MINUTE = 'MINUTE',
  MMHG = 'MMHG',
  MONTH = 'MONTH',
  NANOFARAD = 'NANOFARAD',
  NAUTICAL_MILE = 'NAUTICAL_MILE',
  NEWTON = 'NEWTON',
  NIT = 'NIT',
  OHM = 'OHM',
  OUNCE = 'OUNCE',
  PASCAL = 'PASCAL',
  PICOFARAD = 'PICOFARAD',
  PIECE = 'PIECE',
  PINT = 'PINT',
  POUND = 'POUND',
  PSI = 'PSI',
  RADIAN = 'RADIAN',
  RANKINE = 'RANKINE',
  REAUMUR = 'REAUMUR',
  REVOLUTION = 'REVOLUTION',
  SECOND = 'SECOND',
  SQUARE_CENTIMETER = 'SQUARE_CENTIMETER',
  SQUARE_DECAMETER = 'SQUARE_DECAMETER',
  SQUARE_DECIMETER = 'SQUARE_DECIMETER',
  SQUARE_FOOT = 'SQUARE_FOOT',
  SQUARE_FURLONG = 'SQUARE_FURLONG',
  SQUARE_HECTOMETER = 'SQUARE_HECTOMETER',
  SQUARE_INCH = 'SQUARE_INCH',
  SQUARE_KILOMETER = 'SQUARE_KILOMETER',
  SQUARE_METER = 'SQUARE_METER',
  SQUARE_MIL = 'SQUARE_MIL',
  SQUARE_MILE = 'SQUARE_MILE',
  SQUARE_MILLIMETER = 'SQUARE_MILLIMETER',
  SQUARE_YARD = 'SQUARE_YARD',
  TERABYTE = 'TERABYTE',
  TERAHERTZ = 'TERAHERTZ',
  TERAWATT = 'TERAWATT',
  TON = 'TON',
  TORR = 'TORR',
  VOLT = 'VOLT',
  WATT = 'WATT',
  WEEK = 'WEEK',
  YARD = 'YARD',
  YARD_PER_HOUR = 'YARD_PER_HOUR',
  YEAR = 'YEAR',
}

export type PimMeasurementAttributeValue = PimAttributeValue & {
  __typename?: 'PimMeasurementAttributeValue';
  attribute: PimMeasurementAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimMediaAttribute = PimAttribute & {
  __typename?: 'PimMediaAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export type PimMediaAttributeValue = PimAttributeValue & {
  __typename?: 'PimMediaAttributeValue';
  attribute: PimMediaAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimMultipleAttribute = PimAttribute & {
  __typename?: 'PimMultipleAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export type PimMultipleAttributeValue = PimAttributeValue & {
  __typename?: 'PimMultipleAttributeValue';
  attribute: PimMultipleAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  multipeValues: Array<PimMultipleAttributeValueElement>;
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type PimMultipleAttributeValueElement = {
  __typename?: 'PimMultipleAttributeValueElement';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PimTextAttribute = PimAttribute & {
  __typename?: 'PimTextAttribute';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  type: PimAttributeType;
  usesJSONStringifiedValue: Scalars['Boolean'];
};

export type PimTextAttributeValue = PimAttributeValue & {
  __typename?: 'PimTextAttributeValue';
  attribute: PimTextAttribute;
  attributeCode: Scalars['String'];
  isViewable: Scalars['Boolean'];
  readOnly: Scalars['Boolean'];
  schemaCategoryAttribute: PimSchemaCategoryAttribute;
  source?: Maybe<Scalars['String']>;
  validationErrors: Array<PimValidationError>;
  value?: Maybe<Scalars['String']>;
};

export type ProfilesV2Page = {
  __typename?: 'ProfilesV2Page';
  items: Array<ProfileV2>;
  pagination?: Maybe<Pagination>;
};

export type RankingInputs = {
  rankings: Array<RankingInput>;
};

export type ReportProfileInput = {
  id?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

export type StringOption = Option & {
  __typename?: 'StringOption';
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primary?: Maybe<Scalars['Boolean']>;
  type: OptionType;
  values: Array<StringOptionValue>;
};

export type StringOptionValue = OptionValue & {
  __typename?: 'StringOptionValue';
  label: Scalars['String'];
  sortOrder: Scalars['Int'];
  value: Scalars['String'];
};

export type UkPaymentMethod = PaymentMethod & {
  __typename?: 'UKPaymentMethod';
  accountNumber?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  type: PaymentMethodType;
};

export type UserFilterInput = {
  filterField: UserFilterFields;
  filterFieldValue: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
  partialMatch?: InputMaybe<Scalars['Boolean']>;
};

export type UserProfile = ProfileV2 & {
  __typename?: 'UserProfile';
  contentOwner?: Maybe<ExplorerContentOwner>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ProfileType;
  user: User;
};

export type VerishopAdminProfile = ProfileV2 & {
  __typename?: 'VerishopAdminProfile';
  contentOwner?: Maybe<ExplorerContentOwner>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ProfileType;
  user: User;
};

export type CreateMultipassUrlMutationVariables = Exact<{
  input: CreateMultipassUrlInput;
}>;

export type CreateMultipassUrlMutation = {
  __typename?: 'Mutation';
  createMultipassUrl: {
    __typename?: 'CreateMultipassUrlMutationResponse';
    multipassUrl?: string | null | undefined;
    success: boolean;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type CreateComingSoonNotificationMutationVariables = Exact<{
  input: ComingSoonNotificationInput;
}>;

export type CreateComingSoonNotificationMutation = {
  __typename?: 'Mutation';
  createComingSoonNotification: {
    __typename?: 'ComingSoonNotificationMutationResponse';
    success: boolean;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type SendDmcaEmailMutationVariables = Exact<{
  input: EmailDmcaInput;
}>;

export type SendDmcaEmailMutation = {
  __typename?: 'Mutation';
  sendDMCAEmail: {
    __typename?: 'GenericMutationResponse';
    success: boolean;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type GetDynamicModulesQueryVariables = Exact<{
  modules:
    | Array<InputMaybe<DynamicModuleInput>>
    | InputMaybe<DynamicModuleInput>;
}>;

export type GetDynamicModulesQuery = {
  __typename?: 'Query';
  getDynamicModules?:
    | Array<
        | {
            __typename?: 'DynamicModuleResponse';
            module: DynamicModuleModule;
            segment: DynamicModuleSegment;
            identifier?: string | null | undefined;
            products: Array<
              | {
                  __typename?: 'Product';
                  brand?: string | null | undefined;
                  brandSlug?: string | null | undefined;
                  family?: string | null | undefined;
                  familySlug?: string | null | undefined;
                  sid: string;
                  slug?: string | null | undefined;
                  title: string;
                  brandEntity?:
                    | {
                        __typename?: 'Brand';
                        label?: string | null | undefined;
                        slug?: string | null | undefined;
                        subscriptionTier?:
                          | MerchantSubscriptionTier
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  categories: Array<{
                    __typename?: 'Category';
                    category: Array<string>;
                  }>;
                  options: Array<
                    | {
                        __typename?: 'ColorOption';
                        label?: string | null | undefined;
                        name: string;
                        type: OptionType;
                        primary?: boolean | null | undefined;
                        values: Array<{
                          __typename?: 'ColorOptionValue';
                          brandColor: string;
                          colorFamily: string;
                          label: string;
                          swatchColorCode: string;
                          value: string;
                        }>;
                      }
                    | {
                        __typename?: 'StringOption';
                        label?: string | null | undefined;
                        name: string;
                        type: OptionType;
                        primary?: boolean | null | undefined;
                        values: Array<{
                          __typename?: 'StringOptionValue';
                          label: string;
                          value: string;
                        }>;
                      }
                  >;
                  variants: Array<{
                    __typename?: 'ProductVariant';
                    compareAtPrice?: number | null | undefined;
                    currencyCode?: string | null | undefined;
                    fullSid: string;
                    inStock?: boolean | null | undefined;
                    price?: number | null | undefined;
                    sku: string;
                    sid: string;
                    shoppingStatus?: ShoppingStatus | null | undefined;
                    title?: string | null | undefined;
                    filterables?:
                      | {
                          __typename?: 'Filterables';
                          responsible?:
                            | Array<string | null | undefined>
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    galleryImages: Array<{ __typename?: 'Image'; url: string }>;
                    selectedOptions: Array<{
                      __typename?: 'SelectedOption';
                      name: string;
                      value: string;
                    }>;
                  }>;
                }
              | null
              | undefined
            >;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type EstimatedDeliveryQueryV3QueryVariables = Exact<{
  lineItems: Array<EstimatedDeliveryLineItem> | EstimatedDeliveryLineItem;
}>;

export type EstimatedDeliveryQueryV3Query = {
  __typename?: 'Query';
  estimatedDeliveryV3: Array<{
    __typename?: 'EstimatedDeliveryV3';
    cutoffDateTime?: string | null | undefined;
    maxDeliveryDate: string;
    minDeliveryDate: string;
    minOrderValue: number;
    minOrderValueEligible: boolean;
    productVariantSku: string;
    shippingMethod: ShippingMethod;
  }>;
  shippingTiers?:
    | Array<{
        __typename?: 'ShippingTier';
        threshold: number;
        type: ShippingTierType;
      }>
    | null
    | undefined;
};

export type GetVariantsForUserMenuQueryVariables = Exact<{
  skus?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type GetVariantsForUserMenuQuery = {
  __typename?: 'Query';
  productVariant: Array<
    | {
        __typename?: 'ProductVariant';
        sku: string;
        productBundleItems?:
          | Array<
              | {
                  __typename?: 'ProductBundleItem';
                  sku: string;
                  quantity: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type GenerateAnonymousIdentityMutationVariables = Exact<{
  anonymousUserId?: InputMaybe<Scalars['String']>;
}>;

export type GenerateAnonymousIdentityMutation = {
  __typename?: 'Mutation';
  generateAnonymousIdentity: {
    __typename?: 'UserIdentityMutationResponse';
    anonymousUserIdentity?:
      | { __typename?: 'AnonymousUserIdentity'; id?: string | null | undefined }
      | null
      | undefined;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type AssociateUserToAnonymousIdMutationVariables = Exact<{
  anonymousUserId: Scalars['String'];
}>;

export type AssociateUserToAnonymousIdMutation = {
  __typename?: 'Mutation';
  associateUserToAnonymousId: {
    __typename?: 'UserIdentityMutationResponse';
    anonymousUserIdentity?:
      | { __typename?: 'AnonymousUserIdentity'; id?: string | null | undefined }
      | null
      | undefined;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type GetPromoCodeQueryVariables = Exact<{ [key: string]: never }>;

export type GetPromoCodeQuery = {
  __typename?: 'Query';
  promoCode?:
    | {
        __typename?: 'PromotionDetailResponse';
        code: string;
        details: string;
        endDate?: string | null | undefined;
        id: string;
        minSubtotal?: number | null | undefined;
        startDate?: string | null | undefined;
        appliesToDiscountedItems?: boolean | null | undefined;
        discounts?:
          | Array<{
              __typename?: 'PromotionDiscount';
              discount: number;
              eligibility?:
                | Array<{
                    __typename?: 'PromotionEligibility';
                    type: PromotionEligibilityType;
                    value: string;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMerchantInfoForOrderQueryVariables = Exact<{
  shopifyOrderId: Scalars['String'];
}>;

export type GetMerchantInfoForOrderQuery = {
  __typename?: 'Query';
  merchantInfoForOrder?:
    | Array<{
        __typename?: 'PublicOrderInfo';
        productVariant: {
          __typename?: 'PublicOrderProductVariant';
          brandSlug: string;
          familySlug: string;
          productId: string;
          productSlug: string;
          title: string;
          options?:
            | Array<
                | {
                    __typename?: 'ColorOption';
                    type: OptionType;
                    name: string;
                    values: Array<{
                      __typename?: 'ColorOptionValue';
                      label: string;
                      value: string;
                    }>;
                  }
                | {
                    __typename?: 'StringOption';
                    type: OptionType;
                    name: string;
                    values: Array<{
                      __typename?: 'StringOptionValue';
                      label: string;
                      value: string;
                    }>;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          selectedOptions?:
            | Array<
                | { __typename?: 'SelectedOption'; name: string; value: string }
                | null
                | undefined
              >
            | null
            | undefined;
        };
        merchantPublicInfo?:
          | {
              __typename?: 'MerchantPublicContactInfo';
              fullName?: string | null | undefined;
              email?: string | null | undefined;
              phoneNumber?: string | null | undefined;
              address?:
                | {
                    __typename?: 'Address';
                    id: string;
                    street1?: string | null | undefined;
                    street2?: string | null | undefined;
                    city?: string | null | undefined;
                    state?: string | null | undefined;
                    country?: string | null | undefined;
                    zipCode?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type SetGiftCardMessageMutationVariables = Exact<{
  cartLineItemId: Scalars['ID'];
  giftCardMessage: Scalars['String'];
}>;

export type SetGiftCardMessageMutation = {
  __typename?: 'Mutation';
  setGiftCardMessage?: string | null | undefined;
};

export type GetGiftNoteQueryVariables = Exact<{ [key: string]: never }>;

export type GetGiftNoteQuery = {
  __typename?: 'Query';
  checkout?:
    | {
        __typename?: 'Checkout';
        customAttributes: {
          __typename?: 'CheckoutCustomAttribute';
          key: string;
          value: string;
        };
      }
    | null
    | undefined;
};

export type SetGiftNoteMutationVariables = Exact<{
  giftNote: Scalars['String'];
}>;

export type SetGiftNoteMutation = {
  __typename?: 'Mutation';
  setGiftNote?: string | null | undefined;
};

export type CreateInStockNotificationMutationVariables = Exact<{
  input: InStockNotificationInput;
}>;

export type CreateInStockNotificationMutation = {
  __typename?: 'Mutation';
  createInStockNotification: {
    __typename?: 'InStockNotificationMutationResponse';
    success: boolean;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type CreateEmailSignUpMutationVariables = Exact<{
  input: EmailSignUpInput;
}>;

export type CreateEmailSignUpMutation = {
  __typename?: 'Mutation';
  createEmailSignUp: {
    __typename?: 'GenericMutationResponse';
    success: boolean;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type GetOrderByIdQueryVariables = Exact<{
  orderId?: InputMaybe<Scalars['String']>;
}>;

export type GetOrderByIdQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    orders?:
      | Array<
          | {
              __typename?: 'Order';
              createdAt?: string | null | undefined;
              currencyCode?: string | null | undefined;
              customerSid?: string | null | undefined;
              id?: string | null | undefined;
              orderNumber?: string | null | undefined;
              name?: string | null | undefined;
              subtotalLineItemsQuantity?: number | null | undefined;
              fulfillments?:
                | Array<{
                    __typename?: 'Fulfillment';
                    shipmentStatus?: FulfillmentEventStatus | null | undefined;
                    status?: string | null | undefined;
                    trackingCompany?: string | null | undefined;
                    trackingNumber?: string | null | undefined;
                    lineItems: Array<{ __typename?: 'LineItem'; sku: string }>;
                  }>
                | null
                | undefined;
              lineItems: Array<{
                __typename?: 'LineItem';
                id: string;
                price: string;
                quantity: number;
                sku: string;
                title: string;
                variantId: string;
                vendor?: string | null | undefined;
                productVariant: {
                  __typename?: 'ProductVariant';
                  inStock?: boolean | null | undefined;
                  shoppingStatus?: ShoppingStatus | null | undefined;
                  galleryImages: Array<{ __typename?: 'Image'; url: string }>;
                  modelImages: Array<{ __typename?: 'Image'; url: string }>;
                  product?:
                    | {
                        __typename?: 'Product';
                        brandSlug?: string | null | undefined;
                        familySlug?: string | null | undefined;
                        sid: string;
                        slug?: string | null | undefined;
                        hierarchicalCategories: {
                          __typename?: 'HierarchicalCategories';
                          lvl0: Array<string>;
                        };
                        options: Array<
                          | {
                              __typename?: 'ColorOption';
                              name: string;
                              type: OptionType;
                              values: Array<{
                                __typename?: 'ColorOptionValue';
                                brandColor: string;
                                colorFamily: string;
                                label: string;
                                swatchColorCode: string;
                                value: string;
                              }>;
                            }
                          | {
                              __typename?: 'StringOption';
                              name: string;
                              type: OptionType;
                              values: Array<{
                                __typename?: 'StringOptionValue';
                                label: string;
                                value: string;
                              }>;
                            }
                        >;
                      }
                    | null
                    | undefined;
                  productImages?:
                    | Array<
                        { __typename?: 'Image'; url: string } | null | undefined
                      >
                    | null
                    | undefined;
                  selectedOptions: Array<{
                    __typename?: 'SelectedOption';
                    name: string;
                    value: string;
                  }>;
                };
              }>;
              paymentDetails?:
                | {
                    __typename?: 'PaymentDetails';
                    creditCardCompany?: string | null | undefined;
                    creditCardNumber?: string | null | undefined;
                  }
                | null
                | undefined;
              shippingAddress?:
                | {
                    __typename?: 'MailingAddress';
                    address1?: string | null | undefined;
                    address2?: string | null | undefined;
                    city?: string | null | undefined;
                    countryCode?: string | null | undefined;
                    name?: string | null | undefined;
                    provinceCode?: string | null | undefined;
                    zip?: string | null | undefined;
                  }
                | null
                | undefined;
              subtotalPriceSet?:
                | {
                    __typename?: 'MoneySet';
                    presentmentMoney?:
                      | {
                          __typename?: 'Money';
                          amount?: number | null | undefined;
                          currencyCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              totalDiscountsSet?:
                | {
                    __typename?: 'MoneySet';
                    presentmentMoney?:
                      | {
                          __typename?: 'Money';
                          amount?: number | null | undefined;
                          currencyCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              totalLineItemsPriceSet?:
                | {
                    __typename?: 'MoneySet';
                    presentmentMoney?:
                      | {
                          __typename?: 'Money';
                          amount?: number | null | undefined;
                          currencyCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              totalPriceSet?:
                | {
                    __typename?: 'MoneySet';
                    presentmentMoney?:
                      | {
                          __typename?: 'Money';
                          amount?: number | null | undefined;
                          currencyCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              totalShippingPriceSet?:
                | {
                    __typename?: 'MoneySet';
                    presentmentMoney?:
                      | {
                          __typename?: 'Money';
                          amount?: number | null | undefined;
                          currencyCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              totalTaxSet?:
                | {
                    __typename?: 'MoneySet';
                    presentmentMoney?:
                      | {
                          __typename?: 'Money';
                          amount?: number | null | undefined;
                          currencyCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type GetOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrdersQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    orders?:
      | Array<
          | {
              __typename?: 'Order';
              createdAt?: string | null | undefined;
              customerSid?: string | null | undefined;
              currencyCode?: string | null | undefined;
              id?: string | null | undefined;
              orderNumber?: string | null | undefined;
              name?: string | null | undefined;
              subtotalLineItemsQuantity?: number | null | undefined;
              fulfillments?:
                | Array<{
                    __typename?: 'Fulfillment';
                    shipmentStatus?: FulfillmentEventStatus | null | undefined;
                    status?: string | null | undefined;
                    trackingCompany?: string | null | undefined;
                    trackingNumber?: string | null | undefined;
                    lineItems: Array<{ __typename?: 'LineItem'; sku: string }>;
                  }>
                | null
                | undefined;
              lineItems: Array<{
                __typename?: 'LineItem';
                id: string;
                price: string;
                quantity: number;
                sku: string;
                title: string;
                variantId: string;
                vendor?: string | null | undefined;
                productVariant: {
                  __typename?: 'ProductVariant';
                  inStock?: boolean | null | undefined;
                  shoppingStatus?: ShoppingStatus | null | undefined;
                  galleryImages: Array<{ __typename?: 'Image'; url: string }>;
                  modelImages: Array<{ __typename?: 'Image'; url: string }>;
                  product?:
                    | {
                        __typename?: 'Product';
                        brandSlug?: string | null | undefined;
                        familySlug?: string | null | undefined;
                        sid: string;
                        slug?: string | null | undefined;
                        hierarchicalCategories: {
                          __typename?: 'HierarchicalCategories';
                          lvl0: Array<string>;
                        };
                        options: Array<
                          | {
                              __typename?: 'ColorOption';
                              name: string;
                              type: OptionType;
                              values: Array<{
                                __typename?: 'ColorOptionValue';
                                brandColor: string;
                                colorFamily: string;
                                label: string;
                                swatchColorCode: string;
                                value: string;
                              }>;
                            }
                          | {
                              __typename?: 'StringOption';
                              name: string;
                              type: OptionType;
                              values: Array<{
                                __typename?: 'StringOptionValue';
                                label: string;
                                value: string;
                              }>;
                            }
                        >;
                      }
                    | null
                    | undefined;
                  productImages?:
                    | Array<
                        { __typename?: 'Image'; url: string } | null | undefined
                      >
                    | null
                    | undefined;
                  selectedOptions: Array<{
                    __typename?: 'SelectedOption';
                    name: string;
                    value: string;
                  }>;
                };
              }>;
              totalPriceSet?:
                | {
                    __typename?: 'MoneySet';
                    presentmentMoney?:
                      | {
                          __typename?: 'Money';
                          amount?: number | null | undefined;
                          currencyCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
  getReviewsForRewards: Array<
    | {
        __typename?: 'ReviewsForRewardByOrder';
        orderId: string;
        products: Array<
          | {
              __typename?: 'ReviewsForRewardStatusItem';
              potentialValue: number;
              productSid: string;
              reviewWritten: boolean;
              rewardGenerated: boolean;
              sku: string;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined
  >;
};

export type WebClaimReviewIncentiveRewardMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;

export type WebClaimReviewIncentiveRewardMutation = {
  __typename?: 'Mutation';
  generateRewardForOrderId: {
    __typename?: 'GenerateRewardForOrderIdResponse';
    success: boolean;
    rewardValue: number;
  };
};

export type EstimatedDeliveryTwoDayShippingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EstimatedDeliveryTwoDayShippingQuery = {
  __typename?: 'Query';
  estimatedDeliveryTwoDayShipping: {
    __typename?: 'TwoDayEstimatedDelivery';
    cutoffDateTime: string;
    maxDeliveryDate: string;
  };
};

export type GetRecommendedProductsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  modelId: Scalars['String'];
  modelVariantId: Scalars['String'];
  productIds:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
}>;

export type GetRecommendedProductsQuery = {
  __typename?: 'Query';
  recommendedMultiKeyProducts: Array<
    | {
        __typename?: 'Product';
        brand?: string | null | undefined;
        brandSlug?: string | null | undefined;
        familySlug?: string | null | undefined;
        sid: string;
        slug?: string | null | undefined;
        title: string;
        categories: Array<{ __typename?: 'Category'; category: Array<string> }>;
        hierarchicalCategories: {
          __typename?: 'HierarchicalCategories';
          lvl0: Array<string>;
          lvl1: Array<string>;
          lvl2: Array<string>;
          lvl3: Array<string>;
        };
        options: Array<
          | {
              __typename?: 'ColorOption';
              type: OptionType;
              name: string;
              values: Array<{
                __typename?: 'ColorOptionValue';
                label: string;
                value: string;
              }>;
            }
          | {
              __typename?: 'StringOption';
              type: OptionType;
              name: string;
              values: Array<{
                __typename?: 'StringOptionValue';
                label: string;
                value: string;
              }>;
            }
        >;
        reviewSummary?:
          | {
              __typename?: 'ReviewSummary';
              averageScore: number;
              totalReview: number;
            }
          | null
          | undefined;
        variants: Array<{
          __typename?: 'ProductVariant';
          compareAtPrice?: number | null | undefined;
          eligiblePromotions: Array<string | null | undefined>;
          inStock?: boolean | null | undefined;
          isDiscountable: boolean;
          isMarketplace?: boolean | null | undefined;
          isProductBundle?: boolean | null | undefined;
          price?: number | null | undefined;
          sid: string;
          sku: string;
          galleryImages: Array<{ __typename?: 'Image'; url: string }>;
          modelImages: Array<{ __typename?: 'Image'; url: string }>;
          offerDetails?:
            | {
                __typename?: 'OfferDetails';
                offerType?: OfferType | null | undefined;
                startDate?: string | null | undefined;
              }
            | null
            | undefined;
          productImages?:
            | Array<{ __typename?: 'Image'; url: string } | null | undefined>
            | null
            | undefined;
          selectedOptions: Array<{
            __typename?: 'SelectedOption';
            name: string;
            value: string;
          }>;
        }>;
      }
    | null
    | undefined
  >;
};

export type GetBrandsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBrandsQuery = {
  __typename?: 'Query';
  brands: Array<
    | {
        __typename?: 'Brand';
        label?: string | null | undefined;
        slug?: string | null | undefined;
      }
    | null
    | undefined
  >;
};

export type ReviewFormMutationMutationVariables = Exact<{
  input: CreateProductReviewInput;
}>;

export type ReviewFormMutationMutation = {
  __typename?: 'Mutation';
  createProductReview: {
    __typename?: 'GenericMutationResponse';
    success: boolean;
    validationErrors: Array<
      | {
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }
      | null
      | undefined
    >;
  };
};

export type WebCreateOnboardingDiscountMutationVariables = Exact<{
  input: CreateOnboardingDiscountInput;
}>;

export type WebCreateOnboardingDiscountMutation = {
  __typename?: 'Mutation';
  createOnboardingDiscount:
    | {
        __typename?: 'GetCreateShopifyAppStatusQueryResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'GenericMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'AssignVpcsToMerchantResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'UserIdentityMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'ExplorerPostUserResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'ChangeMerchantAdminUserResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'ClaimLiveShopDiscountResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'CustomerMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'CreateSignedUrlMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'BookmarkMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'BookmarkListMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'ComingSoonNotificationMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'CreateExplorerPostResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'InStockNotificationMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'CreateMerchantResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'CreateMultipassUrlMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'CreateProfileMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'CreateSAMLResponseMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'CreateShopifyAppMutationResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'CreateSignedUrlsForSecuredAssetsResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'CreateVerishopProductCodeRequestResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'CreatorMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'DeleteBookmarkMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'DeleteProfileMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'ProfileFollowMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'GenerateRewardForOrderIdResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'InviteEmailToProfileResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'LiveShopMutationResponse';
        success: boolean;
        validationErrors?:
          | Array<
              | {
                  __typename?: 'ValidationError';
                  code: number;
                  field: string;
                  message: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | {
        __typename?: 'PimArchiveProductsMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimArchiveProductVariantsMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimBulkUpdateProductVariantsMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimBulkUpsertOffersResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimDeleteProductsMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimEnqueueBulkProductsUpdateResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimGroupProductVariantsMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimSyncProductResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimUngroupProductVariantsMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimUpdateProductMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimUpdateProductArchiveStatusMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimUpdateProductCategoriesMutationResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'PimUpdateProductsMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimUpdateProductSchemaCategoryMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimUpdateProductVariantMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimUpdateProductVariantArchiveStatusMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimUpdateProductVariantsMutationResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'PimUpdateProductVariantsAcceptanceStatusMutationResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'PimUpsertOffersResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'RedeemVerifiedDealResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'ReloadMerchantCatalogResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'RevokeMerchantProfileResponse';
        success: boolean;
        validationErrors: Array<{
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }>;
      }
    | {
        __typename?: 'SetCreateShopifyAppStatusMutationResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'ShopPartyMutationResponse';
        success: boolean;
        validationErrors?:
          | Array<
              | {
                  __typename?: 'ValidationError';
                  code: number;
                  field: string;
                  message: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | {
        __typename?: 'ShopPartyJoinMutationResponse';
        success: boolean;
        validationErrors?:
          | Array<
              | {
                  __typename?: 'ValidationError';
                  code: number;
                  field: string;
                  message: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | {
        __typename?: 'UpdateMerchantResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'UpdateMerchantBankInfoResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'UpdateMerchantStatusResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'UpdateMerchantVpcRequestLimitResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'ProfileMutationResponse';
        success: boolean;
        validationErrors: Array<
          | {
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }
          | null
          | undefined
        >;
      }
    | {
        __typename?: 'UpdateVerishopProductCodeRequestResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      }
    | {
        __typename?: 'UpsertMerchantResponse';
        success: boolean;
        validationErrors?:
          | Array<{
              __typename?: 'ValidationError';
              code: number;
              field: string;
              message: string;
            }>
          | null
          | undefined;
      };
};

export type VariantsForProductCarouselQueryVariables = Exact<{
  sids?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  skus?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type VariantsForProductCarouselQuery = {
  __typename?: 'Query';
  productVariant: Array<
    | {
        __typename?: 'ProductVariant';
        compareAtPrice?: number | null | undefined;
        eligiblePromotions: Array<string | null | undefined>;
        isDiscountable: boolean;
        isMarketplace?: boolean | null | undefined;
        isProductBundle?: boolean | null | undefined;
        price?: number | null | undefined;
        sid: string;
        sku: string;
        galleryImages: Array<{ __typename?: 'Image'; url: string }>;
        modelImages: Array<{ __typename?: 'Image'; url: string }>;
        offerDetails?:
          | {
              __typename?: 'OfferDetails';
              offerType?: OfferType | null | undefined;
            }
          | null
          | undefined;
        product?:
          | {
              __typename?: 'Product';
              brand?: string | null | undefined;
              brandSlug?: string | null | undefined;
              familySlug?: string | null | undefined;
              sid: string;
              slug?: string | null | undefined;
              title: string;
              categories: Array<{
                __typename?: 'Category';
                category: Array<string>;
              }>;
              hierarchicalCategories: {
                __typename?: 'HierarchicalCategories';
                lvl0: Array<string>;
                lvl1: Array<string>;
                lvl2: Array<string>;
                lvl3: Array<string>;
              };
            }
          | null
          | undefined;
        productImages?:
          | Array<{ __typename?: 'Image'; url: string } | null | undefined>
          | null
          | undefined;
        selectedOptions: Array<{
          __typename?: 'SelectedOption';
          name: string;
          value: string;
        }>;
      }
    | null
    | undefined
  >;
};

export type GetShoppablePhotosBySkuQueryVariables = Exact<{
  skus?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type GetShoppablePhotosBySkuQuery = {
  __typename?: 'Query';
  productVariant: Array<
    | {
        __typename?: 'ProductVariant';
        price?: number | null | undefined;
        sku: string;
        product?:
          | {
              __typename?: 'Product';
              brand?: string | null | undefined;
              brandSlug?: string | null | undefined;
              familySlug?: string | null | undefined;
              sid: string;
              slug?: string | null | undefined;
            }
          | null
          | undefined;
        productImages?:
          | Array<{ __typename?: 'Image'; url: string } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type GetProductVariantsBySkuForBlocksModuleQueryVariables = Exact<{
  skus?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type GetProductVariantsBySkuForBlocksModuleQuery = {
  __typename?: 'Query';
  productVariant: Array<
    | {
        __typename?: 'ProductVariant';
        price?: number | null | undefined;
        sku: string;
        product?:
          | {
              __typename?: 'Product';
              brand?: string | null | undefined;
              brandSlug?: string | null | undefined;
              familySlug?: string | null | undefined;
              sid: string;
              slug?: string | null | undefined;
              title: string;
            }
          | null
          | undefined;
        productImages?:
          | Array<{ __typename?: 'Image'; url: string } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type CartLineItemsInfoQueryVariables = Exact<{
  skus?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type CartLineItemsInfoQuery = {
  __typename?: 'Query';
  productVariant: Array<
    | {
        __typename?: 'ProductVariant';
        bulky?: boolean | null | undefined;
        compareAtPrice?: number | null | undefined;
        eligiblePromotions: Array<string | null | undefined>;
        finalSale?: boolean | null | undefined;
        fullSid: string;
        hazmat?: boolean | null | undefined;
        inStock?: boolean | null | undefined;
        isDiscountable: boolean;
        isDropshipped: boolean;
        isGiftCard?: boolean | null | undefined;
        isLowStock: boolean;
        isMarketplace?: boolean | null | undefined;
        isProductBundle?: boolean | null | undefined;
        price?: number | null | undefined;
        shoppingStatus?: ShoppingStatus | null | undefined;
        sid: string;
        sku: string;
        title?: string | null | undefined;
        galleryImages: Array<{ __typename?: 'Image'; url: string }>;
        product?:
          | {
              __typename?: 'Product';
              brand?: string | null | undefined;
              brandSlug?: string | null | undefined;
              familySlug?: string | null | undefined;
              sid: string;
              slug?: string | null | undefined;
              title: string;
              categories: Array<{
                __typename?: 'Category';
                category: Array<string>;
              }>;
              options: Array<
                | {
                    __typename?: 'ColorOption';
                    label?: string | null | undefined;
                    name: string;
                    type: OptionType;
                    values: Array<{
                      __typename?: 'ColorOptionValue';
                      brandColor: string;
                      colorFamily: string;
                      label: string;
                      swatchColorCode: string;
                      value: string;
                    }>;
                  }
                | {
                    __typename?: 'StringOption';
                    label?: string | null | undefined;
                    name: string;
                    type: OptionType;
                    values: Array<{
                      __typename?: 'StringOptionValue';
                      label: string;
                      value: string;
                    }>;
                  }
              >;
            }
          | null
          | undefined;
        productBundleItems?:
          | Array<
              | {
                  __typename?: 'ProductBundleItem';
                  sid: string;
                  sku: string;
                  quantity: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        productImages?:
          | Array<{ __typename?: 'Image'; url: string } | null | undefined>
          | null
          | undefined;
        selectedOptions: Array<{
          __typename?: 'SelectedOption';
          name: string;
          value: string;
        }>;
      }
    | null
    | undefined
  >;
  shippingTiers?:
    | Array<{
        __typename?: 'ShippingTier';
        shippingFee: number;
        shippingMethod: ShippingMethod;
        threshold: number;
        type: ShippingTierType;
      }>
    | null
    | undefined;
};

export type RemoveItemsFromCartMutationVariables = Exact<{
  cartLineItemIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
}>;

export type RemoveItemsFromCartMutation = {
  __typename?: 'Mutation';
  removeItemsFromCart?:
    | {
        __typename?: 'RemoveItemsFromCartResponse';
        checkout?: { __typename?: 'Checkout'; id: string } | null | undefined;
        userErrors?:
          | {
              __typename?: 'UserErrors';
              code?: string | null | undefined;
              field?: string | null | undefined;
              message?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SaveForLaterBookmarkListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SaveForLaterBookmarkListQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    saveForLaterBookmarkList?:
      | {
          __typename?: 'BookmarkList';
          id: string;
          name: string;
          bookmarks: {
            __typename?: 'BookmarkPaginatedResponse';
            items: Array<
              | { __typename?: 'ExplorerPostBookmark' }
              | {
                  __typename?: 'ProductBookmark';
                  product: {
                    __typename?: 'Product';
                    brand?: string | null | undefined;
                    sid: string;
                    title: string;
                  };
                }
              | null
              | undefined
            >;
          };
        }
      | null
      | undefined;
  };
};

export type DeleteBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBookmarkMutation = {
  __typename?: 'Mutation';
  deleteBookmark: {
    __typename?: 'DeleteBookmarkMutationResponse';
    id: string;
    success: boolean;
  };
};

export type GetSaveForLaterBookmarkItemsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSaveForLaterBookmarkItemsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    saveForLaterBookmarkList?:
      | {
          __typename?: 'BookmarkList';
          id: string;
          bookmarks: {
            __typename?: 'BookmarkPaginatedResponse';
            items: Array<
              | { __typename?: 'ExplorerPostBookmark'; id: string }
              | { __typename?: 'ProductBookmark'; id: string }
              | null
              | undefined
            >;
          };
        }
      | null
      | undefined;
  };
};

export type GetSaveForLaterItemsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSaveForLaterItemsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    saveForLaterBookmarkList?:
      | {
          __typename?: 'BookmarkList';
          id: string;
          bookmarks: {
            __typename?: 'BookmarkPaginatedResponse';
            items: Array<
              | { __typename?: 'ExplorerPostBookmark' }
              | {
                  __typename?: 'ProductBookmark';
                  id: string;
                  product: {
                    __typename?: 'Product';
                    brand?: string | null | undefined;
                    brandSlug?: string | null | undefined;
                    familySlug?: string | null | undefined;
                    sid: string;
                    slug?: string | null | undefined;
                    title: string;
                    hierarchicalCategories: {
                      __typename?: 'HierarchicalCategories';
                      lvl0: Array<string>;
                    };
                    options: Array<
                      | {
                          __typename?: 'ColorOption';
                          label?: string | null | undefined;
                          name: string;
                          values: Array<{
                            __typename?: 'ColorOptionValue';
                            label: string;
                            value: string;
                          }>;
                        }
                      | {
                          __typename?: 'StringOption';
                          label?: string | null | undefined;
                          name: string;
                          values: Array<{
                            __typename?: 'StringOptionValue';
                            label: string;
                            value: string;
                          }>;
                        }
                    >;
                    variants: Array<{
                      __typename?: 'ProductVariant';
                      compareAtPrice?: number | null | undefined;
                      inStock?: boolean | null | undefined;
                      price?: number | null | undefined;
                      sid: string;
                      sku: string;
                      galleryImages: Array<{
                        __typename?: 'Image';
                        url: string;
                      }>;
                      productBundleItems?:
                        | Array<
                            | {
                                __typename?: 'ProductBundleItem';
                                quantity: number;
                                sid: string;
                                sku: string;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      productImages?:
                        | Array<
                            | { __typename?: 'Image'; url: string }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      selectedOptions: Array<{
                        __typename?: 'SelectedOption';
                        name: string;
                        value: string;
                      }>;
                    }>;
                  };
                  selectedOptions?:
                    | Array<
                        | {
                            __typename?: 'SelectedOption';
                            name: string;
                            value: string;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >;
          };
        }
      | null
      | undefined;
  };
};

export type GetCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCategoriesQuery = {
  __typename?: 'Query';
  categories: Array<
    | {
        __typename?: 'CategoryV2';
        label: string;
        parentSlug?: string | null | undefined;
        slug: string;
      }
    | null
    | undefined
  >;
};

export type ProductBookmarksBySidsQueryVariables = Exact<{
  sids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;

export type ProductBookmarksBySidsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    productBookmarksBySids: Array<
      | {
          __typename?: 'ProductBookmarksBySidResult';
          productSid: string;
          productBookmarks: Array<
            | {
                __typename?: 'ProductBookmark';
                id: string;
                bookmarkList: {
                  __typename?: 'BookmarkList';
                  id: string;
                  name: string;
                };
                selectedOptions?:
                  | Array<
                      | {
                          __typename?: 'SelectedOption';
                          name: string;
                          value: string;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
        }
      | null
      | undefined
    >;
  };
};

export type GetCustomerReviewsQueryVariables = Exact<{
  productSid: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetCustomerReviewsQuery = {
  __typename?: 'Query';
  customerReviewsPage?:
    | {
        __typename?: 'ReviewsPage';
        pageInfo: {
          __typename?: 'ReviewsPageInfo';
          currentPage: number;
          numberOfPages: number;
        };
        reviews: Array<{
          __typename?: 'ReviewV2';
          content: string;
          id: string;
          reviewDate: string;
          score: number;
          sourceName?: string | null | undefined;
          sourceUrl?: string | null | undefined;
          title?: string | null | undefined;
          verifiedBuyer: boolean;
          comment?:
            | {
                __typename?: 'ReviewComment';
                content?: string | null | undefined;
                createdAt: string;
                id: string;
              }
            | null
            | undefined;
          imagesData?:
            | Array<{
                __typename?: 'ReviewImageData';
                id: string;
                thumbUrl?: string | null | undefined;
                originalUrl?: string | null | undefined;
              }>
            | null
            | undefined;
          user?:
            | {
                __typename?: 'ReviewUser';
                displayName?: string | null | undefined;
              }
            | null
            | undefined;
        }>;
        summary: {
          __typename?: 'ReviewSummary';
          averageScore: number;
          totalReview: number;
          ratingDistribution: Array<{
            __typename?: 'Rating';
            count: number;
            score: number;
          }>;
        };
      }
    | null
    | undefined;
};

export type GetRecommendationsDisplayDataBySkuQueryVariables = Exact<{
  skus?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type GetRecommendationsDisplayDataBySkuQuery = {
  __typename?: 'Query';
  productVariant: Array<
    | {
        __typename?: 'ProductVariant';
        compareAtPrice?: number | null | undefined;
        eligiblePromotions: Array<string | null | undefined>;
        isDiscountable: boolean;
        isMarketplace?: boolean | null | undefined;
        price?: number | null | undefined;
        sid: string;
        sku: string;
        galleryImages: Array<{ __typename?: 'Image'; url: string }>;
        modelImages: Array<{ __typename?: 'Image'; url: string }>;
        offerDetails?:
          | {
              __typename?: 'OfferDetails';
              endDate?: string | null | undefined;
              startDate?: string | null | undefined;
            }
          | null
          | undefined;
        product?:
          | {
              __typename?: 'Product';
              brand?: string | null | undefined;
              brandSlug?: string | null | undefined;
              familySlug?: string | null | undefined;
              sid: string;
              slug?: string | null | undefined;
              title: string;
              categories: Array<{
                __typename?: 'Category';
                category: Array<string>;
              }>;
              hierarchicalCategories: {
                __typename?: 'HierarchicalCategories';
                lvl0: Array<string>;
                lvl1: Array<string>;
                lvl2: Array<string>;
                lvl3: Array<string>;
              };
              options: Array<
                | {
                    __typename?: 'ColorOption';
                    name: string;
                    type: OptionType;
                    values: Array<{
                      __typename?: 'ColorOptionValue';
                      brandColor: string;
                      colorFamily: string;
                      label: string;
                      swatchColorCode: string;
                      value: string;
                    }>;
                  }
                | {
                    __typename?: 'StringOption';
                    name: string;
                    type: OptionType;
                    values: Array<{
                      __typename?: 'StringOptionValue';
                      label: string;
                      value: string;
                    }>;
                  }
              >;
              reviewSummary?:
                | {
                    __typename?: 'ReviewSummary';
                    averageScore: number;
                    totalReview: number;
                  }
                | null
                | undefined;
              variants: Array<{ __typename?: 'ProductVariant'; sku: string }>;
            }
          | null
          | undefined;
        productImages?:
          | Array<{ __typename?: 'Image'; url: string } | null | undefined>
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type GetShippingMethodQueryVariables = Exact<{
  lineItems: Array<EstimatedDeliveryLineItem> | EstimatedDeliveryLineItem;
}>;

export type GetShippingMethodQuery = {
  __typename?: 'Query';
  estimatedDeliveryV3: Array<{
    __typename?: 'EstimatedDeliveryV3';
    shippingMethod: ShippingMethod;
  }>;
};

export type GetProductInStockInfoQueryVariables = Exact<{
  productSids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetProductInStockInfoQuery = {
  __typename?: 'Query';
  product: Array<
    | {
        __typename?: 'Product';
        variants: Array<{
          __typename?: 'ProductVariant';
          shoppingStatus?: ShoppingStatus | null | undefined;
          inStock?: boolean | null | undefined;
          isLowStock: boolean;
          sid: string;
          sku: string;
        }>;
      }
    | null
    | undefined
  >;
};

export type GetPdpFromSsrQueryVariables = Exact<{
  productSid: Scalars['String'];
  brandSlug: Scalars['String'];
}>;

export type GetPdpFromSsrQuery = {
  __typename?: 'Query';
  brandReviewsPage?:
    | {
        __typename?: 'ReviewsPage';
        summary: {
          __typename?: 'ReviewSummary';
          averageScore: number;
          totalReview: number;
          ratingDistribution: Array<{
            __typename?: 'Rating';
            count: number;
            score: number;
          }>;
        };
      }
    | null
    | undefined;
  customerReviewsPage?:
    | {
        __typename?: 'ReviewsPage';
        pageInfo: {
          __typename?: 'ReviewsPageInfo';
          currentPage: number;
          numberOfPages: number;
        };
        reviews: Array<{
          __typename?: 'ReviewV2';
          content: string;
          id: string;
          reviewDate: string;
          score: number;
          sourceName?: string | null | undefined;
          sourceUrl?: string | null | undefined;
          title?: string | null | undefined;
          verifiedBuyer: boolean;
          comment?:
            | {
                __typename?: 'ReviewComment';
                content?: string | null | undefined;
                createdAt: string;
                id: string;
              }
            | null
            | undefined;
          imagesData?:
            | Array<{
                __typename?: 'ReviewImageData';
                id: string;
                thumbUrl?: string | null | undefined;
                originalUrl?: string | null | undefined;
              }>
            | null
            | undefined;
          user?:
            | {
                __typename?: 'ReviewUser';
                displayName?: string | null | undefined;
              }
            | null
            | undefined;
        }>;
        summary: {
          __typename?: 'ReviewSummary';
          averageScore: number;
          totalReview: number;
          ratingDistribution: Array<{
            __typename?: 'Rating';
            count: number;
            score: number;
          }>;
        };
      }
    | null
    | undefined;
  categories: Array<
    | {
        __typename?: 'CategoryV2';
        algoliaHierarchy: string;
        hasChildren: boolean;
        label: string;
        level: number;
        parentSlug?: string | null | undefined;
        slug: string;
      }
    | null
    | undefined
  >;
  getProfile?:
    | { __typename?: 'Profile'; biography?: string | null | undefined }
    | null
    | undefined;
  pdpInfo: {
    __typename?: 'PdpInfo';
    product?:
      | {
          __typename?: 'Product';
          brand?: string | null | undefined;
          brandSlug?: string | null | undefined;
          description?: string | null | undefined;
          descriptionLegacy?: string | null | undefined;
          family?: string | null | undefined;
          familySlug?: string | null | undefined;
          sid: string;
          slug?: string | null | undefined;
          title: string;
          brandEntity?:
            | {
                __typename?: 'Brand';
                label?: string | null | undefined;
                slug?: string | null | undefined;
                subscriptionTier?: MerchantSubscriptionTier | null | undefined;
              }
            | null
            | undefined;
          categories: Array<{
            __typename?: 'Category';
            category: Array<string>;
          }>;
          hierarchicalCategories: {
            __typename?: 'HierarchicalCategories';
            lvl0: Array<string>;
            lvl1: Array<string>;
            lvl2: Array<string>;
            lvl3: Array<string>;
          };
          variants: Array<{
            __typename?: 'ProductVariant';
            bulky?: boolean | null | undefined;
            collections?: Array<string | null | undefined> | null | undefined;
            compareAtPrice?: number | null | undefined;
            currencyCode?: string | null | undefined;
            description?: string | null | undefined;
            editorsNote?: string | null | undefined;
            editorsNoteAuthor?: string | null | undefined;
            eligiblePromotions: Array<string | null | undefined>;
            finalSale?: boolean | null | undefined;
            fit?: string | null | undefined;
            fullSid: string;
            ingredients?: string | null | undefined;
            inStock?: boolean | null | undefined;
            isDiscountable: boolean;
            isDropshipped: boolean;
            isGiftCard?: boolean | null | undefined;
            isLowStock: boolean;
            isMarketplace?: boolean | null | undefined;
            isProductBundle?: boolean | null | undefined;
            materialList?: Array<string | null | undefined> | null | undefined;
            materials?: string | null | undefined;
            price?: number | null | undefined;
            publishDate?: string | null | undefined;
            isReplenishable?: boolean | null | undefined;
            requiresShipping?: boolean | null | undefined;
            shoppingStatus?: ShoppingStatus | null | undefined;
            sid: string;
            sku: string;
            termsAndConditions?: string | null | undefined;
            title?: string | null | undefined;
            hazmat?: boolean | null | undefined;
            weight?: number | null | undefined;
            weightUnit?: WeightUnit | null | undefined;
            filterables?:
              | {
                  __typename?: 'Filterables';
                  responsible?:
                    | Array<string | null | undefined>
                    | null
                    | undefined;
                }
              | null
              | undefined;
            galleryAssets: Array<
              | {
                  __typename?: 'Image';
                  url: string;
                  type?: AssetType | null | undefined;
                  ecommModel?:
                    | {
                        __typename?: 'EcommModel';
                        bust?: string | null | undefined;
                        chest?: string | null | undefined;
                        height?: string | null | undefined;
                        hip?: string | null | undefined;
                        inseam?: string | null | undefined;
                        waist?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  ecommModelSize?:
                    | {
                        __typename?: 'EcommModelSize';
                        name?: string | null | undefined;
                        value?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | {
                  __typename?: 'YoutubeVideo';
                  url: string;
                  type?: AssetType | null | undefined;
                }
              | {
                  __typename?: 'Video';
                  cloudinaryPublicId?: string | null | undefined;
                  url: string;
                  type?: AssetType | null | undefined;
                }
            >;
            galleryImages: Array<{ __typename?: 'Image'; url: string }>;
            howToVideos?:
              | Array<
                  | {
                      __typename?: 'YoutubeVideo';
                      url: string;
                      type?: AssetType | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            modelImages: Array<{ __typename?: 'Image'; url: string }>;
            offerDetails?:
              | {
                  __typename?: 'OfferDetails';
                  endDate?: string | null | undefined;
                  offerType?: OfferType | null | undefined;
                  startDate?: string | null | undefined;
                }
              | null
              | undefined;
            productBundleItems?:
              | Array<
                  | {
                      __typename?: 'ProductBundleItem';
                      quantity: number;
                      sid: string;
                      sku: string;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            productImages?:
              | Array<{ __typename?: 'Image'; url: string } | null | undefined>
              | null
              | undefined;
            selectedOptions: Array<{
              __typename?: 'SelectedOption';
              name: string;
              value: string;
            }>;
            specifications?:
              | Array<
                  | {
                      __typename?: 'ProductSpecification';
                      label: string;
                      value: string;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }>;
          options: Array<
            | {
                __typename?: 'ColorOption';
                label?: string | null | undefined;
                name: string;
                type: OptionType;
                values: Array<{
                  __typename?: 'ColorOptionValue';
                  brandColor: string;
                  colorFamily: string;
                  label: string;
                  swatchColorCode: string;
                  value: string;
                }>;
              }
            | {
                __typename?: 'StringOption';
                label?: string | null | undefined;
                name: string;
                type: OptionType;
                values: Array<{
                  __typename?: 'StringOptionValue';
                  label: string;
                  value: string;
                }>;
              }
          >;
        }
      | null
      | undefined;
    status: {
      __typename?: 'PdpInfoStatus';
      code: string;
      newSid?: string | null | undefined;
    };
  };
};

export type GetProductPdpQueryVariables = Exact<{
  productSid: Scalars['String'];
  omitDiscontinued?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetProductPdpQuery = {
  __typename?: 'Query';
  product: Array<
    | {
        __typename?: 'Product';
        brand?: string | null | undefined;
        brandSlug?: string | null | undefined;
        description?: string | null | undefined;
        descriptionLegacy?: string | null | undefined;
        family?: string | null | undefined;
        familySlug?: string | null | undefined;
        sid: string;
        slug?: string | null | undefined;
        title: string;
        brandEntity?:
          | {
              __typename?: 'Brand';
              label?: string | null | undefined;
              slug?: string | null | undefined;
              subscriptionTier?: MerchantSubscriptionTier | null | undefined;
            }
          | null
          | undefined;
        categories: Array<{ __typename?: 'Category'; category: Array<string> }>;
        hierarchicalCategories: {
          __typename?: 'HierarchicalCategories';
          lvl0: Array<string>;
          lvl1: Array<string>;
          lvl2: Array<string>;
          lvl3: Array<string>;
        };
        variants: Array<{
          __typename?: 'ProductVariant';
          bulky?: boolean | null | undefined;
          collections?: Array<string | null | undefined> | null | undefined;
          compareAtPrice?: number | null | undefined;
          currencyCode?: string | null | undefined;
          description?: string | null | undefined;
          editorsNote?: string | null | undefined;
          editorsNoteAuthor?: string | null | undefined;
          eligiblePromotions: Array<string | null | undefined>;
          finalSale?: boolean | null | undefined;
          fit?: string | null | undefined;
          fullSid: string;
          ingredients?: string | null | undefined;
          inStock?: boolean | null | undefined;
          isDiscountable: boolean;
          isDropshipped: boolean;
          isGiftCard?: boolean | null | undefined;
          isLowStock: boolean;
          isMarketplace?: boolean | null | undefined;
          isProductBundle?: boolean | null | undefined;
          materialList?: Array<string | null | undefined> | null | undefined;
          materials?: string | null | undefined;
          price?: number | null | undefined;
          publishDate?: string | null | undefined;
          isReplenishable?: boolean | null | undefined;
          requiresShipping?: boolean | null | undefined;
          shoppingStatus?: ShoppingStatus | null | undefined;
          sid: string;
          sku: string;
          termsAndConditions?: string | null | undefined;
          title?: string | null | undefined;
          hazmat?: boolean | null | undefined;
          weight?: number | null | undefined;
          weightUnit?: WeightUnit | null | undefined;
          filterables?:
            | {
                __typename?: 'Filterables';
                responsible?:
                  | Array<string | null | undefined>
                  | null
                  | undefined;
              }
            | null
            | undefined;
          galleryAssets: Array<
            | {
                __typename?: 'Image';
                url: string;
                type?: AssetType | null | undefined;
                ecommModel?:
                  | {
                      __typename?: 'EcommModel';
                      bust?: string | null | undefined;
                      chest?: string | null | undefined;
                      height?: string | null | undefined;
                      hip?: string | null | undefined;
                      inseam?: string | null | undefined;
                      waist?: string | null | undefined;
                    }
                  | null
                  | undefined;
                ecommModelSize?:
                  | {
                      __typename?: 'EcommModelSize';
                      name?: string | null | undefined;
                      value?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | {
                __typename?: 'YoutubeVideo';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'Video';
                cloudinaryPublicId?: string | null | undefined;
                url: string;
                type?: AssetType | null | undefined;
              }
          >;
          galleryImages: Array<{ __typename?: 'Image'; url: string }>;
          howToVideos?:
            | Array<
                | {
                    __typename?: 'YoutubeVideo';
                    url: string;
                    type?: AssetType | null | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          modelImages: Array<{ __typename?: 'Image'; url: string }>;
          offerDetails?:
            | {
                __typename?: 'OfferDetails';
                endDate?: string | null | undefined;
                offerType?: OfferType | null | undefined;
                startDate?: string | null | undefined;
              }
            | null
            | undefined;
          productBundleItems?:
            | Array<
                | {
                    __typename?: 'ProductBundleItem';
                    quantity: number;
                    sid: string;
                    sku: string;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          productImages?:
            | Array<{ __typename?: 'Image'; url: string } | null | undefined>
            | null
            | undefined;
          selectedOptions: Array<{
            __typename?: 'SelectedOption';
            name: string;
            value: string;
          }>;
          specifications?:
            | Array<
                | {
                    __typename?: 'ProductSpecification';
                    label: string;
                    value: string;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        }>;
        options: Array<
          | {
              __typename?: 'ColorOption';
              label?: string | null | undefined;
              name: string;
              type: OptionType;
              values: Array<{
                __typename?: 'ColorOptionValue';
                brandColor: string;
                colorFamily: string;
                label: string;
                swatchColorCode: string;
                value: string;
              }>;
            }
          | {
              __typename?: 'StringOption';
              label?: string | null | undefined;
              name: string;
              type: OptionType;
              values: Array<{
                __typename?: 'StringOptionValue';
                label: string;
                value: string;
              }>;
            }
        >;
      }
    | null
    | undefined
  >;
};

export type GetPdpbtfQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  modelId: Scalars['String'];
  modelVariantId: Scalars['String'];
  productIds:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
}>;

export type GetPdpbtfQuery = {
  __typename?: 'Query';
  recommendedMultiKeyProducts: Array<
    | {
        __typename?: 'Product';
        brand?: string | null | undefined;
        brandSlug?: string | null | undefined;
        familySlug?: string | null | undefined;
        sid: string;
        slug?: string | null | undefined;
        title: string;
        categories: Array<{ __typename?: 'Category'; category: Array<string> }>;
        hierarchicalCategories: {
          __typename?: 'HierarchicalCategories';
          lvl0: Array<string>;
          lvl1: Array<string>;
          lvl2: Array<string>;
          lvl3: Array<string>;
        };
        options: Array<
          | {
              __typename?: 'ColorOption';
              type: OptionType;
              name: string;
              values: Array<{
                __typename?: 'ColorOptionValue';
                label: string;
                value: string;
              }>;
            }
          | {
              __typename?: 'StringOption';
              type: OptionType;
              name: string;
              values: Array<{
                __typename?: 'StringOptionValue';
                label: string;
                value: string;
              }>;
            }
        >;
        reviewSummary?:
          | {
              __typename?: 'ReviewSummary';
              averageScore: number;
              totalReview: number;
            }
          | null
          | undefined;
        variants: Array<{
          __typename?: 'ProductVariant';
          compareAtPrice?: number | null | undefined;
          eligiblePromotions: Array<string | null | undefined>;
          inStock?: boolean | null | undefined;
          isDiscountable: boolean;
          isMarketplace?: boolean | null | undefined;
          isProductBundle?: boolean | null | undefined;
          price?: number | null | undefined;
          sid: string;
          sku: string;
          galleryImages: Array<{ __typename?: 'Image'; url: string }>;
          modelImages: Array<{ __typename?: 'Image'; url: string }>;
          offerDetails?:
            | {
                __typename?: 'OfferDetails';
                offerType?: OfferType | null | undefined;
                startDate?: string | null | undefined;
              }
            | null
            | undefined;
          productImages?:
            | Array<{ __typename?: 'Image'; url: string } | null | undefined>
            | null
            | undefined;
          selectedOptions: Array<{
            __typename?: 'SelectedOption';
            name: string;
            value: string;
          }>;
        }>;
      }
    | null
    | undefined
  >;
};

export type GetCustomerReviewsPageQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  productSid: Scalars['String'];
}>;

export type GetCustomerReviewsPageQuery = {
  __typename?: 'Query';
  customerReviewsPage?:
    | {
        __typename?: 'ReviewsPage';
        pageInfo: {
          __typename?: 'ReviewsPageInfo';
          currentPage: number;
          numberOfPages: number;
        };
        reviews: Array<{
          __typename?: 'ReviewV2';
          content: string;
          id: string;
          reviewDate: string;
          score: number;
          sourceName?: string | null | undefined;
          sourceUrl?: string | null | undefined;
          title?: string | null | undefined;
          verifiedBuyer: boolean;
          comment?:
            | {
                __typename?: 'ReviewComment';
                content?: string | null | undefined;
                createdAt: string;
                id: string;
              }
            | null
            | undefined;
          imagesData?:
            | Array<{
                __typename?: 'ReviewImageData';
                id: string;
                thumbUrl?: string | null | undefined;
                originalUrl?: string | null | undefined;
              }>
            | null
            | undefined;
          user?:
            | {
                __typename?: 'ReviewUser';
                displayName?: string | null | undefined;
              }
            | null
            | undefined;
        }>;
        summary: {
          __typename?: 'ReviewSummary';
          averageScore: number;
          totalReview: number;
          ratingDistribution: Array<{
            __typename?: 'Rating';
            count: number;
            score: number;
          }>;
        };
      }
    | null
    | undefined;
};

export type WebSetReviewWrittenForProductAndOrderIdMutationVariables = Exact<{
  productSid: Scalars['String'];
  orderId: Scalars['String'];
}>;

export type WebSetReviewWrittenForProductAndOrderIdMutation = {
  __typename?: 'Mutation';
  setReviewWrittenForProductAndOrderId: {
    __typename?: 'GenericMutationResponse';
    success: boolean;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; code: number; message: string }
      | null
      | undefined
    >;
  };
};

export type GetCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    createdAt: string;
    email: string;
    firstName?: string | null | undefined;
    id: string;
    lastName?: string | null | undefined;
    multipassUrl?: string | null | undefined;
    shopifyCheckoutId?: string | null | undefined;
    yotpoId?: string | null | undefined;
    profiles?:
      | Array<
          | {
              __typename?: 'Profile';
              biography?: string | null | undefined;
              brandSlug?: string | null | undefined;
              displayName?: string | null | undefined;
              facebookName?: string | null | undefined;
              followerCount?: number | null | undefined;
              followingCount?: number | null | undefined;
              id: string;
              instagramName?: string | null | undefined;
              isFollowing?: boolean | null | undefined;
              isProfileOwner?: boolean | null | undefined;
              likeCount?: number | null | undefined;
              metaDescription?: string | null | undefined;
              metaTitle?: string | null | undefined;
              twitterName?: string | null | undefined;
              type: ExplorerContentOwnerType;
              username?: string | null | undefined;
              coverImageAsset?:
                | { __typename?: 'Image'; url: string }
                | { __typename?: 'YoutubeVideo'; url: string }
                | { __typename?: 'Video'; url: string }
                | null
                | undefined;
              creatorInfo?:
                | {
                    __typename?: 'CreatorInfo';
                    isCreator: boolean;
                    status?: CreatorStatus | null | undefined;
                  }
                | null
                | undefined;
              profileAsset?:
                | { __typename?: 'Image'; url: string }
                | { __typename?: 'YoutubeVideo'; url: string }
                | { __typename?: 'Video'; url: string }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type ProductBookmarkFragment = {
  __typename?: 'ProductBookmark';
  id: string;
  bookmarkList: { __typename?: 'BookmarkList'; id: string; name: string };
  product: {
    __typename?: 'Product';
    brand?: string | null | undefined;
    brandSlug?: string | null | undefined;
    family?: string | null | undefined;
    familySlug?: string | null | undefined;
    sid: string;
    slug?: string | null | undefined;
    title: string;
    categories: Array<{ __typename?: 'Category'; category: Array<string> }>;
    options: Array<
      | {
          __typename?: 'ColorOption';
          label?: string | null | undefined;
          name: string;
          primary?: boolean | null | undefined;
          type: OptionType;
          values: Array<{
            __typename?: 'ColorOptionValue';
            brandColor: string;
            colorFamily: string;
            swatchColorCode: string;
            label: string;
            sortOrder: number;
            value: string;
          }>;
        }
      | {
          __typename?: 'StringOption';
          label?: string | null | undefined;
          name: string;
          primary?: boolean | null | undefined;
          type: OptionType;
          values: Array<{
            __typename?: 'StringOptionValue';
            label: string;
            sortOrder: number;
            value: string;
          }>;
        }
    >;
    variants: Array<{
      __typename?: 'ProductVariant';
      compareAtPrice?: number | null | undefined;
      currencyCode?: string | null | undefined;
      fullSid: string;
      inStock?: boolean | null | undefined;
      price?: number | null | undefined;
      sku: string;
      sid: string;
      shoppingStatus?: ShoppingStatus | null | undefined;
      title?: string | null | undefined;
      filterables?:
        | {
            __typename?: 'Filterables';
            responsible?: Array<string | null | undefined> | null | undefined;
          }
        | null
        | undefined;
      galleryImages: Array<{ __typename?: 'Image'; url: string }>;
      selectedOptions: Array<{
        __typename?: 'SelectedOption';
        name: string;
        value: string;
      }>;
    }>;
  };
  selectedOptions?:
    | Array<
        | { __typename?: 'SelectedOption'; name: string; value: string }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type SelectedOptionFragment = {
  __typename?: 'SelectedOption';
  name: string;
  value: string;
};

type ProductOptionPdp_ColorOption_Fragment = {
  __typename?: 'ColorOption';
  label?: string | null | undefined;
  name: string;
  type: OptionType;
  values: Array<{
    __typename?: 'ColorOptionValue';
    brandColor: string;
    colorFamily: string;
    label: string;
    swatchColorCode: string;
    value: string;
  }>;
};

type ProductOptionPdp_StringOption_Fragment = {
  __typename?: 'StringOption';
  label?: string | null | undefined;
  name: string;
  type: OptionType;
  values: Array<{
    __typename?: 'StringOptionValue';
    label: string;
    value: string;
  }>;
};

export type ProductOptionPdpFragment =
  | ProductOptionPdp_ColorOption_Fragment
  | ProductOptionPdp_StringOption_Fragment;

export type ProductVariantPdpFragment = {
  __typename?: 'ProductVariant';
  bulky?: boolean | null | undefined;
  collections?: Array<string | null | undefined> | null | undefined;
  compareAtPrice?: number | null | undefined;
  currencyCode?: string | null | undefined;
  description?: string | null | undefined;
  editorsNote?: string | null | undefined;
  editorsNoteAuthor?: string | null | undefined;
  eligiblePromotions: Array<string | null | undefined>;
  finalSale?: boolean | null | undefined;
  fit?: string | null | undefined;
  fullSid: string;
  ingredients?: string | null | undefined;
  inStock?: boolean | null | undefined;
  isDiscountable: boolean;
  isDropshipped: boolean;
  isGiftCard?: boolean | null | undefined;
  isLowStock: boolean;
  isMarketplace?: boolean | null | undefined;
  isProductBundle?: boolean | null | undefined;
  materialList?: Array<string | null | undefined> | null | undefined;
  materials?: string | null | undefined;
  price?: number | null | undefined;
  publishDate?: string | null | undefined;
  isReplenishable?: boolean | null | undefined;
  requiresShipping?: boolean | null | undefined;
  shoppingStatus?: ShoppingStatus | null | undefined;
  sid: string;
  sku: string;
  termsAndConditions?: string | null | undefined;
  title?: string | null | undefined;
  hazmat?: boolean | null | undefined;
  weight?: number | null | undefined;
  weightUnit?: WeightUnit | null | undefined;
  filterables?:
    | {
        __typename?: 'Filterables';
        responsible?: Array<string | null | undefined> | null | undefined;
      }
    | null
    | undefined;
  galleryAssets: Array<
    | {
        __typename?: 'Image';
        url: string;
        type?: AssetType | null | undefined;
        ecommModel?:
          | {
              __typename?: 'EcommModel';
              bust?: string | null | undefined;
              chest?: string | null | undefined;
              height?: string | null | undefined;
              hip?: string | null | undefined;
              inseam?: string | null | undefined;
              waist?: string | null | undefined;
            }
          | null
          | undefined;
        ecommModelSize?:
          | {
              __typename?: 'EcommModelSize';
              name?: string | null | undefined;
              value?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | {
        __typename?: 'YoutubeVideo';
        url: string;
        type?: AssetType | null | undefined;
      }
    | {
        __typename?: 'Video';
        cloudinaryPublicId?: string | null | undefined;
        url: string;
        type?: AssetType | null | undefined;
      }
  >;
  galleryImages: Array<{ __typename?: 'Image'; url: string }>;
  howToVideos?:
    | Array<
        | {
            __typename?: 'YoutubeVideo';
            url: string;
            type?: AssetType | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  modelImages: Array<{ __typename?: 'Image'; url: string }>;
  offerDetails?:
    | {
        __typename?: 'OfferDetails';
        endDate?: string | null | undefined;
        offerType?: OfferType | null | undefined;
        startDate?: string | null | undefined;
      }
    | null
    | undefined;
  productBundleItems?:
    | Array<
        | {
            __typename?: 'ProductBundleItem';
            quantity: number;
            sid: string;
            sku: string;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  productImages?:
    | Array<{ __typename?: 'Image'; url: string } | null | undefined>
    | null
    | undefined;
  selectedOptions: Array<{
    __typename?: 'SelectedOption';
    name: string;
    value: string;
  }>;
  specifications?:
    | Array<
        | { __typename?: 'ProductSpecification'; label: string; value: string }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ProductPdpFragment = {
  __typename?: 'Product';
  brand?: string | null | undefined;
  brandSlug?: string | null | undefined;
  description?: string | null | undefined;
  descriptionLegacy?: string | null | undefined;
  family?: string | null | undefined;
  familySlug?: string | null | undefined;
  sid: string;
  slug?: string | null | undefined;
  title: string;
  brandEntity?:
    | {
        __typename?: 'Brand';
        label?: string | null | undefined;
        slug?: string | null | undefined;
        subscriptionTier?: MerchantSubscriptionTier | null | undefined;
      }
    | null
    | undefined;
  categories: Array<{ __typename?: 'Category'; category: Array<string> }>;
  hierarchicalCategories: {
    __typename?: 'HierarchicalCategories';
    lvl0: Array<string>;
    lvl1: Array<string>;
    lvl2: Array<string>;
    lvl3: Array<string>;
  };
  variants: Array<{
    __typename?: 'ProductVariant';
    bulky?: boolean | null | undefined;
    collections?: Array<string | null | undefined> | null | undefined;
    compareAtPrice?: number | null | undefined;
    currencyCode?: string | null | undefined;
    description?: string | null | undefined;
    editorsNote?: string | null | undefined;
    editorsNoteAuthor?: string | null | undefined;
    eligiblePromotions: Array<string | null | undefined>;
    finalSale?: boolean | null | undefined;
    fit?: string | null | undefined;
    fullSid: string;
    ingredients?: string | null | undefined;
    inStock?: boolean | null | undefined;
    isDiscountable: boolean;
    isDropshipped: boolean;
    isGiftCard?: boolean | null | undefined;
    isLowStock: boolean;
    isMarketplace?: boolean | null | undefined;
    isProductBundle?: boolean | null | undefined;
    materialList?: Array<string | null | undefined> | null | undefined;
    materials?: string | null | undefined;
    price?: number | null | undefined;
    publishDate?: string | null | undefined;
    isReplenishable?: boolean | null | undefined;
    requiresShipping?: boolean | null | undefined;
    shoppingStatus?: ShoppingStatus | null | undefined;
    sid: string;
    sku: string;
    termsAndConditions?: string | null | undefined;
    title?: string | null | undefined;
    hazmat?: boolean | null | undefined;
    weight?: number | null | undefined;
    weightUnit?: WeightUnit | null | undefined;
    filterables?:
      | {
          __typename?: 'Filterables';
          responsible?: Array<string | null | undefined> | null | undefined;
        }
      | null
      | undefined;
    galleryAssets: Array<
      | {
          __typename?: 'Image';
          url: string;
          type?: AssetType | null | undefined;
          ecommModel?:
            | {
                __typename?: 'EcommModel';
                bust?: string | null | undefined;
                chest?: string | null | undefined;
                height?: string | null | undefined;
                hip?: string | null | undefined;
                inseam?: string | null | undefined;
                waist?: string | null | undefined;
              }
            | null
            | undefined;
          ecommModelSize?:
            | {
                __typename?: 'EcommModelSize';
                name?: string | null | undefined;
                value?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | {
          __typename?: 'YoutubeVideo';
          url: string;
          type?: AssetType | null | undefined;
        }
      | {
          __typename?: 'Video';
          cloudinaryPublicId?: string | null | undefined;
          url: string;
          type?: AssetType | null | undefined;
        }
    >;
    galleryImages: Array<{ __typename?: 'Image'; url: string }>;
    howToVideos?:
      | Array<
          | {
              __typename?: 'YoutubeVideo';
              url: string;
              type?: AssetType | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    modelImages: Array<{ __typename?: 'Image'; url: string }>;
    offerDetails?:
      | {
          __typename?: 'OfferDetails';
          endDate?: string | null | undefined;
          offerType?: OfferType | null | undefined;
          startDate?: string | null | undefined;
        }
      | null
      | undefined;
    productBundleItems?:
      | Array<
          | {
              __typename?: 'ProductBundleItem';
              quantity: number;
              sid: string;
              sku: string;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    productImages?:
      | Array<{ __typename?: 'Image'; url: string } | null | undefined>
      | null
      | undefined;
    selectedOptions: Array<{
      __typename?: 'SelectedOption';
      name: string;
      value: string;
    }>;
    specifications?:
      | Array<
          | {
              __typename?: 'ProductSpecification';
              label: string;
              value: string;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  }>;
  options: Array<
    | {
        __typename?: 'ColorOption';
        label?: string | null | undefined;
        name: string;
        type: OptionType;
        values: Array<{
          __typename?: 'ColorOptionValue';
          brandColor: string;
          colorFamily: string;
          label: string;
          swatchColorCode: string;
          value: string;
        }>;
      }
    | {
        __typename?: 'StringOption';
        label?: string | null | undefined;
        name: string;
        type: OptionType;
        values: Array<{
          __typename?: 'StringOptionValue';
          label: string;
          value: string;
        }>;
      }
  >;
};

export type PostBookmarkFragment = {
  __typename?: 'ExplorerPostBookmark';
  bookmarkedEntityId: string;
  id: string;
  explorerPost?:
    | {
        __typename?: 'ExplorerPost';
        description?: string | null | undefined;
        headline?: string | null | undefined;
        postId: string;
        type?: ExplorerPostType | null | undefined;
        assets?:
          | Array<
              | {
                  __typename?: 'ExplorerImageAsset';
                  id?: string | null | undefined;
                  type?: ExplorerAssetType | null | undefined;
                  url?: string | null | undefined;
                }
              | {
                  __typename?: 'ExplorerVideoAsset';
                  id?: string | null | undefined;
                  type?: ExplorerAssetType | null | undefined;
                  url?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        profile?:
          | {
              __typename?: 'Profile';
              brandSlug?: string | null | undefined;
              id: string;
              type: ExplorerContentOwnerType;
              username?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RelatedProductFieldsFragment = {
  __typename?: 'Product';
  brand?: string | null | undefined;
  brandSlug?: string | null | undefined;
  description?: string | null | undefined;
  familySlug?: string | null | undefined;
  sid: string;
  slug?: string | null | undefined;
  title: string;
  variants: Array<{
    __typename?: 'ProductVariant';
    currencyCode?: string | null | undefined;
    inStock?: boolean | null | undefined;
    price?: number | null | undefined;
    shoppingStatus?: ShoppingStatus | null | undefined;
    sid: string;
    sku: string;
    title?: string | null | undefined;
    filterables?:
      | {
          __typename?: 'Filterables';
          responsible?: Array<string | null | undefined> | null | undefined;
        }
      | null
      | undefined;
    galleryImages: Array<{ __typename?: 'Image'; url: string }>;
    offerDetails?:
      | {
          __typename?: 'OfferDetails';
          endDate?: string | null | undefined;
          offerType?: OfferType | null | undefined;
          startDate?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type ImageDisplayUrlFragment = { __typename?: 'Image'; url: string };

export type CustomerFieldsFragment = {
  __typename?: 'Customer';
  createdAt: string;
  email: string;
  firstName?: string | null | undefined;
  id: string;
  lastName?: string | null | undefined;
  multipassUrl?: string | null | undefined;
  shopifyCheckoutId?: string | null | undefined;
  yotpoId?: string | null | undefined;
};

export type ProfileFieldsFragment = {
  __typename?: 'Profile';
  biography?: string | null | undefined;
  brandSlug?: string | null | undefined;
  displayName?: string | null | undefined;
  facebookName?: string | null | undefined;
  followerCount?: number | null | undefined;
  followingCount?: number | null | undefined;
  id: string;
  instagramName?: string | null | undefined;
  isFollowing?: boolean | null | undefined;
  isProfileOwner?: boolean | null | undefined;
  likeCount?: number | null | undefined;
  metaDescription?: string | null | undefined;
  metaTitle?: string | null | undefined;
  twitterName?: string | null | undefined;
  type: ExplorerContentOwnerType;
  username?: string | null | undefined;
  coverImageAsset?:
    | { __typename?: 'Image'; url: string }
    | { __typename?: 'YoutubeVideo'; url: string }
    | { __typename?: 'Video'; url: string }
    | null
    | undefined;
  creatorInfo?:
    | {
        __typename?: 'CreatorInfo';
        isCreator: boolean;
        status?: CreatorStatus | null | undefined;
      }
    | null
    | undefined;
  profileAsset?:
    | { __typename?: 'Image'; url: string }
    | { __typename?: 'YoutubeVideo'; url: string }
    | { __typename?: 'Video'; url: string }
    | null
    | undefined;
};

export type ReviewsPageFieldsFragment = {
  __typename?: 'ReviewsPage';
  pageInfo: {
    __typename?: 'ReviewsPageInfo';
    currentPage: number;
    numberOfPages: number;
  };
  reviews: Array<{
    __typename?: 'ReviewV2';
    content: string;
    id: string;
    reviewDate: string;
    score: number;
    sourceName?: string | null | undefined;
    sourceUrl?: string | null | undefined;
    title?: string | null | undefined;
    verifiedBuyer: boolean;
    comment?:
      | {
          __typename?: 'ReviewComment';
          content?: string | null | undefined;
          createdAt: string;
          id: string;
        }
      | null
      | undefined;
    imagesData?:
      | Array<{
          __typename?: 'ReviewImageData';
          id: string;
          thumbUrl?: string | null | undefined;
          originalUrl?: string | null | undefined;
        }>
      | null
      | undefined;
    user?:
      | { __typename?: 'ReviewUser'; displayName?: string | null | undefined }
      | null
      | undefined;
  }>;
  summary: {
    __typename?: 'ReviewSummary';
    averageScore: number;
    totalReview: number;
    ratingDistribution: Array<{
      __typename?: 'Rating';
      count: number;
      score: number;
    }>;
  };
};

export type CreateProductBookmarkMutationVariables = Exact<{
  input: ProductBookmarkInput;
}>;

export type CreateProductBookmarkMutation = {
  __typename?: 'Mutation';
  createProductBookmark: {
    __typename?: 'BookmarkMutationResponse';
    success: boolean;
    bookmark?:
      | { __typename?: 'ExplorerPostBookmark'; id: string }
      | {
          __typename?: 'ProductBookmark';
          id: string;
          bookmarkList: {
            __typename?: 'BookmarkList';
            id: string;
            name: string;
          };
          selectedOptions?:
            | Array<
                | { __typename?: 'SelectedOption'; name: string; value: string }
                | null
                | undefined
              >
            | null
            | undefined;
        }
      | null
      | undefined;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type DeleteBookmarkFromBookmarkButtonMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBookmarkFromBookmarkButtonMutation = {
  __typename?: 'Mutation';
  deleteBookmark: {
    __typename?: 'DeleteBookmarkMutationResponse';
    id: string;
    success: boolean;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type CreateBookmarkListMutationVariables = Exact<{
  input: BookmarkListInput;
}>;

export type CreateBookmarkListMutation = {
  __typename?: 'Mutation';
  createBookmarkList: {
    __typename?: 'BookmarkListMutationResponse';
    success: boolean;
    bookmarkList?:
      | { __typename?: 'BookmarkList'; id: string }
      | null
      | undefined;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type DeleteBookmarkListMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBookmarkListMutation = {
  __typename?: 'Mutation';
  deleteBookmarkList: {
    __typename?: 'DeleteBookmarkMutationResponse';
    success: boolean;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type GetBookmarkListsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetBookmarkListsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    bookmarkLists: {
      __typename?: 'BookmarkListPaginatedResponse';
      items: Array<
        | {
            __typename?: 'BookmarkList';
            id: string;
            name: string;
            public: boolean;
            slug: string;
            bookmarks: {
              __typename?: 'BookmarkPaginatedResponse';
              items: Array<
                | {
                    __typename?: 'ExplorerPostBookmark';
                    type: BookmarkType;
                    explorerPost?:
                      | {
                          __typename?: 'ExplorerPost';
                          assets?:
                            | Array<
                                | {
                                    __typename?: 'ExplorerImageAsset';
                                    type?: ExplorerAssetType | null | undefined;
                                    url?: string | null | undefined;
                                  }
                                | {
                                    __typename?: 'ExplorerVideoAsset';
                                    type?: ExplorerAssetType | null | undefined;
                                    url?: string | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | {
                    __typename?: 'ProductBookmark';
                    type: BookmarkType;
                    product: {
                      __typename?: 'Product';
                      variants: Array<{
                        __typename?: 'ProductVariant';
                        galleryAssets: Array<
                          | {
                              __typename?: 'Image';
                              type?: AssetType | null | undefined;
                              url: string;
                            }
                          | {
                              __typename?: 'YoutubeVideo';
                              type?: AssetType | null | undefined;
                              url: string;
                            }
                          | {
                              __typename?: 'Video';
                              type?: AssetType | null | undefined;
                              url: string;
                            }
                        >;
                        selectedOptions: Array<{
                          __typename?: 'SelectedOption';
                          name: string;
                          value: string;
                        }>;
                      }>;
                    };
                    selectedOptions?:
                      | Array<
                          | {
                              __typename?: 'SelectedOption';
                              name: string;
                              value: string;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >;
              pagination: { __typename?: 'Pagination'; totalCount: number };
            };
          }
        | null
        | undefined
      >;
      pagination: {
        __typename?: 'Pagination';
        cursor?: string | null | undefined;
        hasMore: boolean;
        totalCount: number;
      };
    };
  };
};

export type GetBookmarkListsModalQueryVariables = Exact<{
  filter: ProductBookmarkInput;
}>;

export type GetBookmarkListsModalQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    bookmarkLists: {
      __typename?: 'BookmarkListPaginatedResponse';
      items: Array<
        | {
            __typename?: 'BookmarkList';
            id: string;
            name: string;
            public: boolean;
            slug: string;
            bookmarks: {
              __typename?: 'BookmarkPaginatedResponse';
              pagination: { __typename?: 'Pagination'; totalCount: number };
            };
            coverPhoto?:
              | { __typename?: 'Image'; url: string }
              | null
              | undefined;
            filteredBookmark?:
              | { __typename?: 'ExplorerPostBookmark'; id: string }
              | { __typename?: 'ProductBookmark'; id: string }
              | null
              | undefined;
          }
        | null
        | undefined
      >;
    };
  };
};

export type UpdateBookmarkListMutationVariables = Exact<{
  input: UpdateBookmarkListInput;
}>;

export type UpdateBookmarkListMutation = {
  __typename?: 'Mutation';
  updateBookmarkList: {
    __typename?: 'BookmarkListMutationResponse';
    success: boolean;
    bookmarkList?:
      | { __typename?: 'BookmarkList'; id: string }
      | null
      | undefined;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type ToggleBookmarkListPrivacyMutationMutationVariables = Exact<{
  input: UpdateBookmarkListInput;
}>;

export type ToggleBookmarkListPrivacyMutationMutation = {
  __typename?: 'Mutation';
  updateBookmarkList: {
    __typename?: 'BookmarkListMutationResponse';
    success: boolean;
    bookmarkList?:
      | {
          __typename?: 'BookmarkList';
          id: string;
          name: string;
          public: boolean;
        }
      | null
      | undefined;
    validationErrors: Array<
      | { __typename?: 'ValidationError'; field: string; message: string }
      | null
      | undefined
    >;
  };
};

export type GetRecommendedPostsQueryVariables = Exact<{
  postTypes?: InputMaybe<
    Array<InputMaybe<ExplorerPostType>> | InputMaybe<ExplorerPostType>
  >;
  productIds:
    | Array<InputMaybe<Scalars['String']>>
    | InputMaybe<Scalars['String']>;
}>;

export type GetRecommendedPostsQuery = {
  __typename?: 'Query';
  productPosts: {
    __typename?: 'ExplorerPostResponse';
    posts: Array<
      | {
          __typename?: 'ExplorerPost';
          description?: string | null | undefined;
          headline?: string | null | undefined;
          id?: string | null | undefined;
          type?: ExplorerPostType | null | undefined;
          transcriptUrl?: string | null | undefined;
          assets?:
            | Array<
                | {
                    __typename?: 'ExplorerImageAsset';
                    id?: string | null | undefined;
                    type?: ExplorerAssetType | null | undefined;
                    url?: string | null | undefined;
                    dimensions?:
                      | {
                          __typename?: 'AssetDimensions';
                          height?: number | null | undefined;
                          width?: number | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | {
                    __typename?: 'ExplorerVideoAsset';
                    id?: string | null | undefined;
                    type?: ExplorerAssetType | null | undefined;
                    url?: string | null | undefined;
                    videoMetadata?:
                      | {
                          __typename?: 'VideoMetadata';
                          longformUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    dimensions?:
                      | {
                          __typename?: 'AssetDimensions';
                          height?: number | null | undefined;
                          width?: number | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          owner: {
            __typename?: 'ExplorerContentOwner';
            brandSlug?: string | null | undefined;
            ownerId: string;
            ownerName: string;
            type: ExplorerContentOwnerType;
          };
          profile?:
            | {
                __typename?: 'Profile';
                brandSlug?: string | null | undefined;
                displayName?: string | null | undefined;
                id: string;
                isFollowing?: boolean | null | undefined;
                isProfileOwner?: boolean | null | undefined;
                type: ExplorerContentOwnerType;
                username?: string | null | undefined;
                profileAsset?:
                  | { __typename?: 'Image'; url: string }
                  | { __typename?: 'YoutubeVideo'; url: string }
                  | { __typename?: 'Video'; url: string }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
};

export type GetBookmarkListsForPlpQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBookmarkListsForPlpQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    id: string;
    bookmarkLists: {
      __typename?: 'BookmarkListPaginatedResponse';
      items: Array<
        | {
            __typename?: 'BookmarkList';
            id: string;
            name: string;
            public: boolean;
            slug: string;
            bookmarks: {
              __typename?: 'BookmarkPaginatedResponse';
              pagination: { __typename?: 'Pagination'; totalCount: number };
            };
          }
        | null
        | undefined
      >;
    };
  };
};

export type PostsForBookmarkListQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type PostsForBookmarkListQuery = {
  __typename?: 'Query';
  bookmarkList?:
    | {
        __typename?: 'BookmarkList';
        id: string;
        name: string;
        public: boolean;
        slug: string;
        bookmarks: {
          __typename?: 'BookmarkPaginatedResponse';
          pagination: {
            __typename?: 'Pagination';
            cursor?: string | null | undefined;
            hasMore: boolean;
            totalCount: number;
          };
          items: Array<
            | {
                __typename?: 'ExplorerPostBookmark';
                bookmarkedEntityId: string;
                id: string;
                explorerPost?:
                  | {
                      __typename?: 'ExplorerPost';
                      description?: string | null | undefined;
                      headline?: string | null | undefined;
                      postId: string;
                      type?: ExplorerPostType | null | undefined;
                      assets?:
                        | Array<
                            | {
                                __typename?: 'ExplorerImageAsset';
                                id?: string | null | undefined;
                                type?: ExplorerAssetType | null | undefined;
                                url?: string | null | undefined;
                              }
                            | {
                                __typename?: 'ExplorerVideoAsset';
                                id?: string | null | undefined;
                                type?: ExplorerAssetType | null | undefined;
                                url?: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      profile?:
                        | {
                            __typename?: 'Profile';
                            brandSlug?: string | null | undefined;
                            id: string;
                            type: ExplorerContentOwnerType;
                            username?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }
            | { __typename?: 'ProductBookmark' }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
};

export type PostsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables =
  Exact<{
    collectionSlug: Scalars['String'];
    cursor?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    ownerId: Scalars['String'];
  }>;

export type PostsForBookmarkListByOwnerIdAndCollectionSlugQuery = {
  __typename?: 'Query';
  bookmarkListByOwnerIdAndCollectionSlug?:
    | {
        __typename?: 'BookmarkList';
        id: string;
        name: string;
        public: boolean;
        slug: string;
        bookmarks: {
          __typename?: 'BookmarkPaginatedResponse';
          pagination: {
            __typename?: 'Pagination';
            cursor?: string | null | undefined;
            hasMore: boolean;
            totalCount: number;
          };
          items: Array<
            | {
                __typename?: 'ExplorerPostBookmark';
                bookmarkedEntityId: string;
                id: string;
                explorerPost?:
                  | {
                      __typename?: 'ExplorerPost';
                      description?: string | null | undefined;
                      headline?: string | null | undefined;
                      postId: string;
                      type?: ExplorerPostType | null | undefined;
                      assets?:
                        | Array<
                            | {
                                __typename?: 'ExplorerImageAsset';
                                id?: string | null | undefined;
                                type?: ExplorerAssetType | null | undefined;
                                url?: string | null | undefined;
                              }
                            | {
                                __typename?: 'ExplorerVideoAsset';
                                id?: string | null | undefined;
                                type?: ExplorerAssetType | null | undefined;
                                url?: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      profile?:
                        | {
                            __typename?: 'Profile';
                            brandSlug?: string | null | undefined;
                            id: string;
                            type: ExplorerContentOwnerType;
                            username?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }
            | { __typename?: 'ProductBookmark' }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
};

export type ProductsForBookmarkListQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type ProductsForBookmarkListQuery = {
  __typename?: 'Query';
  bookmarkList?:
    | {
        __typename?: 'BookmarkList';
        id: string;
        name: string;
        public: boolean;
        slug: string;
        bookmarks: {
          __typename?: 'BookmarkPaginatedResponse';
          pagination: {
            __typename?: 'Pagination';
            cursor?: string | null | undefined;
            hasMore: boolean;
            totalCount: number;
          };
          items: Array<
            | {
                __typename?: 'ExplorerPostBookmark';
                id: string;
                bookmarkList: {
                  __typename?: 'BookmarkList';
                  id: string;
                  name: string;
                };
              }
            | {
                __typename?: 'ProductBookmark';
                id: string;
                bookmarkList: {
                  __typename?: 'BookmarkList';
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: 'Product';
                  brand?: string | null | undefined;
                  brandSlug?: string | null | undefined;
                  family?: string | null | undefined;
                  familySlug?: string | null | undefined;
                  sid: string;
                  slug?: string | null | undefined;
                  title: string;
                  categories: Array<{
                    __typename?: 'Category';
                    category: Array<string>;
                  }>;
                  options: Array<
                    | {
                        __typename?: 'ColorOption';
                        label?: string | null | undefined;
                        name: string;
                        primary?: boolean | null | undefined;
                        type: OptionType;
                        values: Array<{
                          __typename?: 'ColorOptionValue';
                          brandColor: string;
                          colorFamily: string;
                          swatchColorCode: string;
                          label: string;
                          sortOrder: number;
                          value: string;
                        }>;
                      }
                    | {
                        __typename?: 'StringOption';
                        label?: string | null | undefined;
                        name: string;
                        primary?: boolean | null | undefined;
                        type: OptionType;
                        values: Array<{
                          __typename?: 'StringOptionValue';
                          label: string;
                          sortOrder: number;
                          value: string;
                        }>;
                      }
                  >;
                  variants: Array<{
                    __typename?: 'ProductVariant';
                    compareAtPrice?: number | null | undefined;
                    currencyCode?: string | null | undefined;
                    fullSid: string;
                    inStock?: boolean | null | undefined;
                    price?: number | null | undefined;
                    sku: string;
                    sid: string;
                    shoppingStatus?: ShoppingStatus | null | undefined;
                    title?: string | null | undefined;
                    filterables?:
                      | {
                          __typename?: 'Filterables';
                          responsible?:
                            | Array<string | null | undefined>
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    galleryImages: Array<{ __typename?: 'Image'; url: string }>;
                    selectedOptions: Array<{
                      __typename?: 'SelectedOption';
                      name: string;
                      value: string;
                    }>;
                  }>;
                };
                selectedOptions?:
                  | Array<
                      | {
                          __typename?: 'SelectedOption';
                          name: string;
                          value: string;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
};

export type ProductsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables =
  Exact<{
    collectionSlug: Scalars['String'];
    cursor?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    ownerId: Scalars['String'];
  }>;

export type ProductsForBookmarkListByOwnerIdAndCollectionSlugQuery = {
  __typename?: 'Query';
  bookmarkListByOwnerIdAndCollectionSlug?:
    | {
        __typename?: 'BookmarkList';
        id: string;
        name: string;
        public: boolean;
        slug: string;
        bookmarks: {
          __typename?: 'BookmarkPaginatedResponse';
          pagination: {
            __typename?: 'Pagination';
            cursor?: string | null | undefined;
            hasMore: boolean;
            totalCount: number;
          };
          items: Array<
            | { __typename?: 'ExplorerPostBookmark' }
            | {
                __typename?: 'ProductBookmark';
                id: string;
                bookmarkList: {
                  __typename?: 'BookmarkList';
                  id: string;
                  name: string;
                };
                product: {
                  __typename?: 'Product';
                  brand?: string | null | undefined;
                  brandSlug?: string | null | undefined;
                  family?: string | null | undefined;
                  familySlug?: string | null | undefined;
                  sid: string;
                  slug?: string | null | undefined;
                  title: string;
                  categories: Array<{
                    __typename?: 'Category';
                    category: Array<string>;
                  }>;
                  options: Array<
                    | {
                        __typename?: 'ColorOption';
                        label?: string | null | undefined;
                        name: string;
                        primary?: boolean | null | undefined;
                        type: OptionType;
                        values: Array<{
                          __typename?: 'ColorOptionValue';
                          brandColor: string;
                          colorFamily: string;
                          swatchColorCode: string;
                          label: string;
                          sortOrder: number;
                          value: string;
                        }>;
                      }
                    | {
                        __typename?: 'StringOption';
                        label?: string | null | undefined;
                        name: string;
                        primary?: boolean | null | undefined;
                        type: OptionType;
                        values: Array<{
                          __typename?: 'StringOptionValue';
                          label: string;
                          sortOrder: number;
                          value: string;
                        }>;
                      }
                  >;
                  variants: Array<{
                    __typename?: 'ProductVariant';
                    compareAtPrice?: number | null | undefined;
                    currencyCode?: string | null | undefined;
                    fullSid: string;
                    inStock?: boolean | null | undefined;
                    price?: number | null | undefined;
                    sku: string;
                    sid: string;
                    shoppingStatus?: ShoppingStatus | null | undefined;
                    title?: string | null | undefined;
                    filterables?:
                      | {
                          __typename?: 'Filterables';
                          responsible?:
                            | Array<string | null | undefined>
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    galleryImages: Array<{ __typename?: 'Image'; url: string }>;
                    selectedOptions: Array<{
                      __typename?: 'SelectedOption';
                      name: string;
                      value: string;
                    }>;
                  }>;
                };
                selectedOptions?:
                  | Array<
                      | {
                          __typename?: 'SelectedOption';
                          name: string;
                          value: string;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
              }
            | null
            | undefined
          >;
        };
      }
    | null
    | undefined;
};

export type ExplorerPostQueryVariables = Exact<{
  postId?: InputMaybe<Scalars['String']>;
  taggedModulesProductLimit?: InputMaybe<Scalars['Int']>;
}>;

export type ExplorerPostQuery = {
  __typename?: 'Query';
  explorerPost?:
    | {
        __typename?: 'ExplorerPost';
        description?: string | null | undefined;
        headline?: string | null | undefined;
        id?: string | null | undefined;
        transcriptUrl?: string | null | undefined;
        assets?:
          | Array<
              | {
                  __typename?: 'ExplorerImageAsset';
                  id?: string | null | undefined;
                  type?: ExplorerAssetType | null | undefined;
                  url?: string | null | undefined;
                  dimensions?:
                    | {
                        __typename?: 'AssetDimensions';
                        height?: number | null | undefined;
                        width?: number | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | {
                  __typename?: 'ExplorerVideoAsset';
                  id?: string | null | undefined;
                  type?: ExplorerAssetType | null | undefined;
                  url?: string | null | undefined;
                  videoMetadata?:
                    | {
                        __typename?: 'VideoMetadata';
                        longformUrl?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  dimensions?:
                    | {
                        __typename?: 'AssetDimensions';
                        height?: number | null | undefined;
                        width?: number | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        modules?:
          | Array<
              | {
                  __typename?: 'ExplorerBannerModule';
                  title?: string | null | undefined;
                  type?: ExplorerModuleType | null | undefined;
                }
              | {
                  __typename?: 'ExplorerBrandModule';
                  slug?: string | null | undefined;
                  skus?: Array<string | null | undefined> | null | undefined;
                  title?: string | null | undefined;
                  type?: ExplorerModuleType | null | undefined;
                }
              | {
                  __typename?: 'ExplorerBrandWebsiteModule';
                  title: string;
                  type: ExplorerModuleType;
                }
              | {
                  __typename?: 'ExplorerCollectionModule';
                  title?: string | null | undefined;
                  type?: ExplorerModuleType | null | undefined;
                }
              | {
                  __typename?: 'ExplorerDynamicProductsListModule';
                  title?: string | null | undefined;
                  type?: ExplorerModuleType | null | undefined;
                }
              | {
                  __typename?: 'ExplorerExternalLinkListModule';
                  title: string;
                  type: ExplorerModuleType;
                }
              | {
                  __typename?: 'ExplorerProductsListModule';
                  skus?: Array<string | null | undefined> | null | undefined;
                  title?: string | null | undefined;
                  type?: ExplorerModuleType | null | undefined;
                  productVariants?:
                    | Array<
                        | {
                            __typename?: 'ProductVariant';
                            colorFamilyCode?: string | null | undefined;
                            compareAtPrice?: number | null | undefined;
                            currencyCode?: string | null | undefined;
                            finalSale?: boolean | null | undefined;
                            fullSid: string;
                            inStock?: boolean | null | undefined;
                            price?: number | null | undefined;
                            shoppingStatus?: ShoppingStatus | null | undefined;
                            sid: string;
                            sku: string;
                            title?: string | null | undefined;
                            filterables?:
                              | {
                                  __typename?: 'Filterables';
                                  responsible?:
                                    | Array<string | null | undefined>
                                    | null
                                    | undefined;
                                }
                              | null
                              | undefined;
                            galleryAssets: Array<
                              | {
                                  __typename?: 'Image';
                                  type?: AssetType | null | undefined;
                                  url: string;
                                }
                              | {
                                  __typename?: 'YoutubeVideo';
                                  type?: AssetType | null | undefined;
                                  url: string;
                                }
                              | {
                                  __typename?: 'Video';
                                  type?: AssetType | null | undefined;
                                  url: string;
                                }
                            >;
                            galleryImages: Array<{
                              __typename?: 'Image';
                              url: string;
                            }>;
                            product?:
                              | {
                                  __typename?: 'Product';
                                  brand?: string | null | undefined;
                                  brandSlug?: string | null | undefined;
                                  description?: string | null | undefined;
                                  family?: string | null | undefined;
                                  familySlug?: string | null | undefined;
                                  sid: string;
                                  slug?: string | null | undefined;
                                  title: string;
                                  categories: Array<{
                                    __typename?: 'Category';
                                    category: Array<string>;
                                  }>;
                                  variants: Array<{
                                    __typename?: 'ProductVariant';
                                    colorFamilyCode?: string | null | undefined;
                                    inStock?: boolean | null | undefined;
                                    shoppingStatus?:
                                      | ShoppingStatus
                                      | null
                                      | undefined;
                                    sid: string;
                                    sku: string;
                                  }>;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | {
                  __typename?: 'ExplorerProfileListModule';
                  title: string;
                  type: ExplorerModuleType;
                }
              | {
                  __typename?: 'ExplorerSectionHeaderModule';
                  title: string;
                  type: ExplorerModuleType;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        owner: {
          __typename?: 'ExplorerContentOwner';
          brandSlug?: string | null | undefined;
          ownerId: string;
          ownerName: string;
          type: ExplorerContentOwnerType;
        };
        profile?:
          | {
              __typename?: 'Profile';
              id: string;
              brandSlug?: string | null | undefined;
              type: ExplorerContentOwnerType;
              username?: string | null | undefined;
              profileAsset?:
                | { __typename?: 'Image'; url: string }
                | { __typename?: 'YoutubeVideo'; url: string }
                | { __typename?: 'Video'; url: string }
                | null
                | undefined;
            }
          | null
          | undefined;
        relatedItems?:
          | {
              __typename?: 'RelatedItemsResponse';
              count?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ExplorerPostRelatedItemQueryVariables = Exact<{
  postId?: InputMaybe<Scalars['String']>;
}>;

export type ExplorerPostRelatedItemQuery = {
  __typename?: 'Query';
  explorerPost?:
    | {
        __typename?: 'ExplorerPost';
        relatedItems?:
          | {
              __typename?: 'RelatedItemsResponse';
              nextPage?: number | null | undefined;
              count?: number | null | undefined;
              products?:
                | Array<
                    | {
                        __typename?: 'Product';
                        brand?: string | null | undefined;
                        brandSlug?: string | null | undefined;
                        description?: string | null | undefined;
                        familySlug?: string | null | undefined;
                        sid: string;
                        slug?: string | null | undefined;
                        title: string;
                        variants: Array<{
                          __typename?: 'ProductVariant';
                          currencyCode?: string | null | undefined;
                          inStock?: boolean | null | undefined;
                          price?: number | null | undefined;
                          shoppingStatus?: ShoppingStatus | null | undefined;
                          sid: string;
                          sku: string;
                          title?: string | null | undefined;
                          filterables?:
                            | {
                                __typename?: 'Filterables';
                                responsible?:
                                  | Array<string | null | undefined>
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          galleryImages: Array<{
                            __typename?: 'Image';
                            url: string;
                          }>;
                          offerDetails?:
                            | {
                                __typename?: 'OfferDetails';
                                endDate?: string | null | undefined;
                                offerType?: OfferType | null | undefined;
                                startDate?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }>;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateAssetSignedUrlsMutationVariables = Exact<{
  input: CreateAssetSignedUrlsInput;
}>;

export type CreateAssetSignedUrlsMutation = {
  __typename?: 'Mutation';
  createAssetSignedUrls: {
    __typename?: 'CreateSignedUrlMutationResponse';
    signedUrls?: Array<string | null | undefined> | null | undefined;
    assetIds?: Array<string | null | undefined> | null | undefined;
  };
};

export type GetBrandQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetBrandQuery = {
  __typename?: 'Query';
  brand?:
    | {
        __typename?: 'Brand';
        label?: string | null | undefined;
        slug?: string | null | undefined;
        subscriptionTier?: MerchantSubscriptionTier | null | undefined;
      }
    | null
    | undefined;
};

export type GetBrandFacetMetadataQueryVariables = Exact<{
  slug: Scalars['String'];
  attributes: Array<Scalars['String']> | Scalars['String'];
  labels: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetBrandFacetMetadataQuery = {
  __typename?: 'Query';
  brand?:
    | {
        __typename?: 'Brand';
        slug?: string | null | undefined;
        facetMetadata?:
          | Array<{
              __typename?: 'BrandFacetMetadata';
              attribute?: string | null | undefined;
              label?: string | null | undefined;
              imageUrl?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetProfileCollectionsListQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  profileId: Scalars['String'];
}>;

export type GetProfileCollectionsListQuery = {
  __typename?: 'Query';
  bookmarkLists?:
    | {
        __typename?: 'BookmarkListPaginatedResponse';
        items: Array<
          | {
              __typename?: 'BookmarkList';
              id: string;
              name: string;
              public: boolean;
              slug: string;
            }
          | null
          | undefined
        >;
        pagination: {
          __typename?: 'Pagination';
          cursor?: string | null | undefined;
          hasMore: boolean;
          totalCount: number;
        };
      }
    | null
    | undefined;
};

export type GetProfileCollectionsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  profileId: Scalars['String'];
}>;

export type GetProfileCollectionsQuery = {
  __typename?: 'Query';
  bookmarkLists?:
    | {
        __typename?: 'BookmarkListPaginatedResponse';
        items: Array<
          | {
              __typename?: 'BookmarkList';
              id: string;
              name: string;
              public: boolean;
              slug: string;
              bookmarks: {
                __typename?: 'BookmarkPaginatedResponse';
                items: Array<
                  | {
                      __typename?: 'ExplorerPostBookmark';
                      type: BookmarkType;
                      explorerPost?:
                        | {
                            __typename?: 'ExplorerPost';
                            assets?:
                              | Array<
                                  | {
                                      __typename?: 'ExplorerImageAsset';
                                      type?:
                                        | ExplorerAssetType
                                        | null
                                        | undefined;
                                      url?: string | null | undefined;
                                    }
                                  | {
                                      __typename?: 'ExplorerVideoAsset';
                                      type?:
                                        | ExplorerAssetType
                                        | null
                                        | undefined;
                                      url?: string | null | undefined;
                                    }
                                  | null
                                  | undefined
                                >
                              | null
                              | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | {
                      __typename?: 'ProductBookmark';
                      type: BookmarkType;
                      product: {
                        __typename?: 'Product';
                        variants: Array<{
                          __typename?: 'ProductVariant';
                          galleryAssets: Array<
                            | {
                                __typename?: 'Image';
                                type?: AssetType | null | undefined;
                                url: string;
                              }
                            | {
                                __typename?: 'YoutubeVideo';
                                type?: AssetType | null | undefined;
                                url: string;
                              }
                            | {
                                __typename?: 'Video';
                                type?: AssetType | null | undefined;
                                url: string;
                              }
                          >;
                          selectedOptions: Array<{
                            __typename?: 'SelectedOption';
                            name: string;
                            value: string;
                          }>;
                        }>;
                      };
                      selectedOptions?:
                        | Array<
                            | {
                                __typename?: 'SelectedOption';
                                name: string;
                                value: string;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >;
                pagination: { __typename?: 'Pagination'; totalCount: number };
              };
            }
          | null
          | undefined
        >;
        pagination: {
          __typename?: 'Pagination';
          cursor?: string | null | undefined;
          hasMore: boolean;
          totalCount: number;
        };
      }
    | null
    | undefined;
};

export type GetProfilePostsQueryVariables = Exact<{
  limit: Scalars['Int'];
  paginationToken?: InputMaybe<Scalars['String']>;
  profileId: Scalars['String'];
}>;

export type GetProfilePostsQuery = {
  __typename?: 'Query';
  explorerPosts: {
    __typename?: 'ExplorerPostResponse';
    paginationToken?: string | null | undefined;
    posts: Array<
      | {
          __typename?: 'ExplorerPost';
          description?: string | null | undefined;
          headline?: string | null | undefined;
          id?: string | null | undefined;
          type?: ExplorerPostType | null | undefined;
          transcriptUrl?: string | null | undefined;
          assets?:
            | Array<
                | {
                    __typename?: 'ExplorerImageAsset';
                    id?: string | null | undefined;
                    type?: ExplorerAssetType | null | undefined;
                    url?: string | null | undefined;
                    dimensions?:
                      | {
                          __typename?: 'AssetDimensions';
                          height?: number | null | undefined;
                          width?: number | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | {
                    __typename?: 'ExplorerVideoAsset';
                    id?: string | null | undefined;
                    type?: ExplorerAssetType | null | undefined;
                    url?: string | null | undefined;
                    dimensions?:
                      | {
                          __typename?: 'AssetDimensions';
                          height?: number | null | undefined;
                          width?: number | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          modules?:
            | Array<
                | {
                    __typename?: 'ExplorerBannerModule';
                    type?: ExplorerModuleType | null | undefined;
                  }
                | {
                    __typename?: 'ExplorerBrandModule';
                    type?: ExplorerModuleType | null | undefined;
                  }
                | {
                    __typename?: 'ExplorerBrandWebsiteModule';
                    type: ExplorerModuleType;
                  }
                | {
                    __typename?: 'ExplorerCollectionModule';
                    type?: ExplorerModuleType | null | undefined;
                  }
                | {
                    __typename?: 'ExplorerDynamicProductsListModule';
                    type?: ExplorerModuleType | null | undefined;
                  }
                | {
                    __typename?: 'ExplorerExternalLinkListModule';
                    type: ExplorerModuleType;
                  }
                | {
                    __typename?: 'ExplorerProductsListModule';
                    skus?: Array<string | null | undefined> | null | undefined;
                    type?: ExplorerModuleType | null | undefined;
                    productVariants?:
                      | Array<
                          | {
                              __typename?: 'ProductVariant';
                              sku: string;
                              galleryAssets: Array<
                                | {
                                    __typename?: 'Image';
                                    type?: AssetType | null | undefined;
                                    url: string;
                                  }
                                | {
                                    __typename?: 'YoutubeVideo';
                                    type?: AssetType | null | undefined;
                                    url: string;
                                  }
                                | {
                                    __typename?: 'Video';
                                    type?: AssetType | null | undefined;
                                    url: string;
                                  }
                              >;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | {
                    __typename?: 'ExplorerProfileListModule';
                    type: ExplorerModuleType;
                  }
                | {
                    __typename?: 'ExplorerSectionHeaderModule';
                    type: ExplorerModuleType;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          owner: {
            __typename?: 'ExplorerContentOwner';
            brandSlug?: string | null | undefined;
            ownerId: string;
            ownerName: string;
            type: ExplorerContentOwnerType;
          };
          profile?:
            | {
                __typename?: 'Profile';
                id: string;
                brandSlug?: string | null | undefined;
                type: ExplorerContentOwnerType;
                username?: string | null | undefined;
                profileAsset?:
                  | { __typename?: 'Image'; url: string }
                  | { __typename?: 'YoutubeVideo'; url: string }
                  | { __typename?: 'Video'; url: string }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
};

export type GetProfileQueryVariables = Exact<{
  brandSlug?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
}>;

export type GetProfileQuery = {
  __typename?: 'Query';
  getProfile?:
    | {
        __typename?: 'Profile';
        biography?: string | null | undefined;
        brandSlug?: string | null | undefined;
        displayName?: string | null | undefined;
        facebookName?: string | null | undefined;
        followerCount?: number | null | undefined;
        followingCount?: number | null | undefined;
        id: string;
        instagramName?: string | null | undefined;
        isFollowing?: boolean | null | undefined;
        isProfileOwner?: boolean | null | undefined;
        likeCount?: number | null | undefined;
        metaDescription?: string | null | undefined;
        metaTitle?: string | null | undefined;
        twitterName?: string | null | undefined;
        type: ExplorerContentOwnerType;
        username?: string | null | undefined;
        coverImageAsset?:
          | { __typename?: 'Image'; url: string }
          | { __typename?: 'YoutubeVideo'; url: string }
          | { __typename?: 'Video'; url: string }
          | null
          | undefined;
        creatorInfo?:
          | {
              __typename?: 'CreatorInfo';
              isCreator: boolean;
              status?: CreatorStatus | null | undefined;
            }
          | null
          | undefined;
        profileAsset?:
          | { __typename?: 'Image'; url: string }
          | { __typename?: 'YoutubeVideo'; url: string }
          | { __typename?: 'Video'; url: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateProfile: {
    __typename?: 'ProfileMutationResponse';
    success: boolean;
    profileError?: ProfileError | null | undefined;
    profile?:
      | {
          __typename?: 'Profile';
          biography?: string | null | undefined;
          brandSlug?: string | null | undefined;
          displayName?: string | null | undefined;
          facebookName?: string | null | undefined;
          followerCount?: number | null | undefined;
          followingCount?: number | null | undefined;
          id: string;
          instagramName?: string | null | undefined;
          isFollowing?: boolean | null | undefined;
          isProfileOwner?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          metaDescription?: string | null | undefined;
          metaTitle?: string | null | undefined;
          twitterName?: string | null | undefined;
          type: ExplorerContentOwnerType;
          username?: string | null | undefined;
          coverImageAsset?:
            | { __typename?: 'Image'; url: string }
            | { __typename?: 'YoutubeVideo'; url: string }
            | { __typename?: 'Video'; url: string }
            | null
            | undefined;
          creatorInfo?:
            | {
                __typename?: 'CreatorInfo';
                isCreator: boolean;
                status?: CreatorStatus | null | undefined;
              }
            | null
            | undefined;
          profileAsset?:
            | { __typename?: 'Image'; url: string }
            | { __typename?: 'YoutubeVideo'; url: string }
            | { __typename?: 'Video'; url: string }
            | null
            | undefined;
        }
      | null
      | undefined;
    validationErrors: Array<
      | {
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }
      | null
      | undefined
    >;
  };
};

export type GetTrendingProfilesQueryVariables = Exact<{
  guestUserId?: InputMaybe<Scalars['String']>;
}>;

export type GetTrendingProfilesQuery = {
  __typename?: 'Query';
  trendingProfiles: Array<
    | {
        __typename?: 'Profile';
        biography?: string | null | undefined;
        brandSlug?: string | null | undefined;
        displayName?: string | null | undefined;
        followerCount?: number | null | undefined;
        followingCount?: number | null | undefined;
        id: string;
        isFollowing?: boolean | null | undefined;
        likeCount?: number | null | undefined;
        type: ExplorerContentOwnerType;
        username?: string | null | undefined;
        coverImageAsset?:
          | { __typename?: 'Image'; url: string }
          | { __typename?: 'YoutubeVideo'; url: string }
          | { __typename?: 'Video'; url: string }
          | null
          | undefined;
        profileAsset?:
          | { __typename?: 'Image'; url: string }
          | { __typename?: 'YoutubeVideo'; url: string }
          | { __typename?: 'Video'; url: string }
          | null
          | undefined;
        topPost?:
          | {
              __typename?: 'ExplorerPost';
              description?: string | null | undefined;
              headline?: string | null | undefined;
              id?: string | null | undefined;
              type?: ExplorerPostType | null | undefined;
              transcriptUrl?: string | null | undefined;
              assets?:
                | Array<
                    | {
                        __typename?: 'ExplorerImageAsset';
                        id?: string | null | undefined;
                        type?: ExplorerAssetType | null | undefined;
                        url?: string | null | undefined;
                        dimensions?:
                          | {
                              __typename?: 'AssetDimensions';
                              height?: number | null | undefined;
                              width?: number | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | {
                        __typename?: 'ExplorerVideoAsset';
                        id?: string | null | undefined;
                        type?: ExplorerAssetType | null | undefined;
                        url?: string | null | undefined;
                        dimensions?:
                          | {
                              __typename?: 'AssetDimensions';
                              height?: number | null | undefined;
                              width?: number | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              modules?:
                | Array<
                    | {
                        __typename?: 'ExplorerBannerModule';
                        type?: ExplorerModuleType | null | undefined;
                      }
                    | {
                        __typename?: 'ExplorerBrandModule';
                        type?: ExplorerModuleType | null | undefined;
                      }
                    | {
                        __typename?: 'ExplorerBrandWebsiteModule';
                        type: ExplorerModuleType;
                      }
                    | {
                        __typename?: 'ExplorerCollectionModule';
                        type?: ExplorerModuleType | null | undefined;
                      }
                    | {
                        __typename?: 'ExplorerDynamicProductsListModule';
                        type?: ExplorerModuleType | null | undefined;
                      }
                    | {
                        __typename?: 'ExplorerExternalLinkListModule';
                        type: ExplorerModuleType;
                      }
                    | {
                        __typename?: 'ExplorerProductsListModule';
                        skus?:
                          | Array<string | null | undefined>
                          | null
                          | undefined;
                        type?: ExplorerModuleType | null | undefined;
                        productVariants?:
                          | Array<
                              | {
                                  __typename?: 'ProductVariant';
                                  sku: string;
                                  galleryAssets: Array<
                                    | {
                                        __typename?: 'Image';
                                        type?: AssetType | null | undefined;
                                        url: string;
                                      }
                                    | {
                                        __typename?: 'YoutubeVideo';
                                        type?: AssetType | null | undefined;
                                        url: string;
                                      }
                                    | {
                                        __typename?: 'Video';
                                        type?: AssetType | null | undefined;
                                        url: string;
                                      }
                                  >;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | {
                        __typename?: 'ExplorerProfileListModule';
                        type: ExplorerModuleType;
                      }
                    | {
                        __typename?: 'ExplorerSectionHeaderModule';
                        type: ExplorerModuleType;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              profile?:
                | {
                    __typename?: 'Profile';
                    id: string;
                    brandSlug?: string | null | undefined;
                    type: ExplorerContentOwnerType;
                    username?: string | null | undefined;
                    profileAsset?:
                      | { __typename?: 'Image'; url: string }
                      | { __typename?: 'YoutubeVideo'; url: string }
                      | { __typename?: 'Video'; url: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type GetBrandReviewsQueryVariables = Exact<{
  brandSlug: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type GetBrandReviewsQuery = {
  __typename?: 'Query';
  brandReviewsPage?:
    | {
        __typename?: 'ReviewsPage';
        pageInfo: {
          __typename?: 'ReviewsPageInfo';
          currentPage: number;
          numberOfPages: number;
        };
        reviews: Array<{
          __typename?: 'ReviewV2';
          content: string;
          id: string;
          reviewDate: string;
          score: number;
          sourceName?: string | null | undefined;
          sourceUrl?: string | null | undefined;
          title?: string | null | undefined;
          verifiedBuyer: boolean;
          comment?:
            | {
                __typename?: 'ReviewComment';
                content?: string | null | undefined;
                createdAt: string;
                id: string;
              }
            | null
            | undefined;
          imagesData?:
            | Array<{
                __typename?: 'ReviewImageData';
                id: string;
                thumbUrl?: string | null | undefined;
                originalUrl?: string | null | undefined;
              }>
            | null
            | undefined;
          product?:
            | {
                __typename?: 'Product';
                brand?: string | null | undefined;
                brandSlug?: string | null | undefined;
                description?: string | null | undefined;
                familySlug?: string | null | undefined;
                sid: string;
                slug?: string | null | undefined;
                title: string;
                hierarchicalCategories: {
                  __typename?: 'HierarchicalCategories';
                  lvl0: Array<string>;
                  lvl1: Array<string>;
                  lvl2: Array<string>;
                  lvl3: Array<string>;
                };
                variants: Array<{
                  __typename?: 'ProductVariant';
                  inStock?: boolean | null | undefined;
                  price?: number | null | undefined;
                  galleryAssets: Array<
                    | {
                        __typename?: 'Image';
                        type?: AssetType | null | undefined;
                        url: string;
                      }
                    | {
                        __typename?: 'YoutubeVideo';
                        type?: AssetType | null | undefined;
                        url: string;
                      }
                    | {
                        __typename?: 'Video';
                        type?: AssetType | null | undefined;
                        url: string;
                      }
                  >;
                }>;
              }
            | null
            | undefined;
          user?:
            | {
                __typename?: 'ReviewUser';
                displayName?: string | null | undefined;
              }
            | null
            | undefined;
        }>;
        summary: {
          __typename?: 'ReviewSummary';
          averageScore: number;
          totalReview: number;
          ratingDistribution: Array<{
            __typename?: 'Rating';
            count: number;
            score: number;
          }>;
        };
      }
    | null
    | undefined;
};

export type PostsFieldsFragment = {
  __typename?: 'ExplorerPost';
  description?: string | null | undefined;
  headline?: string | null | undefined;
  id?: string | null | undefined;
  type?: ExplorerPostType | null | undefined;
  transcriptUrl?: string | null | undefined;
  assets?:
    | Array<
        | {
            __typename?: 'ExplorerImageAsset';
            id?: string | null | undefined;
            type?: ExplorerAssetType | null | undefined;
            url?: string | null | undefined;
            dimensions?:
              | {
                  __typename?: 'AssetDimensions';
                  height?: number | null | undefined;
                  width?: number | null | undefined;
                }
              | null
              | undefined;
          }
        | {
            __typename?: 'ExplorerVideoAsset';
            id?: string | null | undefined;
            type?: ExplorerAssetType | null | undefined;
            url?: string | null | undefined;
            dimensions?:
              | {
                  __typename?: 'AssetDimensions';
                  height?: number | null | undefined;
                  width?: number | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  modules?:
    | Array<
        | {
            __typename?: 'ExplorerBannerModule';
            type?: ExplorerModuleType | null | undefined;
          }
        | {
            __typename?: 'ExplorerBrandModule';
            type?: ExplorerModuleType | null | undefined;
          }
        | {
            __typename?: 'ExplorerBrandWebsiteModule';
            type: ExplorerModuleType;
          }
        | {
            __typename?: 'ExplorerCollectionModule';
            type?: ExplorerModuleType | null | undefined;
          }
        | {
            __typename?: 'ExplorerDynamicProductsListModule';
            type?: ExplorerModuleType | null | undefined;
          }
        | {
            __typename?: 'ExplorerExternalLinkListModule';
            type: ExplorerModuleType;
          }
        | {
            __typename?: 'ExplorerProductsListModule';
            skus?: Array<string | null | undefined> | null | undefined;
            type?: ExplorerModuleType | null | undefined;
            productVariants?:
              | Array<
                  | {
                      __typename?: 'ProductVariant';
                      sku: string;
                      galleryAssets: Array<
                        | {
                            __typename?: 'Image';
                            type?: AssetType | null | undefined;
                            url: string;
                          }
                        | {
                            __typename?: 'YoutubeVideo';
                            type?: AssetType | null | undefined;
                            url: string;
                          }
                        | {
                            __typename?: 'Video';
                            type?: AssetType | null | undefined;
                            url: string;
                          }
                      >;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | { __typename?: 'ExplorerProfileListModule'; type: ExplorerModuleType }
        | {
            __typename?: 'ExplorerSectionHeaderModule';
            type: ExplorerModuleType;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  profile?:
    | {
        __typename?: 'Profile';
        id: string;
        brandSlug?: string | null | undefined;
        type: ExplorerContentOwnerType;
        username?: string | null | undefined;
        profileAsset?:
          | { __typename?: 'Image'; url: string }
          | { __typename?: 'YoutubeVideo'; url: string }
          | { __typename?: 'Video'; url: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type LiveShopOwnerFieldsFragment = {
  __typename?: 'Profile';
  brandSlug?: string | null | undefined;
  displayName?: string | null | undefined;
  type: ExplorerContentOwnerType;
  username?: string | null | undefined;
  profileAsset?:
    | { __typename?: 'Image'; url: string; type?: AssetType | null | undefined }
    | {
        __typename?: 'YoutubeVideo';
        url: string;
        type?: AssetType | null | undefined;
      }
    | { __typename?: 'Video'; url: string; type?: AssetType | null | undefined }
    | null
    | undefined;
};

export type LiveShopWithProductsNoAuthFieldsFragment = {
  __typename?: 'LiveShop';
  confirmedEndsAt?: string | null | undefined;
  confirmedStartsAt?: string | null | undefined;
  description?: string | null | undefined;
  endsAt: string;
  id: string;
  startsAt: string;
  status: LiveShopStatus;
  title: string;
  coverAsset?:
    | { __typename?: 'Image'; url: string; type?: AssetType | null | undefined }
    | {
        __typename?: 'YoutubeVideo';
        url: string;
        type?: AssetType | null | undefined;
      }
    | { __typename?: 'Video'; url: string; type?: AssetType | null | undefined }
    | null
    | undefined;
  inviteCodes: Array<{
    __typename?: 'LiveShopInviteCode';
    inviteCode: string;
    type: LiveShopInviteCodeType;
  }>;
  owner: {
    __typename?: 'Profile';
    brandSlug?: string | null | undefined;
    displayName?: string | null | undefined;
    type: ExplorerContentOwnerType;
    username?: string | null | undefined;
    profileAsset?:
      | {
          __typename?: 'Image';
          url: string;
          type?: AssetType | null | undefined;
        }
      | {
          __typename?: 'YoutubeVideo';
          url: string;
          type?: AssetType | null | undefined;
        }
      | {
          __typename?: 'Video';
          url: string;
          type?: AssetType | null | undefined;
        }
      | null
      | undefined;
  };
  featuredProducts: Array<{
    __typename?: 'LiveShopProductVariant';
    productVariant: {
      __typename?: 'ProductVariant';
      compareAtPrice?: number | null | undefined;
      price?: number | null | undefined;
      sku: string;
      title?: string | null | undefined;
      galleryAssets: Array<
        | { __typename?: 'Image'; url: string }
        | { __typename?: 'YoutubeVideo'; url: string }
        | { __typename?: 'Video'; url: string }
      >;
      product?:
        | {
            __typename?: 'Product';
            brand?: string | null | undefined;
            brandSlug?: string | null | undefined;
            familySlug?: string | null | undefined;
            sid: string;
            slug?: string | null | undefined;
            title: string;
          }
        | null
        | undefined;
    };
  }>;
  teaserAsset?:
    | { __typename?: 'Image'; url: string; type?: AssetType | null | undefined }
    | {
        __typename?: 'YoutubeVideo';
        url: string;
        type?: AssetType | null | undefined;
      }
    | { __typename?: 'Video'; url: string; type?: AssetType | null | undefined }
    | null
    | undefined;
};

export type LiveShopNoAuthFieldsFragment = {
  __typename?: 'LiveShop';
  confirmedEndsAt?: string | null | undefined;
  confirmedStartsAt?: string | null | undefined;
  description?: string | null | undefined;
  endsAt: string;
  startsAt: string;
  status: LiveShopStatus;
  title: string;
  coverAsset?:
    | { __typename?: 'Image'; url: string; type?: AssetType | null | undefined }
    | {
        __typename?: 'YoutubeVideo';
        url: string;
        type?: AssetType | null | undefined;
      }
    | { __typename?: 'Video'; url: string; type?: AssetType | null | undefined }
    | null
    | undefined;
  inviteCodes: Array<{
    __typename?: 'LiveShopInviteCode';
    inviteCode: string;
    type: LiveShopInviteCodeType;
  }>;
  owner: {
    __typename?: 'Profile';
    brandSlug?: string | null | undefined;
    displayName?: string | null | undefined;
    type: ExplorerContentOwnerType;
    username?: string | null | undefined;
    profileAsset?:
      | {
          __typename?: 'Image';
          url: string;
          type?: AssetType | null | undefined;
        }
      | {
          __typename?: 'YoutubeVideo';
          url: string;
          type?: AssetType | null | undefined;
        }
      | {
          __typename?: 'Video';
          url: string;
          type?: AssetType | null | undefined;
        }
      | null
      | undefined;
  };
  teaserAsset?:
    | { __typename?: 'Image'; url: string; type?: AssetType | null | undefined }
    | {
        __typename?: 'YoutubeVideo';
        url: string;
        type?: AssetType | null | undefined;
      }
    | { __typename?: 'Video'; url: string; type?: AssetType | null | undefined }
    | null
    | undefined;
};

export type GetLiveShopDataQueryVariables = Exact<{
  inviteCode: Scalars['String'];
}>;

export type GetLiveShopDataQuery = {
  __typename?: 'Query';
  liveShopByInviteCode?:
    | {
        __typename?: 'LiveShop';
        confirmedEndsAt?: string | null | undefined;
        confirmedStartsAt?: string | null | undefined;
        description?: string | null | undefined;
        endsAt: string;
        id: string;
        startsAt: string;
        status: LiveShopStatus;
        title: string;
        coverAsset?:
          | {
              __typename?: 'Image';
              url: string;
              type?: AssetType | null | undefined;
            }
          | {
              __typename?: 'YoutubeVideo';
              url: string;
              type?: AssetType | null | undefined;
            }
          | {
              __typename?: 'Video';
              url: string;
              type?: AssetType | null | undefined;
            }
          | null
          | undefined;
        inviteCodes: Array<{
          __typename?: 'LiveShopInviteCode';
          inviteCode: string;
          type: LiveShopInviteCodeType;
        }>;
        owner: {
          __typename?: 'Profile';
          brandSlug?: string | null | undefined;
          displayName?: string | null | undefined;
          type: ExplorerContentOwnerType;
          username?: string | null | undefined;
          profileAsset?:
            | {
                __typename?: 'Image';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'YoutubeVideo';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'Video';
                url: string;
                type?: AssetType | null | undefined;
              }
            | null
            | undefined;
        };
        featuredProducts: Array<{
          __typename?: 'LiveShopProductVariant';
          productVariant: {
            __typename?: 'ProductVariant';
            compareAtPrice?: number | null | undefined;
            price?: number | null | undefined;
            sku: string;
            title?: string | null | undefined;
            galleryAssets: Array<
              | { __typename?: 'Image'; url: string }
              | { __typename?: 'YoutubeVideo'; url: string }
              | { __typename?: 'Video'; url: string }
            >;
            product?:
              | {
                  __typename?: 'Product';
                  brand?: string | null | undefined;
                  brandSlug?: string | null | undefined;
                  familySlug?: string | null | undefined;
                  sid: string;
                  slug?: string | null | undefined;
                  title: string;
                }
              | null
              | undefined;
          };
        }>;
        teaserAsset?:
          | {
              __typename?: 'Image';
              url: string;
              type?: AssetType | null | undefined;
            }
          | {
              __typename?: 'YoutubeVideo';
              url: string;
              type?: AssetType | null | undefined;
            }
          | {
              __typename?: 'Video';
              url: string;
              type?: AssetType | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetLiveShopsByOwnerQueryVariables = Exact<{
  ownerId: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
  status?: InputMaybe<
    Array<InputMaybe<LiveShopStatus>> | InputMaybe<LiveShopStatus>
  >;
}>;

export type GetLiveShopsByOwnerQuery = {
  __typename?: 'Query';
  liveShopsByOwner: {
    __typename?: 'LiveShopPaginatedResponse';
    items: Array<
      | {
          __typename?: 'LiveShop';
          confirmedEndsAt?: string | null | undefined;
          confirmedStartsAt?: string | null | undefined;
          description?: string | null | undefined;
          endsAt: string;
          startsAt: string;
          status: LiveShopStatus;
          title: string;
          coverAsset?:
            | {
                __typename?: 'Image';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'YoutubeVideo';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'Video';
                url: string;
                type?: AssetType | null | undefined;
              }
            | null
            | undefined;
          inviteCodes: Array<{
            __typename?: 'LiveShopInviteCode';
            inviteCode: string;
            type: LiveShopInviteCodeType;
          }>;
          owner: {
            __typename?: 'Profile';
            brandSlug?: string | null | undefined;
            displayName?: string | null | undefined;
            type: ExplorerContentOwnerType;
            username?: string | null | undefined;
            profileAsset?:
              | {
                  __typename?: 'Image';
                  url: string;
                  type?: AssetType | null | undefined;
                }
              | {
                  __typename?: 'YoutubeVideo';
                  url: string;
                  type?: AssetType | null | undefined;
                }
              | {
                  __typename?: 'Video';
                  url: string;
                  type?: AssetType | null | undefined;
                }
              | null
              | undefined;
          };
          teaserAsset?:
            | {
                __typename?: 'Image';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'YoutubeVideo';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'Video';
                url: string;
                type?: AssetType | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
};

export type GetUpcomingLiveShopDataQueryVariables = Exact<{
  bounds: LiveShopBounds;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PaginationOrder>;
  privacy?: InputMaybe<
    Array<InputMaybe<LiveShopPrivacy>> | InputMaybe<LiveShopPrivacy>
  >;
  status?: InputMaybe<
    Array<InputMaybe<LiveShopStatus>> | InputMaybe<LiveShopStatus>
  >;
}>;

export type GetUpcomingLiveShopDataQuery = {
  __typename?: 'Query';
  liveShopsByDateBounded: {
    __typename?: 'LiveShopPaginatedResponse';
    items: Array<
      | {
          __typename?: 'LiveShop';
          confirmedEndsAt?: string | null | undefined;
          confirmedStartsAt?: string | null | undefined;
          description?: string | null | undefined;
          endsAt: string;
          startsAt: string;
          status: LiveShopStatus;
          title: string;
          coverAsset?:
            | {
                __typename?: 'Image';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'YoutubeVideo';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'Video';
                url: string;
                type?: AssetType | null | undefined;
              }
            | null
            | undefined;
          inviteCodes: Array<{
            __typename?: 'LiveShopInviteCode';
            inviteCode: string;
            type: LiveShopInviteCodeType;
          }>;
          owner: {
            __typename?: 'Profile';
            brandSlug?: string | null | undefined;
            displayName?: string | null | undefined;
            type: ExplorerContentOwnerType;
            username?: string | null | undefined;
            profileAsset?:
              | {
                  __typename?: 'Image';
                  url: string;
                  type?: AssetType | null | undefined;
                }
              | {
                  __typename?: 'YoutubeVideo';
                  url: string;
                  type?: AssetType | null | undefined;
                }
              | {
                  __typename?: 'Video';
                  url: string;
                  type?: AssetType | null | undefined;
                }
              | null
              | undefined;
          };
          teaserAsset?:
            | {
                __typename?: 'Image';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'YoutubeVideo';
                url: string;
                type?: AssetType | null | undefined;
              }
            | {
                __typename?: 'Video';
                url: string;
                type?: AssetType | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
};

export type ClaimLiveShopDiscountMutationVariables = Exact<{
  input: ClaimLiveShopDiscountInput;
}>;

export type ClaimLiveShopDiscountMutation = {
  __typename?: 'Mutation';
  claimLiveShopDiscount: {
    __typename?: 'ClaimLiveShopDiscountResponse';
    success: boolean;
    verifiedDealId?: string | null | undefined;
    validationErrors: Array<
      | {
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }
      | null
      | undefined
    >;
  };
};

export type FollowMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type FollowMutation = {
  __typename?: 'Mutation';
  follow: {
    __typename?: 'ProfileFollowMutationResponse';
    success: boolean;
    validationErrors: Array<
      | {
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }
      | null
      | undefined
    >;
  };
};

export type UnfollowMutationVariables = Exact<{
  input: Scalars['String'];
}>;

export type UnfollowMutation = {
  __typename?: 'Mutation';
  unfollow: {
    __typename?: 'ProfileFollowMutationResponse';
    success: boolean;
    validationErrors: Array<
      | {
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }
      | null
      | undefined
    >;
  };
};

export type GetLiveShopsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetLiveShopsByIdsQuery = {
  __typename?: 'Query';
  liveShopsByIds: Array<{
    __typename?: 'LiveShop';
    id: string;
    title: string;
    owner: { __typename?: 'Profile'; username?: string | null | undefined };
  }>;
};

export type GetExperimentsQueryVariables = Exact<{
  id: Scalars['String'];
  idType: ExperimentIdType;
  namespace: Scalars['String'];
}>;

export type GetExperimentsQuery = {
  __typename?: 'Query';
  experiment?:
    | {
        __typename?: 'ExperimentResponse';
        lastUpdatedTs: number;
        experiments: Array<
          | {
              __typename?: 'Experiment';
              bucket: number;
              name: string;
              version: number;
              values?:
                | Array<
                    | {
                        __typename?: 'ExperimentValue';
                        key: string;
                        value: string;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type WebGenerateOnboardingInviteCodeMutationVariables = Exact<{
  url: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
}>;

export type WebGenerateOnboardingInviteCodeMutation = {
  __typename?: 'Mutation';
  generateOnboardingInviteCode: {
    __typename?: 'GenerateOnboardingInviteCodeResponse';
    code: string;
    success: boolean;
  };
};

export type WebMarkRewardsSeenMutationMutationVariables = Exact<{
  verifiedDealIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type WebMarkRewardsSeenMutationMutation = {
  __typename?: 'Mutation';
  markVerifiedDealsAsSeen: {
    __typename?: 'GenericMutationResponse';
    success: boolean;
    validationErrors: Array<
      { __typename?: 'ValidationError'; message: string } | null | undefined
    >;
  };
};

export type WebOfferCenterGetVerifiedDealsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type WebOfferCenterGetVerifiedDealsQuery = {
  __typename?: 'Query';
  verifiedDeals: {
    __typename?: 'VerifiedDealPaginatedResponse';
    cursor?: string | null | undefined;
    deals: Array<
      | {
          __typename?: 'VerifiedDeal';
          appliedShopifyGiftCardId?: string | null | undefined;
          createdAt: string;
          description: string;
          expiresAt: string;
          id: string;
          isSeen: boolean;
          liveShopId?: string | null | undefined;
          maximumOrderCount?: number | null | undefined;
          minimumSpend?: number | null | undefined;
          productIds?: Array<string | null | undefined> | null | undefined;
          shopPartyIds?: Array<string | null | undefined> | null | undefined;
          shopifyGiftCardCode?: string | null | undefined;
          shopifyGiftCardId?: string | null | undefined;
          shopifyIds?: Array<string | null | undefined> | null | undefined;
          shopifyOrderId?: string | null | undefined;
          skus?: Array<string | null | undefined> | null | undefined;
          status: VerifiedDealStatus;
          type: string;
          unlocksAt?: string | null | undefined;
          userId: string;
          value: number;
          valueType: VerifiedDealValueType;
          pictureAsset?:
            | { __typename?: 'Image'; url: string }
            | { __typename?: 'YoutubeVideo'; url: string }
            | { __typename?: 'Video'; url: string }
            | null
            | undefined;
        }
      | null
      | undefined
    >;
  };
};

export type RedeemVerifiedDealMutationVariables = Exact<{
  input: RedeemVerifiedDealInput;
}>;

export type RedeemVerifiedDealMutation = {
  __typename?: 'Mutation';
  redeemVerifiedDeal: {
    __typename?: 'RedeemVerifiedDealResponse';
    shopifyGiftCardCode?: string | null | undefined;
    shopifyGiftCardId?: string | null | undefined;
    shopifyGiftCardValue?: number | null | undefined;
    success: boolean;
    validationErrors: Array<
      | {
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }
      | null
      | undefined
    >;
  };
};

export type WebSaveAppliedShopifyGiftCardIdMutationVariables = Exact<{
  input: SaveAppliedShopifyGiftCardIdInput;
}>;

export type WebSaveAppliedShopifyGiftCardIdMutation = {
  __typename?: 'Mutation';
  saveAppliedShopifyGiftCardId: {
    __typename?: 'GenericMutationResponse';
    success: boolean;
    validationErrors: Array<
      | {
          __typename?: 'ValidationError';
          code: number;
          field: string;
          message: string;
        }
      | null
      | undefined
    >;
  };
};

export type GetShortLinkQueryVariables = Exact<{
  url: Scalars['String'];
}>;

export type GetShortLinkQuery = {
  __typename?: 'Query';
  affiliateLink: {
    __typename?: 'AffiliateLinkResponse';
    success: boolean;
    url?: string | null | undefined;
  };
};

export type GetOrdersForProductQueryVariables = Exact<{
  productSid?: InputMaybe<Scalars['String']>;
}>;

export type GetOrdersForProductQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'Customer';
    orders?:
      | Array<
          | {
              __typename?: 'Order';
              orderNumber?: string | null | undefined;
              fulfillments?:
                | Array<{
                    __typename?: 'Fulfillment';
                    shipmentStatus?: FulfillmentEventStatus | null | undefined;
                    trackingCompany?: string | null | undefined;
                    trackingNumber?: string | null | undefined;
                    lineItems: Array<{
                      __typename?: 'LineItem';
                      productId: string;
                    }>;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export const ProductBookmarkFragmentDoc = gql`
  fragment productBookmark on ProductBookmark {
    bookmarkList {
      id
      name
    }
    id
    product {
      brand
      brandSlug
      categories {
        category
      }
      family
      familySlug
      options {
        label
        name
        primary
        type
        values {
          label
          sortOrder
          value
          ... on ColorOptionValue {
            brandColor
            colorFamily
            swatchColorCode
          }
        }
      }
      sid
      slug
      title
      variants {
        compareAtPrice
        currencyCode
        filterables {
          responsible
        }
        fullSid
        galleryImages {
          url
        }
        inStock
        price
        selectedOptions {
          name
          value
        }
        sku
        sid
        shoppingStatus
        title
      }
    }
    selectedOptions {
      name
      value
    }
  }
`;
export const SelectedOptionFragmentDoc = gql`
  fragment SelectedOption on SelectedOption {
    name
    value
  }
`;
export const ProductVariantPdpFragmentDoc = gql`
  fragment ProductVariantPDP on ProductVariant {
    bulky
    collections
    compareAtPrice
    currencyCode
    description
    editorsNote
    editorsNoteAuthor
    eligiblePromotions
    filterables {
      responsible
    }
    finalSale
    fit
    fullSid
    galleryAssets {
      url
      type
      ... on Image {
        ecommModel {
          bust
          chest
          height
          hip
          inseam
          waist
        }
        ecommModelSize {
          name
          value
        }
      }
      ... on Video {
        cloudinaryPublicId
      }
    }
    galleryImages {
      url
    }
    howToVideos {
      url
      type
    }
    ingredients
    inStock
    isDiscountable
    isDropshipped
    isGiftCard
    isLowStock
    isMarketplace
    isProductBundle
    materialList
    materials
    modelImages {
      url
    }
    offerDetails {
      endDate
      offerType
      startDate
    }
    price
    productBundleItems {
      quantity
      sid
      sku
    }
    productImages {
      url
    }
    publishDate
    isReplenishable
    requiresShipping
    selectedOptions {
      ...SelectedOption
    }
    specifications {
      label
      value
    }
    shoppingStatus
    sid
    sku
    termsAndConditions
    title
    hazmat
    weight
    weightUnit
  }
  ${SelectedOptionFragmentDoc}
`;
export const ProductOptionPdpFragmentDoc = gql`
  fragment ProductOptionPDP on Option {
    ... on ColorOption {
      label
      name
      type
      values {
        brandColor
        colorFamily
        label
        swatchColorCode
        value
      }
    }
    ... on StringOption {
      label
      name
      type
      values {
        label
        value
      }
    }
  }
`;
export const ProductPdpFragmentDoc = gql`
  fragment ProductPDP on Product {
    brand
    brandEntity {
      label
      slug
      subscriptionTier
    }
    brandSlug
    categories {
      category
    }
    description
    descriptionLegacy
    family
    familySlug
    hierarchicalCategories {
      lvl0
      lvl1
      lvl2
      lvl3
    }
    sid
    slug
    title
    variants(omitDiscontinued: true, isCollectionsNeeded: true) {
      ...ProductVariantPDP
    }
    options {
      ...ProductOptionPDP
    }
    slug
  }
  ${ProductVariantPdpFragmentDoc}
  ${ProductOptionPdpFragmentDoc}
`;
export const PostBookmarkFragmentDoc = gql`
  fragment postBookmark on ExplorerPostBookmark {
    bookmarkedEntityId
    explorerPost {
      assets {
        id
        type
        url
      }
      description
      headline
      postId
      profile {
        brandSlug
        id
        type
        username
      }
      type
    }
    id
  }
`;
export const RelatedProductFieldsFragmentDoc = gql`
  fragment relatedProductFields on Product {
    brand
    brandSlug
    description
    familySlug
    sid
    slug
    title
    variants {
      currencyCode
      filterables {
        responsible
      }
      galleryImages {
        url
      }
      inStock
      offerDetails {
        endDate
        offerType
        startDate
      }
      price
      shoppingStatus
      sid
      sku
      title
    }
  }
`;
export const ImageDisplayUrlFragmentDoc = gql`
  fragment ImageDisplayUrl on Image {
    url
  }
`;
export const CustomerFieldsFragmentDoc = gql`
  fragment customerFields on Customer {
    createdAt
    email
    firstName
    id
    lastName
    multipassUrl
    shopifyCheckoutId
    yotpoId
  }
`;
export const ProfileFieldsFragmentDoc = gql`
  fragment profileFields on Profile {
    biography
    brandSlug
    coverImageAsset {
      url
    }
    creatorInfo {
      isCreator
      status
    }
    displayName
    facebookName
    followerCount
    followingCount
    id
    instagramName
    isFollowing
    isProfileOwner
    likeCount
    metaDescription
    metaTitle
    profileAsset {
      url
    }
    twitterName
    type
    username
  }
`;
export const ReviewsPageFieldsFragmentDoc = gql`
  fragment reviewsPageFields on ReviewsPage {
    pageInfo {
      currentPage
      numberOfPages
    }
    reviews {
      comment {
        content
        createdAt
        id
      }
      content
      id
      imagesData {
        id
        thumbUrl
        originalUrl
      }
      reviewDate
      score
      sourceName
      sourceUrl
      title
      user {
        displayName
      }
      verifiedBuyer
    }
    summary {
      averageScore
      ratingDistribution {
        count
        score
      }
      totalReview
    }
  }
`;
export const PostsFieldsFragmentDoc = gql`
  fragment postsFields on ExplorerPost {
    assets {
      dimensions {
        height
        width
      }
      id
      type
      url
    }
    description
    headline
    id
    modules {
      type
      ... on ExplorerProductsListModule {
        productVariants {
          sku
          galleryAssets {
            type
            url
          }
        }
        skus
      }
    }
    profile {
      id
      brandSlug
      profileAsset {
        url
      }
      type
      username
    }
    type
    transcriptUrl
  }
`;
export const LiveShopOwnerFieldsFragmentDoc = gql`
  fragment liveShopOwnerFields on Profile {
    brandSlug
    displayName
    profileAsset {
      url
      type
    }
    type
    username
  }
`;
export const LiveShopWithProductsNoAuthFieldsFragmentDoc = gql`
  fragment liveShopWithProductsNoAuthFields on LiveShop {
    confirmedEndsAt
    confirmedStartsAt
    coverAsset {
      url
      type
    }
    description
    endsAt
    id
    inviteCodes {
      inviteCode
      type
    }
    owner {
      ...liveShopOwnerFields
    }
    featuredProducts {
      productVariant {
        compareAtPrice
        galleryAssets {
          url
        }
        price
        product {
          brand
          brandSlug
          familySlug
          sid
          slug
          title
        }
        sku
        title
      }
    }
    startsAt
    status
    teaserAsset {
      url
      type
    }
    title
  }
  ${LiveShopOwnerFieldsFragmentDoc}
`;
export const LiveShopNoAuthFieldsFragmentDoc = gql`
  fragment liveShopNoAuthFields on LiveShop {
    confirmedEndsAt
    confirmedStartsAt
    coverAsset {
      url
      type
    }
    description
    endsAt
    inviteCodes {
      inviteCode
      type
    }
    owner {
      ...liveShopOwnerFields
    }
    startsAt
    status
    teaserAsset {
      url
      type
    }
    title
  }
  ${LiveShopOwnerFieldsFragmentDoc}
`;
export const CreateMultipassUrlDocument = gql`
  mutation createMultipassUrl($input: CreateMultipassUrlInput!) {
    createMultipassUrl(input: $input) {
      multipassUrl
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useCreateMultipassUrlMutation__
 *
 * To run a mutation, you first call `useCreateMultipassUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultipassUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultipassUrlMutation, { data, loading, error }] = useCreateMultipassUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMultipassUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMultipassUrlMutation,
    CreateMultipassUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMultipassUrlMutation,
    CreateMultipassUrlMutationVariables
  >(CreateMultipassUrlDocument, options);
}
export type CreateMultipassUrlMutationHookResult = ReturnType<
  typeof useCreateMultipassUrlMutation
>;
export type CreateMultipassUrlMutationResult =
  Apollo.MutationResult<CreateMultipassUrlMutation>;
export type CreateMultipassUrlMutationOptions = Apollo.BaseMutationOptions<
  CreateMultipassUrlMutation,
  CreateMultipassUrlMutationVariables
>;
export const CreateComingSoonNotificationDocument = gql`
  mutation createComingSoonNotification($input: ComingSoonNotificationInput!) {
    createComingSoonNotification(input: $input) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useCreateComingSoonNotificationMutation__
 *
 * To run a mutation, you first call `useCreateComingSoonNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComingSoonNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComingSoonNotificationMutation, { data, loading, error }] = useCreateComingSoonNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateComingSoonNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateComingSoonNotificationMutation,
    CreateComingSoonNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateComingSoonNotificationMutation,
    CreateComingSoonNotificationMutationVariables
  >(CreateComingSoonNotificationDocument, options);
}
export type CreateComingSoonNotificationMutationHookResult = ReturnType<
  typeof useCreateComingSoonNotificationMutation
>;
export type CreateComingSoonNotificationMutationResult =
  Apollo.MutationResult<CreateComingSoonNotificationMutation>;
export type CreateComingSoonNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateComingSoonNotificationMutation,
    CreateComingSoonNotificationMutationVariables
  >;
export const SendDmcaEmailDocument = gql`
  mutation sendDMCAEmail($input: EmailDMCAInput!) {
    sendDMCAEmail(input: $input) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useSendDmcaEmailMutation__
 *
 * To run a mutation, you first call `useSendDmcaEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDmcaEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDmcaEmailMutation, { data, loading, error }] = useSendDmcaEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendDmcaEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendDmcaEmailMutation,
    SendDmcaEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendDmcaEmailMutation,
    SendDmcaEmailMutationVariables
  >(SendDmcaEmailDocument, options);
}
export type SendDmcaEmailMutationHookResult = ReturnType<
  typeof useSendDmcaEmailMutation
>;
export type SendDmcaEmailMutationResult =
  Apollo.MutationResult<SendDmcaEmailMutation>;
export type SendDmcaEmailMutationOptions = Apollo.BaseMutationOptions<
  SendDmcaEmailMutation,
  SendDmcaEmailMutationVariables
>;
export const GetDynamicModulesDocument = gql`
  query getDynamicModules($modules: [DynamicModuleInput]!) {
    getDynamicModules(modules: $modules) {
      module
      segment
      identifier
      products {
        brand
        brandEntity {
          label
          slug
          subscriptionTier
        }
        brandSlug
        categories {
          category
        }
        family
        familySlug
        options {
          ... on ColorOption {
            label
            name
            type
            primary
            values {
              brandColor
              colorFamily
              label
              swatchColorCode
              value
            }
          }
          ... on StringOption {
            label
            name
            type
            primary
            values {
              label
              value
            }
          }
        }
        sid
        slug
        title
        variants {
          compareAtPrice
          currencyCode
          filterables {
            responsible
          }
          fullSid
          galleryImages {
            url
          }
          inStock
          price
          selectedOptions {
            name
            value
          }
          sku
          sid
          shoppingStatus
          title
        }
      }
    }
  }
`;

/**
 * __useGetDynamicModulesQuery__
 *
 * To run a query within a React component, call `useGetDynamicModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicModulesQuery({
 *   variables: {
 *      modules: // value for 'modules'
 *   },
 * });
 */
export function useGetDynamicModulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDynamicModulesQuery,
    GetDynamicModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDynamicModulesQuery,
    GetDynamicModulesQueryVariables
  >(GetDynamicModulesDocument, options);
}
export function useGetDynamicModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDynamicModulesQuery,
    GetDynamicModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDynamicModulesQuery,
    GetDynamicModulesQueryVariables
  >(GetDynamicModulesDocument, options);
}
export type GetDynamicModulesQueryHookResult = ReturnType<
  typeof useGetDynamicModulesQuery
>;
export type GetDynamicModulesLazyQueryHookResult = ReturnType<
  typeof useGetDynamicModulesLazyQuery
>;
export type GetDynamicModulesQueryResult = Apollo.QueryResult<
  GetDynamicModulesQuery,
  GetDynamicModulesQueryVariables
>;
export const EstimatedDeliveryQueryV3Document = gql`
  query estimatedDeliveryQueryV3($lineItems: [EstimatedDeliveryLineItem!]!) {
    estimatedDeliveryV3(lineItems: $lineItems) {
      cutoffDateTime
      maxDeliveryDate
      minDeliveryDate
      minOrderValue
      minOrderValueEligible
      productVariantSku
      shippingMethod
    }
    shippingTiers {
      threshold
      type
    }
  }
`;

/**
 * __useEstimatedDeliveryQueryV3Query__
 *
 * To run a query within a React component, call `useEstimatedDeliveryQueryV3Query` and pass it any options that fit your needs.
 * When your component renders, `useEstimatedDeliveryQueryV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimatedDeliveryQueryV3Query({
 *   variables: {
 *      lineItems: // value for 'lineItems'
 *   },
 * });
 */
export function useEstimatedDeliveryQueryV3Query(
  baseOptions: Apollo.QueryHookOptions<
    EstimatedDeliveryQueryV3Query,
    EstimatedDeliveryQueryV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EstimatedDeliveryQueryV3Query,
    EstimatedDeliveryQueryV3QueryVariables
  >(EstimatedDeliveryQueryV3Document, options);
}
export function useEstimatedDeliveryQueryV3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimatedDeliveryQueryV3Query,
    EstimatedDeliveryQueryV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EstimatedDeliveryQueryV3Query,
    EstimatedDeliveryQueryV3QueryVariables
  >(EstimatedDeliveryQueryV3Document, options);
}
export type EstimatedDeliveryQueryV3QueryHookResult = ReturnType<
  typeof useEstimatedDeliveryQueryV3Query
>;
export type EstimatedDeliveryQueryV3LazyQueryHookResult = ReturnType<
  typeof useEstimatedDeliveryQueryV3LazyQuery
>;
export type EstimatedDeliveryQueryV3QueryResult = Apollo.QueryResult<
  EstimatedDeliveryQueryV3Query,
  EstimatedDeliveryQueryV3QueryVariables
>;
export const GetVariantsForUserMenuDocument = gql`
  query getVariantsForUserMenu($skus: [String]) {
    productVariant(skus: $skus) {
      productBundleItems {
        sku
        quantity
      }
      sku
    }
  }
`;

/**
 * __useGetVariantsForUserMenuQuery__
 *
 * To run a query within a React component, call `useGetVariantsForUserMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVariantsForUserMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVariantsForUserMenuQuery({
 *   variables: {
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useGetVariantsForUserMenuQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVariantsForUserMenuQuery,
    GetVariantsForUserMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVariantsForUserMenuQuery,
    GetVariantsForUserMenuQueryVariables
  >(GetVariantsForUserMenuDocument, options);
}
export function useGetVariantsForUserMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVariantsForUserMenuQuery,
    GetVariantsForUserMenuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVariantsForUserMenuQuery,
    GetVariantsForUserMenuQueryVariables
  >(GetVariantsForUserMenuDocument, options);
}
export type GetVariantsForUserMenuQueryHookResult = ReturnType<
  typeof useGetVariantsForUserMenuQuery
>;
export type GetVariantsForUserMenuLazyQueryHookResult = ReturnType<
  typeof useGetVariantsForUserMenuLazyQuery
>;
export type GetVariantsForUserMenuQueryResult = Apollo.QueryResult<
  GetVariantsForUserMenuQuery,
  GetVariantsForUserMenuQueryVariables
>;
export const GenerateAnonymousIdentityDocument = gql`
  mutation generateAnonymousIdentity($anonymousUserId: String) {
    generateAnonymousIdentity(anonymousUserId: $anonymousUserId) {
      anonymousUserIdentity {
        id
      }
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useGenerateAnonymousIdentityMutation__
 *
 * To run a mutation, you first call `useGenerateAnonymousIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAnonymousIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAnonymousIdentityMutation, { data, loading, error }] = useGenerateAnonymousIdentityMutation({
 *   variables: {
 *      anonymousUserId: // value for 'anonymousUserId'
 *   },
 * });
 */
export function useGenerateAnonymousIdentityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateAnonymousIdentityMutation,
    GenerateAnonymousIdentityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateAnonymousIdentityMutation,
    GenerateAnonymousIdentityMutationVariables
  >(GenerateAnonymousIdentityDocument, options);
}
export type GenerateAnonymousIdentityMutationHookResult = ReturnType<
  typeof useGenerateAnonymousIdentityMutation
>;
export type GenerateAnonymousIdentityMutationResult =
  Apollo.MutationResult<GenerateAnonymousIdentityMutation>;
export type GenerateAnonymousIdentityMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateAnonymousIdentityMutation,
    GenerateAnonymousIdentityMutationVariables
  >;
export const AssociateUserToAnonymousIdDocument = gql`
  mutation associateUserToAnonymousId($anonymousUserId: String!) {
    associateUserToAnonymousId(anonymousUserId: $anonymousUserId) {
      anonymousUserIdentity {
        id
      }
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useAssociateUserToAnonymousIdMutation__
 *
 * To run a mutation, you first call `useAssociateUserToAnonymousIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateUserToAnonymousIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateUserToAnonymousIdMutation, { data, loading, error }] = useAssociateUserToAnonymousIdMutation({
 *   variables: {
 *      anonymousUserId: // value for 'anonymousUserId'
 *   },
 * });
 */
export function useAssociateUserToAnonymousIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssociateUserToAnonymousIdMutation,
    AssociateUserToAnonymousIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssociateUserToAnonymousIdMutation,
    AssociateUserToAnonymousIdMutationVariables
  >(AssociateUserToAnonymousIdDocument, options);
}
export type AssociateUserToAnonymousIdMutationHookResult = ReturnType<
  typeof useAssociateUserToAnonymousIdMutation
>;
export type AssociateUserToAnonymousIdMutationResult =
  Apollo.MutationResult<AssociateUserToAnonymousIdMutation>;
export type AssociateUserToAnonymousIdMutationOptions =
  Apollo.BaseMutationOptions<
    AssociateUserToAnonymousIdMutation,
    AssociateUserToAnonymousIdMutationVariables
  >;
export const GetPromoCodeDocument = gql`
  query getPromoCode {
    promoCode {
      code
      details
      discounts {
        discount
        eligibility {
          type
          value
        }
      }
      endDate
      id
      minSubtotal
      startDate
      appliesToDiscountedItems
    }
  }
`;

/**
 * __useGetPromoCodeQuery__
 *
 * To run a query within a React component, call `useGetPromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromoCodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPromoCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPromoCodeQuery,
    GetPromoCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPromoCodeQuery, GetPromoCodeQueryVariables>(
    GetPromoCodeDocument,
    options
  );
}
export function useGetPromoCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPromoCodeQuery,
    GetPromoCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPromoCodeQuery, GetPromoCodeQueryVariables>(
    GetPromoCodeDocument,
    options
  );
}
export type GetPromoCodeQueryHookResult = ReturnType<
  typeof useGetPromoCodeQuery
>;
export type GetPromoCodeLazyQueryHookResult = ReturnType<
  typeof useGetPromoCodeLazyQuery
>;
export type GetPromoCodeQueryResult = Apollo.QueryResult<
  GetPromoCodeQuery,
  GetPromoCodeQueryVariables
>;
export const GetMerchantInfoForOrderDocument = gql`
  query getMerchantInfoForOrder($shopifyOrderId: String!) {
    merchantInfoForOrder(shopifyOrderId: $shopifyOrderId) {
      productVariant {
        brandSlug
        familySlug
        options {
          type
          name
          values {
            label
            value
          }
        }
        productId
        productSlug
        selectedOptions {
          name
          value
        }
        title
      }
      merchantPublicInfo {
        fullName
        email
        phoneNumber
        address {
          id
          street1
          street2
          city
          state
          country
          zipCode
        }
      }
    }
  }
`;

/**
 * __useGetMerchantInfoForOrderQuery__
 *
 * To run a query within a React component, call `useGetMerchantInfoForOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantInfoForOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantInfoForOrderQuery({
 *   variables: {
 *      shopifyOrderId: // value for 'shopifyOrderId'
 *   },
 * });
 */
export function useGetMerchantInfoForOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMerchantInfoForOrderQuery,
    GetMerchantInfoForOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMerchantInfoForOrderQuery,
    GetMerchantInfoForOrderQueryVariables
  >(GetMerchantInfoForOrderDocument, options);
}
export function useGetMerchantInfoForOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMerchantInfoForOrderQuery,
    GetMerchantInfoForOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMerchantInfoForOrderQuery,
    GetMerchantInfoForOrderQueryVariables
  >(GetMerchantInfoForOrderDocument, options);
}
export type GetMerchantInfoForOrderQueryHookResult = ReturnType<
  typeof useGetMerchantInfoForOrderQuery
>;
export type GetMerchantInfoForOrderLazyQueryHookResult = ReturnType<
  typeof useGetMerchantInfoForOrderLazyQuery
>;
export type GetMerchantInfoForOrderQueryResult = Apollo.QueryResult<
  GetMerchantInfoForOrderQuery,
  GetMerchantInfoForOrderQueryVariables
>;
export const SetGiftCardMessageDocument = gql`
  mutation setGiftCardMessage($cartLineItemId: ID!, $giftCardMessage: String!) {
    setGiftCardMessage(
      cartLineItemId: $cartLineItemId
      giftCardMessage: $giftCardMessage
    ) @client
  }
`;

/**
 * __useSetGiftCardMessageMutation__
 *
 * To run a mutation, you first call `useSetGiftCardMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGiftCardMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGiftCardMessageMutation, { data, loading, error }] = useSetGiftCardMessageMutation({
 *   variables: {
 *      cartLineItemId: // value for 'cartLineItemId'
 *      giftCardMessage: // value for 'giftCardMessage'
 *   },
 * });
 */
export function useSetGiftCardMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetGiftCardMessageMutation,
    SetGiftCardMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetGiftCardMessageMutation,
    SetGiftCardMessageMutationVariables
  >(SetGiftCardMessageDocument, options);
}
export type SetGiftCardMessageMutationHookResult = ReturnType<
  typeof useSetGiftCardMessageMutation
>;
export type SetGiftCardMessageMutationResult =
  Apollo.MutationResult<SetGiftCardMessageMutation>;
export type SetGiftCardMessageMutationOptions = Apollo.BaseMutationOptions<
  SetGiftCardMessageMutation,
  SetGiftCardMessageMutationVariables
>;
export const GetGiftNoteDocument = gql`
  query getGiftNote {
    checkout @client {
      customAttributes {
        key
        value
      }
    }
  }
`;

/**
 * __useGetGiftNoteQuery__
 *
 * To run a query within a React component, call `useGetGiftNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftNoteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGiftNoteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGiftNoteQuery,
    GetGiftNoteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGiftNoteQuery, GetGiftNoteQueryVariables>(
    GetGiftNoteDocument,
    options
  );
}
export function useGetGiftNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGiftNoteQuery,
    GetGiftNoteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGiftNoteQuery, GetGiftNoteQueryVariables>(
    GetGiftNoteDocument,
    options
  );
}
export type GetGiftNoteQueryHookResult = ReturnType<typeof useGetGiftNoteQuery>;
export type GetGiftNoteLazyQueryHookResult = ReturnType<
  typeof useGetGiftNoteLazyQuery
>;
export type GetGiftNoteQueryResult = Apollo.QueryResult<
  GetGiftNoteQuery,
  GetGiftNoteQueryVariables
>;
export const SetGiftNoteDocument = gql`
  mutation setGiftNote($giftNote: String!) {
    setGiftNote(giftNote: $giftNote) @client
  }
`;

/**
 * __useSetGiftNoteMutation__
 *
 * To run a mutation, you first call `useSetGiftNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGiftNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGiftNoteMutation, { data, loading, error }] = useSetGiftNoteMutation({
 *   variables: {
 *      giftNote: // value for 'giftNote'
 *   },
 * });
 */
export function useSetGiftNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetGiftNoteMutation,
    SetGiftNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetGiftNoteMutation, SetGiftNoteMutationVariables>(
    SetGiftNoteDocument,
    options
  );
}
export type SetGiftNoteMutationHookResult = ReturnType<
  typeof useSetGiftNoteMutation
>;
export type SetGiftNoteMutationResult =
  Apollo.MutationResult<SetGiftNoteMutation>;
export type SetGiftNoteMutationOptions = Apollo.BaseMutationOptions<
  SetGiftNoteMutation,
  SetGiftNoteMutationVariables
>;
export const CreateInStockNotificationDocument = gql`
  mutation createInStockNotification($input: InStockNotificationInput!) {
    createInStockNotification(input: $input) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useCreateInStockNotificationMutation__
 *
 * To run a mutation, you first call `useCreateInStockNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInStockNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInStockNotificationMutation, { data, loading, error }] = useCreateInStockNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInStockNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInStockNotificationMutation,
    CreateInStockNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInStockNotificationMutation,
    CreateInStockNotificationMutationVariables
  >(CreateInStockNotificationDocument, options);
}
export type CreateInStockNotificationMutationHookResult = ReturnType<
  typeof useCreateInStockNotificationMutation
>;
export type CreateInStockNotificationMutationResult =
  Apollo.MutationResult<CreateInStockNotificationMutation>;
export type CreateInStockNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInStockNotificationMutation,
    CreateInStockNotificationMutationVariables
  >;
export const CreateEmailSignUpDocument = gql`
  mutation createEmailSignUp($input: EmailSignUpInput!) {
    createEmailSignUp(input: $input) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useCreateEmailSignUpMutation__
 *
 * To run a mutation, you first call `useCreateEmailSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailSignUpMutation, { data, loading, error }] = useCreateEmailSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmailSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEmailSignUpMutation,
    CreateEmailSignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEmailSignUpMutation,
    CreateEmailSignUpMutationVariables
  >(CreateEmailSignUpDocument, options);
}
export type CreateEmailSignUpMutationHookResult = ReturnType<
  typeof useCreateEmailSignUpMutation
>;
export type CreateEmailSignUpMutationResult =
  Apollo.MutationResult<CreateEmailSignUpMutation>;
export type CreateEmailSignUpMutationOptions = Apollo.BaseMutationOptions<
  CreateEmailSignUpMutation,
  CreateEmailSignUpMutationVariables
>;
export const GetOrderByIdDocument = gql`
  query getOrderById($orderId: String) {
    me {
      id
      orders(id: $orderId) {
        createdAt
        currencyCode
        customerSid
        fulfillments {
          lineItems {
            sku
          }
          shipmentStatus
          status
          trackingCompany
          trackingNumber
        }
        id
        lineItems {
          id
          price
          productVariant {
            galleryImages {
              url
            }
            inStock
            modelImages {
              url
            }
            product {
              brandSlug
              familySlug
              hierarchicalCategories {
                lvl0
              }
              sid
              slug
              options {
                ... on ColorOption {
                  name
                  type
                  values {
                    brandColor
                    colorFamily
                    label
                    swatchColorCode
                    value
                  }
                }
                ... on StringOption {
                  name
                  type
                  values {
                    label
                    value
                  }
                }
              }
            }
            productImages {
              url
            }
            selectedOptions {
              name
              value
            }
            shoppingStatus
          }
          quantity
          sku
          title
          variantId
          vendor
        }
        orderNumber
        paymentDetails {
          creditCardCompany
          creditCardNumber
        }
        shippingAddress {
          address1
          address2
          city
          countryCode
          name
          provinceCode
          zip
        }
        subtotalPriceSet {
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalDiscountsSet {
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalLineItemsPriceSet {
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalPriceSet {
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalShippingPriceSet {
          presentmentMoney {
            amount
            currencyCode
          }
        }
        totalTaxSet {
          presentmentMoney {
            amount
            currencyCode
          }
        }
        name
        subtotalLineItemsQuantity
      }
    }
  }
`;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export function useGetOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export type GetOrderByIdQueryHookResult = ReturnType<
  typeof useGetOrderByIdQuery
>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrderByIdLazyQuery
>;
export type GetOrderByIdQueryResult = Apollo.QueryResult<
  GetOrderByIdQuery,
  GetOrderByIdQueryVariables
>;
export const GetOrdersDocument = gql`
  query getOrders {
    me {
      id
      orders {
        createdAt
        customerSid
        currencyCode
        fulfillments {
          lineItems {
            sku
          }
          shipmentStatus
          status
          trackingCompany
          trackingNumber
        }
        id
        lineItems {
          id
          price
          productVariant {
            galleryImages {
              ...ImageDisplayUrl
            }
            inStock
            modelImages {
              ...ImageDisplayUrl
            }
            product {
              brandSlug
              familySlug
              hierarchicalCategories {
                lvl0
              }
              sid
              slug
              options {
                ... on ColorOption {
                  name
                  type
                  values {
                    brandColor
                    colorFamily
                    label
                    swatchColorCode
                    value
                  }
                }
                ... on StringOption {
                  name
                  type
                  values {
                    label
                    value
                  }
                }
              }
            }
            productImages {
              ...ImageDisplayUrl
            }
            selectedOptions {
              name
              value
            }
            shoppingStatus
          }
          quantity
          sku
          title
          variantId
          vendor
        }
        orderNumber
        totalPriceSet {
          presentmentMoney {
            amount
            currencyCode
          }
        }
        name
        subtotalLineItemsQuantity
      }
    }
    getReviewsForRewards {
      orderId
      products {
        potentialValue
        productSid
        reviewWritten
        rewardGenerated
        sku
      }
    }
  }
  ${ImageDisplayUrlFragmentDoc}
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options
  );
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersQuery,
    GetOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options
  );
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<
  typeof useGetOrdersLazyQuery
>;
export type GetOrdersQueryResult = Apollo.QueryResult<
  GetOrdersQuery,
  GetOrdersQueryVariables
>;
export const WebClaimReviewIncentiveRewardDocument = gql`
  mutation webClaimReviewIncentiveReward($orderId: String!) {
    generateRewardForOrderId(orderId: $orderId) {
      success
      rewardValue
    }
  }
`;

/**
 * __useWebClaimReviewIncentiveRewardMutation__
 *
 * To run a mutation, you first call `useWebClaimReviewIncentiveRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebClaimReviewIncentiveRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webClaimReviewIncentiveRewardMutation, { data, loading, error }] = useWebClaimReviewIncentiveRewardMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useWebClaimReviewIncentiveRewardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebClaimReviewIncentiveRewardMutation,
    WebClaimReviewIncentiveRewardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebClaimReviewIncentiveRewardMutation,
    WebClaimReviewIncentiveRewardMutationVariables
  >(WebClaimReviewIncentiveRewardDocument, options);
}
export type WebClaimReviewIncentiveRewardMutationHookResult = ReturnType<
  typeof useWebClaimReviewIncentiveRewardMutation
>;
export type WebClaimReviewIncentiveRewardMutationResult =
  Apollo.MutationResult<WebClaimReviewIncentiveRewardMutation>;
export type WebClaimReviewIncentiveRewardMutationOptions =
  Apollo.BaseMutationOptions<
    WebClaimReviewIncentiveRewardMutation,
    WebClaimReviewIncentiveRewardMutationVariables
  >;
export const EstimatedDeliveryTwoDayShippingDocument = gql`
  query estimatedDeliveryTwoDayShipping {
    estimatedDeliveryTwoDayShipping {
      cutoffDateTime
      maxDeliveryDate
    }
  }
`;

/**
 * __useEstimatedDeliveryTwoDayShippingQuery__
 *
 * To run a query within a React component, call `useEstimatedDeliveryTwoDayShippingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimatedDeliveryTwoDayShippingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimatedDeliveryTwoDayShippingQuery({
 *   variables: {
 *   },
 * });
 */
export function useEstimatedDeliveryTwoDayShippingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EstimatedDeliveryTwoDayShippingQuery,
    EstimatedDeliveryTwoDayShippingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EstimatedDeliveryTwoDayShippingQuery,
    EstimatedDeliveryTwoDayShippingQueryVariables
  >(EstimatedDeliveryTwoDayShippingDocument, options);
}
export function useEstimatedDeliveryTwoDayShippingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimatedDeliveryTwoDayShippingQuery,
    EstimatedDeliveryTwoDayShippingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EstimatedDeliveryTwoDayShippingQuery,
    EstimatedDeliveryTwoDayShippingQueryVariables
  >(EstimatedDeliveryTwoDayShippingDocument, options);
}
export type EstimatedDeliveryTwoDayShippingQueryHookResult = ReturnType<
  typeof useEstimatedDeliveryTwoDayShippingQuery
>;
export type EstimatedDeliveryTwoDayShippingLazyQueryHookResult = ReturnType<
  typeof useEstimatedDeliveryTwoDayShippingLazyQuery
>;
export type EstimatedDeliveryTwoDayShippingQueryResult = Apollo.QueryResult<
  EstimatedDeliveryTwoDayShippingQuery,
  EstimatedDeliveryTwoDayShippingQueryVariables
>;
export const GetRecommendedProductsDocument = gql`
  query getRecommendedProducts(
    $cursor: Int
    $limit: Int
    $modelId: String!
    $modelVariantId: String!
    $productIds: [String]!
  ) {
    recommendedMultiKeyProducts(
      key: {
        modelId: $modelId
        modelVariantId: $modelVariantId
        productIds: $productIds
      }
      page: { cursor: $cursor, limit: $limit }
    ) {
      brand
      brandSlug
      categories {
        category
      }
      familySlug
      hierarchicalCategories {
        lvl0
        lvl1
        lvl2
        lvl3
      }
      options {
        type
        name
        values {
          label
          value
        }
      }
      reviewSummary {
        averageScore
        totalReview
      }
      sid
      slug
      title
      variants(filterByShoppable: true) {
        compareAtPrice
        eligiblePromotions
        galleryImages {
          url
        }
        inStock
        isDiscountable
        isMarketplace
        isProductBundle
        modelImages {
          url
        }
        offerDetails {
          offerType
          startDate
        }
        price
        productImages {
          url
        }
        selectedOptions {
          name
          value
        }
        sid
        sku
      }
    }
  }
`;

/**
 * __useGetRecommendedProductsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedProductsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      modelId: // value for 'modelId'
 *      modelVariantId: // value for 'modelVariantId'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useGetRecommendedProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecommendedProductsQuery,
    GetRecommendedProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecommendedProductsQuery,
    GetRecommendedProductsQueryVariables
  >(GetRecommendedProductsDocument, options);
}
export function useGetRecommendedProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecommendedProductsQuery,
    GetRecommendedProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecommendedProductsQuery,
    GetRecommendedProductsQueryVariables
  >(GetRecommendedProductsDocument, options);
}
export type GetRecommendedProductsQueryHookResult = ReturnType<
  typeof useGetRecommendedProductsQuery
>;
export type GetRecommendedProductsLazyQueryHookResult = ReturnType<
  typeof useGetRecommendedProductsLazyQuery
>;
export type GetRecommendedProductsQueryResult = Apollo.QueryResult<
  GetRecommendedProductsQuery,
  GetRecommendedProductsQueryVariables
>;
export const GetBrandsDocument = gql`
  query getBrands {
    brands {
      label
      slug
    }
  }
`;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(
    GetBrandsDocument,
    options
  );
}
export function useGetBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandsQuery,
    GetBrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(
    GetBrandsDocument,
    options
  );
}
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<
  typeof useGetBrandsLazyQuery
>;
export type GetBrandsQueryResult = Apollo.QueryResult<
  GetBrandsQuery,
  GetBrandsQueryVariables
>;
export const ReviewFormMutationDocument = gql`
  mutation reviewFormMutation($input: CreateProductReviewInput!) {
    createProductReview(createProductReviewInput: $input) {
      success
      validationErrors {
        code
        field
        message
      }
    }
  }
`;

/**
 * __useReviewFormMutationMutation__
 *
 * To run a mutation, you first call `useReviewFormMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewFormMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewFormMutationMutation, { data, loading, error }] = useReviewFormMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewFormMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewFormMutationMutation,
    ReviewFormMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReviewFormMutationMutation,
    ReviewFormMutationMutationVariables
  >(ReviewFormMutationDocument, options);
}
export type ReviewFormMutationMutationHookResult = ReturnType<
  typeof useReviewFormMutationMutation
>;
export type ReviewFormMutationMutationResult =
  Apollo.MutationResult<ReviewFormMutationMutation>;
export type ReviewFormMutationMutationOptions = Apollo.BaseMutationOptions<
  ReviewFormMutationMutation,
  ReviewFormMutationMutationVariables
>;
export const WebCreateOnboardingDiscountDocument = gql`
  mutation webCreateOnboardingDiscount($input: CreateOnboardingDiscountInput!) {
    createOnboardingDiscount(input: $input) {
      success
      validationErrors {
        code
        field
        message
      }
    }
  }
`;

/**
 * __useWebCreateOnboardingDiscountMutation__
 *
 * To run a mutation, you first call `useWebCreateOnboardingDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebCreateOnboardingDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webCreateOnboardingDiscountMutation, { data, loading, error }] = useWebCreateOnboardingDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebCreateOnboardingDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebCreateOnboardingDiscountMutation,
    WebCreateOnboardingDiscountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebCreateOnboardingDiscountMutation,
    WebCreateOnboardingDiscountMutationVariables
  >(WebCreateOnboardingDiscountDocument, options);
}
export type WebCreateOnboardingDiscountMutationHookResult = ReturnType<
  typeof useWebCreateOnboardingDiscountMutation
>;
export type WebCreateOnboardingDiscountMutationResult =
  Apollo.MutationResult<WebCreateOnboardingDiscountMutation>;
export type WebCreateOnboardingDiscountMutationOptions =
  Apollo.BaseMutationOptions<
    WebCreateOnboardingDiscountMutation,
    WebCreateOnboardingDiscountMutationVariables
  >;
export const VariantsForProductCarouselDocument = gql`
  query variantsForProductCarousel($sids: [String], $skus: [String]) {
    productVariant(sids: $sids, skus: $skus) {
      compareAtPrice
      eligiblePromotions
      galleryImages {
        url
      }
      isDiscountable
      isMarketplace
      isProductBundle
      modelImages {
        url
      }
      offerDetails {
        offerType
      }
      price
      product {
        brand
        brandSlug
        categories {
          category
        }
        familySlug
        hierarchicalCategories {
          lvl0
          lvl1
          lvl2
          lvl3
        }
        sid
        slug
        title
      }
      productImages {
        url
      }
      selectedOptions {
        name
        value
      }
      sid
      sku
    }
  }
`;

/**
 * __useVariantsForProductCarouselQuery__
 *
 * To run a query within a React component, call `useVariantsForProductCarouselQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantsForProductCarouselQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantsForProductCarouselQuery({
 *   variables: {
 *      sids: // value for 'sids'
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useVariantsForProductCarouselQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VariantsForProductCarouselQuery,
    VariantsForProductCarouselQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VariantsForProductCarouselQuery,
    VariantsForProductCarouselQueryVariables
  >(VariantsForProductCarouselDocument, options);
}
export function useVariantsForProductCarouselLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VariantsForProductCarouselQuery,
    VariantsForProductCarouselQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VariantsForProductCarouselQuery,
    VariantsForProductCarouselQueryVariables
  >(VariantsForProductCarouselDocument, options);
}
export type VariantsForProductCarouselQueryHookResult = ReturnType<
  typeof useVariantsForProductCarouselQuery
>;
export type VariantsForProductCarouselLazyQueryHookResult = ReturnType<
  typeof useVariantsForProductCarouselLazyQuery
>;
export type VariantsForProductCarouselQueryResult = Apollo.QueryResult<
  VariantsForProductCarouselQuery,
  VariantsForProductCarouselQueryVariables
>;
export const GetShoppablePhotosBySkuDocument = gql`
  query getShoppablePhotosBySku($skus: [String]) {
    productVariant(skus: $skus) {
      price
      product {
        brand
        brandSlug
        familySlug
        sid
        slug
      }
      productImages {
        url
      }
      sku
    }
  }
`;

/**
 * __useGetShoppablePhotosBySkuQuery__
 *
 * To run a query within a React component, call `useGetShoppablePhotosBySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShoppablePhotosBySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShoppablePhotosBySkuQuery({
 *   variables: {
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useGetShoppablePhotosBySkuQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetShoppablePhotosBySkuQuery,
    GetShoppablePhotosBySkuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetShoppablePhotosBySkuQuery,
    GetShoppablePhotosBySkuQueryVariables
  >(GetShoppablePhotosBySkuDocument, options);
}
export function useGetShoppablePhotosBySkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShoppablePhotosBySkuQuery,
    GetShoppablePhotosBySkuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetShoppablePhotosBySkuQuery,
    GetShoppablePhotosBySkuQueryVariables
  >(GetShoppablePhotosBySkuDocument, options);
}
export type GetShoppablePhotosBySkuQueryHookResult = ReturnType<
  typeof useGetShoppablePhotosBySkuQuery
>;
export type GetShoppablePhotosBySkuLazyQueryHookResult = ReturnType<
  typeof useGetShoppablePhotosBySkuLazyQuery
>;
export type GetShoppablePhotosBySkuQueryResult = Apollo.QueryResult<
  GetShoppablePhotosBySkuQuery,
  GetShoppablePhotosBySkuQueryVariables
>;
export const GetProductVariantsBySkuForBlocksModuleDocument = gql`
  query getProductVariantsBySkuForBlocksModule($skus: [String]) {
    productVariant(skus: $skus) {
      price
      product {
        brand
        brandSlug
        familySlug
        sid
        slug
        title
      }
      productImages {
        url
      }
      sku
    }
  }
`;

/**
 * __useGetProductVariantsBySkuForBlocksModuleQuery__
 *
 * To run a query within a React component, call `useGetProductVariantsBySkuForBlocksModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVariantsBySkuForBlocksModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVariantsBySkuForBlocksModuleQuery({
 *   variables: {
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useGetProductVariantsBySkuForBlocksModuleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductVariantsBySkuForBlocksModuleQuery,
    GetProductVariantsBySkuForBlocksModuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductVariantsBySkuForBlocksModuleQuery,
    GetProductVariantsBySkuForBlocksModuleQueryVariables
  >(GetProductVariantsBySkuForBlocksModuleDocument, options);
}
export function useGetProductVariantsBySkuForBlocksModuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductVariantsBySkuForBlocksModuleQuery,
    GetProductVariantsBySkuForBlocksModuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductVariantsBySkuForBlocksModuleQuery,
    GetProductVariantsBySkuForBlocksModuleQueryVariables
  >(GetProductVariantsBySkuForBlocksModuleDocument, options);
}
export type GetProductVariantsBySkuForBlocksModuleQueryHookResult = ReturnType<
  typeof useGetProductVariantsBySkuForBlocksModuleQuery
>;
export type GetProductVariantsBySkuForBlocksModuleLazyQueryHookResult =
  ReturnType<typeof useGetProductVariantsBySkuForBlocksModuleLazyQuery>;
export type GetProductVariantsBySkuForBlocksModuleQueryResult =
  Apollo.QueryResult<
    GetProductVariantsBySkuForBlocksModuleQuery,
    GetProductVariantsBySkuForBlocksModuleQueryVariables
  >;
export const CartLineItemsInfoDocument = gql`
  query cartLineItemsInfo($skus: [String]) {
    productVariant(skus: $skus) {
      bulky
      compareAtPrice
      eligiblePromotions
      finalSale
      fullSid
      galleryImages {
        url
      }
      hazmat
      inStock
      isDiscountable
      isDropshipped
      isGiftCard
      isLowStock
      isMarketplace
      isProductBundle
      price
      product {
        brand
        brandSlug
        categories {
          category
        }
        familySlug
        options {
          ... on ColorOption {
            label
            name
            type
            values {
              brandColor
              colorFamily
              label
              swatchColorCode
              value
            }
          }
          ... on StringOption {
            label
            name
            type
            values {
              label
              value
            }
          }
        }
        sid
        slug
        title
      }
      productBundleItems {
        sid
        sku
        quantity
      }
      productImages {
        url
      }
      selectedOptions {
        name
        value
      }
      shoppingStatus
      sid
      sku
      title
    }
    shippingTiers {
      shippingFee
      shippingMethod
      threshold
      type
    }
  }
`;

/**
 * __useCartLineItemsInfoQuery__
 *
 * To run a query within a React component, call `useCartLineItemsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartLineItemsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartLineItemsInfoQuery({
 *   variables: {
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useCartLineItemsInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CartLineItemsInfoQuery,
    CartLineItemsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CartLineItemsInfoQuery,
    CartLineItemsInfoQueryVariables
  >(CartLineItemsInfoDocument, options);
}
export function useCartLineItemsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CartLineItemsInfoQuery,
    CartLineItemsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CartLineItemsInfoQuery,
    CartLineItemsInfoQueryVariables
  >(CartLineItemsInfoDocument, options);
}
export type CartLineItemsInfoQueryHookResult = ReturnType<
  typeof useCartLineItemsInfoQuery
>;
export type CartLineItemsInfoLazyQueryHookResult = ReturnType<
  typeof useCartLineItemsInfoLazyQuery
>;
export type CartLineItemsInfoQueryResult = Apollo.QueryResult<
  CartLineItemsInfoQuery,
  CartLineItemsInfoQueryVariables
>;
export const RemoveItemsFromCartDocument = gql`
  mutation removeItemsFromCart($cartLineItemIds: [ID]!) {
    removeItemsFromCart(cartLineItemIds: $cartLineItemIds) @client {
      checkout {
        id
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

/**
 * __useRemoveItemsFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemsFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemsFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemsFromCartMutation, { data, loading, error }] = useRemoveItemsFromCartMutation({
 *   variables: {
 *      cartLineItemIds: // value for 'cartLineItemIds'
 *   },
 * });
 */
export function useRemoveItemsFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveItemsFromCartMutation,
    RemoveItemsFromCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveItemsFromCartMutation,
    RemoveItemsFromCartMutationVariables
  >(RemoveItemsFromCartDocument, options);
}
export type RemoveItemsFromCartMutationHookResult = ReturnType<
  typeof useRemoveItemsFromCartMutation
>;
export type RemoveItemsFromCartMutationResult =
  Apollo.MutationResult<RemoveItemsFromCartMutation>;
export type RemoveItemsFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemsFromCartMutation,
  RemoveItemsFromCartMutationVariables
>;
export const SaveForLaterBookmarkListDocument = gql`
  query saveForLaterBookmarkList {
    me {
      id
      saveForLaterBookmarkList {
        bookmarks(order: ASC) {
          items {
            ... on ProductBookmark {
              product {
                brand
                sid
                title
              }
            }
          }
        }
        id
        name
      }
    }
  }
`;

/**
 * __useSaveForLaterBookmarkListQuery__
 *
 * To run a query within a React component, call `useSaveForLaterBookmarkListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaveForLaterBookmarkListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaveForLaterBookmarkListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSaveForLaterBookmarkListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SaveForLaterBookmarkListQuery,
    SaveForLaterBookmarkListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SaveForLaterBookmarkListQuery,
    SaveForLaterBookmarkListQueryVariables
  >(SaveForLaterBookmarkListDocument, options);
}
export function useSaveForLaterBookmarkListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SaveForLaterBookmarkListQuery,
    SaveForLaterBookmarkListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SaveForLaterBookmarkListQuery,
    SaveForLaterBookmarkListQueryVariables
  >(SaveForLaterBookmarkListDocument, options);
}
export type SaveForLaterBookmarkListQueryHookResult = ReturnType<
  typeof useSaveForLaterBookmarkListQuery
>;
export type SaveForLaterBookmarkListLazyQueryHookResult = ReturnType<
  typeof useSaveForLaterBookmarkListLazyQuery
>;
export type SaveForLaterBookmarkListQueryResult = Apollo.QueryResult<
  SaveForLaterBookmarkListQuery,
  SaveForLaterBookmarkListQueryVariables
>;
export const DeleteBookmarkDocument = gql`
  mutation deleteBookmark($id: ID!) {
    deleteBookmark(id: $id) {
      id
      success
    }
  }
`;

/**
 * __useDeleteBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkMutation, { data, loading, error }] = useDeleteBookmarkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBookmarkMutation,
    DeleteBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBookmarkMutation,
    DeleteBookmarkMutationVariables
  >(DeleteBookmarkDocument, options);
}
export type DeleteBookmarkMutationHookResult = ReturnType<
  typeof useDeleteBookmarkMutation
>;
export type DeleteBookmarkMutationResult =
  Apollo.MutationResult<DeleteBookmarkMutation>;
export type DeleteBookmarkMutationOptions = Apollo.BaseMutationOptions<
  DeleteBookmarkMutation,
  DeleteBookmarkMutationVariables
>;
export const GetSaveForLaterBookmarkItemsDocument = gql`
  query getSaveForLaterBookmarkItems {
    me {
      id
      saveForLaterBookmarkList {
        bookmarks {
          items {
            id
          }
        }
        id
      }
    }
  }
`;

/**
 * __useGetSaveForLaterBookmarkItemsQuery__
 *
 * To run a query within a React component, call `useGetSaveForLaterBookmarkItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaveForLaterBookmarkItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaveForLaterBookmarkItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSaveForLaterBookmarkItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSaveForLaterBookmarkItemsQuery,
    GetSaveForLaterBookmarkItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSaveForLaterBookmarkItemsQuery,
    GetSaveForLaterBookmarkItemsQueryVariables
  >(GetSaveForLaterBookmarkItemsDocument, options);
}
export function useGetSaveForLaterBookmarkItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSaveForLaterBookmarkItemsQuery,
    GetSaveForLaterBookmarkItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSaveForLaterBookmarkItemsQuery,
    GetSaveForLaterBookmarkItemsQueryVariables
  >(GetSaveForLaterBookmarkItemsDocument, options);
}
export type GetSaveForLaterBookmarkItemsQueryHookResult = ReturnType<
  typeof useGetSaveForLaterBookmarkItemsQuery
>;
export type GetSaveForLaterBookmarkItemsLazyQueryHookResult = ReturnType<
  typeof useGetSaveForLaterBookmarkItemsLazyQuery
>;
export type GetSaveForLaterBookmarkItemsQueryResult = Apollo.QueryResult<
  GetSaveForLaterBookmarkItemsQuery,
  GetSaveForLaterBookmarkItemsQueryVariables
>;
export const GetSaveForLaterItemsDocument = gql`
  query getSaveForLaterItems {
    me {
      id
      saveForLaterBookmarkList {
        bookmarks {
          items {
            ... on ProductBookmark {
              id
              product {
                brand
                brandSlug
                familySlug
                hierarchicalCategories {
                  lvl0
                }
                options {
                  label
                  name
                  values {
                    label
                    value
                  }
                }
                sid
                slug
                title
                variants {
                  compareAtPrice
                  galleryImages {
                    url
                  }
                  inStock
                  price
                  productBundleItems {
                    quantity
                    sid
                    sku
                  }
                  productImages {
                    url
                  }
                  selectedOptions {
                    name
                    value
                  }
                  sid
                  sku
                }
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
        id
      }
    }
  }
`;

/**
 * __useGetSaveForLaterItemsQuery__
 *
 * To run a query within a React component, call `useGetSaveForLaterItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaveForLaterItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaveForLaterItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSaveForLaterItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSaveForLaterItemsQuery,
    GetSaveForLaterItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSaveForLaterItemsQuery,
    GetSaveForLaterItemsQueryVariables
  >(GetSaveForLaterItemsDocument, options);
}
export function useGetSaveForLaterItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSaveForLaterItemsQuery,
    GetSaveForLaterItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSaveForLaterItemsQuery,
    GetSaveForLaterItemsQueryVariables
  >(GetSaveForLaterItemsDocument, options);
}
export type GetSaveForLaterItemsQueryHookResult = ReturnType<
  typeof useGetSaveForLaterItemsQuery
>;
export type GetSaveForLaterItemsLazyQueryHookResult = ReturnType<
  typeof useGetSaveForLaterItemsLazyQuery
>;
export type GetSaveForLaterItemsQueryResult = Apollo.QueryResult<
  GetSaveForLaterItemsQuery,
  GetSaveForLaterItemsQueryVariables
>;
export const GetCategoriesDocument = gql`
  query getCategories {
    categories {
      label
      parentSlug
      slug
    }
  }
`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCategoriesQuery,
    GetCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    options
  );
}
export function useGetCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoriesQuery,
    GetCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
    GetCategoriesDocument,
    options
  );
}
export type GetCategoriesQueryHookResult = ReturnType<
  typeof useGetCategoriesQuery
>;
export type GetCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetCategoriesLazyQuery
>;
export type GetCategoriesQueryResult = Apollo.QueryResult<
  GetCategoriesQuery,
  GetCategoriesQueryVariables
>;
export const ProductBookmarksBySidsDocument = gql`
  query productBookmarksBySids($sids: [String]!) {
    me {
      id
      productBookmarksBySids(sids: $sids) {
        productBookmarks {
          bookmarkList {
            id
            name
          }
          id
          selectedOptions {
            name
            value
          }
        }
        productSid
      }
    }
  }
`;

/**
 * __useProductBookmarksBySidsQuery__
 *
 * To run a query within a React component, call `useProductBookmarksBySidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBookmarksBySidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBookmarksBySidsQuery({
 *   variables: {
 *      sids: // value for 'sids'
 *   },
 * });
 */
export function useProductBookmarksBySidsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductBookmarksBySidsQuery,
    ProductBookmarksBySidsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductBookmarksBySidsQuery,
    ProductBookmarksBySidsQueryVariables
  >(ProductBookmarksBySidsDocument, options);
}
export function useProductBookmarksBySidsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductBookmarksBySidsQuery,
    ProductBookmarksBySidsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductBookmarksBySidsQuery,
    ProductBookmarksBySidsQueryVariables
  >(ProductBookmarksBySidsDocument, options);
}
export type ProductBookmarksBySidsQueryHookResult = ReturnType<
  typeof useProductBookmarksBySidsQuery
>;
export type ProductBookmarksBySidsLazyQueryHookResult = ReturnType<
  typeof useProductBookmarksBySidsLazyQuery
>;
export type ProductBookmarksBySidsQueryResult = Apollo.QueryResult<
  ProductBookmarksBySidsQuery,
  ProductBookmarksBySidsQueryVariables
>;
export const GetCustomerReviewsDocument = gql`
  query getCustomerReviews($productSid: String!, $page: Int, $pageSize: Int) {
    customerReviewsPage(
      productSid: $productSid
      page: $page
      pageSize: $pageSize
    ) {
      pageInfo {
        currentPage
        numberOfPages
      }
      reviews {
        comment {
          content
          createdAt
          id
        }
        content
        id
        imagesData {
          id
          thumbUrl
          originalUrl
        }
        reviewDate
        score
        sourceName
        sourceUrl
        title
        user {
          displayName
        }
        verifiedBuyer
      }
      summary {
        averageScore
        ratingDistribution {
          count
          score
        }
        totalReview
      }
    }
  }
`;

/**
 * __useGetCustomerReviewsQuery__
 *
 * To run a query within a React component, call `useGetCustomerReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReviewsQuery({
 *   variables: {
 *      productSid: // value for 'productSid'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetCustomerReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerReviewsQuery,
    GetCustomerReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomerReviewsQuery,
    GetCustomerReviewsQueryVariables
  >(GetCustomerReviewsDocument, options);
}
export function useGetCustomerReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerReviewsQuery,
    GetCustomerReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomerReviewsQuery,
    GetCustomerReviewsQueryVariables
  >(GetCustomerReviewsDocument, options);
}
export type GetCustomerReviewsQueryHookResult = ReturnType<
  typeof useGetCustomerReviewsQuery
>;
export type GetCustomerReviewsLazyQueryHookResult = ReturnType<
  typeof useGetCustomerReviewsLazyQuery
>;
export type GetCustomerReviewsQueryResult = Apollo.QueryResult<
  GetCustomerReviewsQuery,
  GetCustomerReviewsQueryVariables
>;
export const GetRecommendationsDisplayDataBySkuDocument = gql`
  query getRecommendationsDisplayDataBySku($skus: [String]) {
    productVariant(skus: $skus) {
      compareAtPrice
      eligiblePromotions
      galleryImages {
        url
      }
      isDiscountable
      isMarketplace
      modelImages {
        url
      }
      offerDetails {
        endDate
        startDate
      }
      price
      product {
        brand
        brandSlug
        categories {
          category
        }
        familySlug
        hierarchicalCategories {
          lvl0
          lvl1
          lvl2
          lvl3
        }
        options {
          ... on ColorOption {
            name
            type
            values {
              brandColor
              colorFamily
              label
              swatchColorCode
              value
            }
          }
          ... on StringOption {
            name
            type
            values {
              label
              value
            }
          }
        }
        reviewSummary {
          averageScore
          totalReview
        }
        sid
        slug
        title
        variants {
          sku
        }
      }
      productImages {
        url
      }
      sid
      sku
    }
  }
`;

/**
 * __useGetRecommendationsDisplayDataBySkuQuery__
 *
 * To run a query within a React component, call `useGetRecommendationsDisplayDataBySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendationsDisplayDataBySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendationsDisplayDataBySkuQuery({
 *   variables: {
 *      skus: // value for 'skus'
 *   },
 * });
 */
export function useGetRecommendationsDisplayDataBySkuQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecommendationsDisplayDataBySkuQuery,
    GetRecommendationsDisplayDataBySkuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecommendationsDisplayDataBySkuQuery,
    GetRecommendationsDisplayDataBySkuQueryVariables
  >(GetRecommendationsDisplayDataBySkuDocument, options);
}
export function useGetRecommendationsDisplayDataBySkuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecommendationsDisplayDataBySkuQuery,
    GetRecommendationsDisplayDataBySkuQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecommendationsDisplayDataBySkuQuery,
    GetRecommendationsDisplayDataBySkuQueryVariables
  >(GetRecommendationsDisplayDataBySkuDocument, options);
}
export type GetRecommendationsDisplayDataBySkuQueryHookResult = ReturnType<
  typeof useGetRecommendationsDisplayDataBySkuQuery
>;
export type GetRecommendationsDisplayDataBySkuLazyQueryHookResult = ReturnType<
  typeof useGetRecommendationsDisplayDataBySkuLazyQuery
>;
export type GetRecommendationsDisplayDataBySkuQueryResult = Apollo.QueryResult<
  GetRecommendationsDisplayDataBySkuQuery,
  GetRecommendationsDisplayDataBySkuQueryVariables
>;
export const GetShippingMethodDocument = gql`
  query getShippingMethod($lineItems: [EstimatedDeliveryLineItem!]!) {
    estimatedDeliveryV3(lineItems: $lineItems) {
      shippingMethod
    }
  }
`;

/**
 * __useGetShippingMethodQuery__
 *
 * To run a query within a React component, call `useGetShippingMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippingMethodQuery({
 *   variables: {
 *      lineItems: // value for 'lineItems'
 *   },
 * });
 */
export function useGetShippingMethodQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShippingMethodQuery,
    GetShippingMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetShippingMethodQuery,
    GetShippingMethodQueryVariables
  >(GetShippingMethodDocument, options);
}
export function useGetShippingMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShippingMethodQuery,
    GetShippingMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetShippingMethodQuery,
    GetShippingMethodQueryVariables
  >(GetShippingMethodDocument, options);
}
export type GetShippingMethodQueryHookResult = ReturnType<
  typeof useGetShippingMethodQuery
>;
export type GetShippingMethodLazyQueryHookResult = ReturnType<
  typeof useGetShippingMethodLazyQuery
>;
export type GetShippingMethodQueryResult = Apollo.QueryResult<
  GetShippingMethodQuery,
  GetShippingMethodQueryVariables
>;
export const GetProductInStockInfoDocument = gql`
  query getProductInStockInfo($productSids: [String!]!) {
    product(sids: $productSids) {
      variants {
        shoppingStatus
        inStock
        isLowStock
        sid
        sku
      }
    }
  }
`;

/**
 * __useGetProductInStockInfoQuery__
 *
 * To run a query within a React component, call `useGetProductInStockInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductInStockInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductInStockInfoQuery({
 *   variables: {
 *      productSids: // value for 'productSids'
 *   },
 * });
 */
export function useGetProductInStockInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductInStockInfoQuery,
    GetProductInStockInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductInStockInfoQuery,
    GetProductInStockInfoQueryVariables
  >(GetProductInStockInfoDocument, options);
}
export function useGetProductInStockInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductInStockInfoQuery,
    GetProductInStockInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductInStockInfoQuery,
    GetProductInStockInfoQueryVariables
  >(GetProductInStockInfoDocument, options);
}
export type GetProductInStockInfoQueryHookResult = ReturnType<
  typeof useGetProductInStockInfoQuery
>;
export type GetProductInStockInfoLazyQueryHookResult = ReturnType<
  typeof useGetProductInStockInfoLazyQuery
>;
export type GetProductInStockInfoQueryResult = Apollo.QueryResult<
  GetProductInStockInfoQuery,
  GetProductInStockInfoQueryVariables
>;
export const GetPdpFromSsrDocument = gql`
  query getPDPFromSSR($productSid: String!, $brandSlug: String!) {
    brandReviewsPage(brandSlug: $brandSlug) {
      summary {
        averageScore
        totalReview
        ratingDistribution {
          count
          score
        }
      }
    }
    customerReviewsPage(productSid: $productSid) {
      ...reviewsPageFields
    }
    categories {
      algoliaHierarchy
      hasChildren
      label
      level
      parentSlug
      slug
    }
    getProfile(brandSlug: $brandSlug) {
      biography
    }
    pdpInfo(sid: $productSid) {
      product {
        ...ProductPDP
      }
      status {
        code
        newSid
      }
    }
  }
  ${ReviewsPageFieldsFragmentDoc}
  ${ProductPdpFragmentDoc}
`;

/**
 * __useGetPdpFromSsrQuery__
 *
 * To run a query within a React component, call `useGetPdpFromSsrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdpFromSsrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdpFromSsrQuery({
 *   variables: {
 *      productSid: // value for 'productSid'
 *      brandSlug: // value for 'brandSlug'
 *   },
 * });
 */
export function useGetPdpFromSsrQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPdpFromSsrQuery,
    GetPdpFromSsrQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPdpFromSsrQuery, GetPdpFromSsrQueryVariables>(
    GetPdpFromSsrDocument,
    options
  );
}
export function useGetPdpFromSsrLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPdpFromSsrQuery,
    GetPdpFromSsrQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPdpFromSsrQuery, GetPdpFromSsrQueryVariables>(
    GetPdpFromSsrDocument,
    options
  );
}
export type GetPdpFromSsrQueryHookResult = ReturnType<
  typeof useGetPdpFromSsrQuery
>;
export type GetPdpFromSsrLazyQueryHookResult = ReturnType<
  typeof useGetPdpFromSsrLazyQuery
>;
export type GetPdpFromSsrQueryResult = Apollo.QueryResult<
  GetPdpFromSsrQuery,
  GetPdpFromSsrQueryVariables
>;
export const GetProductPdpDocument = gql`
  query getProductPDP($productSid: String!, $omitDiscontinued: Boolean) {
    product(sids: [$productSid], omitDiscontinued: $omitDiscontinued) {
      ...ProductPDP
    }
  }
  ${ProductPdpFragmentDoc}
`;

/**
 * __useGetProductPdpQuery__
 *
 * To run a query within a React component, call `useGetProductPdpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductPdpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductPdpQuery({
 *   variables: {
 *      productSid: // value for 'productSid'
 *      omitDiscontinued: // value for 'omitDiscontinued'
 *   },
 * });
 */
export function useGetProductPdpQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductPdpQuery,
    GetProductPdpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductPdpQuery, GetProductPdpQueryVariables>(
    GetProductPdpDocument,
    options
  );
}
export function useGetProductPdpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductPdpQuery,
    GetProductPdpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductPdpQuery, GetProductPdpQueryVariables>(
    GetProductPdpDocument,
    options
  );
}
export type GetProductPdpQueryHookResult = ReturnType<
  typeof useGetProductPdpQuery
>;
export type GetProductPdpLazyQueryHookResult = ReturnType<
  typeof useGetProductPdpLazyQuery
>;
export type GetProductPdpQueryResult = Apollo.QueryResult<
  GetProductPdpQuery,
  GetProductPdpQueryVariables
>;
export const GetPdpbtfDocument = gql`
  query getPDPBTF(
    $cursor: Int
    $limit: Int
    $modelId: String!
    $modelVariantId: String!
    $productIds: [String]!
  ) {
    recommendedMultiKeyProducts(
      key: {
        modelId: $modelId
        modelVariantId: $modelVariantId
        productIds: $productIds
      }
      page: { cursor: $cursor, limit: $limit }
    ) {
      brand
      brandSlug
      categories {
        category
      }
      familySlug
      hierarchicalCategories {
        lvl0
        lvl1
        lvl2
        lvl3
      }
      options {
        type
        name
        values {
          label
          value
        }
      }
      reviewSummary {
        averageScore
        totalReview
      }
      sid
      slug
      title
      variants(filterByShoppable: true) {
        compareAtPrice
        eligiblePromotions
        galleryImages {
          url
        }
        inStock
        isDiscountable
        isMarketplace
        isProductBundle
        modelImages {
          url
        }
        offerDetails {
          offerType
          startDate
        }
        price
        productImages {
          url
        }
        selectedOptions {
          name
          value
        }
        sid
        sku
      }
    }
  }
`;

/**
 * __useGetPdpbtfQuery__
 *
 * To run a query within a React component, call `useGetPdpbtfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdpbtfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdpbtfQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      modelId: // value for 'modelId'
 *      modelVariantId: // value for 'modelVariantId'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useGetPdpbtfQuery(
  baseOptions: Apollo.QueryHookOptions<GetPdpbtfQuery, GetPdpbtfQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPdpbtfQuery, GetPdpbtfQueryVariables>(
    GetPdpbtfDocument,
    options
  );
}
export function useGetPdpbtfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPdpbtfQuery,
    GetPdpbtfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPdpbtfQuery, GetPdpbtfQueryVariables>(
    GetPdpbtfDocument,
    options
  );
}
export type GetPdpbtfQueryHookResult = ReturnType<typeof useGetPdpbtfQuery>;
export type GetPdpbtfLazyQueryHookResult = ReturnType<
  typeof useGetPdpbtfLazyQuery
>;
export type GetPdpbtfQueryResult = Apollo.QueryResult<
  GetPdpbtfQuery,
  GetPdpbtfQueryVariables
>;
export const GetCustomerReviewsPageDocument = gql`
  query getCustomerReviewsPage(
    $page: Int!
    $pageSize: Int!
    $productSid: String!
  ) {
    customerReviewsPage(
      productSid: $productSid
      page: $page
      pageSize: $pageSize
    ) {
      ...reviewsPageFields
    }
  }
  ${ReviewsPageFieldsFragmentDoc}
`;

/**
 * __useGetCustomerReviewsPageQuery__
 *
 * To run a query within a React component, call `useGetCustomerReviewsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReviewsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReviewsPageQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      productSid: // value for 'productSid'
 *   },
 * });
 */
export function useGetCustomerReviewsPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCustomerReviewsPageQuery,
    GetCustomerReviewsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomerReviewsPageQuery,
    GetCustomerReviewsPageQueryVariables
  >(GetCustomerReviewsPageDocument, options);
}
export function useGetCustomerReviewsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerReviewsPageQuery,
    GetCustomerReviewsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomerReviewsPageQuery,
    GetCustomerReviewsPageQueryVariables
  >(GetCustomerReviewsPageDocument, options);
}
export type GetCustomerReviewsPageQueryHookResult = ReturnType<
  typeof useGetCustomerReviewsPageQuery
>;
export type GetCustomerReviewsPageLazyQueryHookResult = ReturnType<
  typeof useGetCustomerReviewsPageLazyQuery
>;
export type GetCustomerReviewsPageQueryResult = Apollo.QueryResult<
  GetCustomerReviewsPageQuery,
  GetCustomerReviewsPageQueryVariables
>;
export const WebSetReviewWrittenForProductAndOrderIdDocument = gql`
  mutation webSetReviewWrittenForProductAndOrderId(
    $productSid: String!
    $orderId: String!
  ) {
    setReviewWrittenForProductAndOrderId(
      productSid: $productSid
      orderId: $orderId
    ) {
      success
      validationErrors {
        code
        message
      }
    }
  }
`;

/**
 * __useWebSetReviewWrittenForProductAndOrderIdMutation__
 *
 * To run a mutation, you first call `useWebSetReviewWrittenForProductAndOrderIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebSetReviewWrittenForProductAndOrderIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webSetReviewWrittenForProductAndOrderIdMutation, { data, loading, error }] = useWebSetReviewWrittenForProductAndOrderIdMutation({
 *   variables: {
 *      productSid: // value for 'productSid'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useWebSetReviewWrittenForProductAndOrderIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebSetReviewWrittenForProductAndOrderIdMutation,
    WebSetReviewWrittenForProductAndOrderIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebSetReviewWrittenForProductAndOrderIdMutation,
    WebSetReviewWrittenForProductAndOrderIdMutationVariables
  >(WebSetReviewWrittenForProductAndOrderIdDocument, options);
}
export type WebSetReviewWrittenForProductAndOrderIdMutationHookResult =
  ReturnType<typeof useWebSetReviewWrittenForProductAndOrderIdMutation>;
export type WebSetReviewWrittenForProductAndOrderIdMutationResult =
  Apollo.MutationResult<WebSetReviewWrittenForProductAndOrderIdMutation>;
export type WebSetReviewWrittenForProductAndOrderIdMutationOptions =
  Apollo.BaseMutationOptions<
    WebSetReviewWrittenForProductAndOrderIdMutation,
    WebSetReviewWrittenForProductAndOrderIdMutationVariables
  >;
export const GetCustomerDocument = gql`
  query getCustomer {
    me {
      ...customerFields
      profiles {
        ...profileFields
      }
    }
  }
  ${CustomerFieldsFragmentDoc}
  ${ProfileFieldsFragmentDoc}
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options
  );
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<
  typeof useGetCustomerLazyQuery
>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
export const CreateProductBookmarkDocument = gql`
  mutation createProductBookmark($input: ProductBookmarkInput!) {
    createProductBookmark(input: $input) {
      bookmark {
        id
        ... on ProductBookmark {
          bookmarkList {
            id
            name
          }
          id
          selectedOptions {
            name
            value
          }
        }
      }
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useCreateProductBookmarkMutation__
 *
 * To run a mutation, you first call `useCreateProductBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductBookmarkMutation, { data, loading, error }] = useCreateProductBookmarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductBookmarkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductBookmarkMutation,
    CreateProductBookmarkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProductBookmarkMutation,
    CreateProductBookmarkMutationVariables
  >(CreateProductBookmarkDocument, options);
}
export type CreateProductBookmarkMutationHookResult = ReturnType<
  typeof useCreateProductBookmarkMutation
>;
export type CreateProductBookmarkMutationResult =
  Apollo.MutationResult<CreateProductBookmarkMutation>;
export type CreateProductBookmarkMutationOptions = Apollo.BaseMutationOptions<
  CreateProductBookmarkMutation,
  CreateProductBookmarkMutationVariables
>;
export const DeleteBookmarkFromBookmarkButtonDocument = gql`
  mutation deleteBookmarkFromBookmarkButton($id: ID!) {
    deleteBookmark(id: $id) {
      id
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useDeleteBookmarkFromBookmarkButtonMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkFromBookmarkButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkFromBookmarkButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkFromBookmarkButtonMutation, { data, loading, error }] = useDeleteBookmarkFromBookmarkButtonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBookmarkFromBookmarkButtonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBookmarkFromBookmarkButtonMutation,
    DeleteBookmarkFromBookmarkButtonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBookmarkFromBookmarkButtonMutation,
    DeleteBookmarkFromBookmarkButtonMutationVariables
  >(DeleteBookmarkFromBookmarkButtonDocument, options);
}
export type DeleteBookmarkFromBookmarkButtonMutationHookResult = ReturnType<
  typeof useDeleteBookmarkFromBookmarkButtonMutation
>;
export type DeleteBookmarkFromBookmarkButtonMutationResult =
  Apollo.MutationResult<DeleteBookmarkFromBookmarkButtonMutation>;
export type DeleteBookmarkFromBookmarkButtonMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteBookmarkFromBookmarkButtonMutation,
    DeleteBookmarkFromBookmarkButtonMutationVariables
  >;
export const CreateBookmarkListDocument = gql`
  mutation createBookmarkList($input: BookmarkListInput!) {
    createBookmarkList(input: $input) {
      bookmarkList {
        id
      }
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useCreateBookmarkListMutation__
 *
 * To run a mutation, you first call `useCreateBookmarkListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookmarkListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookmarkListMutation, { data, loading, error }] = useCreateBookmarkListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookmarkListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookmarkListMutation,
    CreateBookmarkListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBookmarkListMutation,
    CreateBookmarkListMutationVariables
  >(CreateBookmarkListDocument, options);
}
export type CreateBookmarkListMutationHookResult = ReturnType<
  typeof useCreateBookmarkListMutation
>;
export type CreateBookmarkListMutationResult =
  Apollo.MutationResult<CreateBookmarkListMutation>;
export type CreateBookmarkListMutationOptions = Apollo.BaseMutationOptions<
  CreateBookmarkListMutation,
  CreateBookmarkListMutationVariables
>;
export const DeleteBookmarkListDocument = gql`
  mutation deleteBookmarkList($id: ID!) {
    deleteBookmarkList(id: $id) {
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useDeleteBookmarkListMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkListMutation, { data, loading, error }] = useDeleteBookmarkListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBookmarkListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBookmarkListMutation,
    DeleteBookmarkListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteBookmarkListMutation,
    DeleteBookmarkListMutationVariables
  >(DeleteBookmarkListDocument, options);
}
export type DeleteBookmarkListMutationHookResult = ReturnType<
  typeof useDeleteBookmarkListMutation
>;
export type DeleteBookmarkListMutationResult =
  Apollo.MutationResult<DeleteBookmarkListMutation>;
export type DeleteBookmarkListMutationOptions = Apollo.BaseMutationOptions<
  DeleteBookmarkListMutation,
  DeleteBookmarkListMutationVariables
>;
export const GetBookmarkListsDocument = gql`
  query getBookmarkLists($cursor: String, $limit: Int) {
    me {
      bookmarkLists(cursor: $cursor, limit: $limit) {
        items {
          id
          bookmarks(bookmarkType: ALL, limit: 3) {
            items {
              ... on ProductBookmark {
                product {
                  variants {
                    galleryAssets {
                      type
                      url
                    }
                    selectedOptions {
                      name
                      value
                    }
                  }
                }
                selectedOptions {
                  name
                  value
                }
              }
              ... on ExplorerPostBookmark {
                explorerPost {
                  assets {
                    type
                    url
                  }
                }
              }
              type
            }
            pagination {
              totalCount
            }
          }
          name
          public
          slug
        }
        pagination {
          cursor
          hasMore
          totalCount
        }
      }
      id
    }
  }
`;

/**
 * __useGetBookmarkListsQuery__
 *
 * To run a query within a React component, call `useGetBookmarkListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarkListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarkListsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetBookmarkListsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBookmarkListsQuery,
    GetBookmarkListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBookmarkListsQuery, GetBookmarkListsQueryVariables>(
    GetBookmarkListsDocument,
    options
  );
}
export function useGetBookmarkListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookmarkListsQuery,
    GetBookmarkListsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBookmarkListsQuery,
    GetBookmarkListsQueryVariables
  >(GetBookmarkListsDocument, options);
}
export type GetBookmarkListsQueryHookResult = ReturnType<
  typeof useGetBookmarkListsQuery
>;
export type GetBookmarkListsLazyQueryHookResult = ReturnType<
  typeof useGetBookmarkListsLazyQuery
>;
export type GetBookmarkListsQueryResult = Apollo.QueryResult<
  GetBookmarkListsQuery,
  GetBookmarkListsQueryVariables
>;
export const GetBookmarkListsModalDocument = gql`
  query getBookmarkListsModal($filter: ProductBookmarkInput!) {
    me {
      bookmarkLists {
        items {
          bookmarks {
            pagination {
              totalCount
            }
          }
          coverPhoto {
            url
          }
          filteredBookmark(filter: $filter) {
            id
          }
          id
          name
          public
          slug
        }
      }
      id
    }
  }
`;

/**
 * __useGetBookmarkListsModalQuery__
 *
 * To run a query within a React component, call `useGetBookmarkListsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarkListsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarkListsModalQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetBookmarkListsModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBookmarkListsModalQuery,
    GetBookmarkListsModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBookmarkListsModalQuery,
    GetBookmarkListsModalQueryVariables
  >(GetBookmarkListsModalDocument, options);
}
export function useGetBookmarkListsModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookmarkListsModalQuery,
    GetBookmarkListsModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBookmarkListsModalQuery,
    GetBookmarkListsModalQueryVariables
  >(GetBookmarkListsModalDocument, options);
}
export type GetBookmarkListsModalQueryHookResult = ReturnType<
  typeof useGetBookmarkListsModalQuery
>;
export type GetBookmarkListsModalLazyQueryHookResult = ReturnType<
  typeof useGetBookmarkListsModalLazyQuery
>;
export type GetBookmarkListsModalQueryResult = Apollo.QueryResult<
  GetBookmarkListsModalQuery,
  GetBookmarkListsModalQueryVariables
>;
export const UpdateBookmarkListDocument = gql`
  mutation updateBookmarkList($input: UpdateBookmarkListInput!) {
    updateBookmarkList(input: $input) {
      bookmarkList {
        id
      }
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useUpdateBookmarkListMutation__
 *
 * To run a mutation, you first call `useUpdateBookmarkListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookmarkListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookmarkListMutation, { data, loading, error }] = useUpdateBookmarkListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookmarkListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBookmarkListMutation,
    UpdateBookmarkListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBookmarkListMutation,
    UpdateBookmarkListMutationVariables
  >(UpdateBookmarkListDocument, options);
}
export type UpdateBookmarkListMutationHookResult = ReturnType<
  typeof useUpdateBookmarkListMutation
>;
export type UpdateBookmarkListMutationResult =
  Apollo.MutationResult<UpdateBookmarkListMutation>;
export type UpdateBookmarkListMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookmarkListMutation,
  UpdateBookmarkListMutationVariables
>;
export const ToggleBookmarkListPrivacyMutationDocument = gql`
  mutation toggleBookmarkListPrivacyMutation($input: UpdateBookmarkListInput!) {
    updateBookmarkList(input: $input) {
      bookmarkList {
        id
        name
        public
      }
      success
      validationErrors {
        field
        message
      }
    }
  }
`;

/**
 * __useToggleBookmarkListPrivacyMutationMutation__
 *
 * To run a mutation, you first call `useToggleBookmarkListPrivacyMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBookmarkListPrivacyMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBookmarkListPrivacyMutationMutation, { data, loading, error }] = useToggleBookmarkListPrivacyMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleBookmarkListPrivacyMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleBookmarkListPrivacyMutationMutation,
    ToggleBookmarkListPrivacyMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleBookmarkListPrivacyMutationMutation,
    ToggleBookmarkListPrivacyMutationMutationVariables
  >(ToggleBookmarkListPrivacyMutationDocument, options);
}
export type ToggleBookmarkListPrivacyMutationMutationHookResult = ReturnType<
  typeof useToggleBookmarkListPrivacyMutationMutation
>;
export type ToggleBookmarkListPrivacyMutationMutationResult =
  Apollo.MutationResult<ToggleBookmarkListPrivacyMutationMutation>;
export type ToggleBookmarkListPrivacyMutationMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleBookmarkListPrivacyMutationMutation,
    ToggleBookmarkListPrivacyMutationMutationVariables
  >;
export const GetRecommendedPostsDocument = gql`
  query getRecommendedPosts(
    $postTypes: [ExplorerPostType]
    $productIds: [String]!
  ) {
    productPosts(postTypes: $postTypes, productIds: $productIds) {
      posts {
        assets {
          dimensions {
            height
            width
          }
          id
          type
          url
          ... on ExplorerVideoAsset {
            videoMetadata {
              longformUrl
            }
          }
        }
        description
        headline
        id
        owner {
          brandSlug
          ownerId
          ownerName
          type
        }
        profile {
          profileAsset {
            url
          }
          brandSlug
          displayName
          id
          isFollowing
          isProfileOwner
          type
          username
        }
        type
        transcriptUrl
      }
    }
  }
`;

/**
 * __useGetRecommendedPostsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedPostsQuery({
 *   variables: {
 *      postTypes: // value for 'postTypes'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useGetRecommendedPostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRecommendedPostsQuery,
    GetRecommendedPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRecommendedPostsQuery,
    GetRecommendedPostsQueryVariables
  >(GetRecommendedPostsDocument, options);
}
export function useGetRecommendedPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecommendedPostsQuery,
    GetRecommendedPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecommendedPostsQuery,
    GetRecommendedPostsQueryVariables
  >(GetRecommendedPostsDocument, options);
}
export type GetRecommendedPostsQueryHookResult = ReturnType<
  typeof useGetRecommendedPostsQuery
>;
export type GetRecommendedPostsLazyQueryHookResult = ReturnType<
  typeof useGetRecommendedPostsLazyQuery
>;
export type GetRecommendedPostsQueryResult = Apollo.QueryResult<
  GetRecommendedPostsQuery,
  GetRecommendedPostsQueryVariables
>;
export const GetBookmarkListsForPlpDocument = gql`
  query getBookmarkListsForPLP {
    me {
      bookmarkLists {
        items {
          bookmarks {
            pagination {
              totalCount
            }
          }
          id
          name
          public
          slug
        }
      }
      id
    }
  }
`;

/**
 * __useGetBookmarkListsForPlpQuery__
 *
 * To run a query within a React component, call `useGetBookmarkListsForPlpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarkListsForPlpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarkListsForPlpQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBookmarkListsForPlpQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBookmarkListsForPlpQuery,
    GetBookmarkListsForPlpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBookmarkListsForPlpQuery,
    GetBookmarkListsForPlpQueryVariables
  >(GetBookmarkListsForPlpDocument, options);
}
export function useGetBookmarkListsForPlpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookmarkListsForPlpQuery,
    GetBookmarkListsForPlpQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBookmarkListsForPlpQuery,
    GetBookmarkListsForPlpQueryVariables
  >(GetBookmarkListsForPlpDocument, options);
}
export type GetBookmarkListsForPlpQueryHookResult = ReturnType<
  typeof useGetBookmarkListsForPlpQuery
>;
export type GetBookmarkListsForPlpLazyQueryHookResult = ReturnType<
  typeof useGetBookmarkListsForPlpLazyQuery
>;
export type GetBookmarkListsForPlpQueryResult = Apollo.QueryResult<
  GetBookmarkListsForPlpQuery,
  GetBookmarkListsForPlpQueryVariables
>;
export const PostsForBookmarkListDocument = gql`
  query postsForBookmarkList($cursor: String, $id: String!, $limit: Int) {
    bookmarkList(id: $id) {
      bookmarks(bookmarkType: EXPLORER_POST, limit: $limit, cursor: $cursor) {
        pagination {
          cursor
          hasMore
          totalCount
        }
        items {
          ...postBookmark
        }
      }
      id
      name
      public
      slug
    }
  }
  ${PostBookmarkFragmentDoc}
`;

/**
 * __usePostsForBookmarkListQuery__
 *
 * To run a query within a React component, call `usePostsForBookmarkListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsForBookmarkListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsForBookmarkListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePostsForBookmarkListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PostsForBookmarkListQuery,
    PostsForBookmarkListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PostsForBookmarkListQuery,
    PostsForBookmarkListQueryVariables
  >(PostsForBookmarkListDocument, options);
}
export function usePostsForBookmarkListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostsForBookmarkListQuery,
    PostsForBookmarkListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PostsForBookmarkListQuery,
    PostsForBookmarkListQueryVariables
  >(PostsForBookmarkListDocument, options);
}
export type PostsForBookmarkListQueryHookResult = ReturnType<
  typeof usePostsForBookmarkListQuery
>;
export type PostsForBookmarkListLazyQueryHookResult = ReturnType<
  typeof usePostsForBookmarkListLazyQuery
>;
export type PostsForBookmarkListQueryResult = Apollo.QueryResult<
  PostsForBookmarkListQuery,
  PostsForBookmarkListQueryVariables
>;
export const PostsForBookmarkListByOwnerIdAndCollectionSlugDocument = gql`
  query postsForBookmarkListByOwnerIdAndCollectionSlug(
    $collectionSlug: String!
    $cursor: String
    $limit: Int
    $ownerId: String!
  ) {
    bookmarkListByOwnerIdAndCollectionSlug(
      collectionSlug: $collectionSlug
      ownerId: $ownerId
    ) {
      bookmarks(bookmarkType: EXPLORER_POST, limit: $limit, cursor: $cursor) {
        pagination {
          cursor
          hasMore
          totalCount
        }
        items {
          ...postBookmark
        }
      }
      id
      name
      public
      slug
    }
  }
  ${PostBookmarkFragmentDoc}
`;

/**
 * __usePostsForBookmarkListByOwnerIdAndCollectionSlugQuery__
 *
 * To run a query within a React component, call `usePostsForBookmarkListByOwnerIdAndCollectionSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsForBookmarkListByOwnerIdAndCollectionSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsForBookmarkListByOwnerIdAndCollectionSlugQuery({
 *   variables: {
 *      collectionSlug: // value for 'collectionSlug'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function usePostsForBookmarkListByOwnerIdAndCollectionSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    PostsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    PostsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PostsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    PostsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >(PostsForBookmarkListByOwnerIdAndCollectionSlugDocument, options);
}
export function usePostsForBookmarkListByOwnerIdAndCollectionSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    PostsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PostsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    PostsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >(PostsForBookmarkListByOwnerIdAndCollectionSlugDocument, options);
}
export type PostsForBookmarkListByOwnerIdAndCollectionSlugQueryHookResult =
  ReturnType<typeof usePostsForBookmarkListByOwnerIdAndCollectionSlugQuery>;
export type PostsForBookmarkListByOwnerIdAndCollectionSlugLazyQueryHookResult =
  ReturnType<typeof usePostsForBookmarkListByOwnerIdAndCollectionSlugLazyQuery>;
export type PostsForBookmarkListByOwnerIdAndCollectionSlugQueryResult =
  Apollo.QueryResult<
    PostsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    PostsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >;
export const ProductsForBookmarkListDocument = gql`
  query productsForBookmarkList($cursor: String, $id: String!, $limit: Int) {
    bookmarkList(id: $id) {
      bookmarks(bookmarkType: PRODUCT, limit: $limit, cursor: $cursor) {
        pagination {
          cursor
          hasMore
          totalCount
        }
        items {
          bookmarkList {
            id
            name
          }
          id
          ...productBookmark
        }
      }
      id
      name
      public
      slug
    }
  }
  ${ProductBookmarkFragmentDoc}
`;

/**
 * __useProductsForBookmarkListQuery__
 *
 * To run a query within a React component, call `useProductsForBookmarkListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForBookmarkListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForBookmarkListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductsForBookmarkListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsForBookmarkListQuery,
    ProductsForBookmarkListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsForBookmarkListQuery,
    ProductsForBookmarkListQueryVariables
  >(ProductsForBookmarkListDocument, options);
}
export function useProductsForBookmarkListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsForBookmarkListQuery,
    ProductsForBookmarkListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsForBookmarkListQuery,
    ProductsForBookmarkListQueryVariables
  >(ProductsForBookmarkListDocument, options);
}
export type ProductsForBookmarkListQueryHookResult = ReturnType<
  typeof useProductsForBookmarkListQuery
>;
export type ProductsForBookmarkListLazyQueryHookResult = ReturnType<
  typeof useProductsForBookmarkListLazyQuery
>;
export type ProductsForBookmarkListQueryResult = Apollo.QueryResult<
  ProductsForBookmarkListQuery,
  ProductsForBookmarkListQueryVariables
>;
export const ProductsForBookmarkListByOwnerIdAndCollectionSlugDocument = gql`
  query productsForBookmarkListByOwnerIdAndCollectionSlug(
    $collectionSlug: String!
    $cursor: String
    $limit: Int
    $ownerId: String!
  ) {
    bookmarkListByOwnerIdAndCollectionSlug(
      collectionSlug: $collectionSlug
      ownerId: $ownerId
    ) {
      bookmarks(bookmarkType: PRODUCT, limit: $limit, cursor: $cursor) {
        pagination {
          cursor
          hasMore
          totalCount
        }
        items {
          ...productBookmark
        }
      }
      id
      name
      public
      slug
    }
  }
  ${ProductBookmarkFragmentDoc}
`;

/**
 * __useProductsForBookmarkListByOwnerIdAndCollectionSlugQuery__
 *
 * To run a query within a React component, call `useProductsForBookmarkListByOwnerIdAndCollectionSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsForBookmarkListByOwnerIdAndCollectionSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsForBookmarkListByOwnerIdAndCollectionSlugQuery({
 *   variables: {
 *      collectionSlug: // value for 'collectionSlug'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useProductsForBookmarkListByOwnerIdAndCollectionSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >(ProductsForBookmarkListByOwnerIdAndCollectionSlugDocument, options);
}
export function useProductsForBookmarkListByOwnerIdAndCollectionSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >(ProductsForBookmarkListByOwnerIdAndCollectionSlugDocument, options);
}
export type ProductsForBookmarkListByOwnerIdAndCollectionSlugQueryHookResult =
  ReturnType<typeof useProductsForBookmarkListByOwnerIdAndCollectionSlugQuery>;
export type ProductsForBookmarkListByOwnerIdAndCollectionSlugLazyQueryHookResult =
  ReturnType<
    typeof useProductsForBookmarkListByOwnerIdAndCollectionSlugLazyQuery
  >;
export type ProductsForBookmarkListByOwnerIdAndCollectionSlugQueryResult =
  Apollo.QueryResult<
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQuery,
    ProductsForBookmarkListByOwnerIdAndCollectionSlugQueryVariables
  >;
export const ExplorerPostDocument = gql`
  query explorerPost($postId: String, $taggedModulesProductLimit: Int) {
    explorerPost(
      postId: $postId
      taggedModulesProductLimit: $taggedModulesProductLimit
    ) {
      assets {
        dimensions {
          height
          width
        }
        id
        type
        url
        ... on ExplorerVideoAsset {
          videoMetadata {
            longformUrl
          }
        }
      }
      description
      headline
      id
      modules {
        title
        type
        ... on ExplorerProductsListModule {
          productVariants {
            colorFamilyCode
            compareAtPrice
            currencyCode
            filterables {
              responsible
            }
            finalSale
            fullSid
            galleryAssets {
              type
              url
            }
            galleryImages {
              url
            }
            inStock
            price
            product {
              brand
              brandSlug
              description
              categories {
                category
              }
              family
              familySlug
              sid
              slug
              title
              variants {
                colorFamilyCode
                inStock
                shoppingStatus
                sid
                sku
              }
            }
            shoppingStatus
            sid
            sku
            title
          }
          skus
        }
        ... on ExplorerBrandModule {
          slug
          skus
        }
      }
      owner {
        brandSlug
        ownerId
        ownerName
        type
      }
      profile {
        id
        brandSlug
        profileAsset {
          url
        }
        type
        username
      }
      relatedItems {
        count
      }
      transcriptUrl
    }
  }
`;

/**
 * __useExplorerPostQuery__
 *
 * To run a query within a React component, call `useExplorerPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerPostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      taggedModulesProductLimit: // value for 'taggedModulesProductLimit'
 *   },
 * });
 */
export function useExplorerPostQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExplorerPostQuery,
    ExplorerPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExplorerPostQuery, ExplorerPostQueryVariables>(
    ExplorerPostDocument,
    options
  );
}
export function useExplorerPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerPostQuery,
    ExplorerPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExplorerPostQuery, ExplorerPostQueryVariables>(
    ExplorerPostDocument,
    options
  );
}
export type ExplorerPostQueryHookResult = ReturnType<
  typeof useExplorerPostQuery
>;
export type ExplorerPostLazyQueryHookResult = ReturnType<
  typeof useExplorerPostLazyQuery
>;
export type ExplorerPostQueryResult = Apollo.QueryResult<
  ExplorerPostQuery,
  ExplorerPostQueryVariables
>;
export const ExplorerPostRelatedItemDocument = gql`
  query explorerPostRelatedItem($postId: String) {
    explorerPost(postId: $postId) {
      relatedItems {
        nextPage
        products {
          ...relatedProductFields
        }
        count
      }
    }
  }
  ${RelatedProductFieldsFragmentDoc}
`;

/**
 * __useExplorerPostRelatedItemQuery__
 *
 * To run a query within a React component, call `useExplorerPostRelatedItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerPostRelatedItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerPostRelatedItemQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useExplorerPostRelatedItemQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExplorerPostRelatedItemQuery,
    ExplorerPostRelatedItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ExplorerPostRelatedItemQuery,
    ExplorerPostRelatedItemQueryVariables
  >(ExplorerPostRelatedItemDocument, options);
}
export function useExplorerPostRelatedItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExplorerPostRelatedItemQuery,
    ExplorerPostRelatedItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExplorerPostRelatedItemQuery,
    ExplorerPostRelatedItemQueryVariables
  >(ExplorerPostRelatedItemDocument, options);
}
export type ExplorerPostRelatedItemQueryHookResult = ReturnType<
  typeof useExplorerPostRelatedItemQuery
>;
export type ExplorerPostRelatedItemLazyQueryHookResult = ReturnType<
  typeof useExplorerPostRelatedItemLazyQuery
>;
export type ExplorerPostRelatedItemQueryResult = Apollo.QueryResult<
  ExplorerPostRelatedItemQuery,
  ExplorerPostRelatedItemQueryVariables
>;
export const CreateAssetSignedUrlsDocument = gql`
  mutation CreateAssetSignedUrls($input: CreateAssetSignedUrlsInput!) {
    createAssetSignedUrls(input: $input) {
      signedUrls
      assetIds
    }
  }
`;

/**
 * __useCreateAssetSignedUrlsMutation__
 *
 * To run a mutation, you first call `useCreateAssetSignedUrlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetSignedUrlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetSignedUrlsMutation, { data, loading, error }] = useCreateAssetSignedUrlsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetSignedUrlsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetSignedUrlsMutation,
    CreateAssetSignedUrlsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAssetSignedUrlsMutation,
    CreateAssetSignedUrlsMutationVariables
  >(CreateAssetSignedUrlsDocument, options);
}
export type CreateAssetSignedUrlsMutationHookResult = ReturnType<
  typeof useCreateAssetSignedUrlsMutation
>;
export type CreateAssetSignedUrlsMutationResult =
  Apollo.MutationResult<CreateAssetSignedUrlsMutation>;
export type CreateAssetSignedUrlsMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetSignedUrlsMutation,
  CreateAssetSignedUrlsMutationVariables
>;
export const GetBrandDocument = gql`
  query getBrand($slug: String!) {
    brand(slug: $slug) {
      label
      slug
      subscriptionTier
    }
  }
`;

/**
 * __useGetBrandQuery__
 *
 * To run a query within a React component, call `useGetBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetBrandQuery(
  baseOptions: Apollo.QueryHookOptions<GetBrandQuery, GetBrandQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandQuery, GetBrandQueryVariables>(
    GetBrandDocument,
    options
  );
}
export function useGetBrandLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandQuery,
    GetBrandQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandQuery, GetBrandQueryVariables>(
    GetBrandDocument,
    options
  );
}
export type GetBrandQueryHookResult = ReturnType<typeof useGetBrandQuery>;
export type GetBrandLazyQueryHookResult = ReturnType<
  typeof useGetBrandLazyQuery
>;
export type GetBrandQueryResult = Apollo.QueryResult<
  GetBrandQuery,
  GetBrandQueryVariables
>;
export const GetBrandFacetMetadataDocument = gql`
  query getBrandFacetMetadata(
    $slug: String!
    $attributes: [String!]!
    $labels: [String!]!
  ) {
    brand(slug: $slug) {
      facetMetadata(input: { attributes: $attributes, labels: $labels }) {
        attribute
        label
        imageUrl
      }
      slug
    }
  }
`;

/**
 * __useGetBrandFacetMetadataQuery__
 *
 * To run a query within a React component, call `useGetBrandFacetMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandFacetMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandFacetMetadataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      attributes: // value for 'attributes'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function useGetBrandFacetMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBrandFacetMetadataQuery,
    GetBrandFacetMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBrandFacetMetadataQuery,
    GetBrandFacetMetadataQueryVariables
  >(GetBrandFacetMetadataDocument, options);
}
export function useGetBrandFacetMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandFacetMetadataQuery,
    GetBrandFacetMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBrandFacetMetadataQuery,
    GetBrandFacetMetadataQueryVariables
  >(GetBrandFacetMetadataDocument, options);
}
export type GetBrandFacetMetadataQueryHookResult = ReturnType<
  typeof useGetBrandFacetMetadataQuery
>;
export type GetBrandFacetMetadataLazyQueryHookResult = ReturnType<
  typeof useGetBrandFacetMetadataLazyQuery
>;
export type GetBrandFacetMetadataQueryResult = Apollo.QueryResult<
  GetBrandFacetMetadataQuery,
  GetBrandFacetMetadataQueryVariables
>;
export const GetProfileCollectionsListDocument = gql`
  query getProfileCollectionsList(
    $cursor: String
    $limit: Int
    $profileId: String!
  ) {
    bookmarkLists(cursor: $cursor, limit: $limit, ownerId: $profileId) {
      items {
        id
        name
        public
        slug
      }
      pagination {
        cursor
        hasMore
        totalCount
      }
    }
  }
`;

/**
 * __useGetProfileCollectionsListQuery__
 *
 * To run a query within a React component, call `useGetProfileCollectionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCollectionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCollectionsListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetProfileCollectionsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileCollectionsListQuery,
    GetProfileCollectionsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProfileCollectionsListQuery,
    GetProfileCollectionsListQueryVariables
  >(GetProfileCollectionsListDocument, options);
}
export function useGetProfileCollectionsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileCollectionsListQuery,
    GetProfileCollectionsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProfileCollectionsListQuery,
    GetProfileCollectionsListQueryVariables
  >(GetProfileCollectionsListDocument, options);
}
export type GetProfileCollectionsListQueryHookResult = ReturnType<
  typeof useGetProfileCollectionsListQuery
>;
export type GetProfileCollectionsListLazyQueryHookResult = ReturnType<
  typeof useGetProfileCollectionsListLazyQuery
>;
export type GetProfileCollectionsListQueryResult = Apollo.QueryResult<
  GetProfileCollectionsListQuery,
  GetProfileCollectionsListQueryVariables
>;
export const GetProfileCollectionsDocument = gql`
  query getProfileCollections(
    $cursor: String
    $limit: Int
    $profileId: String!
  ) {
    bookmarkLists(cursor: $cursor, limit: $limit, ownerId: $profileId) {
      items {
        id
        bookmarks(bookmarkType: ALL, limit: 4) {
          items {
            ... on ProductBookmark {
              product {
                variants {
                  galleryAssets {
                    type
                    url
                  }
                  selectedOptions {
                    name
                    value
                  }
                }
              }
              selectedOptions {
                name
                value
              }
            }
            ... on ExplorerPostBookmark {
              explorerPost {
                assets {
                  type
                  url
                }
              }
            }
            type
          }
          pagination {
            totalCount
          }
        }
        name
        public
        slug
      }
      pagination {
        cursor
        hasMore
        totalCount
      }
    }
  }
`;

/**
 * __useGetProfileCollectionsQuery__
 *
 * To run a query within a React component, call `useGetProfileCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCollectionsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetProfileCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileCollectionsQuery,
    GetProfileCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProfileCollectionsQuery,
    GetProfileCollectionsQueryVariables
  >(GetProfileCollectionsDocument, options);
}
export function useGetProfileCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileCollectionsQuery,
    GetProfileCollectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProfileCollectionsQuery,
    GetProfileCollectionsQueryVariables
  >(GetProfileCollectionsDocument, options);
}
export type GetProfileCollectionsQueryHookResult = ReturnType<
  typeof useGetProfileCollectionsQuery
>;
export type GetProfileCollectionsLazyQueryHookResult = ReturnType<
  typeof useGetProfileCollectionsLazyQuery
>;
export type GetProfileCollectionsQueryResult = Apollo.QueryResult<
  GetProfileCollectionsQuery,
  GetProfileCollectionsQueryVariables
>;
export const GetProfilePostsDocument = gql`
  query getProfilePosts(
    $limit: Int!
    $paginationToken: String
    $profileId: String!
  ) {
    explorerPosts(
      limit: $limit
      paginationToken: $paginationToken
      profileId: $profileId
    ) {
      paginationToken
      posts {
        assets {
          dimensions {
            height
            width
          }
          id
          type
          url
        }
        description
        headline
        id
        modules {
          type
          ... on ExplorerProductsListModule {
            productVariants {
              sku
              galleryAssets {
                type
                url
              }
            }
            skus
          }
        }
        owner {
          brandSlug
          ownerId
          ownerName
          type
        }
        profile {
          id
          brandSlug
          profileAsset {
            url
          }
          type
          username
        }
        type
        transcriptUrl
      }
    }
  }
`;

/**
 * __useGetProfilePostsQuery__
 *
 * To run a query within a React component, call `useGetProfilePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilePostsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      paginationToken: // value for 'paginationToken'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetProfilePostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfilePostsQuery,
    GetProfilePostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfilePostsQuery, GetProfilePostsQueryVariables>(
    GetProfilePostsDocument,
    options
  );
}
export function useGetProfilePostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfilePostsQuery,
    GetProfilePostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProfilePostsQuery,
    GetProfilePostsQueryVariables
  >(GetProfilePostsDocument, options);
}
export type GetProfilePostsQueryHookResult = ReturnType<
  typeof useGetProfilePostsQuery
>;
export type GetProfilePostsLazyQueryHookResult = ReturnType<
  typeof useGetProfilePostsLazyQuery
>;
export type GetProfilePostsQueryResult = Apollo.QueryResult<
  GetProfilePostsQuery,
  GetProfilePostsQueryVariables
>;
export const GetProfileDocument = gql`
  query getProfile(
    $brandSlug: String
    $profileId: String
    $userId: String
    $username: String
  ) {
    getProfile(
      brandSlug: $brandSlug
      profileId: $profileId
      userId: $userId
      username: $username
    ) {
      ...profileFields
    }
  }
  ${ProfileFieldsFragmentDoc}
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      brandSlug: // value for 'brandSlug'
 *      profileId: // value for 'profileId'
 *      userId: // value for 'userId'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options
  );
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options
  );
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<
  typeof useGetProfileLazyQuery
>;
export type GetProfileQueryResult = Apollo.QueryResult<
  GetProfileQuery,
  GetProfileQueryVariables
>;
export const UpdateProfileDocument = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      profile {
        ...profileFields
      }
      success
      profileError
      validationErrors {
        code
        field
        message
      }
    }
  }
  ${ProfileFieldsFragmentDoc}
`;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult =
  Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const GetTrendingProfilesDocument = gql`
  query getTrendingProfiles($guestUserId: String) {
    trendingProfiles(guestUserId: $guestUserId) {
      biography
      brandSlug
      coverImageAsset {
        url
      }
      displayName
      followerCount
      followingCount
      id
      isFollowing
      likeCount
      profileAsset {
        url
      }
      type
      username
      topPost {
        ...postsFields
      }
    }
  }
  ${PostsFieldsFragmentDoc}
`;

/**
 * __useGetTrendingProfilesQuery__
 *
 * To run a query within a React component, call `useGetTrendingProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrendingProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrendingProfilesQuery({
 *   variables: {
 *      guestUserId: // value for 'guestUserId'
 *   },
 * });
 */
export function useGetTrendingProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTrendingProfilesQuery,
    GetTrendingProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTrendingProfilesQuery,
    GetTrendingProfilesQueryVariables
  >(GetTrendingProfilesDocument, options);
}
export function useGetTrendingProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTrendingProfilesQuery,
    GetTrendingProfilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTrendingProfilesQuery,
    GetTrendingProfilesQueryVariables
  >(GetTrendingProfilesDocument, options);
}
export type GetTrendingProfilesQueryHookResult = ReturnType<
  typeof useGetTrendingProfilesQuery
>;
export type GetTrendingProfilesLazyQueryHookResult = ReturnType<
  typeof useGetTrendingProfilesLazyQuery
>;
export type GetTrendingProfilesQueryResult = Apollo.QueryResult<
  GetTrendingProfilesQuery,
  GetTrendingProfilesQueryVariables
>;
export const GetBrandReviewsDocument = gql`
  query getBrandReviews($brandSlug: String!, $page: Int, $pageSize: Int) {
    brandReviewsPage(brandSlug: $brandSlug, page: $page, pageSize: $pageSize) {
      pageInfo {
        currentPage
        numberOfPages
      }
      reviews {
        comment {
          content
          createdAt
          id
        }
        content
        id
        imagesData {
          id
          thumbUrl
          originalUrl
        }
        product {
          brand
          brandSlug
          description
          familySlug
          hierarchicalCategories {
            lvl0
            lvl1
            lvl2
            lvl3
          }
          sid
          slug
          title
          variants {
            galleryAssets {
              type
              url
            }
            inStock
            price
          }
        }
        reviewDate
        score
        sourceName
        sourceUrl
        title
        user {
          displayName
        }
        verifiedBuyer
      }
      summary {
        averageScore
        ratingDistribution {
          count
          score
        }
        totalReview
      }
    }
  }
`;

/**
 * __useGetBrandReviewsQuery__
 *
 * To run a query within a React component, call `useGetBrandReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandReviewsQuery({
 *   variables: {
 *      brandSlug: // value for 'brandSlug'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetBrandReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBrandReviewsQuery,
    GetBrandReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandReviewsQuery, GetBrandReviewsQueryVariables>(
    GetBrandReviewsDocument,
    options
  );
}
export function useGetBrandReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandReviewsQuery,
    GetBrandReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBrandReviewsQuery,
    GetBrandReviewsQueryVariables
  >(GetBrandReviewsDocument, options);
}
export type GetBrandReviewsQueryHookResult = ReturnType<
  typeof useGetBrandReviewsQuery
>;
export type GetBrandReviewsLazyQueryHookResult = ReturnType<
  typeof useGetBrandReviewsLazyQuery
>;
export type GetBrandReviewsQueryResult = Apollo.QueryResult<
  GetBrandReviewsQuery,
  GetBrandReviewsQueryVariables
>;
export const GetLiveShopDataDocument = gql`
  query getLiveShopData($inviteCode: String!) {
    liveShopByInviteCode(inviteCode: $inviteCode) {
      ...liveShopWithProductsNoAuthFields
    }
  }
  ${LiveShopWithProductsNoAuthFieldsFragmentDoc}
`;

/**
 * __useGetLiveShopDataQuery__
 *
 * To run a query within a React component, call `useGetLiveShopDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveShopDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveShopDataQuery({
 *   variables: {
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useGetLiveShopDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLiveShopDataQuery,
    GetLiveShopDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLiveShopDataQuery, GetLiveShopDataQueryVariables>(
    GetLiveShopDataDocument,
    options
  );
}
export function useGetLiveShopDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiveShopDataQuery,
    GetLiveShopDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLiveShopDataQuery,
    GetLiveShopDataQueryVariables
  >(GetLiveShopDataDocument, options);
}
export type GetLiveShopDataQueryHookResult = ReturnType<
  typeof useGetLiveShopDataQuery
>;
export type GetLiveShopDataLazyQueryHookResult = ReturnType<
  typeof useGetLiveShopDataLazyQuery
>;
export type GetLiveShopDataQueryResult = Apollo.QueryResult<
  GetLiveShopDataQuery,
  GetLiveShopDataQueryVariables
>;
export const GetLiveShopsByOwnerDocument = gql`
  query getLiveShopsByOwner(
    $ownerId: String!
    $cursor: String
    $limit: Int
    $order: PaginationOrder
    $status: [LiveShopStatus]
  ) {
    liveShopsByOwner(
      ownerId: $ownerId
      cursor: $cursor
      limit: $limit
      order: $order
      status: $status
    ) {
      items {
        ...liveShopNoAuthFields
      }
    }
  }
  ${LiveShopNoAuthFieldsFragmentDoc}
`;

/**
 * __useGetLiveShopsByOwnerQuery__
 *
 * To run a query within a React component, call `useGetLiveShopsByOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveShopsByOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveShopsByOwnerQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetLiveShopsByOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLiveShopsByOwnerQuery,
    GetLiveShopsByOwnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLiveShopsByOwnerQuery,
    GetLiveShopsByOwnerQueryVariables
  >(GetLiveShopsByOwnerDocument, options);
}
export function useGetLiveShopsByOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiveShopsByOwnerQuery,
    GetLiveShopsByOwnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLiveShopsByOwnerQuery,
    GetLiveShopsByOwnerQueryVariables
  >(GetLiveShopsByOwnerDocument, options);
}
export type GetLiveShopsByOwnerQueryHookResult = ReturnType<
  typeof useGetLiveShopsByOwnerQuery
>;
export type GetLiveShopsByOwnerLazyQueryHookResult = ReturnType<
  typeof useGetLiveShopsByOwnerLazyQuery
>;
export type GetLiveShopsByOwnerQueryResult = Apollo.QueryResult<
  GetLiveShopsByOwnerQuery,
  GetLiveShopsByOwnerQueryVariables
>;
export const GetUpcomingLiveShopDataDocument = gql`
  query getUpcomingLiveShopData(
    $bounds: LiveShopBounds!
    $limit: Int
    $order: PaginationOrder
    $privacy: [LiveShopPrivacy]
    $status: [LiveShopStatus]
  ) {
    liveShopsByDateBounded(
      bounds: $bounds
      limit: $limit
      order: $order
      privacy: $privacy
      status: $status
    ) {
      items {
        ...liveShopNoAuthFields
      }
    }
  }
  ${LiveShopNoAuthFieldsFragmentDoc}
`;

/**
 * __useGetUpcomingLiveShopDataQuery__
 *
 * To run a query within a React component, call `useGetUpcomingLiveShopDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingLiveShopDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingLiveShopDataQuery({
 *   variables: {
 *      bounds: // value for 'bounds'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      privacy: // value for 'privacy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUpcomingLiveShopDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUpcomingLiveShopDataQuery,
    GetUpcomingLiveShopDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUpcomingLiveShopDataQuery,
    GetUpcomingLiveShopDataQueryVariables
  >(GetUpcomingLiveShopDataDocument, options);
}
export function useGetUpcomingLiveShopDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpcomingLiveShopDataQuery,
    GetUpcomingLiveShopDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUpcomingLiveShopDataQuery,
    GetUpcomingLiveShopDataQueryVariables
  >(GetUpcomingLiveShopDataDocument, options);
}
export type GetUpcomingLiveShopDataQueryHookResult = ReturnType<
  typeof useGetUpcomingLiveShopDataQuery
>;
export type GetUpcomingLiveShopDataLazyQueryHookResult = ReturnType<
  typeof useGetUpcomingLiveShopDataLazyQuery
>;
export type GetUpcomingLiveShopDataQueryResult = Apollo.QueryResult<
  GetUpcomingLiveShopDataQuery,
  GetUpcomingLiveShopDataQueryVariables
>;
export const ClaimLiveShopDiscountDocument = gql`
  mutation claimLiveShopDiscount($input: ClaimLiveShopDiscountInput!) {
    claimLiveShopDiscount(input: $input) {
      success
      validationErrors {
        code
        field
        message
      }
      verifiedDealId
    }
  }
`;

/**
 * __useClaimLiveShopDiscountMutation__
 *
 * To run a mutation, you first call `useClaimLiveShopDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimLiveShopDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimLiveShopDiscountMutation, { data, loading, error }] = useClaimLiveShopDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimLiveShopDiscountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimLiveShopDiscountMutation,
    ClaimLiveShopDiscountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClaimLiveShopDiscountMutation,
    ClaimLiveShopDiscountMutationVariables
  >(ClaimLiveShopDiscountDocument, options);
}
export type ClaimLiveShopDiscountMutationHookResult = ReturnType<
  typeof useClaimLiveShopDiscountMutation
>;
export type ClaimLiveShopDiscountMutationResult =
  Apollo.MutationResult<ClaimLiveShopDiscountMutation>;
export type ClaimLiveShopDiscountMutationOptions = Apollo.BaseMutationOptions<
  ClaimLiveShopDiscountMutation,
  ClaimLiveShopDiscountMutationVariables
>;
export const FollowDocument = gql`
  mutation follow($input: String!) {
    follow(profileId: $input) {
      success
      validationErrors {
        code
        field
        message
      }
    }
  }
`;

/**
 * __useFollowMutation__
 *
 * To run a mutation, you first call `useFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followMutation, { data, loading, error }] = useFollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FollowMutation,
    FollowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FollowMutation, FollowMutationVariables>(
    FollowDocument,
    options
  );
}
export type FollowMutationHookResult = ReturnType<typeof useFollowMutation>;
export type FollowMutationResult = Apollo.MutationResult<FollowMutation>;
export type FollowMutationOptions = Apollo.BaseMutationOptions<
  FollowMutation,
  FollowMutationVariables
>;
export const UnfollowDocument = gql`
  mutation unfollow($input: String!) {
    unfollow(profileId: $input) {
      success
      validationErrors {
        code
        field
        message
      }
    }
  }
`;

/**
 * __useUnfollowMutation__
 *
 * To run a mutation, you first call `useUnfollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowMutation, { data, loading, error }] = useUnfollowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfollowMutation,
    UnfollowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnfollowMutation, UnfollowMutationVariables>(
    UnfollowDocument,
    options
  );
}
export type UnfollowMutationHookResult = ReturnType<typeof useUnfollowMutation>;
export type UnfollowMutationResult = Apollo.MutationResult<UnfollowMutation>;
export type UnfollowMutationOptions = Apollo.BaseMutationOptions<
  UnfollowMutation,
  UnfollowMutationVariables
>;
export const GetLiveShopsByIdsDocument = gql`
  query getLiveShopsByIds($ids: [String!]!) {
    liveShopsByIds(ids: $ids) {
      id
      owner {
        username
      }
      title
    }
  }
`;

/**
 * __useGetLiveShopsByIdsQuery__
 *
 * To run a query within a React component, call `useGetLiveShopsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveShopsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveShopsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetLiveShopsByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLiveShopsByIdsQuery,
    GetLiveShopsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLiveShopsByIdsQuery,
    GetLiveShopsByIdsQueryVariables
  >(GetLiveShopsByIdsDocument, options);
}
export function useGetLiveShopsByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiveShopsByIdsQuery,
    GetLiveShopsByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLiveShopsByIdsQuery,
    GetLiveShopsByIdsQueryVariables
  >(GetLiveShopsByIdsDocument, options);
}
export type GetLiveShopsByIdsQueryHookResult = ReturnType<
  typeof useGetLiveShopsByIdsQuery
>;
export type GetLiveShopsByIdsLazyQueryHookResult = ReturnType<
  typeof useGetLiveShopsByIdsLazyQuery
>;
export type GetLiveShopsByIdsQueryResult = Apollo.QueryResult<
  GetLiveShopsByIdsQuery,
  GetLiveShopsByIdsQueryVariables
>;
export const GetExperimentsDocument = gql`
  query getExperiments(
    $id: String!
    $idType: ExperimentIdType!
    $namespace: String!
  ) {
    experiment(id: $id, idType: $idType, namespace: $namespace) {
      experiments {
        bucket
        name
        values {
          key
          value
        }
        version
      }
      lastUpdatedTs
    }
  }
`;

/**
 * __useGetExperimentsQuery__
 *
 * To run a query within a React component, call `useGetExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperimentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      idType: // value for 'idType'
 *      namespace: // value for 'namespace'
 *   },
 * });
 */
export function useGetExperimentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExperimentsQuery,
    GetExperimentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExperimentsQuery, GetExperimentsQueryVariables>(
    GetExperimentsDocument,
    options
  );
}
export function useGetExperimentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExperimentsQuery,
    GetExperimentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExperimentsQuery, GetExperimentsQueryVariables>(
    GetExperimentsDocument,
    options
  );
}
export type GetExperimentsQueryHookResult = ReturnType<
  typeof useGetExperimentsQuery
>;
export type GetExperimentsLazyQueryHookResult = ReturnType<
  typeof useGetExperimentsLazyQuery
>;
export type GetExperimentsQueryResult = Apollo.QueryResult<
  GetExperimentsQuery,
  GetExperimentsQueryVariables
>;
export const WebGenerateOnboardingInviteCodeDocument = gql`
  mutation webGenerateOnboardingInviteCode($url: String!, $userId: String) {
    generateOnboardingInviteCode(url: $url, userId: $userId) {
      code
      success
    }
  }
`;

/**
 * __useWebGenerateOnboardingInviteCodeMutation__
 *
 * To run a mutation, you first call `useWebGenerateOnboardingInviteCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebGenerateOnboardingInviteCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webGenerateOnboardingInviteCodeMutation, { data, loading, error }] = useWebGenerateOnboardingInviteCodeMutation({
 *   variables: {
 *      url: // value for 'url'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useWebGenerateOnboardingInviteCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebGenerateOnboardingInviteCodeMutation,
    WebGenerateOnboardingInviteCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebGenerateOnboardingInviteCodeMutation,
    WebGenerateOnboardingInviteCodeMutationVariables
  >(WebGenerateOnboardingInviteCodeDocument, options);
}
export type WebGenerateOnboardingInviteCodeMutationHookResult = ReturnType<
  typeof useWebGenerateOnboardingInviteCodeMutation
>;
export type WebGenerateOnboardingInviteCodeMutationResult =
  Apollo.MutationResult<WebGenerateOnboardingInviteCodeMutation>;
export type WebGenerateOnboardingInviteCodeMutationOptions =
  Apollo.BaseMutationOptions<
    WebGenerateOnboardingInviteCodeMutation,
    WebGenerateOnboardingInviteCodeMutationVariables
  >;
export const WebMarkRewardsSeenMutationDocument = gql`
  mutation webMarkRewardsSeenMutation($verifiedDealIds: [String!]!) {
    markVerifiedDealsAsSeen(verifiedDealIds: $verifiedDealIds) {
      success
      validationErrors {
        message
      }
    }
  }
`;

/**
 * __useWebMarkRewardsSeenMutationMutation__
 *
 * To run a mutation, you first call `useWebMarkRewardsSeenMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebMarkRewardsSeenMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webMarkRewardsSeenMutationMutation, { data, loading, error }] = useWebMarkRewardsSeenMutationMutation({
 *   variables: {
 *      verifiedDealIds: // value for 'verifiedDealIds'
 *   },
 * });
 */
export function useWebMarkRewardsSeenMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebMarkRewardsSeenMutationMutation,
    WebMarkRewardsSeenMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebMarkRewardsSeenMutationMutation,
    WebMarkRewardsSeenMutationMutationVariables
  >(WebMarkRewardsSeenMutationDocument, options);
}
export type WebMarkRewardsSeenMutationMutationHookResult = ReturnType<
  typeof useWebMarkRewardsSeenMutationMutation
>;
export type WebMarkRewardsSeenMutationMutationResult =
  Apollo.MutationResult<WebMarkRewardsSeenMutationMutation>;
export type WebMarkRewardsSeenMutationMutationOptions =
  Apollo.BaseMutationOptions<
    WebMarkRewardsSeenMutationMutation,
    WebMarkRewardsSeenMutationMutationVariables
  >;
export const WebOfferCenterGetVerifiedDealsDocument = gql`
  query webOfferCenterGetVerifiedDeals($cursor: String, $limit: Int) {
    verifiedDeals(cursor: $cursor, limit: $limit) {
      cursor
      deals {
        appliedShopifyGiftCardId
        createdAt
        description
        expiresAt
        id
        isSeen
        liveShopId
        maximumOrderCount
        minimumSpend
        pictureAsset {
          url
        }
        productIds
        shopPartyIds
        shopifyGiftCardCode
        shopifyGiftCardId
        shopifyIds
        shopifyOrderId
        skus
        status
        type
        unlocksAt
        userId
        value
        valueType
      }
    }
  }
`;

/**
 * __useWebOfferCenterGetVerifiedDealsQuery__
 *
 * To run a query within a React component, call `useWebOfferCenterGetVerifiedDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebOfferCenterGetVerifiedDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebOfferCenterGetVerifiedDealsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useWebOfferCenterGetVerifiedDealsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WebOfferCenterGetVerifiedDealsQuery,
    WebOfferCenterGetVerifiedDealsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WebOfferCenterGetVerifiedDealsQuery,
    WebOfferCenterGetVerifiedDealsQueryVariables
  >(WebOfferCenterGetVerifiedDealsDocument, options);
}
export function useWebOfferCenterGetVerifiedDealsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WebOfferCenterGetVerifiedDealsQuery,
    WebOfferCenterGetVerifiedDealsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WebOfferCenterGetVerifiedDealsQuery,
    WebOfferCenterGetVerifiedDealsQueryVariables
  >(WebOfferCenterGetVerifiedDealsDocument, options);
}
export type WebOfferCenterGetVerifiedDealsQueryHookResult = ReturnType<
  typeof useWebOfferCenterGetVerifiedDealsQuery
>;
export type WebOfferCenterGetVerifiedDealsLazyQueryHookResult = ReturnType<
  typeof useWebOfferCenterGetVerifiedDealsLazyQuery
>;
export type WebOfferCenterGetVerifiedDealsQueryResult = Apollo.QueryResult<
  WebOfferCenterGetVerifiedDealsQuery,
  WebOfferCenterGetVerifiedDealsQueryVariables
>;
export const RedeemVerifiedDealDocument = gql`
  mutation redeemVerifiedDeal($input: RedeemVerifiedDealInput!) {
    redeemVerifiedDeal(input: $input) {
      shopifyGiftCardCode
      shopifyGiftCardId
      shopifyGiftCardValue
      success
      validationErrors {
        code
        field
        message
      }
    }
  }
`;

/**
 * __useRedeemVerifiedDealMutation__
 *
 * To run a mutation, you first call `useRedeemVerifiedDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemVerifiedDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemVerifiedDealMutation, { data, loading, error }] = useRedeemVerifiedDealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRedeemVerifiedDealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedeemVerifiedDealMutation,
    RedeemVerifiedDealMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RedeemVerifiedDealMutation,
    RedeemVerifiedDealMutationVariables
  >(RedeemVerifiedDealDocument, options);
}
export type RedeemVerifiedDealMutationHookResult = ReturnType<
  typeof useRedeemVerifiedDealMutation
>;
export type RedeemVerifiedDealMutationResult =
  Apollo.MutationResult<RedeemVerifiedDealMutation>;
export type RedeemVerifiedDealMutationOptions = Apollo.BaseMutationOptions<
  RedeemVerifiedDealMutation,
  RedeemVerifiedDealMutationVariables
>;
export const WebSaveAppliedShopifyGiftCardIdDocument = gql`
  mutation webSaveAppliedShopifyGiftCardId(
    $input: saveAppliedShopifyGiftCardIdInput!
  ) {
    saveAppliedShopifyGiftCardId(input: $input) {
      success
      validationErrors {
        code
        field
        message
      }
    }
  }
`;

/**
 * __useWebSaveAppliedShopifyGiftCardIdMutation__
 *
 * To run a mutation, you first call `useWebSaveAppliedShopifyGiftCardIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebSaveAppliedShopifyGiftCardIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webSaveAppliedShopifyGiftCardIdMutation, { data, loading, error }] = useWebSaveAppliedShopifyGiftCardIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebSaveAppliedShopifyGiftCardIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebSaveAppliedShopifyGiftCardIdMutation,
    WebSaveAppliedShopifyGiftCardIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebSaveAppliedShopifyGiftCardIdMutation,
    WebSaveAppliedShopifyGiftCardIdMutationVariables
  >(WebSaveAppliedShopifyGiftCardIdDocument, options);
}
export type WebSaveAppliedShopifyGiftCardIdMutationHookResult = ReturnType<
  typeof useWebSaveAppliedShopifyGiftCardIdMutation
>;
export type WebSaveAppliedShopifyGiftCardIdMutationResult =
  Apollo.MutationResult<WebSaveAppliedShopifyGiftCardIdMutation>;
export type WebSaveAppliedShopifyGiftCardIdMutationOptions =
  Apollo.BaseMutationOptions<
    WebSaveAppliedShopifyGiftCardIdMutation,
    WebSaveAppliedShopifyGiftCardIdMutationVariables
  >;
export const GetShortLinkDocument = gql`
  query getShortLink($url: String!) {
    affiliateLink(url: $url) {
      success
      url
    }
  }
`;

/**
 * __useGetShortLinkQuery__
 *
 * To run a query within a React component, call `useGetShortLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShortLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShortLinkQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetShortLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShortLinkQuery,
    GetShortLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShortLinkQuery, GetShortLinkQueryVariables>(
    GetShortLinkDocument,
    options
  );
}
export function useGetShortLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShortLinkQuery,
    GetShortLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetShortLinkQuery, GetShortLinkQueryVariables>(
    GetShortLinkDocument,
    options
  );
}
export type GetShortLinkQueryHookResult = ReturnType<
  typeof useGetShortLinkQuery
>;
export type GetShortLinkLazyQueryHookResult = ReturnType<
  typeof useGetShortLinkLazyQuery
>;
export type GetShortLinkQueryResult = Apollo.QueryResult<
  GetShortLinkQuery,
  GetShortLinkQueryVariables
>;
export const GetOrdersForProductDocument = gql`
  query getOrdersForProduct($productSid: String) {
    me {
      orders(productSid: $productSid) {
        fulfillments {
          lineItems {
            productId
          }
          shipmentStatus
          trackingCompany
          trackingNumber
        }
        orderNumber
      }
    }
  }
`;

/**
 * __useGetOrdersForProductQuery__
 *
 * To run a query within a React component, call `useGetOrdersForProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersForProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersForProductQuery({
 *   variables: {
 *      productSid: // value for 'productSid'
 *   },
 * });
 */
export function useGetOrdersForProductQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrdersForProductQuery,
    GetOrdersForProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrdersForProductQuery,
    GetOrdersForProductQueryVariables
  >(GetOrdersForProductDocument, options);
}
export function useGetOrdersForProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersForProductQuery,
    GetOrdersForProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrdersForProductQuery,
    GetOrdersForProductQueryVariables
  >(GetOrdersForProductDocument, options);
}
export type GetOrdersForProductQueryHookResult = ReturnType<
  typeof useGetOrdersForProductQuery
>;
export type GetOrdersForProductLazyQueryHookResult = ReturnType<
  typeof useGetOrdersForProductLazyQuery
>;
export type GetOrdersForProductQueryResult = Apollo.QueryResult<
  GetOrdersForProductQuery,
  GetOrdersForProductQueryVariables
>;
