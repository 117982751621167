import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NextLink,
  Resolvers,
  isReference,
} from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import mergeWith from 'lodash/mergeWith';
import uniqBy from 'lodash/uniqBy';

import { referralVar } from './cache/client';
import { IS_DEVELOPMENT } from 'config/utils/env';

import { isBrowser } from 'lib/utils/browser';

import { ExplorerPostResponse, ReviewsPage } from 'types/generated/api';

import Logger from '../utils/Logger';

interface InitApolloProps extends Partial<ApolloClientOptions<unknown>> {
  apolloClientOptions?: {
    name: string;
    version?: string;
  };
  clientId: string;
  enableAPQ?: boolean;
  initialState?: object;
  linkConfig: { headers?: unknown; uri?: string };
  linkMiddleware?: ApolloLink;
  resolvers?: Resolvers | Resolvers[];
  skipCache?: boolean;
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const apolloClients = new Map<string, ApolloClient<unknown>>();

// Default middleware for cases when one is not created/passed-in (example - shopify API)
const defaultLinkMiddleWare = new ApolloLink((operation, forward: NextLink) => {
  return forward(operation);
});

const mergePosts = (
  existing: ExplorerPostResponse | undefined,
  incoming: ExplorerPostResponse
): ExplorerPostResponse => {
  const existingPosts = existing?.posts ?? [];
  const incomingPosts = incoming?.posts ?? [];

  return {
    ...incoming,
    posts: [...existingPosts, ...incomingPosts],
  };
};

function mergeReviews(
  existing: Partial<ReviewsPage> | undefined,
  incoming: Partial<ReviewsPage>
) {
  const existingReviews = existing?.reviews ?? [];
  const incomingReviews = incoming?.reviews ?? [];
  const reviews = uniqBy([...existingReviews, ...incomingReviews], review => {
    if (isReference(review)) {
      return review.__ref;
    }
    return review.id;
  });

  return {
    ...incoming,
    reviews,
  };
}

function fetchWithCustomLogging(uri: RequestInfo | URL, options?: RequestInit) {
  return fetch(uri, options).then(async (response: Response) => {
    if (response.status >= 400) {
      Logger.error(
        `Failed to fetch ${response.url}: ${response.status} ${response.statusText}`,
        undefined,
        {
          metaData: {
            responseBody: await response.clone().text(),
          },
        }
      );
      if (!response.headers.get('content-type')?.includes('application/json')) {
        // If response is not json type, return an empty body so that Apollo won't choke on parsing response body.
        return new Response('{}', {
          headers: response.headers,
          status: response.status,
          statusText: response.statusText,
        });
      }
    }
    return response;
  });
}

function create({
  apolloClientOptions,
  enableAPQ = false,
  initialState = {},
  linkConfig,
  linkMiddleware = defaultLinkMiddleWare,
  ssrMode,
  resolvers,
}: InitApolloProps) {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const { headers, uri } = linkConfig;

  const cache = new InMemoryCache({
    possibleTypes: {
      Option: ['ColorOption', 'StringOption'],
    },
    typePolicies: {
      BookmarkList: {
        fields: {
          bookmarks: {
            keyArgs: ['id', 'bookmarkType'],
          },
        },
      },
      Brand: {
        keyFields: ['slug'],
      },
      Customer: {
        fields: {
          bookmarkLists: {
            keyArgs: [],
          },
        },
      },
      Query: {
        fields: {
          bookmarkLists: {
            keyArgs: ['ownerId'],
          },
          brandReviewsPage: {
            keyArgs: ['brandSlug'],
            merge: mergeReviews,
          },
          customerReviewsPage: {
            keyArgs: ['productSid'],
            merge: mergeReviews,
          },
          explorerPosts: {
            keyArgs: ['profileId'],
            merge: mergePosts,
          },
          referral: {
            read() {
              return referralVar();
            },
          },
        },
      },
    },
  }).restore({
    ...initialState,
  });

  let httpLink: ApolloLink = new HttpLink({
    credentials: 'same-origin',
    fetch: fetchWithCustomLogging,
    headers,
    uri,
  });

  if (enableAPQ && !IS_DEVELOPMENT) {
    httpLink = createPersistedQueryLink({
      sha256,
      useGETForHashedQueries: true,
    }).concat(httpLink);
  }

  const link = [linkMiddleware, httpLink];

  return new ApolloClient({
    cache,
    connectToDevTools: isBrowser() && IS_DEVELOPMENT,
    link: ApolloLink.from(link),
    resolvers,
    ssrMode: !isBrowser() && ssrMode, // Disables forceFetch on the server (so queries are only run once)
    ...apolloClientOptions,
  });
}

export default function initializeApollo(
  props: InitApolloProps
): ApolloClient<unknown> {
  if (!`${props.clientId}`) {
    throw new Error('initializeApollo failed. ClientId is required');
  }

  // Reuse client on the client-side
  const cachedClient = apolloClients.get(props.clientId);
  const apolloClientSingleton = cachedClient ? cachedClient : create(props);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (props.initialState && cachedClient) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClientSingleton.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = mergeWith(
      props.initialState,
      existingCache,
      // combine arrays using object equality (like in sets)
      (objValue: unknown, srcValue: unknown) => {
        if (isArray(objValue) && isArray(srcValue)) {
          return [
            ...(srcValue ?? []),
            ...objValue.filter(d =>
              srcValue.every((s: unknown) => !isEqual(d, s))
            ),
          ];
        }
      }
    );

    // Restore the cache with the merged data
    apolloClientSingleton.cache.restore(data);
  }

  if (!cachedClient && isBrowser()) {
    apolloClients.set(props.clientId, apolloClientSingleton);
  }

  return apolloClientSingleton;
}
