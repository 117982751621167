import AnalyticsEvent from './events';
import { formatPDPViewedData } from './utils';

import { ExperimentExposureValue } from 'lib/experiment/experimentConfig';
import { getAnonymousUserId } from 'lib/multiStorageWrapper/multiStorageWrapper';
import Logger from 'lib/utils/Logger';
import { IS_ANALYTICS_ENABLED } from 'lib/utils/tracking';

import {
  PDPProductTrackingAttributes,
  PDPVariantTrackingAttributes,
} from 'data/graphql/types';
import { AttributionInput, QueryExperimentArgs } from 'types/generated/api';
import {
  AddToCartTrackingData,
  CartViewedEventData,
  PageViewData,
  PLPFilteredEventData,
  ProductEventData,
  ProductEventDataForBundle,
  ProductShippingEstimateEventData,
  TraitData,
  WishlistPrivacyEventData,
  WishlistSharedEventData,
} from 'types/segment';

/**
 * The following are wrappers around Segment.io's analytics.js library.
 * window.analytics is being loaded in by a JS snippet.
 * To learn more aboout Segments canonical e-commerce events see here: https://segment.com/docs/spec/ecommerce/v2/
 */

export enum PLP_TYPE {
  BRAND = 'brand',
  CATEGORY = 'category',
  COLLECTION = 'collection',
  LIVE_SHOP = 'live shop',
  NEW_ARRIVALS = 'new arrivals',
  SALE = 'sale',
  SEARCH = 'search results',
  TASTEMAKERS = 'tastemakers',
}

export type PLPScrolledTrackingData = {
  count: number;
  filters?: string;
  list_id: PLP_TYPE;
  query?: string;
  slug?: string;
  sort?: string;
};

export type PLPShippingEstimateTrackingData = {
  filters?: string;
  list_id: PLP_TYPE;
  query?: string;
  slug?: string;
  sort?: string;
};

export type PLPViewedTrackingData = {
  // undefined when the PLP doesn't origin from algolia
  algolia_index_name?: string;
  list_id: PLP_TYPE;
  nbHits?: number;
  products?: ProductEventData[];
  query?: string;
  slug?: string;
};

export enum SOURCE_TYPE {
  BRAND = 'brand',
  CATEGORY = 'category',
  COLLECTION = 'collection',
  NEW_ARRIVALS = 'new arrivals',
  SALE = 'sale',
  SEARCH = 'search results',
  TASTEMAKERS = 'tastemakers',
}

export type PostModalOpenTrackData = {
  attribution: AttributionInput;
  postId: string | null;
};

export type PostModalNavigationTrackData = {
  attribution: AttributionInput;
  index: number;
  postId: string;
  totalAssets: number;
  totalItems: number;
};

export type PostModalAssetNavigationTrackData = {
  assetIndex: number;
  attribution: AttributionInput;
  postId: string;
  postIndex: number;
  totalAssets: number;
  totalItems: number;
};

export type ProductRecommendationPaginationData = {
  modelId: string;
  pagesRequested: number;
  productSids: string[];
  title: string;
};

export enum EVENT_SOURCE {
  WEB = 'web',
}

export const trackEvent = (
  eventName: AnalyticsEvent,
  eventData?: Record<string, unknown>
): void => {
  if (!window) {
    Logger.error(
      `Analytics trackEvent('${eventName}') being called without window`
    );
  }
  if (!window.analytics) {
    Logger.error(
      `Analytics trackEvent('${eventName}') being called without analytics initialized`
    );
  }
  try {
    if (IS_ANALYTICS_ENABLED) {
      window.analytics.track(eventName, eventData);
    } else {
      Logger.log(
        `trackAnalyticalEvent: ${eventName} => ${JSON.stringify(
          eventData,
          null,
          2
        )}`
      );
    }
  } catch (error) {
    Logger.error(
      `Uncaught error calling analytics trackEvent('${eventName}')`,
      error
    );
  }
};

export const trackWishlistSetPrivate = (
  wishlistPrivacyEventData: WishlistPrivacyEventData
): void =>
  trackEvent(AnalyticsEvent.WISHLIST_SET_PRIVATE, wishlistPrivacyEventData);

export const trackWishlistSetPublic = (
  wishlistPrivacyEventData: WishlistPrivacyEventData
): void =>
  trackEvent(AnalyticsEvent.WISHLIST_SET_PUBLIC, wishlistPrivacyEventData);

export const trackWishlistShared = (
  wishlistSharedEventData: WishlistSharedEventData
): void => trackEvent(AnalyticsEvent.WISHLIST_SHARED, wishlistSharedEventData);

export const trackLoginAttempt = (): void =>
  trackEvent(AnalyticsEvent.LOGIN_ATTEMPT);

export const trackLoginFail = (): void => trackEvent(AnalyticsEvent.LOGIN_FAIL);

export const trackLoginSuccess = (userId: string): void =>
  trackEvent(AnalyticsEvent.LOGIN_SUCCESS, {
    userId,
  });

export const trackSignUpAttempt = (): void =>
  trackEvent(AnalyticsEvent.SIGN_UP_ATTEMPT);

export const trackSignUpFail = (): void =>
  trackEvent(AnalyticsEvent.SIGN_UP_FAIL);

export const trackSignUpSuccess = (userId: string, source?: string): void => {
  try {
    trackEvent(AnalyticsEvent.SIGN_UP_SUCCESS, { source, userId });
  } catch (error) {
    Logger.error(
      `Uncaught error calling analytics trackEvent(SIGN_UP_SUCCESS, '${userId} - ${source}')`,
      error
    );
  }
};

export const trackSignOut = (): void => trackEvent(AnalyticsEvent.SIGN_OUT);

export const trackCartViewed = (
  cartViewedEventData: CartViewedEventData
): void => trackEvent(AnalyticsEvent.CART_VIEWED, cartViewedEventData);

type CouponEventTypes =
  | AnalyticsEvent.COUPON_APPLIED
  | AnalyticsEvent.COUPON_DENIED
  | AnalyticsEvent.COUPON_ENTERED
  | AnalyticsEvent.COUPON_REMOVED;

const trackCouponEvent = (
  eventName: CouponEventTypes,
  cartId: string,
  couponId: string,
  products?: ProductEventData[]
): void =>
  trackEvent(eventName, {
    cart_id: cartId,
    coupon_id: couponId,
    products,
  });

export const trackCouponApplied = (
  cartId: string,
  couponId: string,
  products?: ProductEventData[]
): void => {
  trackCouponEvent(AnalyticsEvent.COUPON_APPLIED, cartId, couponId, products);
};

export const trackCouponDenied = (cartId: string, couponId: string): void => {
  trackCouponEvent(AnalyticsEvent.COUPON_DENIED, cartId, couponId);
};

export const trackCouponEntered = (cartId: string, couponId: string): void => {
  trackCouponEvent(AnalyticsEvent.COUPON_ENTERED, cartId, couponId);
};

export const trackCouponRemoved = (cartId: string, couponId: string): void => {
  trackCouponEvent(AnalyticsEvent.COUPON_REMOVED, cartId, couponId);
};

export const trackPageView = (pageViewData: PageViewData = {}): void => {
  // Note: url, title, path, and referrer are collected automatically by Segment.
  try {
    const { category, properties } = pageViewData;

    // These properties are normally provided by Segment but we found that they were often incorrect (maybe becase Segment uses the canonical url).
    const mergedProperties = {
      path: document.location.pathname,
      referrer: document.referrer,
      search: document.location.search,
      title: document.title,
      url: document.location.href,
      ...properties,
    };

    window.analytics.page(category, mergedProperties.title, mergedProperties);
  } catch (error) {
    Logger.error(
      `Uncaught error calling analytics page('${JSON.stringify(
        pageViewData
      )}')`,
      error
    );
  }
};

export const trackProductRecommendationPagination = (
  recommendationPaginationData: ProductRecommendationPaginationData
): void => {
  trackEvent(
    AnalyticsEvent.PRODUCT_RECOMMENDATION_SEE_MORE,
    recommendationPaginationData
  );
};

export const trackProductsSearched = (query: string): void =>
  trackEvent(AnalyticsEvent.PRODUCTS_SEARCHED, { query });

export const trackPlpPageScrolled = (
  trackingData: PLPScrolledTrackingData
): void => trackEvent(AnalyticsEvent.PRODUCT_LIST_SCROLLED, trackingData);

/**
 * Fired when a user adjusts filtered facets and price range
 */
export const trackProductListFiltered = (
  trackingData: PLPFilteredEventData
): void => trackEvent(AnalyticsEvent.PRODUCT_LIST_FILTERED, { trackingData });

export const trackPdpShippingEstimateDisplayed = (
  trackingData: ProductShippingEstimateEventData = {}
): void => {
  try {
    trackEvent(AnalyticsEvent.PRODUCT_DETAIL_SHIPPING_ESTIMATE_VISIBLE, {
      trackingData,
    });
  } catch (error) {
    Logger.error(
      `Uncaught error calling analytics trackPdpShippingEstimateDisplayed('${JSON.stringify(
        trackingData
      )}')`,
      error
    );
  }
};

/**
 * Fired when email sign up modal is opened
 */
export const trackNewsletterSignupViewed = (): void =>
  trackEvent(AnalyticsEvent.NEWSLETTER_SIGNUP_VIEWED);

/**
 * Fired when user signs up for newsletter
 */
export const trackNewsletterSignupSuccess = (trait: TraitData): void => {
  trackEvent(AnalyticsEvent.NEWSLETTER_SIGNUP_SUCCESS);
  try {
    const anonymousId = getAnonymousUserId();
    if (anonymousId) {
      // identify(traits?: Object, options?: SegmentOpts, callback?: () => void): void;
      window.analytics.identify(
        {
          ...trait,
          eventMethod: 'trackNewsletterSignupSuccess',
          eventSource: EVENT_SOURCE.WEB,
        },
        { anonymousId } // prevents the anonymousId from being reset by Segment
      );
    }
  } catch (error) {
    Logger.error(
      `Uncaught error calling analytics identify('${JSON.stringify(trait)}')`,
      error
    );
  }
};

/**
 * Fired when user is shown the AppInstall banner
 */
export const trackAppInstallBannerShown = (): void => {
  trackEvent(AnalyticsEvent.APP_INSTALL_BANNER_SHOWN);
};

/**
 * Fired when the user dismisses the AppInstall banner
 */
export const trackAppInstallDismissBanner = (): void => {
  trackEvent(AnalyticsEvent.APP_INSTALL_DISMISS_BANNER);
};

/**
 * Fired when user clicks to install the app in AppInstall banner
 */
export const trackAppInstallFromBanner = (): void => {
  trackEvent(AnalyticsEvent.APP_INSTALL_FROM_BANNER);
};

/**
 * Fires on clicking the recommended post
 */
export const trackRecommendedPostModalOpened = (
  data: PostModalOpenTrackData
): void => {
  trackEvent(AnalyticsEvent.RELATED_POST_MODAL_OPENED, data);
};

export const trackHorizontalNavLivestreamsClick = (): void => {
  trackEvent(AnalyticsEvent.HORIZONTAL_NAV_LIVESTREAM_CLICKED);
};

export const trackHorizontalNavNewArrivalsClick = (): void => {
  trackEvent(AnalyticsEvent.HORIZONTAL_NAV_NEW_ARRIVALS_CLICKED);
};

export const trackHorizontalNavWomenClick = (): void => {
  trackEvent(AnalyticsEvent.HORIZONTAL_NAV_WOMEN_CLICKED);
};

export const trackHorizontalNavDressesClick = (): void => {
  trackEvent(AnalyticsEvent.HORIZONTAL_NAV_DRESSES_CLICKED);
};

/**
 * Fires when navigating the recommended posts in the modal
 */
export const trackPostModalNavigation = (
  data: PostModalNavigationTrackData
): void => {
  trackEvent(AnalyticsEvent.RELATED_POST_MODAL_NAVIGATED, data);
};

/**
 * Fires when navigating the recommended posts in the modal
 */
export const trackPostModalAssetChange = (
  data: PostModalAssetNavigationTrackData
): void => {
  trackEvent(AnalyticsEvent.RELATED_POST_MODAL_ASSET_CHANGED, data);
};

/**
 * Fired when user clicks to install the app in AppInstall interstitial
 */
export const trackAppInstallOptedIn = (): void => {
  trackEvent(AnalyticsEvent.APP_INSTALL_OPTED_IN);
};

/**
 * Fired when a link to a product is clicked and the user is directed to a PDP.
 */
export const trackProductClicked = (product: ProductEventData): void =>
  trackEvent(AnalyticsEvent.PRODUCT_CLICKED, product);

export const trackProductViewed = (
  product: PDPProductTrackingAttributes,
  variant: PDPVariantTrackingAttributes,
  url: string
): void => {
  const viewedData = formatPDPViewedData(product, variant, url);
  const eventData = {
    ...viewedData,
  };
  trackEvent(AnalyticsEvent.PRODUCT_VIEWED, eventData);
};

export const trackProductVariantViewed = (
  product: PDPProductTrackingAttributes,
  variant: PDPVariantTrackingAttributes,
  url: string
): void => {
  const viewedData = formatPDPViewedData(product, variant, url);
  const eventData = {
    ...viewedData,
  };
  trackEvent(AnalyticsEvent.PRODUCT_VARIANT_VIEWED, eventData);
};

export const trackProductAddedToCart = (
  trackingData: AddToCartTrackingData
): void => {
  trackEvent(AnalyticsEvent.PRODUCT_ADDED, trackingData);
};

export const trackProductRemovedFromCart = (
  data: ProductEventData | ProductEventDataForBundle
): void => trackEvent(AnalyticsEvent.PRODUCT_REMOVED, data);

export const trackProductAddedToCartFromSaved = (
  trackingData: AddToCartTrackingData
): void => trackEvent(AnalyticsEvent.PRODUCT_ADDED_FROM_SAVED, trackingData);

export const trackProductSaved = (
  product: ProductEventData | ProductEventDataForBundle
): void => trackEvent(AnalyticsEvent.PRODUCT_SAVED, product);

type ProductVideoViewedData = ProductEventData & {
  nonInteraction: number;
};

export const trackProductVideoViewed = (
  productData: ProductVideoViewedData
): void => {
  try {
    window.analytics.track('Product Video Viewed', productData);
  } catch (error) {
    Logger.error(
      `Uncaught error calling analytics trackEvent(Product Video Viewed, '${JSON.stringify(
        productData
      )}')`,
      error
    );
  }
};

export enum ContentPageType {
  AndroidComingSoon = 'Android Coming Soon',
  CategoryLanding = 'Category Landing',
  DynamicLanding = 'Dynamic Landing',
  Editorial = 'Editorial',
  Home = 'Home',
  IosOnlyFeature = 'iOS Only Feature',
}

type ReviewProperties = {
  averageScore: number;
  brand?: string;
  productId?: string;
  totalReviews: number;
};
/**
 * Sends a Segment Event showing that the **review section** has been reviewed
 * which shows the summary. Contrary to this function's name, it is not for
 * individual reviews, but for the section of reviews.
 */
export const trackReviewViewed = ({
  averageScore,
  brand,
  productId,
  totalReviews,
}: ReviewProperties): void => {
  trackEvent(AnalyticsEvent.REVIEW_VIEWED, {
    averageScore,
    brand,
    productId,
    totalReviews,
  });
};

export const trackExperimentExposures = (
  exposures: ExperimentExposureValue[],
  queries?: QueryExperimentArgs
): void => {
  trackEvent(AnalyticsEvent.EXPERIMENT_EXPOSURES, {
    experiments: exposures,
    queries,
  });
};

export const resetAnalytics = (): void => {
  try {
    window.heap.resetIdentity();
  } catch (error) {
    Logger.error('Uncaught error calling analytics resetIdentity()', error);
  }
};

export const trackFollow = (profileId: string): void =>
  trackEvent(AnalyticsEvent.FOLLOW, {
    source: 'profile',
    target_profile_id: profileId,
  });

export const trackUnfollow = (profileId: string): void =>
  trackEvent(AnalyticsEvent.UNFOLLOW, {
    source: 'profile',
    target_profile_id: profileId,
  });

export const trackAlgoliaSearch = (params: {
  query: string;
  responseTimeMs: number;
  resultCount: number;
}): void => {
  trackEvent(AnalyticsEvent.ALGOLIA_SEARCH, params);
};

export const trackLiveLandingCategoryClicked = (slug: string): void =>
  trackEvent(AnalyticsEvent.LIVE_LANDING_CATEGORY_CLICKED, { slug });

export const trackLiveLandingProductThumbnailClicked = (
  postId: string,
  productId: string
): void =>
  trackEvent(AnalyticsEvent.LIVE_LANDING_PRODUCT_THUMBNAIL_CLICKED, {
    postId,
    productId,
  });

export const trackPDPAffirmButtonClicked = (): void =>
  trackEvent(AnalyticsEvent.AFFIRM_PDP_BUTTON_CLICKED);
