import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';

import { LoginLink, SignupLink } from 'lib/links';

import styles from './OptionsForm.module.scss';

export const OPTIONS_HEADER_TEXT = 'You have great taste!';

type OptionsFormProps = {
  closeAuthModal(): void;
  guestCheckoutWebUrl: string;
  onCheckoutAsGuestButtonClick(): void;
};

const OptionsForm = (props: OptionsFormProps) => {
  const { closeAuthModal, guestCheckoutWebUrl, onCheckoutAsGuestButtonClick } =
    props;

  return (
    <div className={styles.root}>
      <div className={styles.subHeader}>
        Please log in or sign up for faster checkout.
      </div>
      <LoginLink redirectUrl={guestCheckoutWebUrl}>
        <PrimaryButton className={styles.authButton} onClick={closeAuthModal}>
          Log in
        </PrimaryButton>
      </LoginLink>

      <SignupLink redirectUrl={guestCheckoutWebUrl}>
        <PrimaryButton className={styles.authButton} onClick={closeAuthModal}>
          Create an Account
        </PrimaryButton>
      </SignupLink>

      <div className={styles.dividerContainer}>
        <div className={styles.line} />
        <div className={styles.or}>OR</div>
        <div className={styles.line} />
      </div>
      <SecondaryButton
        className={styles.checkoutButton}
        onClick={onCheckoutAsGuestButtonClick}
      >
        Continue as a Guest
      </SecondaryButton>
    </div>
  );
};

export default OptionsForm;
