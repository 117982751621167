import { MutationOptions } from '@apollo/client';

import { CHECKOUT_ATTRIBUTES_UPDATE_V2 } from '../mutations.shopify';
import { CustomAttribute } from '../types.shopify';

export const CHECKOUT_SOURCE_VALUE = 'web';

export enum CheckoutAttributesSupportedKeys {
  CART_TOKEN = 'cart_token',
  CHECKOUT_SOURCE = '_checkout_source',
  GIFT_MESSAGE = 'Gift Message',
  YOTPO_ACCOUNT_ID = '_yotpo_account_id',
}

export const CHECKOUT_REQUIRED_ATTRIBUTES = [
  {
    key: CheckoutAttributesSupportedKeys.CHECKOUT_SOURCE,
    value: CHECKOUT_SOURCE_VALUE,
  },
];

export class CheckoutAttributesMutation {
  static getUpdateMutation(
    checkoutId: string,
    customAttributes: CustomAttribute[]
  ): MutationOptions {
    return {
      mutation: CHECKOUT_ATTRIBUTES_UPDATE_V2,
      variables: {
        checkoutId,
        input: {
          customAttributes,
        },
      },
    };
  }
}
