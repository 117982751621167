import { ApolloClient, ApolloProvider } from '@apollo/client';
import { RouterContext } from 'next/dist/shared/lib/router-context';
import { NextRouter } from 'next/router';
import { ComponentType, createContext } from 'react';
import { InstantSearchProps } from 'react-instantsearch-dom';
import { findResultsState } from 'react-instantsearch-dom/server';

import algoliaClient from './client';
import { getDefaultSortOption } from './utils';

import { SearchStateProps } from './types';

import Logger from '../utils/Logger';

type AlgoliaSSRProviderProps = Pick<
  InstantSearchProps,
  'widgetsCollector' | 'searchClient' | 'indexName'
> & {
  currentUrl?: string;
  searchStateUpdater?: (searchState: SearchStateProps) => void;
};

export type AlgoliaInitialState = {
  resultsState: unknown;
  searchState: unknown;
};

export const AlgoliaSSRContext = createContext<
  AlgoliaSSRProviderProps | undefined
>(undefined);

function withAlgoliaSSRProvider<TProps>(
  WrappedComponent: ComponentType<TProps>,
  apolloClient: ApolloClient<unknown>,
  props: TProps,
  currentUrl?: string,
  searchStateUpdater?: (searchState: SearchStateProps) => void
) {
  const AlgoliaSSRProviderComponent = (
    algoliaProps: AlgoliaSSRProviderProps
  ) => {
    // Need to make our own provider for NextRouter or else useRouter returns undefined.
    // Please add fields to the mock as needed.
    return (
      <RouterContext.Provider
        value={
          {
            asPath: currentUrl ?? '',
          } as NextRouter
        }
      >
        <ApolloProvider client={apolloClient}>
          <AlgoliaSSRContext.Provider
            value={{
              ...algoliaProps,
              currentUrl,
              searchStateUpdater,
            }}
          >
            <WrappedComponent {...props} />
          </AlgoliaSSRContext.Provider>
        </ApolloProvider>
      </RouterContext.Provider>
    );
  };
  AlgoliaSSRProviderComponent.displayName = 'AlgoliaSSRProviderComponent';
  return AlgoliaSSRProviderComponent;
}

const ALGOLIA_STATE_PROP_NAME = '_algolia_state';

/**
 * Main entry point for enabling SSR for algolia search.
 *
 * See page/brand/[brand_slug].tsx for an example. If Algolia search SSR is setup correctly,
 * the page should be populated with search results and no algolia search query will be made
 * from client on page load.
 *
 * @param WrappedComponent Root component used to generate the algolia query
 * It should contain exactly one withAlgolia wrapped component with enableForSSR set to true
 * @param apolloClient Apollo client to use for rendering WrappedComponent
 * @param props props to WrappedComponent
 * @param currentUrl if trackSearchStateInUrl is true in withAlgolia, currentUrl needs to be
 * passed in to create the corresponding searchState.
 */
export async function getAlgoliaServerState<TProps>(
  WrappedComponent: ComponentType<TProps>,
  apolloClient: ApolloClient<unknown>,
  props: TProps,
  currentUrl?: string
): Promise<AlgoliaInitialState> {
  let searchState: SearchStateProps | undefined;
  try {
    const resultsState = await findResultsState(
      withAlgoliaSSRProvider(
        WrappedComponent,
        apolloClient,
        props,
        currentUrl,
        updatedSearchState => {
          searchState = updatedSearchState;
        }
      ),
      {
        indexName: getDefaultSortOption(currentUrl).value,
        searchClient: algoliaClient,
      }
    );

    return {
      resultsState,
      searchState,
    };
  } catch (e) {
    Logger.log('Error getAlgoliaServerState' + e);
  }
  return {
    resultsState: null,
    searchState: null,
  };
}

export function addAlgoliaInitialStateToProps(
  algoliaState: AlgoliaInitialState,
  props: { [key: string]: unknown } = {}
) {
  props[ALGOLIA_STATE_PROP_NAME] = JSON.stringify(algoliaState);

  return props;
}

export function getAlgoliaInitialState(props: {
  [key: string]: unknown;
}): AlgoliaInitialState | undefined {
  if (!props[ALGOLIA_STATE_PROP_NAME]) {
    return undefined;
  }
  return JSON.parse(props[ALGOLIA_STATE_PROP_NAME] as string);
}
