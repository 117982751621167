import {
  ExperimentValueResolver,
  ExperimentValues,
} from './experimentValueResolver';
import { ExperimentValueResolverProvider } from './index';
import isTruthy from './isTruthy';

export class BooleanExperimentValueResolver extends ExperimentValueResolver<boolean> {
  parseScalarValue(rawValue: string): boolean {
    return isTruthy(rawValue);
  }

  static provider(
    experimentName: string,
    defaultValue = false
  ): ExperimentValueResolverProvider {
    return (rawValues?: ExperimentValues) =>
      new BooleanExperimentValueResolver(
        defaultValue,
        experimentName,
        rawValues
      );
  }
}
