const CACHE_TIERS = {
  FIVE_MINUTES: 's-maxage=300, max-age=150',
  NO_CACHE: 'no-store, must-revalidate',
  ONE_DAY: 's-maxage=86400, max-age=43200',
  ONE_HOUR: 's-maxage=3600, max-age=1800',
};

const AUTH_REDIRECT_QUERY_PARAM = 'redirectUrl';
const LOGIN_PATH = '/login';
const SIGNUP_PATH = '/signup';

const pageRoutes = {
  about: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: () => '/about',
    internalUrl: () => '/about',
  },
  account: {
    confirm: {
      displayUrl: () => '/account/confirm',
      internalUrl: () => '/account/confirm',
    },
    edit: {
      displayUrl: () => '/account/edit',
      internalUrl: () => '/account/edit',
    },
    profile: {
      landing: {
        displayUrl: () => '/account',
        internalUrl: () => '/account',
      },
      tab: {
        displayUrl: tab => `/account/${tab}`,
        internalUrl: tab => `/account/${tab}`,
      },
    },
  },
  androidComingSoon: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: () => '/android-coming-soon',
    internalUrl: () => '/android-coming-soon',
  },
  auth: {
    forgotPassword: {
      displayUrl: () => '/forgot-password',
      internalUrl: () => '/forgot-password',
    },
    forgotPasswordConfirm: {
      displayUrl: () => '/forgot-password/confirm',
      internalUrl: () => '/forgot-password/confirm',
    },
    login: {
      displayUrl: (options = {}) => {
        const { redirectUrl } = options;
        let path = LOGIN_PATH;

        if (redirectUrl) {
          path += `?${AUTH_REDIRECT_QUERY_PARAM}=${redirectUrl}`;
        }

        return path;
      },
      internalUrl: (options = {}) => {
        const { redirectUrl } = options;
        let path = LOGIN_PATH;

        if (redirectUrl) {
          path += `?${AUTH_REDIRECT_QUERY_PARAM}=${redirectUrl}`;
        }

        return path;
      },
    },
    signUp: {
      displayUrl: (options = {}) => {
        const { redirectUrl } = options;
        let path = SIGNUP_PATH;

        if (redirectUrl) {
          path += `?${AUTH_REDIRECT_QUERY_PARAM}=${redirectUrl}`;
        }

        return path;
      },
      internalUrl: (options = {}) => {
        const { redirectUrl } = options;
        let path = SIGNUP_PATH;

        if (redirectUrl) {
          path += `?${AUTH_REDIRECT_QUERY_PARAM}=${redirectUrl}`;
        }

        return path;
      },
    },
  },
  bookmarks: {
    home: {
      displayUrl: collectionUserId => `/u/${collectionUserId}/collections`,
      internalUrl: collectionUserId => `/u/${collectionUserId}/collections`,
    },
    plp: {
      displayUrl: (bookmarkListSlug, collectionUserId) =>
        `/u/${collectionUserId}/collections/${bookmarkListSlug}`,
      internalUrl: (bookmarkListSlug, collectionUserId) =>
        `/u/${collectionUserId}/collections/${bookmarkListSlug}`,
    },
  },
  brand: {
    collection: {
      displayUrl: (brandSlug, collectionSlug) =>
        `/brand/${brandSlug}/collection/${collectionSlug}`,
      internalUrl: (brandSlug, collectionSlug) =>
        `/brand/${brandSlug}/collection/${collectionSlug}`,
    },
    detail: {
      cacheControl: CACHE_TIERS.ONE_HOUR,
      landing: {
        displayUrl: (brandSlug, queryParams) => ({
          pathname: `/brand/${brandSlug}`,
          query: queryParams,
        }),
        internalUrl: (brandSlug, queryParams) => ({
          pathname: `/brand/${brandSlug}`,
          query: {
            brandSlug,
            ...queryParams,
          },
        }),
      },
      tab: {
        displayUrl: (brandSlug, queryParams, tab) => ({
          pathname: `/brand/${brandSlug}/${tab}`,
          query: queryParams,
        }),
        internalUrl: (brandSlug, queryParams, tab) => ({
          pathname: `/brand/${brandSlug}/${tab}`,
          query: {
            brandSlug,
            ...queryParams,
          },
        }),
      },
    },
    list: {
      cacheControl: CACHE_TIERS.ONE_HOUR,
      displayUrl: () => '/brands',
      internalUrl: () => '/brands',
    },
  },
  cart: {
    displayUrl: () => '/cart',
    internalUrl: () => '/cart',
  },
  category: {
    landing: {
      cacheControl: CACHE_TIERS.ONE_HOUR,
      displayUrl: (categorySlug, queryParams) => ({
        pathname: `/cl/${categorySlug}`,
        query: queryParams,
      }),
      internalUrl: (categorySlug, queryParams) => ({
        pathname: `/cl/${categorySlug}`,
        query: queryParams,
      }),
    },
    plp: {
      cacheControl: CACHE_TIERS.ONE_HOUR,
      displayUrl: (categorySlug, queryParams) => ({
        pathname: `/c/${categorySlug}`,
        query: queryParams,
      }),
      internalUrl: (categorySlug, queryParams) => ({
        pathname: `/c/${categorySlug}`,
        query: queryParams,
      }),
    },
  },
  collection: {
    cacheControl: CACHE_TIERS.ONE_HOUR,
    displayUrl: (collectionSlug, queryParams) => ({
      pathname: `/collection/${collectionSlug}`,
      query: queryParams,
    }),
    internalUrl: (collectionSlug, queryParams) => ({
      pathname: `/collection/${collectionSlug}`,
      query: queryParams,
    }),
  },
  communityGuidelines: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: () => '/community-guidelines',
    internalUrl: () => '/community-guidelines',
  },
  dmca: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: () => '/forms/dmca',
    internalUrl: () => '/forms/dmca',
  },
  dynamicPage: {
    cacheControl: CACHE_TIERS.ONE_HOUR,
    displayUrl: pageSlug => `/page/${pageSlug}`,
    internalUrl: pageSlug => `/page/${pageSlug}`,
  },
  editorial: {
    cacheControl: CACHE_TIERS.ONE_HOUR,
    displayUrl: ({ publishDate, slug }) =>
      publishDate ? `/editorial/${publishDate}/${slug}` : `/editorial/${slug}`,
    internalUrl: slug => `/editorial/${slug}`,
  },
  explore: {
    post: {
      cacheControl: CACHE_TIERS.ONE_DAY,
      displayUrl: ({ postId }) => `/explore/post/${postId}`,
      internalUrl: ({ postId }) => `/explore/post/${postId}`,
    },
  },
  home: {
    cacheControl: CACHE_TIERS.ONE_HOUR,
    displayUrl: () => '/',
    internalUrl: () => '/',
  },
  iosOnlyFeature: {
    displayUrl: () => `/ios-only-feature`,
    internalUrl: () => `/ios-only-feature`,
  },
  liveShop: {
    cacheControl: CACHE_TIERS.FIVE_MINUTES,
    displayUrl: ({ inviteCode }) => `/live-shop/${inviteCode}`,
    internalUrl: ({ inviteCode }) => `/live-shop/${inviteCode}`,
  },
  liveShopLanding: {
    cacheControl: CACHE_TIERS.FIVE_MINUTES,
    displayUrl: () => `/live-shop`,
    internalUrl: () => `/live-shop`,
  },
  media: {
    displayUrl: () => '/media',
    internalUrl: () => '/media',
  },
  newArrivals: {
    displayUrl: () => '/new-arrivals',
    internalUrl: () => '/new-arrivals',
  },
  newArrivalsByDepartment: {
    displayUrl: ({ department }) => `/new-arrivals/${department}`,
    internalUrl: ({ department }) => `/new-arrivals/${department}`,
  },
  order: {
    detail: {
      displayUrl: orderId => `/orders/${orderId}`,
      internalUrl: orderId => `/orders/${orderId}`,
    },
    list: {
      displayUrl: () => '/orders',
      internalUrl: () => '/orders',
    },
    merchantInfo: {
      displayUrl: orderId => `/orders/${orderId}/merchant-info`,
      internalUrl: orderId => `/orders/${orderId}/merchant-info`,
    },
  },
  postPage: {
    cacheControl: CACHE_TIERS.ONE_HOUR,
    displayUrl: postSlug => `/posts/${postSlug}`,
    internalUrl: postSlug => `/posts/${postSlug}`,
  },
  privacyPolicy: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: () => '/privacy-policy',
    internalUrl: () => '/privacy-policy',
  },
  productDetail: {
    cacheControl: CACHE_TIERS.ONE_HOUR,
    displayUrl: ({
      brandSlug,
      familySlug,
      productSid,
      productSlug,
      queryStringParams = {},
    } = {}) => ({
      pathname: `/${brandSlug}/${familySlug}/${productSlug}/p${productSid}`,
      query: {
        ...queryStringParams,
      },
    }),
    internalUrl: ({
      brandSlug,
      familySlug,
      productSid,
      productSlug,
      queryStringParams = {},
    } = {}) => ({
      pathname: `/${brandSlug}/${familySlug}/${productSlug}/p${productSid}`,
      query: {
        ...queryStringParams,
        brandSlug,
        familySlug,
        productSid,
        productSlug,
      },
    }),
  },
  productDetailWithP: {
    cacheControl: CACHE_TIERS.ONE_HOUR,
    displayUrl: ({
      brandSlug,
      productSid,
      productSlug,
      queryStringParams = {},
    } = {}) => ({
      pathname: `/p/${brandSlug}/${productSlug}/p${productSid}`,
      query: {
        ...queryStringParams,
      },
    }),
    internalUrl: ({
      brandSlug,
      productSid,
      productSlug,
      queryStringParams = {},
    } = {}) => ({
      pathname: `/p/${brandSlug}/${productSlug}/p${productSid}`,
      query: {
        ...queryStringParams,
      },
    }),
  },
  profile: {
    // TODO(ningchong): turn on cache when release to public. https://verishop.atlassian.net/browse/SPE-2343
    // cacheControl: CACHE_TIERS.ONE_HOUR,
    collection: {
      displayUrl: (collectionUsername, collectionSlug) =>
        `/profile/${collectionUsername}/collection/${collectionSlug}`,
      internalUrl: (collectionUsername, collectionSlug) =>
        `/profile/${collectionUsername}/collection/${collectionSlug}`,
    },
    landing: {
      displayUrl: ({ username }) => `/profile/${username}`,
      internalUrl: ({ username }) => `/profile/${username}`,
    },
    tab: {
      displayUrl: ({ tab, username }) => `/profile/${username}/${tab}`,
      internalUrl: ({ tab, username }) => `/profile/${username}/${tab}`,
    },
  },
  reportBug: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: () => '/report-bug',
    internalUrl: () => '/report-bug',
  },
  returnPolicy: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: () => '/return-policy',
    internalUrl: () => '/return-policy',
  },
  rewards: {
    displayUrl: () => '/rewards',
    internalUrl: () => '/rewards',
  },
  sale: {
    cacheControl: 's-maxage=600, max-age=300',
    displayUrl: () => '/sale',
    internalUrl: () => '/sale',
  },
  search: {
    cacheControl: CACHE_TIERS.ONE_HOUR,
    displayUrl: searchTerm => {
      const query = searchTerm
        ? {
            q: searchTerm,
          }
        : null;
      return {
        pathname: '/search',
        query,
      };
    },
    internalUrl: searchTerm => {
      const query = searchTerm
        ? {
            q: searchTerm,
          }
        : null;
      return {
        pathname: '/search',
        query,
      };
    },
  },
  shopParty: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: shopPartyId => `/shop-party/${shopPartyId}`,
    internalUrl: shopPartyId => ({
      pathname: '/home',
      query: { shopPartyId },
    }),
  },
  tastemakers: {
    detail: {
      cacheControl: CACHE_TIERS.ONE_HOUR,
      displayUrl: slug =>
        slug !== 'inspo' ? `/tastemakers/${slug}` : '/inspo',
      internalUrl: slug => `/tastemakers/${slug}`,
    },
    landing: {
      cacheControl: CACHE_TIERS.ONE_HOUR,
      displayUrl: () => '/tastemakers',
      internalUrl: () => '/tastemakers',
    },
  },
  terms: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: () => '/terms',
    internalUrl: () => '/terms',
  },
  trustCorpCard: {
    cacheControl: CACHE_TIERS.ONE_DAY,
    displayUrl: () => '/TrustCorpCard',
    internalUrl: () => '/TrustCorpCard',
  },
};

const apiUrls = {};

module.exports = {
  AUTH_REDIRECT_QUERY_PARAM,
  CACHE_TIERS,
  LOGIN_PATH,
  SIGNUP_PATH,
  apiUrls,
  pageRoutes,
};
