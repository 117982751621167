const fragments = {
  bookmarkListsFields: `
    fragment bookmarkListsFields on Customer {
      bookmarkLists {
        bookmarks {
          edges {
            node {
              id
              product {
                variants {
                  modelImages {
                    url
                  }
                }
              }
            }
          }
        }
        id
        name
      }
    }
  `,
  bookmarksFields: `
    fragment bookmarksFields on Customer {
      bookmarks {
        edges {
          node {
            id
            product {
              brand
              options {
                ... on ColorOption {
                  name
                  type
                  values {
                    brandColor
                    colorFamily
                    label
                    swatchColorCode
                    value
                  }
                }
                ... on StringOption {
                  name
                  type
                  values {
                    label
                    value
                  }
                }
              }
              sid
              title
              variants {
                compareAtPrice
                fit
                inStock
                materials
                modelImages {
                 url
                }
                price
                selectedOptions {
                 name
                 value
                }
                sid
                sku
                weight
                weightUnit
              }
              sid
            }
            productSid
          }
        }
      }
    }
  `,
  checkoutFields: `
    fragment checkoutFields on Checkout {
      appliedGiftCards {
        id
        amountUsedV2 {
          ... on MoneyV2 {
            amount
          }
        }
      }
      completedAt
      currencyCode
      customAttributes {
        key
        value
      }
      discountApplications(first: 100) {
        edges {
          node {
            ... on DiscountCodeApplication {
              applicable
              code
              value {
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
      }
      id
      lineItems(first: 100) {
        edges {
          cursor
          node {
            id
            customAttributes {
              key
              value
            }
            discountAllocations {
              allocatedAmount {
                amount
                currencyCode
              }
              discountApplication {
                ... on ScriptDiscountApplication {
                  title
                  value
                }
              }
            }
            quantity
            title
            variant {
              id
              compareAtPrice {
                amount
              }
              product {
                id
                tags
                vendor
              }
              price {
                amount
              }
              requiresShipping
              selectedOptions {
                name
                value
              }
              sku
              image {
                altText
                originalSrc
              }
            }
          }
        }
      }
      lineItemsSubtotalPrice {
        amount
        currencyCode
      }
      orderStatusUrl
      subtotalPriceV2 {
        amount
        currencyCode
      }
      totalPrice {
        amount
      }
      totalTax {
        amount
      }
      webUrl
    }
  `,
  customerFields: `
    fragment customerFields on Customer {
      createdAt
      email
      firstName
      id
      lastName
      multipassUrl
      shopifyCheckoutId
      yotpoId
    }
  `,
  productByHandleFields: `
    fragment productByHandleFields on Product {
      brand
      brandSlug
      description
      family
      familySlug
      sid
      slug
      title
      variants {
        compareAtPrice
        currencyCode
        description
        finalSale
        fit
        fullSid
        galleryImages {
          url
        }
        inStock
        materials
        modelImages {
          url
        }
        price
        productImages {
          url
        }
        selectedOptions {
          name
          value
        }
        shoppingStatus
        sid
        sku
        hazmat
        weight
        weightUnit
      }
      options {
        ... on ColorOption {
          label
          name
          type
          values {
            brandColor
            colorFamily
            label
            swatchColorCode
            value
          }
        }
        ... on StringOption {
          label
          name
          type
          values {
            label
            value
          }
        }
      }
      slug
    }
  `,
  productDetailPageFields: `
    fragment productDetailPageFields on Product {
      brand
      brandSlug
      description
      descriptionLegacy
      family
      familySlug
      sid
      slug
      title
      variants {
        compareAtPrice
        currencyCode
        description
        editorsNote
        editorsNoteAuthor
        filterables {
         responsible
        }
        finalSale
        fit
        fullSid
        galleryAssets {
          url
          type
          ... on Image {
            ecommModel {
              height
            }
            ecommModelSize {
              name
              value
            }
          }
          ... on Video {
            cloudinaryPublicId
          }
        }
        galleryImages {
          url
        }
        howToVideos {
          url
          type
        }
        ingredients
        isGiftCard
        isProductBundle
        materialList
        materials
        modelImages {
          url
        }
        price
        productBundleItems {
          quantity
          sku
        }
        productImages {
          url
        }
        requiresShipping
        selectedOptions {
          name
          value
        }
        specifications {
          label
          value
        }
        shoppingStatus
        sid
        sku
        termsAndConditions
        title
        hazmat
        weight
        weightUnit
      }
      options {
        ... on ColorOption {
          label
          name
          type
          values {
            brandColor
            colorFamily
            label
            swatchColorCode
            value
          }
        }
        ... on StringOption {
          label
          name
          type
          values {
            label
            value
          }
        }
      }
      slug
    }`,
  variantByPlpGroupFields: `
    fragment variantByPlpGroupFields on ProductVariant {
      compareAtPrice
      currencyCode
      description
      finalSale
      fit
      fullSid
      galleryImages {
        url
      }
      inStock
      materials
      modelImages {
        url
      }
      price
      productImages {
        url
      }
      selectedOptions {
        name
        value
      }
      shoppingStatus
      sid
      sku
      hazmat
    }
  `,
};

export default fragments;
